import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { getComparator, stableSort } from '../../modules/sort';

function StockReportView({ merchandiseMapping, stockData }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const yearMonth = stockData.id
  const currentData = Object.keys(stockData.merchandises).reduce((acc, cur) => {
    acc.push({ id: cur, ...stockData.merchandises[cur] })
    return acc
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const formatData = (data) => {
    const newData = { ...data }
    newData.name = merchandiseMapping[newData.id].name
    newData.orderUnit = merchandiseMapping[newData.id].orderUnit
    newData.biAmount = newData.bi.amount
    newData.biUnitPrice = newData.bi.unitPrice
    newData.biPrice = newData.biAmount * newData.biUnitPrice

    newData.pimAmount = newData.pim.amount
    newData.pimUnitPrice = newData.pim.unitPrice
    newData.pimPrice = newData.pimAmount * newData.pimUnitPrice

    newData.pimPMAmount = newData.pimPM.amount
    newData.pimPMUnitPrice = newData.pimPM.unitPrice
    newData.pimPMPrice = Math.abs(newData.pimPMAmount * newData.pimPMUnitPrice)

    newData.dimAmount = newData.dim.amount
    newData.dimUnitPrice = newData.dim.unitPrice
    newData.dimPrice = newData.dimAmount * newData.dimUnitPrice

    newData.dimPMAmount = newData.dimPM.amount
    newData.dimPMUnitPrice = newData.dimPM.unitPrice
    newData.dimPMPrice = newData.dimPMAmount * newData.dimPMUnitPrice

    newData.cosAmount = newData.cos.amount
    newData.cosUnitPrice = newData.cos.unitPrice
    newData.cosPrice = newData.cosAmount * newData.cosUnitPrice

    newData.eiAmount = newData.ei.amount
    newData.eiUnitPrice = newData.ei.unitPrice
    newData.eiPrice = newData.eiAmount * newData.eiUnitPrice
    return newData
  }

  function createdGroupedHeader() {
    const headerCells = [
      { field: 'bi', span: 3, bgColor: '#eee' },
      { field: 'pim', span: 3 },
      { field: 'pimPM', span: 3, bgColor: '#eee' },
      { field: 'dim', span: 3 },
      { field: 'dimPM', span: 3, bgColor: '#eee' },
      { field: 'cos', span: 3 },
      { field: 'ei', span: 3, bgColor: '#eee' },
    ].map(c => { c.text = formatMessage({ id: `report.stock.detail.${c.field}.root` }); return c })

    return (
      <TableRow>
        <TableCell colSpan={2} />
        {headerCells.map(cell => {
          return <TableCell sx={{ backgroundColor: cell.bgColor }} align="center" colSpan={cell.span} key={cell.field}>{cell.text}</TableCell>
        })}
      </TableRow>
    );
  }

  function getTotalValue(field) {
    let newValue = 0
    for (const c of currentData) {
      newValue += Math.abs(c[field].amount) * c[field].unitPrice
    }

    return newValue
  }

  async function exportData() {
    const rows = [',,,' + ['bi', 'pim', 'pimPM', 'dim', 'dimPM', 'cos', 'ei'].map(i => `${formatMessage({ id: `report.stock.detail.${i}.root` })},,`).join(',')]
    rows.push(headerCells.map(header => header.text).join(','))
    for (const m of currentData.map(m => formatData(m))) {
      rows.push([
        m.name,
        m.orderUnit,
        m['biAmount'],
        m['biUnitPrice'],
        m['biPrice'],

        m['pimAmount'],
        m['pimUnitPrice'],
        m['pimPrice'],

        m['pimPMAmount'],
        m['pimPMUnitPrice'],
        m['pimPMPrice'],

        m['dimAmount'],
        m['dimUnitPrice'],
        m['dimPrice'],

        m['dimPMAmount'],
        m['dimPMUnitPrice'],
        m['dimPMPrice'],

        m['cosAmount'],
        m['cosUnitPrice'],
        m['cosPrice'],

        m['eiAmount'],
        m['eiUnitPrice'],
        m['eiPrice'],
      ].join(','))
    }
    rows.push([
      formatMessage({ id: 'report.stock.detail.total.root' }),
      '',
      ['bi', 'pim', 'pimPM', 'dim', 'dimPM', 'cos', 'ei'].map(i => `,,${getTotalValue(i)}`).join(',')
    ].join(','))

    const content = rows.join('\n')
    const csvData = new Blob(['\uFEFF' + content], { type: 'text/csv' })
    const csvUrl = URL.createObjectURL(csvData)
    let aExport = document.createElement('a')
    aExport.href = csvUrl
    aExport.target = '_blank'
    aExport.download = `${yearMonth}_進銷存報表.csv`
    aExport.click()
    aExport = null
  }

  const _headerCells = [
    { text: 'name', order: 0 },
    { text: 'orderUnit', order: 0 },
  ]

  const _rowCells = [
    { field: 'name', order: 0 },
    { field: 'orderUnit', order: 0 },
  ]

  let count = 0
  const colorMapping = {
    bi: '#eee',
    pim: '#fff',
    pimPM: '#eee',
    dim: '#fff',
    dimPM: '#eee',
    cos: '#fff',
    ei: '#eee',
  }
  for (const x of ['bi', 'pim', 'pimPM', 'dim', 'dimPM', 'cos', 'ei']) {
    const sx = { backgroundColor: colorMapping[x] }
    _headerCells.push({ text: 'amount', type: x, order: count + 1, sx, align: 'center' })
    _headerCells.push({ text: 'unitPrice', type: x, order: count + 2, sx, align: 'center' })
    _headerCells.push({ text: 'price', type: x, order: count + 3, sx, align: 'center' })

    _rowCells.push({ field: `${x}Amount`, order: count + 1, sx, align: 'center' })
    _rowCells.push({ field: `${x}UnitPrice`, order: count + 2, sx, align: 'center' })
    _rowCells.push({ field: `${x}Price`, order: count + 3, sx, align: 'center' })

    count += 3
  }

  const headerCells = _headerCells.map(c => {
    if (['name', 'orderUnit'].includes(c.text)) {
      c.text = formatMessage({ id: `report.stock.detail.${c.text}` });
    } else {
      c.text = formatMessage({ id: `report.stock.detail.${c.type}.${c.text}` });
    }

    c.key = `${c.type}${c.text}`

    return c
  }).sort((a, b) => a.order - b.order)
  const rowCells = _rowCells.sort((a, b) => a.order - b.order)

  return (
    <div style={{ padding: '15px', flexGrow: 1, height: '100%' }}>
      <Box p={0}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="collapsible table">
            <EnhancedTableHead
              headerCells={headerCells}
              rowCount={0}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              groupedHeader={createdGroupedHeader()}
            />
            <TableBody>
              {stableSort(currentData.map(i => formatData(i)), getComparator(order, orderBy)).map(data => (
                <EnhancedTableRow
                  key={data.id}
                  rowCells={rowCells}
                  cellData={data}
                />
              ))}
              <TableRow>
                <TableCell colSpan={4} >{formatMessage({ id: 'report.stock.detail.total.root' })}</TableCell>
                <TableCell align="center">{getTotalValue('bi')}</TableCell>
                <TableCell colSpan={2} />
                <TableCell align="center">{getTotalValue('pim')}</TableCell>
                <TableCell colSpan={2} />
                <TableCell align="center">{getTotalValue('pimPM')}</TableCell>
                <TableCell colSpan={2} />
                <TableCell align="center">{getTotalValue('dim')}</TableCell>
                <TableCell colSpan={2} />
                <TableCell align="center">{getTotalValue('dimPM')}</TableCell>
                <TableCell colSpan={2} />
                <TableCell align="center">{getTotalValue('cos')}</TableCell>
                <TableCell colSpan={2} />
                <TableCell align="center">{getTotalValue('ei')}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item key="buttons" xs={12} sm={12} md={12}>
            <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
              <LoadingButton
                color="primary"
                onClick={exportData}
                loadingPosition="start"
                loadingIndicator={<CircularProgress size={24} />}
                startIcon={<div />}
                variant="contained"
              >
                <FormattedMessage id="button.export" />
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

StockReportView.propTypes = {
  stockData: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
};

export default StockReportView;
