import React, { useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';
// import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Switch from '@mui/material/Switch';

import InputMask from 'react-input-mask';

const SwitchContainer = styled('div')(() => ({
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
}));

const DividerText = styled('div')(() => ({
  position: 'absolute',
  top: '15px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

const EditNewUserView = forwardRef((props, ref) => {
  const { user } = props
  const { formatMessage } = useIntl()

  const defaultState = {
    displayName: user.displayName || '',
    email: user.email,
    employeeId: user.employeeId,
    active: user.active,
    'finance-create': user.userRights ? user.userRights['finance-create'] || false : false,
    'finance-edit': user.userRights ? user.userRights['finance-edit'] || false : false,
    'finance-view': user.userRights ? user.userRights['finance-view'] || false : false,
    'medicine-create': user.userRights ? user.userRights['medicine-create'] || false : false,
    'medicine-edit': user.userRights ? user.userRights['medicine-edit'] || false : false,
    'medicine-view': user.userRights ? user.userRights['medicine-view'] || false : false,
    'merchandise-create': user.userRights ? user.userRights['merchandise-create'] || false : false,
    'merchandise-edit': user.userRights ? user.userRights['merchandise-edit'] || false : false,
    'merchandise-view': user.userRights ? user.userRights['merchandise-view'] || false : false,
    'product-create': user.userRights ? user.userRights['product-create'] || false : false,
    'product-edit': user.userRights ? user.userRights['product-edit'] || false : false,
    'product-view': user.userRights ? user.userRights['product-view'] || false : false,
    'vendor-create': user.userRights ? user.userRights['vendor-create'] || false : false,
    'vendor-edit': user.userRights ? user.userRights['vendor-edit'] || false : false,
    'vendor-view': user.userRights ? user.userRights['vendor-view'] || false : false,
    'report-view': user.userRights ? user.userRights['report-view'] || false : false
  }

  const [userData, setUserData] = useState(defaultState);

  useImperativeHandle(
    ref, () => ({
      getContent: () => {
        return _getContent()
      }
    }),
  )

  const _fields = [
    // 要有: displayName, email, 工號, 啟用/停用, 6組權限和3個子權限的 checkbox
    { name: 'displayName', type: 'text', sm: 6, required: true },
    { name: 'email', type: 'text', sm: 6, required: true },
    { name: 'employeeId', type: 'text', sm: 6 },
    { name: 'active', type: 'switch', sm: 6, textOff: '停用', textOn: '啟用' },
    { name: 'userRights', text: '權限設定', type: '-', order: 9 },

    { name: 'finance-create', type: 'checkbox', sm: 6, },
    { name: 'finance-edit', type: 'checkbox', sm: 6, },
    { name: 'finance-view', type: 'checkbox', sm: 6, },

    { name: 'medicine-create', type: 'checkbox', sm: 6, },
    { name: 'medicine-edit', type: 'checkbox', sm: 6, },
    { name: 'medicine-view', type: 'checkbox', sm: 6, },

    { name: 'merchandise-create', type: 'checkbox', sm: 6, },
    { name: 'merchandise-edit', type: 'checkbox', sm: 6, },
    { name: 'merchandise-view', type: 'checkbox', sm: 6, },

    { name: 'product-create', type: 'checkbox', sm: 6, },
    { name: 'product-edit', type: 'checkbox', sm: 6, },
    { name: 'product-view', type: 'checkbox', sm: 6, },

    { name: 'vendor-create', type: 'checkbox', sm: 6, },
    { name: 'vendor-edit', type: 'checkbox', sm: 6, },
    { name: 'vendor-view', type: 'checkbox', sm: 6, },

    { name: 'report-view', type: 'checkbox', sm: 6, },
  ]

  const fields = []
  for (const field of _fields) {
    fields.push({ ...field, required: field.required || false, md: field.md || 3 })
  }

  function getMenuItem(field) {
    return []
  }


  function validateField(field, value) {
    return ''
  }

  function updateUserData(field, value) {
    let newValue = value
    if (field.allowCharacter) {
      newValue = newValue.replace(field.allowCharacter, '')
    }
    if (field.maxLength) {
      newValue = newValue.substring(0, field.maxLength)
    }
    if (userData[field.name] === newValue) return;

    let err = validateField(field, value)

    let newData = { ...userData, [field.name]: newValue, [`${field.name}_err`]: err }
    setUserData(newData)
  }

  function createField(field) {
    if (field.type === 'text') {
      return <Grid item key={`${field.name}.${field.index || 0}.${field.subField || 0}`} xs={12} sm={field.sm} md={field.md}>
        <TextField
          disabled={field.disabled}
          required={field.required}
          type="text"
          size="small"
          label={formatMessage({ id: `user.table.detail.${field.subField || field.name}` })}
          variant="outlined"
          onChange={e => { updateUserData(field, e.target.value) }}
          value={field.subField ? userData[field.name][field.index][field.subField] : userData[field.name]}
          error={
            field.subField ?
              (userData[field.name][field.index][`${field.subField}_err`] ? true : false) :
              (userData[`${field.name}_err`] ? true : false)
          }
          helperText={
            field.subField ?
              userData[field.name][field.index][`${field.subField}_err`] :
              userData[`${field.name}_err`]
          }
          fullWidth
        />
      </Grid>
    } else if (field.type === 'checkbox') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={3}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={userData[field.name]}
              onChange={e => { updateUserData(field, e.target.checked) }}
              color="primary"
            />
          }
          label={formatMessage({ id: `user.table.detail.${field.name}` })}
        />
      </Grid>
    } else if (field.type === 'switch') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={3}>
        <Typography variant="subtitle1">
          <SwitchContainer>
            <div>{field.textOff}</div>
            <Switch
              checked={userData[field.name]}
              onChange={e => { updateUserData(field, e.target.checked) }}
              color="primary"
              name="editmode"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <div>{field.textOn}</div>
          </SwitchContainer>
        </Typography>
      </Grid>
    } else if (field.type === 'phone') {
      return <Grid item key={`${field.name}.${field.index || 0}.${field.subField || 0}`} xs={12} sm={field.sm} md={3}>
        <InputMask
          mask={field.mask}
          maskChar=" "
          onChange={e => { updateUserData(field, e.target.value) }}
          value={field.subField ? userData[field.name][field.index][field.subField] : userData[field.name]}
        >
          {() => <TextField
            required={field.required}
            type="text"
            size="small"
            label={formatMessage({ id: `user.table.detail.${field.subField || field.name}` })}
            variant="outlined"
            onCompositionStart={
              e => {
                e.target.addEventListener('input', e2 => {
                  if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e2.data)) {
                    e2.preventDefault()
                    e2.stopPropagation()
                    e2.stopImmediatePropagation()
                  }
                }, { once: true })
              }
            }
            error={
              field.subField ?
                (userData[field.name][field.index][`${field.subField}_err`] ? true : false) :
                (userData[`${field.name}_err`] ? true : false)
            }
            helperText={
              field.subField ?
                userData[field.name][field.index][`${field.subField}_err`] :
                userData[`${field.name}_err`]
            }
            fullWidth
          />}
        </InputMask>
      </Grid>
    } else if (field.type === 'select') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={3}>
        <TextField
          required={field.required}
          select
          type="text"
          size="small"
          label={formatMessage({ id: `user.table.detail.${field.name}` })}
          variant="outlined"
          onChange={e => { updateUserData(field, e.target.value) }}
          value={userData[field.name]}
          error={userData[`${field.name}_err`] ? true : false}
          helperText={userData[`${field.name}_err`]}
          fullWidth
        >
          {getMenuItem(field.name).map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    } else if (field.type === 'multiline') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={3}>
        <TextField
          multiline
          required={field.required}
          type="text"
          size="small"
          label={formatMessage({ id: `user.table.detail.${field.name}` })}
          variant="outlined"
          onChange={e => { updateUserData(field, e.target.value) }}
          value={userData[field.name]}
          fullWidth
        />
      </Grid>
    } else if (field.type === '-') {
      if (field.text) {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12} style={{ position: 'relative' }}>
          <Divider />
          <DividerText>
            <Typography color="textSecondary" variant="caption">{field.text}</Typography>
          </DividerText>
        </Grid>
      } else {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
      }
    }
  }

  function _getContent() {
    // setLoading(true);
    const contactSubFields = ['contactName', 'contactPhone', 'contactMobile', 'contactNote']
    let err = {}
    for (let field of fields) {
      if (field.required && userData[field.name] === '') {
        err[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
      }
    }
    let newData = userData
    if (Object.keys(err).length > 0) {
      newData = { ...userData, ...err }
      setUserData(newData)
    }
    for (let field of fields) {
      if (field.name === 'contacts') {
        // 檢查 contacts 欄位的 err
        for (let contact of newData.contacts) {
          for (const f of contactSubFields) {
            if (contact[`${f}_err`] !== undefined && contact[`${f}_err`] !== '') {
              return
            }
          }
        }
      } else {
        if (newData[`${field.name}_err`] !== undefined && newData[`${field.name}_err`] !== '') {
          return
        }
      }
    }
    let data = {}
    for (let field of _fields) {
      if (field.type === '-') continue
      if (['displayName', 'email', 'employeeId', 'active'].includes(field.name)) {
        data[field.name] = newData[field.name]
      } else {
        if (newData[field.name]) {
          data.userRights = { [field.name]: newData[field.name], ...data.userRights }
        }
      }
    }

    if (data.userRights) {
      if (data.userRights['finance-create'] && data.userRights['finance-edit'] && data.userRights['finance-view']) {
        delete data.userRights['finance-create']
        delete data.userRights['finance-edit']
        delete data.userRights['finance-view']
        data.userRights['finance-*'] = true
      }

      if (data.userRights['medicine-create'] && data.userRights['medicine-edit'] && data.userRights['medicine-view']) {
        delete data.userRights['medicine-create']
        delete data.userRights['medicine-edit']
        delete data.userRights['medicine-view']
        data.userRights['medicine-*'] = true
      }

      if (data.userRights['merchandise-create'] && data.userRights['merchandise-edit'] && data.userRights['merchandise-view']) {
        delete data.userRights['merchandise-create']
        delete data.userRights['merchandise-edit']
        delete data.userRights['merchandise-view']
        data.userRights['merchandise-*'] = true
      }

      if (data.userRights['product-create'] && data.userRights['product-edit'] && data.userRights['product-view']) {
        delete data.userRights['product-create']
        delete data.userRights['product-edit']
        delete data.userRights['product-view']
        data.userRights['product-*'] = true
      }

      if (data.userRights['vendor-create'] && data.userRights['vendor-edit'] && data.userRights['vendor-view']) {
        delete data.userRights['vendor-create']
        delete data.userRights['vendor-edit']
        delete data.userRights['vendor-view']
        data.userRights['vendor-*'] = true
      }
    }

    if (user.userRights) {
      if (user.userRights.debugging) {
        data.userRights.debugging = true
      }

      if (user.userRights['user-create'] && user.userRights['user-edit'] && user.userRights['user-view']) {
        data.userRights['user-*'] = true
      }
    }
    // data.basicPurchaseAmount = data.basicPurchaseAmount ? parseInt(data.basicPurchaseAmount) : 0
    // data.freeShippingAmount = data.freeShippingAmount ? parseInt(data.freeShippingAmount) : 0
    // data.shippingOut = data.shippingOut.reduce((acc, cur) => { acc[cur.id] = true; return acc; }, {})
    // data.contacts = data.contacts.filter(c => c.contactName !== '' || c.contactPhone !== '' || c.contactMobile !== '' || c.contactNote !== '') // 儲存 contacts 欄位, 去掉4欄都沒有資料的

    return { ...data }
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        {fields.map(field => createField(field))}
      </Grid>
    </div>
  );
})

EditNewUserView.displayName = 'EditNewUserView'

EditNewUserView.propTypes = {
  user: PropTypes.object,
  ignoreFields: PropTypes.arrayOf(PropTypes.string),
};

export default EditNewUserView;
