import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import DatePickerField from './DatePickerField';

export default function SelectYMCustomerDialog(props) {
  const { title, handleClose, handleExecute, action, data, customers } = props;
  const maxDate = dayjs().startOf('month').format('YYYY-MM-DD')

  const [yearMonth, setYearMonth] = useState(maxDate);
  const [customer, setCustomer] = useState('');

  function onDateChange(date) {
    setYearMonth(`${dayjs(date).format('YYYY-MM')}-01`)
  }

  function handleAction() {
    const ym = dayjs(yearMonth).format('YYYY-MM')
    if (data) {
      handleExecute({ ...data, action, yearMonth: ym, customer })
    } else {
      handleExecute({ action, yearMonth: ym, customer })
    }
    handleClose()
  }

  return (
    <Dialog fullWidth open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Grid container sx={{ p: 1 }} spacing={3}>
          <Grid item xs={6} sm={6} md={6}>
            <DatePickerField
              label={'表單月份'}
              inputFormat="YYYY-MMM"
              value={dayjs(yearMonth)}
              required
              onChange={onDateChange}
              maxDate={new Date(maxDate)}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <TextField
              select
              type="text"
              label={'客戶'}
              variant="outlined"
              value={customer}
              onChange={e => {setCustomer(e.target.value)}}
              fullWidth
              size="small"
            >
              {customers.map(c => <MenuItem key={c.id} value={c.id}>
                {c.nickname}
              </MenuItem>)}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button variant="contained" onClick={handleAction} color="primary">
          <FormattedMessage id="button.add" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SelectYMCustomerDialog.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.object.isRequired),
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleExecute: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  data: PropTypes.object,
};
