import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import SimpleTableToolbar from '../../components/SimpleTableToolbar';
import SearchBox from '../../components/SearchBox';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { getComparator, stableSort } from '../../modules/sort';
import { useFirestoreDataAndMapping, useSupplierDataAndMapping } from '../../modules/uitls';

function MerchandiseDetail({ merchandiseMapping, receipt }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');

  const headerCells = [
    { field: 'purchaseOrderSn', sort: 'purchaseOrderSn' },
    { field: 'code', sort: 'code' },
    { field: 'name' },
    { field: 'amount', align: 'right' },
    { field: 'orderUnit', align: 'right' },
    { field: 'note', align: 'right' },
  ].map(c => {c.text = formatMessage({ id: `receipt.merchandise.${c.field}` });return c})

  const rowCells = [
    { field: 'purchaseOrderSn', sort: 'purchaseOrderSn' },
    { field: 'code', sort: 'code' },
    { field: 'name' },
    { field: 'amount', align: 'right' },
    { field: 'orderUnit', align: 'right' },
    { field: 'note', align: 'right' },
  ]

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function formatData(merchandise) {
    return {
      ...merchandise,
      code: merchandiseMapping[merchandise.id].code,
      name: merchandiseMapping[merchandise.id].name,
      nickname: merchandiseMapping[merchandise.id].nickname,
      orderUnit: merchandise.orderBySku ? merchandiseMapping[merchandise.id].sku : merchandiseMapping[merchandise.id].orderUnit,
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="collapsible table">
        <EnhancedTableHead
          headerCells={headerCells}
          rowCount={receipt.merchandises.length}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort(receipt.merchandises.map(m => formatData(m)), getComparator(order, orderBy)).map(merchandise => (
            <EnhancedTableRow
              key={`${merchandise.purchaseOrderId}@${merchandise.id}`}
              rowCells={rowCells}
              cellData={merchandise}
              expandable={false}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

MerchandiseDetail.propTypes = {
  merchandiseMapping: PropTypes.object.isRequired,
  receipt: PropTypes.object.isRequired,
};

function SelectReceiptDialog({ customer, handleClose, handleSave }) {
  const { formatMessage } = useIntl()
  const [userMapping] = useFirestoreDataAndMapping('users')
  const [supplierMapping] = useSupplierDataAndMapping()
  const [customerMapping] = useFirestoreDataAndMapping('customers')
  const [merchandiseMapping] = useFirestoreDataAndMapping('merchandises')

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('supplier');
  const [selectedItem, setSelectedItem] = useState('');
  const [currentFilter, setCurrentFilter] = useState(null)
  const [receipts, setReceipts] = useState([]);

  const filteredReceipts = currentFilter && currentFilter.text ? filterByText() : receipts

  useEffect(() => {
    const date = dayjs().subtract(1, 'years').format('YYYY-MM')
    const onSnapshot = snapshot => {
      const receipts = []
      snapshot.forEach(doc => {
        receipts.push({ ...doc.data(), id: doc.id })
      });
      setReceipts(receipts)
    }
    const unsubscribe = firebase.firestore().collection('receipts').where('status', '==', 'done').where('source', '==', customer).where('date', '>=', date).onSnapshot(onSnapshot, err => {})
    return () => unsubscribe()
  }, []);

  const headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'supplier', sort: 'supplier' },
    { text: 'createdBy', sort: 'createdBy' },
    { text: 'date', sort: 'createdAt' },
  ].map(c => {c.text = formatMessage({ id: `receipt.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'sn' },
    { field: 'supplier' },
    { field: 'createdBy' },
    { field: 'date' },
  ]

  function filterByText() {
    const lowerCaseText = currentFilter.text.toLowerCase()
    if (currentFilter.name === 'supplierName') {
      return receipts.filter(s => supplierMapping[s.supplier]?.nickname.toLowerCase().includes(lowerCaseText))
    } else if (currentFilter.name === 'createdBy') {
      return receipts.filter(s => userMapping[s.createdBy]?.displayName.toLowerCase().includes(lowerCaseText))
    } else if (currentFilter.name === 'merchandiseName') {
      return receipts.filter(s => Object.keys(s.merchandises || {}).filter(c => merchandiseMapping[c])
        .filter(c => merchandiseMapping[c].name.toLowerCase().includes(lowerCaseText) ||
          merchandiseMapping[c].nickname.toLowerCase().includes(lowerCaseText)).length)
    } else if (currentFilter.name === 'merchandiseCode') {
      return receipts.filter(s => Object.keys(s.merchandises || {}).filter(c => merchandiseMapping[c])
        .filter(c => merchandiseMapping[c].code.toLowerCase().includes(lowerCaseText)).length)
    } else {
      return receipts.filter(s => s[currentFilter.name].toLowerCase().includes(lowerCaseText))
    }
  }

  const formatData = (receipt) => {
    const newData = { ...receipt }
    newData.sourceName = customerMapping[newData.source]?.nickname
    newData.createdBy = userMapping[newData.createdBy]?.displayName
    newData.supplier = supplierMapping[newData.supplier]?.nickname

    const keys = receipt.merchandises ? Object.keys(receipt.merchandises) : []
    const extKeys = receipt.extraMerchandises ? Object.keys(receipt.extraMerchandises) : []
    newData.merchandises = keys.map(k => {
      return { ...receipt.merchandises[k], id: k }
    }).concat(extKeys.map(k => {
      return { ...receipt.extraMerchandises[k], purchaseOrderSn: 'N/A', purchaseOrderId: 'N/A', id: k }
    }))
    delete newData.extraMerchandises

    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filterItems = [
    { name: 'sn' },
    { name: 'supplierName' },
    { name: 'createdBy' },
    { name: 'merchandiseName' },
    { name: 'merchandiseCode' },
    { name: 'note' },
  ].map(i => {i.text = formatMessage({ id: `receipt.table.detail.${i.name}` });return i})

  const handleRadioButtonClick = (id) => {
    setSelectedItem(id);
  }

  const onApply = () => {
    const receipt = receipts.find(p => p.id === selectedItem)
    handleSave(receipt)
    handleClose()
  }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <SimpleTableToolbar
          title="SelectReceiptDialog.title"
          toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
        />
        {/* <FormattedMessage id={'SelectReceiptDialog.title'} /> */}
      </DialogTitle>
      <DialogContent dividers={true}>
        <div style={{ flexGrow: 1 }}>
          {/* {loading && <div>loading...</div>} */}
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                numSelected={0}
                order={order}
                orderBy={orderBy}
                expandable
                onRequestSort={handleRequestSort}
                rowCount={filteredReceipts.length}
                radioButton
              />
              <TableBody>
                {stableSort(filteredReceipts.map(p => formatData(p)), getComparator(order, orderBy)).map(receipt => (
                  <EnhancedTableRow
                    key={receipt.id}
                    rowCells={rowCells}
                    cellData={receipt}
                    onRadioButtonClick={(e) => handleRadioButtonClick(receipt.id)}
                    selected={receipt.id === selectedItem}
                    expandable
                    expandContent={<MerchandiseDetail merchandiseMapping={merchandiseMapping} receipt={receipt} />}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button variant="contained" onClick={onApply} disabled={!selectedItem} color="primary">
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SelectReceiptDialog.propTypes = {
  customer: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default SelectReceiptDialog;
