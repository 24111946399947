import React, { useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

import SelectCustomerDialog from './SelectCustomerDialog';
import SelectTagDialog from './SelectTagDialog';

import { useFirestoreDataAndMapping } from '../modules/uitls';

const SwitchContainer = styled('div')(() => ({
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
}));

const EditNewProductView = forwardRef((props, ref) => {
  const { ignoreFields = [], requiredFields = [], disabledFields = [], product } = props
  const { formatMessage } = useIntl()

  const [customerMapping] = useFirestoreDataAndMapping('customers')

  const customers = Object.keys(product.customers || []).filter(c => customerMapping[c])
  const defaultState = {
    name: product.name,
    type: product.type,
    tags: product.tags,
    cost: product.cost,
    staffprice: product.staffprice,
    staffRelativesPrice: product.staffRelativesPrice,
    vipprice: product.vipprice,
    customers: customers.map(s => customerMapping[s]),
    note: product.note,
    consumable: product.consumable,
  }

  const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
  const [openTagDialog, setOpenTagDialog] = useState(false);

  const [productData, setProductData] = useState(defaultState);

  useImperativeHandle(
    ref, () => ({
      getContent: () => {
        return _getContent()
      },
      setEmployeePirce: (field, value) => {
        return _setEmployeePirce(field, value)
      }
    }),
  )

  const floatingPointNumberRule = /[^0-9.]/g

  const fields = [
    { name: 'name', type: 'text', sm: 6 },
    { name: 'type', type: 'select', sm: 6 },
    { name: 'tags', type: 'popup', sm: 6, onClick: setOpenTagDialog },
    { name: 'consumable', type: 'switch', sm: 6 },
    { name: 'info', type: '-' },
    { name: 'cost', type: 'text', sm: 6, allowCharacter: floatingPointNumberRule },
    { name: 'staffprice', type: 'text', sm: 6, allowCharacter: floatingPointNumberRule },
    { name: 'staffRelativesPrice', type: 'text', sm: 6, allowCharacter: floatingPointNumberRule },
    { name: 'vipprice', type: 'text', sm: 6, allowCharacter: floatingPointNumberRule },
    { name: 'customers', type: 'popup', sm: 12, md: 12, onClick: setOpenCustomerDialog },
    { name: 'note', type: 'multiline', sm: 12 },
  ].map(field => { field.disabled = disabledFields.includes(field.name); field.required = requiredFields.includes(field.name); field.md = field.md || 3; return field })

  function getMenuItem(field) {
    if (field === 'type') {
      return [
        { value: 'beauty', header: true },
        { value: 'AM' },
        { value: 'OP' },
        { value: 'SK' },
        { value: 'DP' },
        { value: 'preventative', header: true },
        { value: 'CN' },
        { value: 'EX' },
        { value: 'TT' },
        { value: 'GE' },
        { value: 'SU' },
        { value: 'ND' },
        { value: 'CU' },
        { value: 'other', header: true },
        { value: 'OT' }
      ].map(i => ({ ...i, label: formatMessage({ id: `product.type.${i.value}` }) }))
    }
    return []
  }

  const onCustomerChanged = (customers) => {
    updateProductData({ name: 'customers' }, customers);
  }

  const onTagChanged = (tags) => {
    updateProductData({ name: 'tags' }, tags);
  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }
    return ''
  }

  function updateProductData(field, value) {
    setProductData(productData => {
      let newValue = value
      if (field.allowCharacter) {
        newValue = newValue.replace(field.allowCharacter, '')
      }
      if (field.maxLength) {
        newValue = newValue.substring(0, field.maxLength)
      }

      // if (newValue === undefined || productData[field.name] === newValue) return;

      let err = validateField(field, value)

      let newData = { ...productData, [field.name]: newValue, [`${field.name}_err`]: err }

      if (field.name === 'cost' && field.name !== '') {
        newData.staffprice = (Number(newData.cost) * 1.2).toFixed(2).replace(/\.?0+$/, '')
        newData.staffRelativesPrice = (Number(newData.cost) * 1.5).toFixed(2).replace(/\.?0+$/, '')
      }
      return newData
    })
  }

  function createField(field) {
    if (ignoreFields.includes(field.name)) return null
    if (field.type === 'text') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          disabled={field.disabled}
          required={field.required}
          type="text"
          size="small"
          label={formatMessage({ id: `product.edit.${field.name}` })}
          variant="outlined"
          onChange={e => { updateProductData(field, e.target.value) }}
          value={productData[field.name]}
          error={productData[`${field.name}_err`] ? true : false}
          helperText={productData[`${field.name}_err`]}
          fullWidth
        />
      </Grid>
    } else if (field.type === 'checkbox') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={3}>
        <FormControlLabel
          control={
            <Checkbox
              checked={productData[field.name]}
              onChange={e => { updateProductData(field, e.target.checked) }}
              color="primary"
            />
          }
          label={formatMessage({ id: `product.edit.${field.name}` })}
        />
      </Grid>
    } else if (field.type === 'popup') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md || 3}>
        <TextField
          disabled={field.disabled}
          required={field.required}
          type="text"
          size="small"
          label={formatMessage({ id: `product.edit.${field.name}` })}
          variant="outlined"
          onClick={() => field.onClick(true)} //open popup
          value={productData[field.name].map(s => field.name === 'tags' ? s : s.name).join(', ')}
          error={productData[`${field.name}_err`] ? true : false}
          helperText={productData[`${field.name}_err`]}
          fullWidth
        />
      </Grid>
    } else if (field.type === 'select') {
      const menuItems = getMenuItem(field.name)
      return <Grid item key={field.name} xs={12} sm={field.sm} md={3}>
        <TextField
          required={field.required}
          disabled={field.disabled || menuItems.length === 0}
          select
          type="text"
          size="small"
          label={formatMessage({ id: `product.edit.${field.name}` })}
          variant="outlined"
          onChange={e => { updateProductData(field, e.target.value) }}
          value={productData[field.name]}
          error={productData[`${field.name}_err`] ? true : false}
          helperText={productData[`${field.name}_err`]}
          fullWidth
        >
          {menuItems.map((option) => {
            return option.header ?
              (<ListSubheader disabled key={option.value}>{option.label}</ListSubheader>) :
              (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)
          })}
        </TextField>
      </Grid>
    } else if (field.type === 'multiline') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={12}>
        <TextField
          multiline
          required={field.required}
          disabled={field.disabled}
          type="text"
          size="small"
          label={formatMessage({ id: `product.edit.${field.name}` })}
          variant="outlined"
          onChange={e => { updateProductData(field, e.target.value) }}
          value={productData[field.name]}
          fullWidth
        />
      </Grid>
    } else if (field.type === '-') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
    } else if (field.type === 'switch') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={3}>
        <Typography component="div" style={{ height: '100%' }}>
          <SwitchContainer>
            {formatMessage({ id: 'product.table.detail.directSales' })}
            <Switch
              disabled={field.disabled}
              color="primary"
              checked={productData[field.name]}
              onChange={e => { updateProductData(field, e.target.checked) }}
              name="consumable"
            />
            {formatMessage({ id: 'product.table.detail.treatmentConsume' })}
          </SwitchContainer>
        </Typography>
      </Grid>
    }
  }

  function _setEmployeePirce(field, value) {
    updateProductData({ name: field }, value);
  }

  function _getContent() {
    // setLoading(true);

    let err = {}
    for (let field of fields) {
      if (field.required && productData[field.name] === '') {
        err[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
      }
    }
    let newData = productData
    if (Object.keys(err).length > 0) {
      newData = { ...productData, ...err }
      setProductData(newData)
    }
    for (let field of fields) {
      if (newData[`${field.name}_err`] !== undefined && newData[`${field.name}_err`] !== '') {
        // setLoading(false);
        return
      }
    }
    let data = {}
    for (let field of fields) {
      if (field.type === '-') continue
      data[field.name] = newData[field.name]
    }

    data.customers = data.customers.reduce((acc, cur) => { acc[cur.id] = true; return acc; }, {})
    data.cost = Number(data.cost || '0')
    data.staffprice = Number(data.staffprice || '0')
    data.staffRelativesPrice = Number(data.staffRelativesPrice || '0')
    data.vipprice = Number(data.vipprice || '0')
    data.cat = data.type
    data.treatments = 'no'

    return { ...data }
  }

  return (
    <div style={{ flexGrow: 1 }}>
      {openCustomerDialog && <SelectCustomerDialog
        defaultSelectedItems={productData.customers}
        handleClose={() => setOpenCustomerDialog(false)}
        handleSave={onCustomerChanged}
        ignoreIds={[]}
      />}
      {openTagDialog && <SelectTagDialog
        defaultSelectedItems={productData.tags}
        handleClose={() => setOpenTagDialog(false)}
        handleSave={onTagChanged}
      />}
      <Grid container spacing={3}>
        {fields.map(field => createField(field))}
      </Grid>
    </div>
  );
})

EditNewProductView.displayName = 'EditNewProductView'

EditNewProductView.propTypes = {
  product: PropTypes.object,
  ignoreFields: PropTypes.arrayOf(PropTypes.string),
  requiredFields: PropTypes.arrayOf(PropTypes.string),
  disabledFields: PropTypes.arrayOf(PropTypes.string),
};

export default EditNewProductView;
