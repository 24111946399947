import React, { useState, useEffect }  from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

import { useFirestoreDataAndMapping, sortByField } from '../../modules/uitls';

export default function StockLogDetails(props) {
  const { info, onClose } = props;
  const { merchandiseId, customerId, startDateTime, endDateTime, ignoreCheckpoint } = info

  const [text, setText] = useState('');
  const [merchandiseMapping] = useFirestoreDataAndMapping('merchandises')

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('wh').doc(customerId)
      .collection('logs').where('merchandiseId', 'in', [merchandiseId, ''])
      .where('dateTime', '>=', startDateTime).where('dateTime', '<=', endDateTime)
      .onSnapshot( snapshot => {
        let newText = ''
        const sku = merchandiseMapping[merchandiseId].sku
        let logs = []
        snapshot.forEach(doc => {
          const data = doc.data()
          if (data.valid !== false) {
            logs.push(data)
          }
        });
        logs = logs.sort(sortByField('dateTime'))

        for (const log of logs) {
          if (newText !== '') newText += '\n'
          if (log.receiptId) {
            newText += `${log.date} [進貨單: ${log.sn}] 進貨 ${log.quantity} ${sku}`
          } else if (log.borrowingReceiptId) {
            newText += `${log.date} [借貨入庫單: ${log.sn}] 入庫 ${log.quantity} ${sku}`
          } else if (log.returnBorrowingReceiptId) {
            newText += `${log.date} [借貨歸還入庫單: ${log.sn}] 入庫 ${log.quantity} ${sku}`
          } else if (log.deliveryOrderId) {
            newText += `${log.date} [銷貨單: ${log.sn}] 出貨 ${-log.quantity} ${sku}`
          } else if (log.borrowingDeliveryId) {
            newText += `${log.date} [借貨出貨單: ${log.sn}] 扣庫 ${-log.quantity} ${sku}`
          } else if (log.returnBorrowingFormId) {
            newText += `${log.date} [借貨歸還單: ${log.sn}] 扣庫 ${-log.quantity} ${sku}`
          } else if (log.returnFormId) {
            newText += `${log.date} [退貨單: ${log.sn}] 扣庫 ${-log.quantity} ${sku}`
          } else if (log.salesReturnFormId) {
            newText += `${log.date} [銷貨退回單: ${log.sn}] 入庫 ${log.quantity} ${sku}`
          } else if (log.stockRequisitionId) {
            newText += `${log.date} [沙貨領用單: ${log.sn}] 扣庫 ${-log.quantity} ${sku}`
          } else if (log.scrapFormId) {
            newText += `${log.date} [報廢出庫單: ${log.sn}] 扣庫 ${-log.quantity} ${sku}`
          } else if (log.kardexId) {
            newText += `${log.date} [Kardex ID: ${log.kardexId}, 客戶 ${log.patient}] 消耗 ${-log.quantity} ${sku}`
          } else if (log.checkpoint && log.balance && log.balance[merchandiseId] && log.inventoryCheckId !== ignoreCheckpoint) {
            newText += `${log.date} [庫存異動單: ${log.sn}] ${log.balance[merchandiseId] > 0 ? '盤盈' : '盤虧' } ${Math.abs(log.balance[merchandiseId])} ${sku}`
          }
        }
        setText(`${merchandiseMapping[merchandiseId].name}:\n------\n${newText || '自基準日後無庫存異動'}`)
      }, err => {})
    return () => unsubscribe()
  }, []);

  return (
    <Dialog onClose={() => onClose()} open={true}>
      <Box p={3}>
        <div style={{ whiteSpace: 'pre-line' }}>
          {text}
        </div>
      </Box>
    </Dialog>
  );
}

StockLogDetails.propTypes = {
  info: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
