const i18n = {
  'newProfile': {
    'tab': {
      'pending': '等候中',
      'done': '已完成',
      'void': '已作廢',
    },
    'review': {
      'title': {
        'supplier': '新供應商申請',
        'merchandise': '新商品申請',
        'product': '新療程申請',
        'folder': '新集合療程申請',
        'package': '新套裝療程申請',
        'combination': '新組合療程申請',
      },
      'note': '備註'
    },
    'dialog': {
      'title': {
        'newSupplier': {
          'add': '新增新供應商申請',
          'edit': '編輯新供應商申請'
        },
        'newMerchandise': {
          'add': '新增新商品申請',
          'edit': '編輯新商品申請'
        },
        'newProduct': {
          'add': '新增新療程申請',
          'edit': '編輯新療程申請'
        },
        'newFolderProduct': {
          'add': '新增新集合療程申請',
          'edit': '編輯新集合療程申請'
        },
        'newPackageProduct': {
          'add': '新增新套裝療程申請',
          'edit': '編輯新套裝療程申請'
        },
        'newCombinationProduct': {
          'add': '新增新組合療程申請',
          'edit': '編輯新組合療程申請'
        }
      }
    },
    'button': {
      'newSupplier': '新增供應商',
      'newMerchandise': '新增商品',
      'newProduct': '新增療程',
      'newFolderProduct': '新增集合療程',
      'newPackageProduct': '新增套裝療程',
      'newCombinationProduct': '新增組合療程',
    },
    'table': {
      'newSupplier': {
        'title': '新供應商申請列表',
        'pending': '新供應商申請列表 - 待簽核',
        'done': '新供應商申請列表 - 待建立',
        'content': '新供應商資訊',
      },
      'newMerchandise': {
        'title': '新商品申請列表',
        'pending': '新商品申請列表 - 待簽核',
        'done': '新商品申請列表 - 待建立',
        'content': '新商品資訊',
      },
      'newProduct': {
        'title': '新療程申請列表',
        'pending': '新療程申請列表 - 待簽核',
        'done': '新療程申請列表 - 待建立',
        'content': '新療程資訊',
      },
      'newFolderProduct': {
        'title': '新集合療程申請列表',
        'pending': '新集合療程申請列表 - 待簽核',
        'done': '新集合療程申請列表 - 待建立',
        'content': '新集合療程資訊',
      },
      'newPackageProduct': {
        'title': '新套裝療程申請列表',
        'pending': '新套裝療程申請列表 - 待簽核',
        'done': '新套裝療程申請列表 - 待建立',
        'content': '新套裝療程資訊',
      },
      'newCombinationProduct': {
        'title': '新組合療程申請列表',
        'pending': '新組合療程申請列表 - 待簽核',
        'done': '新組合療程申請列表 - 待建立',
        'content': '新組合療程資訊',
      },
      'header': {
        'sn': '申請單號',
        'source': '申請單位',
        'sourceName': '申請單位',
        'createdBy': '申請人',
        'date': '申請日期',
      },
      'detail': {
        'sn': '申請單號',
        'source': '申請單位',
        'sourceName': '申請單位',
        'createdBy': '申請人',
        'date': '申請日期',
        'note': '備註',
      },
    }
  }
}
export default i18n
