import React, { useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';

import DatePickerField from './DatePickerField'
import EnhancedTableToolbar from './EnhancedTableToolbar'
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableRow from './EnhancedTableRow';
import SelectMerchandiseDialog from './SelectMerchandiseDialog';
import SelectCommonPriceDialog from './SelectCommonPriceDialog';
import SelectProductDialog from './SelectProductDialog'
import { getComparator, stableSort } from '../modules/sort';
import { useFirestoreDataAndMapping } from '../modules/uitls';

const SwitchContainer = styled('div')(() => ({
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
}));

const DividerText = styled('div')(() => ({
  position: 'absolute',
  top: '0px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

const EditNewProductView = forwardRef((props, ref) => {
  const { ignoreFields = [], requiredFields = [], disabledFields = [], product, consumable, customerId, cat, openCode, commonPriceData, salesBonusTemplates, productMapping } = props
  const { formatMessage } = useIntl()

  const [merchandiseMapping] = useFirestoreDataAndMapping('merchandises')
  const newConvertibleProducts = (product.convertibleProducts || []).reduce((acc, cur) => {
    acc.push({ ...cur, name: productMapping[cur.id].name, code: productMapping[cur.id].code })
    return acc
  }, [])
  const defaultState = {
    nickname: product.nickname || '',
    price: product.price || '',
    lowestPrice: product.lowestPrice || '',
    duration: product.duration || '',
    bonusType: product.bonusType || '', // 銷售抽成/操作抽成 獎金分類
    doctorBonusType: product.doctorBonusType || '', // 醫師工獎分類
    salesBonusType: product.salesBonusType?.id || '', // 客代獎金分類
    description: product.description || '',
    onShelfDate: product.onShelfDate ? dayjs(product.onShelfDate) : null,
    offShelfDate: product.offShelfDate ? dayjs(product.offShelfDate) : null,
    merchandiseType: product.merchandiseType,
    commonPrice: product.commonPrice || [],
    operationBonus: product.operationBonus || '',
    convertibleProducts: newConvertibleProducts
  }

  const [productData, setProductData] = useState(defaultState);
  const [openDialog, setOpenDialog] = useState(false)
  const [openPriceDialog, setOpenPriceDialog] = useState(false)
  const [openProductDialog, setOpenProductDialog] = useState(false)
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedPriceItems, setSelectedPriceItems] = useState({});
  const [selectedProductItems, setSelectedProductItems] = useState({});
  const filterTemplates = [{ id: 'none', name: '無' }].concat(salesBonusTemplates.filter(s => s.customer.includes(customerId)))

  useImperativeHandle(
    ref, () => ({
      getContent: () => {
        return _getContent()
      }
    }),
  )

  const numberRule = /[^0-9]/g
  const bonusStringRule = /[^0-9%]/g


  const _fields = [
    { name: 'edit', type: '-', order: 0 },

    { name: 'nickname', type: 'text', sm: 6, order: 1 },
    { name: 'price', type: 'text', sm: 6, allowCharacter: numberRule, order: 2 },
    { name: 'lowestPrice', type: 'text', sm: 6, allowCharacter: numberRule, order: 3 },
    { name: 'duration', type: 'text', sm: 6, allowCharacter: numberRule, order: 4 },

    { name: 'onShelf', type: '-', order: 9 },

    { name: 'onShelfDate', type: 'onTime', md: 3, sm: 6, order: 10 },
    { name: 'offShelfDate', type: 'offTime', md: 3, sm: 6, order: 11 },

    { name: 'description', type: 'multiline', sm: 12, order: 12 },
    { name: 'merchandises', type: '-', order: 13 },
  ]

  if (['8', '1'].includes(openCode)) {
    _fields.push({ name: 'doctorBonusType', type: 'select', sm: 6, order: 5 })
    _fields.push({ name: 'bonusType', type: 'select', sm: 6, order: 6 })
    _fields.push({ name: 'salesBonusType', type: 'select', sm: 6, order: 7 })
    _fields.push({ name: 'operationBonus', type: 'text', allowCharacter: bonusStringRule, sm: 6, order: 8 })
  } else if (['9'].includes(openCode)) {
    _fields.push({ name: 'doctorBonusType', type: 'select', sm: 6, order: 5 })
    _fields.push({ name: 'bonusType', type: 'select', sm: 6, order: 6 })
    _fields.push({ name: 'salesBonusType', type: 'select', sm: 6, order: 7 })
  }

  const fields = _fields.sort((a, b) => { return a.order - b.order })
    .map(field => { field.disabled = disabledFields.includes(field.name); field.required = requiredFields.includes(field.name); field.md = field.md || 3; return field })

  function getMenuItem(field) {
    if (field === 'bonusType') {
      return [
        { value: 'none' },
        { value: 'purchaseOrder' },
        { value: 'treatment' }
      ].map(i => ({ ...i, label: formatMessage({ id: `product.bonusType.${i.value}` }) }))
    } else if (field === 'doctorBonusType') {
      return [
        { value: 'none' },
        { value: 'laser' },
        { value: 'microShaping' },
        { value: 'lift' },
        { value: 'bodyFiguring' },
        { value: 'instalift' },
        { value: 'noImplantOp' },
        { value: 'other' },
      ].map(i => ({ ...i, label: formatMessage({ id: `product.doctorBonusType.${i.value}` }) }))
    } else if (field === 'salesBonusType') {
      return filterTemplates.reduce((acc, cur) => {
        acc.push({ value: cur.id, label: cur.name, ...cur })
        return acc
      }, []).sort((a, b) => {
        if (a.label === '無') {
          return -1
        } else if (b.label === '無') {
          return 1
        }
        if (a.label > b.label) {
          return 1
        } else if (a.label < b.label) {
          return -1
        } else {
          return 0
        }
      })
    }
    return []
  }

  const headerCells = [
    { text: 'name', sort: 'name' },
    { text: 'type.root', sort: 'type' },
    { text: 'total', sort: 'total' },
  ].map(c => { c.text = formatMessage({ id: `product.merchandise.${c.text}` }); return c })

  const convertibleProductHeaderCells = [
    { text: 'code', sort: 'code' },
    { text: 'name', sort: 'name' },
    { text: 'points', sort: 'points' },
  ].map(c => { c.text = formatMessage({ id: `product.convertibleProduct.${c.text}` }); return c })

  const convertibleProductFields = ['points']
  const convertibleProductRowCells = [
    { field: 'code' },
    { field: 'name' },
    {
      field: 'points',
      fullWidth: true,
      align: 'left',
      type: 'input-number',
      required: true,
      label: formatMessage({ id: 'product.convertibleProduct.points' }),
      onValueChanged: onCellProductChanged
    },
  ]

  const commonPriceHeaderCells = [
    { text: 'name', sort: 'name' },
    { text: 'useAmount', sort: 'useAmount' },
    { text: 'price', sort: 'price' },
    { text: 'fixedAmount', sort: 'fixedAmount' },
    { text: 'onShelfDate', sort: 'onShelfDate' },
    { text: 'offShelfDate', sort: 'offShelfDate' },
  ].map(c => { c.text = formatMessage({ id: `product.commonPrice.${c.text}` }); return c })

  const commonPriceRowCells = [
    {
      field: 'name',
      fullWidth: true,
      align: 'left',
      type: 'input',
      label: formatMessage({ id: 'product.commonPrice.name' }),
      onValueChanged: onCellCommonPriceChanged
    },
    {
      field: 'useAmount',
      fullWidth: true,
      align: 'left',
      type: 'input-number',
      label: formatMessage({ id: 'product.commonPrice.useAmount' }),
      onValueChanged: onCellCommonPriceChanged
    },
    {
      field: 'price',
      fullWidth: true,
      align: 'left',
      type: 'input-floating-point-number',
      label: formatMessage({ id: 'product.commonPrice.price' }),
      onValueChanged: onCellCommonPriceChanged
    },
    {
      field: 'fixedAmount',
      fullWidth: true,
      align: 'left',
      type: 'input-floating-point-number',
      label: formatMessage({ id: 'product.commonPrice.fixedAmount' }),
      onValueChanged: onCellCommonPriceChanged
    },
    {
      field: 'onShelfDate',
      fullWidth: true,
      align: 'left',
      type: 'date',
      label: formatMessage({ id: 'product.commonPrice.onShelfDate' }),
      onValueChanged: onCellCommonPriceChanged
    },
    {
      field: 'offShelfDate',
      fullWidth: true,
      align: 'left',
      type: 'date',
      label: formatMessage({ id: 'product.commonPrice.offShelfDate' }),
      onValueChanged: onCellCommonPriceChanged
    },
  ];

  const merchandiseFields = ['type']
  const rowCells = [
    { field: 'name' },
    {
      field: 'type',
      fullWidth: true,
      align: 'left',
      type: 'input-select',
      required: true,
      label: formatMessage({ id: 'product.merchandise.type.root' }),
      onValueChanged: onCellValueChanged,
      getMenuItems: getTypeList,
      getEnableStatus: getEnableStatus
    },
    { field: 'total', align: 'left', type: 'input-number', required: true, onValueChanged: onCellValueChanged, getEnableStatus: getEnableStatus },
  ]


  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }
    return ''
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function onDeleteItems() {
    const merchandises = productData.merchandiseType.filter(m => !selectedItems[m.id])
    if (merchandises.length !== productData.merchandiseType.length) {
      updateProductData({ name: 'merchandiseType' }, merchandises);
      setSelectedItems({});
    }
  }

  function onDeletePriceItems() {
    const commonPrice = productData.commonPrice.filter(m => !selectedPriceItems[m.id])
    if (commonPrice.length !== productData.commonPrice.length) {
      updateProductData({ name: 'commonPrice' }, commonPrice);
      setSelectedPriceItems({});
    }
  }

  function onDeleteProductItems() {
    const convertibleProduct = productData.convertibleProducts.filter(m => !selectedProductItems[m.id])
    if (convertibleProduct.length !== productData.convertibleProducts.length) {
      updateProductData({ name: 'convertibleProducts' }, convertibleProduct);
      setSelectedPriceItems({});
    }
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = productData.merchandiseType.reduce((acc, cur) => { acc[cur.id] = true; return acc }, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  }

  function handleSelectAllPriceClick(event) {
    if (event.target.checked) {
      const newSelecteds = productData.commonPrice.reduce((acc, cur) => { acc[cur.id] = true; return acc }, {});
      setSelectedPriceItems(newSelecteds);
      return;
    }
    setSelectedPriceItems({});
  }

  function handleSelectAllProductClick(event) {
    if (event.target.checked) {
      const newSelecteds = productData.convertibleProducts.reduce((acc, cur) => { acc[cur.id] = true; return acc }, {});
      setSelectedProductItems(newSelecteds);
      return;
    }
    setSelectedProductItems({});
  }

  function onMerchandiseChanged(merchandises) {
    if (merchandises.length) {
      for (let m of merchandises) {
        m.total = ''
        m.type = consumable ? '' : 'merchandises'
      }
      updateProductData({ name: 'merchandiseType' }, (productData.merchandiseType || []).concat(merchandises));
    }
  }

  function onCommonPriceChanged(commonPrices) {
    if (commonPrices.length) {
      updateProductData({ name: 'commonPrice' }, (productData.commonPrice || []).concat(commonPrices));
    }
  }

  function onConvertibleProductChanged(convertibleProducts) {
    if (convertibleProducts.length) {
      for (let p of convertibleProducts) {
        p.points = ''
      }
      updateProductData({ name: 'convertibleProducts' }, (productData.convertibleProducts || []).concat(convertibleProducts));
    }
  }

  function handleCheckboxClick(id) {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  function handleCheckboxPriceClick(id) {
    const selected = selectedPriceItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedPriceItems }
      delete newSelecteds[id]
      setSelectedPriceItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedPriceItems, [id]: true }
      setSelectedPriceItems(newSelecteds);
    }
  }

  function handleCheckboxProductClick(id) {
    const selected = selectedProductItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedProductItems }
      delete newSelecteds[id]
      setSelectedProductItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedProductItems, [id]: true }
      setSelectedProductItems(newSelecteds);
    }
  }

  function onCellValueChanged(id, field, value) {
    for (let m of productData.merchandiseType) {
      if (m.id === id) {
        m[field] = value
      }
    }

    updateProductData({ name: 'merchandiseType' }, [...productData.merchandiseType]);
  }

  function onCellCommonPriceChanged(id, field, value) {
    for (let m of productData.commonPrice) {
      if (m.id === id) {
        m[field] = value
      }
    }

    updateProductData({ name: 'commonPrice' }, [...productData.commonPrice]);
  }

  function onCellProductChanged(id, field, value) {
    for (let m of productData.convertibleProducts) {
      if (m.id === id) {
        m[field] = value
      }
    }

    updateProductData({ name: 'convertibleProducts' }, [...productData.convertibleProducts]);
  }

  function getTypeList() {
    return ['merchandises', 'adjustables'].map(m => (
      { label: formatMessage({ id: `product.merchandise.type.${m}` }), value: m }
    ))
  }

  function getEnableStatus(merchandise, field) {
    if (field === 'type') {
      if (!consumable) {
        return false
      }
      return true
    }

    if (merchandise.type === 'merchandises') {
      if (field === 'total') {
        return true
      }
      return false
    }

    return false
  }

  function updateProductData(field, value) {
    let newValue = value
    if (field.allowCharacter) {
      newValue = newValue.replace(field.allowCharacter, '')
    }

    if (field.maxLength) {
      newValue = newValue.substring(0, field.maxLength)
    }

    if (newValue === undefined || productData[field.name] === newValue) return;

    let err = validateField(field, value)

    let newData = { ...productData, [field.name]: newValue, [`${field.name}_err`]: err }

    setProductData(newData)
  }

  const formateData = (merchandise) => {
    const newData = { ...merchandise }
    newData.name = merchandiseMapping[newData.id].name

    return newData
  }

  function createField(field) {
    if (ignoreFields.includes(field.name)) return null
    if (field.type === 'text') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          disabled={field.disabled}
          required={field.required}
          type="text"
          size="small"
          label={formatMessage({ id: `product.edit.${field.name}` })}
          variant="outlined"
          onChange={e => { updateProductData(field, e.target.value) }}
          value={productData[field.name]}
          error={productData[`${field.name}_err`] ? true : false}
          helperText={productData[`${field.name}_err`]}
          fullWidth
        />
      </Grid>
    } else if (field.type === 'checkbox') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={3}>
        <FormControlLabel
          control={
            <Checkbox
              checked={productData[field.name]}
              onChange={e => { updateProductData(field, e.target.checked) }}
              color="primary"
            />
          }
          label={formatMessage({ id: `product.edit.${field.name}` })}
        />
      </Grid>
    } else if (field.type === 'select') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={3}>
        <TextField
          required={field.required}
          disabled={field.disabled}
          select
          type="text"
          size="small"
          label={formatMessage({ id: `product.edit.${field.name}` })}
          variant="outlined"
          onChange={e => { updateProductData(field, e.target.value) }}
          value={productData[field.name]}
          error={productData[`${field.name}_err`] ? true : false}
          helperText={productData[`${field.name}_err`]}
          fullWidth
        >
          {getMenuItem(field.name).map((option) => {
            return option.header ?
              (<ListSubheader disabled key={option.value}>{option.label}</ListSubheader>) :
              (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)
          })}
        </TextField>
      </Grid>
    } else if (field.type === 'multiline') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={12}>
        <TextField
          multiline
          disabled={field.disabled}
          required={field.required}
          type="text"
          size="small"
          label={formatMessage({ id: `product.edit.${field.name}` })}
          variant="outlined"
          onChange={e => { updateProductData(field, e.target.value) }}
          value={productData[field.name]}
          fullWidth
        />
      </Grid>
    } else if (field.type === '-') {
      if (field.text) {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12} style={{ position: 'relative' }}>
          <Divider />
          <DividerText>
            <Typography color="textSecondary" variant="caption">{field.text}</Typography>
          </DividerText>
        </Grid>
      } else {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
      }
    } else if (field.type === 'switch') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={3}>
        <Typography component="div" style={{ height: '100%' }}>
          <SwitchContainer>
            {formatMessage({ id: 'product.table.detail.directSales' })}
            <Switch
              color="primary"
              disabled={field.disabled}
              checked={productData[field.name]}
              onChange={e => { updateProductData(field, e.target.checked) }}
              name="consumable"
            />
            {formatMessage({ id: 'product.table.detail.treatmentConsume' })}
          </SwitchContainer>
        </Typography>
      </Grid>
    } else if (field.type === 'onTime') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <DatePickerField
          fullWidth
          label={formatMessage({ id: `product.edit.${field.name}` })}
          value={productData[field.name]}
          onChange={date => updateProductData(field, date)}
          minDateMessage={formatMessage({ id: 'form.date.beforeStartDate' })}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        />
      </Grid>
    } else if (field.type === 'offTime') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <DatePickerField
          fullWidth
          label={formatMessage({ id: `product.edit.${field.name}` })}
          value={productData[field.name]}
          minDate={dayjs(productData.onShelfDate).toDate()}
          onChange={date => updateProductData(field, date)}
          minDateMessage={formatMessage({ id: 'form.date.beforeStartDate' })}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        />
      </Grid>
    }
  }

  function _getContent() {
    let err = {}
    let newData = productData

    let merchandiseError = false
    for (const s of productData.merchandiseType) {
      let _fields = s.type === 'merchandises' ? [...merchandiseFields, 'total'] : [...merchandiseFields]

      for (const field of _fields) {
        if (s[field] === '') {
          if (!s.errors) s.errors = {}
          s.errors[field] = formatMessage({ id: 'form.field.isRequired' })
          merchandiseError = true
        }
      }
    }

    let convertibleProductError = false
    for (const s of productData.convertibleProducts) {
      for (const field of convertibleProductFields) {
        if (s[field] === '') {
          if (!s.errors) s.errors = {}
          s.errors[field] = formatMessage({ id: 'form.field.isRequired' })
          convertibleProductError = true
        }
      }
    }

    if (convertibleProductError) {
      newData = { ...productData, convertibleProducts: [...productData.convertibleProducts] }
    }

    if (merchandiseError) {
      newData = { ...productData, merchandiseType: [...productData.merchandiseType] }
    }

    if (Object.keys(err).length > 0) {
      newData = { ...productData, ...err }
    }
    if (Object.keys(err).length > 0 || merchandiseError || convertibleProductError) {
      setProductData(newData)
      return
    }

    for (const s of newData.merchandiseType) {
      let _fields = s.type === 'merchandises' ? [...merchandiseFields, 'total'] : [...merchandiseFields]
      for (const field of _fields) {
        if (s.errors && s.errors[field] !== undefined && s.errors[field] !== '') {
          // setLoading(false);
          return
        }
      }
    }

    let data = {}

    for (let field of fields) {
      if (field.type === '-') continue
      data[field.name] = newData[field.name]
    }

    if (newData.commonPrice.length > 0) {
      let newPrice = {}
      for (const c of newData.commonPrice) {
        newPrice[c.id] = {
          name: c.name,
          useAmount: Number(c.useAmount),
          price: Number(c.price),
          fixedAmount: c.fixedAmount,
          offShelfDate: c.offShelfDate ? dayjs(c.offShelfDate).format('YYYY-MM-DD') : '',
          onShelfDate: c.onShelfDate ? dayjs(c.onShelfDate).format('YYYY-MM-DD') : ''
        }

        if (newPrice[c.id].fixedAmount === '') {
          delete newPrice[c.id].fixedAmount
        } else {
          newPrice[c.id].fixedAmount = Number(newPrice[c.id].fixedAmount)
        }
      }

      data.commonPrice = newPrice
    }

    data.convertibleProducts = {}
    if (newData.convertibleProducts.length > 0) {
      for (const p of newData.convertibleProducts) {
        data.convertibleProducts[p.id] = Number(p.points)
      }
    }

    data.merchandises = []
    data.adjustables = []

    for (const merchandise of productData.merchandiseType) {
      if (merchandise.type === 'merchandises') {
        const newData = {
          id: merchandise.id,
          total: merchandise.total,
        }
        data.merchandises.push(newData)
      } else if (merchandise.type === 'adjustables') {
        const newData = {
          id: merchandise.id,
        }
        data.adjustables.push(newData)
      }
    }

    for (const key of Object.keys(data)) {
      if (!data[key] ||
        ![
          'nickname',
          'price',
          'operationBonus',
          'lowestPrice',
          'duration',
          'bonusType',
          'doctorBonusType',
          'salesBonusType',
          'onShelfDate',
          'offShelfDate',
          'merchandises',
          'adjustables',
          'description',
          'commonPrice',
          'convertibleProducts'
        ].includes(key)) {
        delete data[key]
      }
    }

    if (!data.nickname) {
      data.nickname = ''
    }

    if (data.price) {
      data.price = parseInt(data.price);
    }
    if (data.lowestPrice) {
      data.lowestPrice = parseInt(data.lowestPrice);
    }
    if (data.duration) {
      data.duration = parseInt(data.duration);
    }

    if (data.operationBonus) {
      data.operationBonus = String(data.operationBonus);
    }

    if (data.onShelfDate) {
      data.onShelfDate = dayjs(data.onShelfDate).format('YYYY-MM-DD')
    }

    if (data.offShelfDate) {
      data.offShelfDate = dayjs(data.offShelfDate).format('YYYY-MM-DD')
    } else {
      data.offShelfDate = ''
    }

    if (data.merchandises) {
      data.merchandises = data.merchandises.reduce((acc, cur) => {
        acc[cur.id] = {
          total: parseInt(cur.total),
        };
        return acc;
      }, {})
    } else {
      data.merchandises = [];
    }

    if (data.adjustables) {
      data.adjustables = data.adjustables.reduce((acc, cur) => {
        acc[cur.id] = true
        return acc;
      }, {})
    } else {
      data.adjustables = [];
    }

    if (data.salesBonusType) {
      if (data.salesBonusType === 'none') {
        delete data.salesBonusType
      } else {
        const currentMapping = filterTemplates.reduce((acc, cur) => {
          if (!acc[cur.id]) {
            acc[cur.id] = { ...cur }
          } else {
            acc = {
              ...acc,
              [cur.id]: { ...cur }
            }
          }
          return acc
        }, {})
        const { customer, ...newType } = currentMapping[data.salesBonusType]
        data.salesBonusType = newType
      }
    }

    return { ...data }
  }

  const filterProducts = Object.keys(productMapping).reduce((acc, cur) => {
    const product = productMapping[cur]
    if (product.customers && product.customers[customerId]) {
      if (product.cat.substr(0, 1) === '1' && product.cat.substr(1, 2) !== 'DP' && product.consumable) {
        acc.push({ id: cur, ...productMapping[cur] })
      }
    }
    return acc
  }, [])

  return (
    <div style={{ flexGrow: 1 }}>
      {openDialog && <SelectMerchandiseDialog
        customer={customerId}
        defaultSelectedItems={[]}
        handleClose={() => setOpenDialog(false)}
        handleSave={onMerchandiseChanged}
        ignoreIds={productData.merchandiseType.map(m => m.id)}
        hideFields={['stock']}
        size="lg"
      />}
      {openPriceDialog && <SelectCommonPriceDialog
        defaultSelectedItems={[]}
        handleClose={() => setOpenPriceDialog(false)}
        handleSave={onCommonPriceChanged}
        ignoreIds={productData.commonPrice.map(m => m.id)}
        hideFields={[]}
        size="xs"
        commonPriceData={commonPriceData}
      />}
      {openProductDialog && <SelectProductDialog
        customer={customerId}
        defaultSelectedItems={[]}
        handleClose={() => setOpenProductDialog(false)}
        handleSave={onConvertibleProductChanged}
        ignoreIds={productData.convertibleProducts.map(m => m.id)}
        hideFields={[]}
        size="lg"
        productData={filterProducts}
      />}
      <Grid container spacing={3} style={{ marginTop: '4px' }}>
        {fields.map(field => createField(field))}
      </Grid>
      <div style={{ margin: '10px 0' }}>
        <EnhancedTableToolbar
          title="product.commonPrices"
          selectdMessage="editReceipt.table.selected"
          numSelected={Object.keys(selectedPriceItems).length}
          toolbox={
            <Button
              disabled={false}
              sx={{ m: 1, whiteSpace: 'nowrap' }}
              variant="contained"
              color="primary"
              onClick={() => setOpenPriceDialog(true)}
            >
              <FormattedMessage id={'product.edit.addCommonPrice'} />
            </Button>
          }
          toolboxForSelection={
            <Button
              variant="contained"
              color="primary"
              startIcon={<DeleteIcon />}
              style={{ whiteSpace: 'nowrap', marginRight: '8px' }}
              onClick={onDeletePriceItems}
            >
              <FormattedMessage id="product.edit.removeCommonPrice" />
            </Button>
          }
        />
        <TableContainer sx={{ overflowX: 'unset' }} component={Paper}>
          <Table aria-label="collapsible table">
            <EnhancedTableHead
              headerCells={commonPriceHeaderCells}
              numSelected={Object.keys(selectedPriceItems).length}
              onCheckboxClick={handleSelectAllPriceClick}
              rowCount={productData.commonPrice.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(productData.commonPrice, getComparator(order, orderBy)).map((common, index) => (
                <EnhancedTableRow
                  key={index}
                  rowCells={commonPriceRowCells}
                  cellData={common}
                  onCheckboxClick={(e) => handleCheckboxPriceClick(common.id)}
                  selected={selectedPriceItems[common.id] || false}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {cat.substr(1, 2) === 'DP' &&
        <div style={{ margin: '10px 0' }}>
          <EnhancedTableToolbar
            title="product.convertibleProducts"
            selectdMessage="editReceipt.table.selected"
            numSelected={Object.keys(selectedProductItems).length}
            toolbox={
              <Button
                disabled={false}
                sx={{ m: 1, whiteSpace: 'nowrap' }}
                variant="contained"
                color="primary"
                onClick={() => setOpenProductDialog(true)}
              >
                <FormattedMessage id={'product.edit.addProduct'} />
              </Button>
            }
            toolboxForSelection={
              <Button
                variant="contained"
                color="primary"
                startIcon={<DeleteIcon />}
                style={{ whiteSpace: 'nowrap', marginRight: '8px' }}
                onClick={onDeleteProductItems}
              >
                <FormattedMessage id="product.edit.removeProduct" />
              </Button>
            }
          />
          <TableContainer sx={{ overflowX: 'unset' }} component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={convertibleProductHeaderCells}
                numSelected={Object.keys(selectedProductItems).length}
                onCheckboxClick={handleSelectAllProductClick}
                rowCount={productData.convertibleProducts.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(productData.convertibleProducts, getComparator(order, orderBy)).map((product, index) => (
                  <EnhancedTableRow
                    key={index}
                    rowCells={convertibleProductRowCells}
                    cellData={product}
                    onCheckboxClick={(e) => handleCheckboxProductClick(product.id)}
                    selected={selectedProductItems[product.id] || false}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>}
      {openCode === '1' && cat.substr(1, 2) !== 'DP' &&
        <div style={{ margin: '8px 0px' }}>
          <EnhancedTableToolbar
            title="product.merchandises"
            selectdMessage="editReceipt.table.selected"
            numSelected={Object.keys(selectedItems).length}
            toolbox={
              <Button
                disabled={false}
                sx={{ m: 1, whiteSpace: 'nowrap' }}
                variant="contained"
                color="primary"
                onClick={() => setOpenDialog(true)}
              >
                <FormattedMessage id={'product.edit.addMerchandise'} />
              </Button>
            }
            toolboxForSelection={
              <Button
                variant="contained"
                color="primary"
                startIcon={<DeleteIcon />}
                style={{ whiteSpace: 'nowrap', marginRight: '8px' }}
                onClick={onDeleteItems}
              >
                <FormattedMessage id="product.edit.removeMerchandise" />
              </Button>
            }
          />
          <TableContainer sx={{ overflowX: 'unset' }} component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                numSelected={Object.keys(selectedItems).length}
                onCheckboxClick={handleSelectAllClick}
                rowCount={productData.merchandiseType.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(productData.merchandiseType, getComparator(order, orderBy)).map(merchandise => (
                  <EnhancedTableRow
                    key={`${merchandise.id}`}
                    rowCells={rowCells}
                    cellData={formateData(merchandise)}
                    onCheckboxClick={(e) => handleCheckboxClick(merchandise.id)}
                    selected={selectedItems[merchandise.id] || false}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>}
    </div>
  );
})

EditNewProductView.displayName = 'EditNewProductView'

EditNewProductView.propTypes = {
  product: PropTypes.object,
  consumable: PropTypes.bool,
  ignoreFields: PropTypes.arrayOf(PropTypes.string),
  requiredFields: PropTypes.arrayOf(PropTypes.string),
  disabledFields: PropTypes.arrayOf(PropTypes.string),
  customerId: PropTypes.string.isRequired,
  cat: PropTypes.string,
  openCode: PropTypes.string,
  commonPriceData: PropTypes.arrayOf(PropTypes.object),
  salesBonusTemplates: PropTypes.arrayOf(PropTypes.object),
  productMapping: PropTypes.object.isRequired
};

export default EditNewProductView;
