import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import ContextStore from '../../modules/context';
import DelectIconButton from '../../components/DelectIconButton';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableToolbar from '../../components/EnhancedTableToolbar';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { getComparator, stableSort } from '../../modules/sort';
import { useFirestoreDataAndMapping, useSupplierDataAndMapping } from '../../modules/uitls';

function ShoppingCart({ source, ignoreSuppliers, highPrioritySuppliers, merchandises: shoppingCartMerchandises }) {
  const { formatMessage } = useIntl()
  const { setPurchaseOrderCache } = useContext(ContextStore)
  const navigate = useNavigate()

  const shoppingCart = { merchandises: shoppingCartMerchandises } //useSelector(state => state.firebase.data.shoppingCart) || {}
  const shoppingCartItemString = Object.keys(shoppingCart.merchandises || []).join()

  const [supplierMapping] = useSupplierDataAndMapping()
  const [merchandiseMapping] = useFirestoreDataAndMapping('merchandises')
  const [selectedItems, setSelectedItems] = useState({});
  const [merchandises, setMerchandises] = useState([])
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('requisitionSn');
  const [logs, setLogs] = useState([]);
  const [dailySnapshot, setDailySnapshot] = useState({});

  useEffect(() => {
    const today = dayjs().format('YYYY-MM-DD')
    const unsubscribe = firebase.firestore().collection('wh').doc(source).collection('logs').where('date', '==', today)
      .onSnapshot( snapshot => {
        const logs = []
        snapshot.forEach(doc => {
          const data = doc.data()
          if (data.valid !== false) {
            logs.push(data)
          }
        });
        setLogs(logs)
      }, err => {})
    return () => unsubscribe()
  }, [source]);

  useEffect(() => {
    const yesterday = dayjs().subtract(1, 'days').format('YYYY-MM-DD')
    const unsubscribe = firebase.firestore().collection('wh').doc(source).collection('dailySnapshot').doc(yesterday)
      .onSnapshot( snapshot => {
        setDailySnapshot(snapshot?.data()?.extData || {})
      }, err => {})
    return () => unsubscribe()
  }, [source]);

  useEffect(() => {
    const mapping = merchandises.reduce((acc, cur) => {acc[cur.key] = cur; return acc;}, {})
    const newMerchandises = Object.keys(shoppingCart.merchandises || [])
      .map(key => {
        const m = {
          key,
          ...(shoppingCart.merchandises[key]),
        }
        if (mapping[key] && mapping[key].supplier) m.supplier = mapping[key].supplier

        if (!m.supplier) {
          const suppliers = { ...(merchandiseMapping[m.id].suppliers) }
          const lps = []
          const hps = []
          for (const key of Object.keys(suppliers)) {
            if (ignoreSuppliers.includes(key)) {
              delete suppliers[key]
            }
            if (highPrioritySuppliers.includes(key)) {
              hps.push(key)
            } else {
              lps.push(key)
            }
          }
          if (hps.length) {
            for (const key of lps) {
              delete suppliers[key]
            }
          }
          const keys = Object.keys(suppliers)
          if (keys.length === 1) {
            m.supplier = keys[0]
          }
        }
        return m
      })

    const mapping2 = newMerchandises.reduce((acc, cur) => {acc[cur.key] = cur; return acc;}, {})
    const newSelecteds = { ...selectedItems }
    let needUpdate = false
    const keys = Object.keys(selectedItems)
    for (const key of keys) {
      if (!mapping2[key]) {
        delete newSelecteds[key]
        needUpdate = true
      }
    }
    if (needUpdate) {
      setSelectedItems(newSelecteds);
    }
    setMerchandises(newMerchandises);
  }, [shoppingCartItemString]);

  const merchandiseDailySnapshot = merchandises.reduce((acc, cur) => {
    if (!acc[cur.id]) acc[cur.id] = 0
    return acc
  }, { ...dailySnapshot })

  const realtimeStock = logs.reduce((acc, cur) => {
    if (acc[cur.merchandiseId] === undefined) return acc
    acc[cur.merchandiseId] += cur.quantity
    return acc
  }, { ...merchandiseDailySnapshot })

  function formatData(merchandise) {
    const newData = { ...merchandise }
    const mData = merchandiseMapping[newData.id]
    const ou = merchandise.orderBySku ? mData.sku : mData.orderUnit
    const sku = mData.sku
    const ou2sku = mData.ou2sku
    const convert = ou !== sku ? `(每${ou}=${ou2sku}${sku})` : ''

    newData.code = mData.code
    newData.name = mData.name
    newData.nickname = mData.nickname
    newData.orderUnit = ou
    const suppliers = { ...(mData.suppliers) }
    newData.suppliers = (Object.keys(suppliers) || []).map(s => ({ id: s, ...(suppliers[s]) }))
    newData.stock = mData.warehousing[source] ? `${realtimeStock[newData.id]}${mData.sku}${convert}` : `${formatMessage({ id: 'merchandise.warehousing.type2' })}${convert}`
    newData.supplier = newData.supplier ? supplierMapping[newData.supplier].nickname : ''
    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = merchandises.reduce((acc, cur) => {acc[cur.key] = true;return acc}, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  }

  function handleCheckboxClick(id) {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  function getSupplierList(merchandise) {
    const items = []
    for (const s of merchandise.suppliers) {
      if (supplierMapping[s.id]) {
        const supplier = supplierMapping[s.id]
        if (supplier.active === false || ignoreSuppliers.includes(supplier.id)) continue
        items.push({
          label: `${supplier.nickname}: MOQ(${s.moq}); 單價(${s.price})`,
          value: s.id,
        })
      }
    }
    return items
  }


  function getEnableStatus(merchandise) {
    return getSupplierList(merchandise).length > 1
  }

  const headerCells = [
    { text: 'requisitionSn', sort: 'requisitionSn' },
    { text: 'code', sort: 'code' },
    { text: 'nickname' },
    { text: 'amount', align: 'right' },
    { text: 'orderUnit', align: 'center' },
    { text: 'stock', align: 'right' },
    { text: 'expectedDate', sort: 'expectedDate', align: 'center' },
    { text: 'supplier', sort: 'supplier', align: 'right' }
  ].map(c => {c.text = formatMessage({ id: `shoppingCart.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'requisitionSn' },
    { field: 'code' },
    { field: 'nickname', tooltip: 'name' },
    { field: 'amount',
      align: 'right',
      type: 'input-number',
      label: '數量',
      params: ['key', 'maxAmount'],
      maxWidth: '100px',
      onValueChanged: onCellValueChanged,
      getEnableStatus: getEnableStatus
    },
    { field: 'orderUnit', align: 'center' },
    { field: 'stock', align: 'right' },
    { field: 'expectedDate', align: 'center' },
    { field: 'supplier',
      align: 'right',
      type: 'input-menu',
      label: '供應商',
      params: ['key'],
      onValueChanged: onCellValueChanged,
      getMenuItems: getSupplierList
    },
  ]

  function onCellValueChanged(key, field, value, params) {
    for (let m of merchandises) {
      if (m.key === params[0]) {
        if (field === 'amount') {
          if (isNaN(value) || value === '' || parseInt(value) === 0) {
            if (!m.errors) {
              m.errors = {}
            }
            m.errors.amount = '數量錯誤'
          } else if (value > params[1]) {
            if (!m.errors) {
              m.errors = {}
            }
            m.errors.amount = '超過請購數量'
          } else {
            if (m.errors && m.errors.amount) {
              delete m.errors.amount
            }
          }
          m.amount = value
        } else {
          m[field] = value
        }
        break
      }
    }
    setMerchandises([...merchandises]);
  }

  async function removeMerchandise(merchandise) {
    try {
      // setMerchandises(merchandises.filter(m => m.key !== merchandise.key)) // 在 UI 上直接移除
      for (const m of merchandises) {
        if (m.key === merchandise.key) {
          m.loading = true
          break
        }
      }
      setMerchandises([...merchandises]);
      await (firebase.functions().httpsCallable('removeFromShoppingCart'))({
        key: merchandise.key,
        requisitionId: merchandise.requisitionId,
        merchandiseId: merchandise.id
      })
    } catch (ex) {
      console.log(ex)
    }
  }

  async function moveItemsToPurchaseOrder() {
    for (const m of merchandises) {
      if (m.errors && m.errors.amount !== undefined) {
        return
      }
    }

    let supplier = ''
    let expectedDate
    const items = {}
    const itemKeys = {}
    for (const m of merchandises) {
      if (selectedItems[m.key]) {

        if (!expectedDate) {
          expectedDate = m.expectedDate
        } else {
          if (m.expectedDate && m.expectedDate < expectedDate) {
            expectedDate = m.expectedDate
          }
        }
        supplier = m.supplier
        const amount = parseInt(m.amount || 0)
        if (items[m.id]) {
          if (!items[m.id].note) {
            items[m.id].note = m.note
          }
          items[m.id].amount += amount
          items[m.id].keys.push(m.key)
          items[m.id].requisitions.push(m.requisitionId)
          // items[m.id].requisitionSnList.push(m.requisitionSn)
        } else {
          items[m.id] = {
            note: m.note,
            amount: amount,
            keys: [m.key],
            requisitions: [m.requisitionId],
            // requisitionSnList: [m.requisitionSn]
          }
          if (m.orderBySku) {
            items[m.id].orderBySku = true
          }
        }
        itemKeys[m.key] = amount
      }
    }

    const hash = getRandomHash()
    setPurchaseOrderCache({ [hash]: {
      expectedDate: expectedDate || '',
      source,
      supplier,
      merchandises: items,
      itemKeys,
    } })
    navigate(`/purchase/purchaseOrder/edit/cache-${hash}`);
  }

  function getRandomHash() {
    let hashCode = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    for (let i = 0; i < 16; ++i) {
      const r = Math.floor((Math.random() * (i === 0 ? 52 : 62) ));
      const char = characters.charAt(r);
      hashCode += char;
    }
    return hashCode;
  }

  let selectedSuppliers = {}
  for (const m of merchandises) {
    if (selectedItems[m.key]) {
      if (m.supplier) {
        selectedSuppliers[m.supplier] = true
      } else {
        selectedSuppliers = {}
        break
      }
    }
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Box p={2} sx={{ minHeight: 'calc(100vh - 160px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <EnhancedTableToolbar
          title="editPurchaseOrder.table.title"
          selectdMessage="editPurchaseOrder.table.selected"
          numSelected={Object.keys(selectedItems).length}
        />
        <TableContainer component={Paper}>
          <Table>
            <EnhancedTableHead
              headerCells={headerCells}
              order={order}
              orderBy={orderBy}
              numSelected={Object.keys(selectedItems).length}
              onCheckboxClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={merchandises.length}
              actionButton
            />
            <TableBody>
              {stableSort(merchandises.map(m => formatData(m)), getComparator(order, orderBy)).map(merchandise => (
                <EnhancedTableRow
                  key={merchandise.key}
                  rowCells={rowCells}
                  cellData={merchandise}
                  onCheckboxClick={(e) => handleCheckboxClick(merchandise.key)}
                  selected={selectedItems[merchandise.key] || false}
                  actionIcons={(!merchandise.purchaseOrders || Object.keys(merchandise.purchaseOrders).length === 0) ?
                    (merchandise.loading ? (
                      <IconButton aria-label="delete" disabled>
                        <CircularProgress size={24} />
                      </IconButton>) :
                      <DelectIconButton text={formatMessage({ id: 'shoppingCart.removeMerchandise' })} onClick={() => removeMerchandise(merchandise)} />) :
                    <IconButton aria-label="delete" disabled>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  }
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button
            disabled={Object.keys(selectedSuppliers).length !== 1}
            sx={{ m: 1, minWidth: 'unset', whiteSpace: 'nowrap' }}
            variant="contained"
            color="primary"
            startIcon={<OpenInNewIcon />}
            onClick={() => moveItemsToPurchaseOrder()}
          >
            <FormattedMessage id="shoppingCart.moveToPurchaseOrder" />
          </Button>
        </Stack>
      </Box>
    </div>
  );
}

ShoppingCart.propTypes = {
  source: PropTypes.string.isRequired,
  ignoreSuppliers: PropTypes.arrayOf(PropTypes.string).isRequired,
  highPrioritySuppliers: PropTypes.arrayOf(PropTypes.string).isRequired,
  merchandises: PropTypes.object.isRequired,
};

export default ShoppingCart;
