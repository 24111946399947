import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import ContextStore from '../../modules/context';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableToolbar from '../../components/EnhancedTableToolbar';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { useFirestoreDataAndMapping } from '../../modules/uitls';
import { getComparator, stableSort } from '../../modules/sort';

import SelectMerchandiseDialog from '../../components/SelectMerchandiseDialog';
import SelectStockDialog from './SelectStockDialog';

function MerchandiseDetail({ merchandise }) {
  const { formatMessage } = useIntl()
  const headerCells = [
    { text: 'expectedDate', sort: 'expectedDate' },
    { text: 'lotNumber' },
    { text: 'wareroomName', align: 'right' },
    { text: 'arrangementAmount', align: 'right' },
    { text: 'targetWareroomName', align: 'right' },
  ].map(c => {c.text = formatMessage({ id: `selectStockDialog.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'expectedDate' },
    { field: 'lotNumber' },
    { field: 'wareroomName', align: 'right' },
    { field: 'arrangementAmount', align: 'right' },
    { field: 'targetWareroomName', align: 'right' },
  ]

  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="collapsible table">
          <EnhancedTableHead
            headerCells={headerCells}
            numSelected={0}
            rowCount={merchandise.items.length}
          />
          <TableBody>
            {merchandise.items.map(m => (
              <EnhancedTableRow
                key={`${m.id}${m.wareroom || '#'}`}
                rowCells={rowCells}
                cellData={m}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

MerchandiseDetail.propTypes = {
  merchandise: PropTypes.object.isRequired,
};

function EditArrangeStockFormPage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const [customerMapping, customers] = useFirestoreDataAndMapping('customers')

  // 目前, 調撥單和出貨單共用權限
  const filteredSources = customers.filter(c => currentUser.deliveryOrderSource.includes(c.id))
  const [merchandiseMapping, merchandises] = useFirestoreDataAndMapping('merchandises')
  const [userMapping] = useFirestoreDataAndMapping('users')

  const [selectedItems, setSelectedItems] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const [loading, setLoading] = useState(false);
  const [lockSource, setLockSource] = useState(false);
  const [selectStockInfo, setSelectStockInfo] = useState(null);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const [logs, setLogs] = useState([]);
  const [dailySnapshot, setDailySnapshot] = useState({});

  const [arrangeStockForm, setArrangeStockForm] = useState({
    id: 'new',
    source: '',
    date: dayjs().format('YYYY-MM-DD'),
    createdBy: currentUser.key,
    createdAt: Math.floor(Date.now() / 1000) * 1000,
    note: '',
    merchandises: [],
  });

  useEffect(() => {
    if (filteredSources.length === 1) {
      updateArrangeStockFormData({ name: 'source' }, filteredSources[0].id);
    }
  }, [filteredSources.length]);

  useEffect(() => {
    const breadcrumbs = [
      { link: '/stock/arrangeStockForm', text: formatMessage({ id: 'sideMenu.stock.arrangeStockForm' }) },
      { text: formatMessage({ id: 'arrangeStockForm.dialog.title.add' }) }
    ]
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    // 撈即時庫存
    const today = dayjs().format('YYYY-MM-DD')
    const unsubscribe = arrangeStockForm.source ? firebase.firestore().collection('wh').doc(arrangeStockForm.source).collection('logs').where('date', '==', today)
      .onSnapshot( snapshot => {
        const logs = []
        snapshot.forEach(doc => {
          const data = doc.data()
          if (data.valid !== false) {
            logs.push(data)
          }
        });
        setLogs(logs)
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, [arrangeStockForm.source]);

  useEffect(() => {
    // 撈即時庫存
    const yesterday = dayjs().subtract(1, 'days').format('YYYY-MM-DD')
    const unsubscribe = arrangeStockForm.source ? firebase.firestore().collection('wh').doc(arrangeStockForm.source).collection('dailySnapshot').doc(yesterday)
      .onSnapshot( snapshot => {
        setDailySnapshot(snapshot?.data()?.extData || {})
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, [arrangeStockForm.source]);

  const merchandiseDailySnapshot = merchandises.reduce((acc, cur) => {
    if (!acc[cur.id]) acc[cur.id] = 0
    return acc
  }, { ...dailySnapshot })

  const realtimeStock = logs.reduce((acc, cur) => {
    if (acc[cur.merchandiseId] === undefined) return acc
    acc[cur.merchandiseId] += cur.quantity
    return acc
  }, { ...merchandiseDailySnapshot })

  const totalArrangement = arrangeStockForm.merchandises.reduce((acc, cur) => acc + cur.arrangementAmount, 0)

  const headerCells = [
    { field: 'code', sort: 'code' },
    { field: 'name' },
    { field: 'stock', align: 'right' },
    { field: 'sku', align: 'right' },
    { field: 'note', align: 'right' },
  ].map(c => {c.text = formatMessage({ id: `arrangeStockForm.merchandise.${c.field}` });return c})

  const rowCells = [
    { field: 'code' },
    { field: 'nickname', tooltip: 'name' },
    { field: 'stock', align: 'right' },
    { field: 'sku', align: 'right' },
    { field: 'note', align: 'right', type: 'input', required: false, label: '備註', onValueChanged: onCellValueChanged },
  ]

  const handleRequestTable1Sort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function formatData(merchandise) {
    const newData = {
      ...merchandise,
      code: merchandiseMapping[merchandise.id].code,
      name: merchandiseMapping[merchandise.id].name,
      nickname: merchandiseMapping[merchandise.id].nickname,
    }
    newData.require = newData.amount + newData.orderUnit
    newData.sku = merchandiseMapping[merchandise.id].sku
    newData.ou2sku = merchandiseMapping[merchandise.id].ou2sku
    newData.stock = realtimeStock[merchandise.id]
    return newData
  }

  function onCellValueChanged(id, field, value, params) {
    for (let m of arrangeStockForm.merchandises) {
      if (m.id === id) {
        m[field] = value
        break
      }
    }
    updateArrangeStockFormData({ name: 'merchandises' }, arrangeStockForm.merchandises);
  }

  function onMerchandiseAdded(merchandises) {
    if (merchandises.length) {
      for (let m of merchandises) {
        m.note = ''
        m.arrangementAmount = 0
        m.items = []
      }
      updateArrangeStockFormData({ name: 'merchandises' }, arrangeStockForm.merchandises.concat(merchandises));
    }
  }

  function onSelectStockItems(m) {
    const arrangementAmount = m.items.reduce((acc, cur) => acc + parseInt(cur.arrangementAmount), 0)
    for (let merchandise of arrangeStockForm.merchandises) {
      if (merchandise.id === m.merchandiseId) {
        merchandise.arrangementAmount = arrangementAmount
        merchandise.items = m.items
        updateArrangeStockFormData({ name: 'merchandises' }, arrangeStockForm.merchandises);
      }
    }
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = arrangeStockForm.merchandises.reduce((acc, cur) => {acc[cur.id] = true;return acc}, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  }

  function handleCheckboxClick(id) {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  function onDeleteItems() {
    const merchandises = arrangeStockForm.merchandises.filter(m => !selectedItems[m.id])
    if (merchandises.length !== arrangeStockForm.merchandises.length) {
      updateArrangeStockFormData({ name: 'merchandises' }, merchandises);
      setSelectedItems({});
    }
  }

  function validateField(field, value) {
    // if (field.required && value === '') {
    //   return formatMessage({id: 'form.field.isRequired'})
    // }
    if (field.name === 'merchandises') {
      for (const m of value) {
        if (m.errors && m.errors.amount) {
          return m.errors.amount
        }
      }
    } else if (field.name === 'expectedDate' && value === '') {
      return formatMessage({ id: 'form.date.formatError' })
    } else if (field.name === 'invoiceNumber' && value && value !== '  -        ' && value.trim().length !== 11) {
      return formatMessage({ id: 'form.invoiceNumber.formatError' })
    }
    return ''
  }

  function updateArrangeStockFormData(field, value) {
    let newValue = value
    if (field.uppercase) {
      newValue = newValue.toUpperCase()
    }

    let err = validateField(field, value)

    let newData = { ...arrangeStockForm, [field.name]: newValue, [`${field.name}_err`]: err }

    setArrangeStockForm(newData)
  }

  async function handleSave() {
    setLoading(true);

    const fields = [
      { name: 'source' },
      { name: 'date' },
      { name: 'merchandises' },
      { name: 'note' },
    ]

    // let err = {}
    let newData = arrangeStockForm
    for (let field of fields) {
      if (newData[`${field.name}_err`] !== undefined && newData[`${field.name}_err`] !== '') {
        setLoading(false);
        return
      }
    }

    let data = {}
    for (let field of fields) {
      if (field.type === '-') continue
      data[field.name] = newData[field.name]
    }

    data.merchandises = data.merchandises.reduce((acc, cur) => {
      if (parseInt(cur.arrangementAmount) > 0) {
        const warehousing = !!merchandiseMapping[cur.id].warehousing[data.source]
        acc[cur.id] = {
          note: cur.note,
        };
        if (warehousing) {
          acc[cur.id].items = cur.items.map(i => ({
            arrangementAmount: i.arrangementAmount,
            id: i.id,
            targetWareroom: i.targetWareroom,
            wareroom: i.wareroom
          }))
        }
      }
      return acc;
    }, {})

    try {
      // console.log(JSON.stringify({id: arrangeStockForm.id, ...data}, null, 4))
      await (firebase.functions().httpsCallable('saveArrangeStockForm'))({ id: arrangeStockForm.id, ...data })
    } catch (ex) {
      console.log(ex)
    }
    // setLoading(false);
    handleClose()
  }

  function handleClose() {
    navigate('/stock/arrangeStockForm');
  }

  function getSourceSelector() {
    if (currentUser.deliveryOrderSource.length === 1) {
      return (<TextField
        disabled
        type="text"
        label={formatMessage({ id: 'arrangeStockForm.table.detail.source' })}
        variant="outlined"
        value={customerMapping[currentUser.deliveryOrderSource[0]].nickname}
        fullWidth
        size="small"
      />)
    } else {
      return (<TextField
        select
        required
        disabled={lockSource}
        type="text"
        label={formatMessage({ id: 'arrangeStockForm.table.detail.source' })}
        variant="outlined"
        value={arrangeStockForm.source}
        onChange={e => updateArrangeStockFormData({ name: 'source' }, e.target.value)}
        fullWidth
        size="small"
        error={arrangeStockForm.source_err ? true : false}
        helperText={arrangeStockForm.source_err}
      >
        {filteredSources.map(c => <MenuItem key={c.id} value={c.id}>
          {c.nickname}
        </MenuItem>)}
      </TextField>)
    }
  }

  return (
    <div style={{ flexGrow: 1 }}>
      {selectStockInfo && <SelectStockDialog
        customer={arrangeStockForm.source}
        merchandise={selectStockInfo}
        handleClose={() => setSelectStockInfo(null)}
        handleSave={onSelectStockItems}
      />}
      {openDialog && <SelectMerchandiseDialog
        customer={arrangeStockForm.source}
        defaultSelectedItems={[]}
        handleClose={() => setOpenDialog(false)}
        handleSave={onMerchandiseAdded}
        ignoreIds={arrangeStockForm.merchandises.map(m => m.id)}
        defaultCategoryFilter={{ warehousing: 'type1' }}
        hideFields={[]}
        loadStockMapping={true}
      />}
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            {getSourceSelector()}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'arrangeStockForm.table.detail.createdBy' })}
              variant="outlined"
              value={userMapping[arrangeStockForm.createdBy] ? userMapping[arrangeStockForm.createdBy].displayName : ''}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'arrangeStockForm.table.detail.createdAt' })}
              variant="outlined"
              value={dayjs(arrangeStockForm.createdAt).format('YYYY-MM-DD')}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: '8px 0px' }} />
        <EnhancedTableToolbar
          title="editArrangeStockForm.table.title"
          selectdMessage="editArrangeStockForm.table.selected"
          numSelected={Object.keys(selectedItems).length}
          toolbox={
            <Button
              disabled={arrangeStockForm.source === ''}
              sx={{ m: 1, whiteSpace: 'nowrap' }}
              variant="contained"
              color="primary"
              onClick={() => {
                setLockSource(true)
                setOpenDialog(true)
              }}
            >
              <FormattedMessage id="editArrangeStockForm.addMerchandise" />
            </Button>
          }
          toolboxForSelection={
            <Button
              variant="contained"
              color="primary"
              startIcon={<DeleteIcon />}
              style={{ whiteSpace: 'nowrap', marginRight: '8px' }}
              onClick={onDeleteItems}
            >
              <FormattedMessage id="editArrangeStockForm.removeMerchandise" />
            </Button>
          }
        />
        <TableContainer component={Paper}>
          <Table>
            <EnhancedTableHead
              headerCells={headerCells}
              numSelected={Object.keys(selectedItems).length}
              onCheckboxClick={handleSelectAllClick}
              rowCount={arrangeStockForm.merchandises.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestTable1Sort}
              actionButton
              expandable
            />
            <TableBody>
              {stableSort(arrangeStockForm.merchandises.map(m => formatData(m)), getComparator(order, orderBy)).map(merchandise => (
                <EnhancedTableRow
                  key={merchandise.id}
                  rowCells={rowCells}
                  cellData={merchandise}
                  onCheckboxClick={(e) => handleCheckboxClick(merchandise.id)}
                  selected={selectedItems[merchandise.id] || false}
                  lock={merchandise.lock}
                  expandable
                  expandContent={<MerchandiseDetail merchandise={merchandise} />}
                  actionIcons={
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ whiteSpace: 'nowrap' }}
                      onClick={() => setSelectStockInfo(merchandise)}
                    >
                      <FormattedMessage id="editArrangeStockForm.selectStockItems" />
                    </Button>
                  }
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'arrangeStockForm.table.detail.note' })}
              variant="outlined"
              value={arrangeStockForm.note}
              onChange={e => updateArrangeStockFormData({ name: 'note' }, e.target.value)}
              fullWidth
              size="small"
              multiline
            />
          </Grid>
        </Grid>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            disabled={loading || (arrangeStockForm.merchandises.length === 0) || totalArrangement === 0}
            loading={loading}
            loadingPosition="start"
            loadingIndicator={<CircularProgress size={24} />}
            startIcon={<div />}
            variant="contained"
          >
            <FormattedMessage id="button.submit" />
          </LoadingButton>
        </Stack>
      </Box>
    </div>
  );
}

export default EditArrangeStockFormPage;
