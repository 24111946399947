const i18n = {
  'sideMenu': {
    'user': '使用者',
    'vendor': '廠商',
    'purchase': {
      'root': '採購',
      'requisition': '請購單',
      'purchaseOrder': '採購單',
      'receipt': '進貨單',
      'returnForm': '退貨單',
    },
    'stock': {
      'root': '庫存管理',
      'query': '庫存查詢',
      'inventoryCheck': '盤點單',
      'inventoryChanges': '庫存異動單',
      'arrangeStockForm': '庫存調撥單',
      'scrapForm': '報廢出庫單',
      'stockRequisition': '沙貨領用單',
    },
    'borrowing': {
      'root': '借貨&歸還',
      'borrowingOrder': '借貨單',
      'borrowingDelivery': '借貨出貨單',
      'borrowingReceipt': '借貨入庫單',
      'returnBorrowingForm': '借貨歸還單',
      'returnBorrowingReceipt': '借貨歸還入庫單',
    },
    'sales': {
      'root': '營業&銷售',
      'deliveryOrder': '銷貨單',
      'salesReturnForm': '銷貨退回單',
    },
    'notification': '提醒',
    'fixed-assets': '固定資產',
    'reports': {
      'root': '報表',
      'receipt': '進貨單',
      'stock': '進銷存'
    },
    'finance': {
      'root': '財務',
      'doctorBonus': '醫師獎金',
      'salesBonus': '銷售/操作獎金',
    },
    'merchandise': '商品管理',
    'medicine': '藥品管理',
    'product': {
      'root': '療程產品管理',
      'warehouse': '療程管理',
      'customer': '客戶療程管理',
    },
    'profile': {
      'root': '新資料申請',
      'newSupplier': '新供應商申請',
      'newMerchandise': '新商品申請',
      'newProduct': '新療程申請',
      'newPackageProduct': '新套裝療程申請',
      'newFolderProduct': '新集合療程申請',
      'newCombinationProduct': '新組合療程申請',
    }
  }
}
export default i18n
