import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// import { useParams } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';

// import DateRangePickerField from '../../components/DateRangePickerField';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import SimpleTableToolbar from '../../components/SimpleTableToolbar';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { getComparator, stableSort } from '../../modules/sort';
import SearchBox from '../../components/SearchBox';
import ExpandButton from '../../components/ExpandButton';
import InventoryCheckView from './InventoryCheckView';
import { useFirestoreDataAndMapping } from '../../modules/uitls';

function InventoryCheckDetail({ userMapping, inventoryCheck, ...props }) {
  const r = { ...inventoryCheck }
  const keys = inventoryCheck.merchandises ? Object.keys(inventoryCheck.merchandises) : []
  r.merchandises = keys.map(k => ({ ...r.merchandises[k], id: k }))
  r.createdBy = { id: r.createdBy, name: userMapping[r.createdBy]?.displayName }
  return (
    <div style={{ padding: 15 }}>
      <InventoryCheckView userMapping={userMapping} inventoryCheck={r} {...props} />
    </div>
  )
}

InventoryCheckDetail.propTypes = {
  inventoryCheck: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
};

function InventoryCheckList({ inventoryChecks, title }) {
  const { formatMessage } = useIntl()
  const [userMapping] = useFirestoreDataAndMapping('users')
  const [customerMapping] = useFirestoreDataAndMapping('customers')
  const [merchandiseMapping] = useFirestoreDataAndMapping('merchandises')

  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('sn')
  const [currentFilter, setCurrentFilter] = useState(null)
  const [expand, setExpand] = useState(true);
  const filteredInventoryChecks = currentFilter && currentFilter.text ? filterByText() : inventoryChecks

  const headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'sourceName', sort: 'sourceName' },
    { text: 'createdBy', sort: 'createdBy' },
    { text: 'date', sort: 'createdAt' },
    { text: 'baseDate', sort: 'baseDate' },
  ].map(c => {c.text = formatMessage({ id: `inventoryCheck.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'sn' },
    { field: 'sourceName' },
    { field: 'createdBy' },
    { field: 'date' },
    { field: 'baseDate' },
  ]

  function filterByText() {
    if (currentFilter.name === 'sourceName') {
      return inventoryChecks.filter(s => customerMapping[s.source]?.nickname.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'createdBy') {
      return inventoryChecks.filter(s => userMapping[s.createdBy]?.displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else {
      return inventoryChecks.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
  }

  const formatData = (inventoryCheck) => {
    const newData = { ...inventoryCheck }
    newData.sourceName = customerMapping[newData.source]?.nickname
    newData.createdBy = userMapping[newData.createdBy]?.displayName
    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filterItems = [
    { name: 'sn' },
    { name: 'sourceName', type: 'customer' },
    { name: 'createdBy' },
    { name: 'date' },
    { name: 'baseDate' },
    { name: 'note' },
  ].map(i => {i.text = formatMessage({ id: `inventoryCheck.table.detail.${i.name}` });return i})

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  return (
    <div>
      <SimpleTableToolbar
        title={title}
        buttons={<ExpandButton open={expand} onExpandChange={setExpand} />}
        toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
      />
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: '650px' }}>
            <EnhancedTableHead
              headerCells={headerCells}
              order={order}
              orderBy={orderBy}
              expandable
              onRequestSort={handleRequestSort}
              rowCount={filteredInventoryChecks.length}
            />
            <TableBody>
              {stableSort(filteredInventoryChecks.map(r => formatData(r)), getComparator(order, orderBy)).map(inventoryCheck => (
                <EnhancedTableRow
                  key={inventoryCheck.id}
                  rowCells={rowCells}
                  cellData={inventoryCheck}
                  expandable
                  expandContent={<InventoryCheckDetail userMapping={userMapping} merchandiseMapping={merchandiseMapping} inventoryCheck={inventoryCheck} />}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </div>
  );
}

InventoryCheckList.propTypes = {
  inventoryChecks: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  title: PropTypes.string.isRequired,
};

export default InventoryCheckList;
