import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import EnhancedTable from '../../components/EnhancedTable';
import ActionDialog from '../../components/ActionDialog';
import ProgressStep from '../../components/ProgressStep';
import { useFirestoreDataAndMapping } from '../../modules/uitls';
import ContextStore from '../../modules/context';
import { TAX_RATE } from '../../constants/index';

function MerchandiseDetail({ items }) {
  const { formatMessage } = useIntl()
  const headerCells = [
    { field: 'amount' },
    { field: 'expectedDate' },
    { field: 'lotNumber' },
  ].map(c => {c.text = formatMessage({ id: `deliveryOrder.merchandise.${c.field}` });return c})

  const rowCells = [
    { field: 'amount' },
    { field: 'expectedDate' },
    { field: 'lotNumber' },
  ]

  return (
    <EnhancedTable
      size="small"
      headerCells={headerCells}
      rowCells={rowCells}
      tableData={items}
    />
  )
}

MerchandiseDetail.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object.isRequired),
};

function DeliveryOrderView({ deliveryOrder, userMapping, merchandiseMapping, formName }) {
  const { formatMessage } = useIntl()
  const { currentUser } = useContext(ContextStore)

  const [dialogData, setDialogData] = useState(null);
  const [loadingVoid, setLoadingVoid] = useState(false);
  const [customerMapping] = useFirestoreDataAndMapping('customers')

  const deliveryOrderHistory = (deliveryOrder.history || []).map(h => ({ ...h }))
  // const currentStep = deliveryOrderHistory.length > 0 ? deliveryOrderHistory[deliveryOrderHistory.length - 1].step : 0

  let refSn = ''
  if (formName === 'deliveryOrder') {
    refSn = 'purchaseOrderSn'
  } else if (formName === 'borrowingDelivery') {
    refSn = 'borrowingOrderSn'
  } else if (formName === 'returnBorrowingForm') {
    refSn = 'borrowingOrderSn'
  }

  const _headerCells = [
    { field: refSn, sort: refSn, order: 0 },
    { field: 'code', sort: 'code', order: 1 },
    { field: 'name', order: 2 },
    // {field: 'unitPrice', align: 'right', order: 3},
    { field: 'amount', align: 'right', order: 4 },
    { field: 'orderUnit', align: 'right', order: 5 },
    { field: 'note', align: 'right', order: 7 }
  ]//.map(c => {c.text = formatMessage({id: `${formName}.merchandise.${c.field}`});return c})

  const _rowCells = [
    { field: refSn, order: 0 },
    { field: 'code', order: 1 },
    { field: 'nickname', tooltip: 'name', order: 2 },
    // {field: 'unitPrice', align: 'right', order: 3},
    { field: 'amount', align: 'right', order: 4 },
    { field: 'orderUnit', align: 'right', order: 5 },
    { field: 'note', align: 'right', order: 7, maxWidth: '120px' }
  ]

  const headerCells = (formName === 'deliveryOrder' ?
    [..._headerCells,
      { field: 'unitPrice', align: 'right', order: 3 },
      { field: 'price', align: 'right', order: 6 }
    ] :
    _headerCells
  ).map(c => {c.text = formatMessage({ id: `${formName}.merchandise.${c.field}` });return c}).sort((a, b) => a.order - b.order)

  const rowCells = (formName === 'deliveryOrder' ?
    [..._rowCells,
      { field: 'unitPrice', align: 'right', order: 3 },
      { field: 'price', align: 'right', order: 6, type: 'calculate',calculate: 'unitPrice*amount' }
    ] :
    _rowCells
  ).sort((a, b) => a.order - b.order)

  const invoiceSubtotal = deliveryOrder.merchandises.reduce((acc, cur) => {
    acc += cur.unitPrice * cur.amount
    return acc;
  }, 0)
  const totalDiscount = deliveryOrder.merchandises.reduce((acc, cur) => {
    acc += (cur.discount || 0)
    return acc;
  }, deliveryOrder.discount || 0)

  const invoiceTaxes = deliveryOrder.taxIncluded ? 0 : Math.round(TAX_RATE * (invoiceSubtotal - totalDiscount));
  const invoiceTotal = invoiceTaxes + invoiceSubtotal - totalDiscount;

  function formatData(merchandise) {
    return {
      ...merchandise,
      code: merchandiseMapping[merchandise.id].code,
      name: merchandiseMapping[merchandise.id].name,
      nickname: merchandiseMapping[merchandise.id].nickname,
      orderUnit: merchandise.orderBySku ? merchandiseMapping[merchandise.id].sku : merchandiseMapping[merchandise.id].orderUnit,
      // statusText,
    }
  }

  // function edit() {
  //   history.push(`/purchase/receipt/edit/${receipt.id}`);
  // }

  // function reject() {
  //   setDialogData({action: 'reject', title: ''})
  // }

  function abandon() {
    setDialogData({ action: 'void', title: '' })
  }

  function handleClose() {
    setDialogData(null)
  }

  async function handleExecute(data) {
    const { action, text } = data
    setDialogData(null)
    setLoadingVoid(true)
    try {
      await (firebase.functions().httpsCallable('reviewDeliveryOrder'))({
        id: deliveryOrder.id,
        formName,
        action,
        note: text
      })
      setLoadingVoid(false)
    } catch (ex) {
      setLoadingVoid(false)
      console.log(ex)
    }
  }

  function decodeModifyLog(log) {
    if (!deliveryOrder.steps[0].overwrite.includes(currentUser.key)) {
      log = log.split('\n').filter(line => (!line.includes('f{discount}') && !line.includes('f{merchandiseDiscount}'))).join('\n')
    }
    log = log.replace(/f{modify}/g, formatMessage({ id: 'step.action.modify' }))
    log = log.replace(/f{update}/g, formatMessage({ id: 'step.action.update' }))
    log = log.replace(/f{add}/g, formatMessage({ id: 'step.action.add' }))
    log = log.replace(/f{remove}/g, formatMessage({ id: 'step.action.remove' }))
    // log = log.replace(/f{note}/g, formatMessage({id: 'deliveryOrder.table.detail.note'}))
    // log = log.replace(/f{discount}/g, formatMessage({id: 'deliveryOrder.table.detail.discount'}))
    // log = log.replace(/f{invoiceNumber}/g, formatMessage({id: 'deliveryOrder.table.detail.invoiceNumber'}))
    // log = log.replace(/f{merchandise}/g, formatMessage({id: 'deliveryOrder.table.detail.merchandise'}))
    // log = log.replace(/f{unitPrice}/g, formatMessage({id: 'deliveryOrder.merchandise.unitPrice'}))
    // log = log.replace(/f{amount}/g, formatMessage({id: 'deliveryOrder.merchandise.amount'}))
    // log = log.replace(/f{purchaseOrderSn}/g, formatMessage({id: 'deliveryOrder.merchandise.purchaseOrderSn'}))
    // log = log.replace(/f{lotNumber}/g, formatMessage({id: 'deliveryOrder.merchandise.lotNumber'}))
    // log = log.replace(/f{expectedDate}/g, formatMessage({id: 'deliveryOrder.merchandise.expectedDate'}))
    // log = log.replace(/f{extra}/g, formatMessage({id: 'deliveryOrder.merchandise.extra'}))
    // log = log.replace(/f{merchandiseDiscount}/g, formatMessage({id: 'deliveryOrder.merchandise.discount'}))
    // log = log.replace(/f{merchandiseNote}/g, formatMessage({id: 'deliveryOrder.merchandise.note'}))

    let matchs = [...new Set((log.match(/i{.+?}/g) || []).map(i => i.substring(2, i.length - 1)))]
    for (const m of matchs) {
      log = log.replace((new RegExp(`i{${m}}`, 'g')), merchandiseMapping[m].name)
    }
    return log
  }

  const steps = [...deliveryOrderHistory]
  for (const step of steps) {
    if (step.action === 'modify') {
      step.detail = decodeModifyLog(step.note)
    }
    if (step.dateTime) {
      const s = step.dateTime.split(' ')
      step.text = step.name + `\n${userMapping[step.user]?.displayName}[${formatMessage({ id: 'step.action.' + step.action })}]`
      step.text += `\n日期: ${s[0]}`
      step.text += `\n時間: ${s[1]}`
      if (step.action === 'modify') {
        step.text += `${step.detail ? '\n' : ''}`
      } else {
        step.text += `${step.note ? '\n備註: ' + step.note : ''}`
      }
    } else {
      step.text = step.name
      if (step.users) {
        step.hint = step.users.map(u => userMapping[u]?.displayName || '').join(' / ')
      }
    }
    if (['reject', 'void'].includes(step.action)) {
      // step.stepProps = {completed: false}
      step.labelProps = { error: true }
    }
  }
  // const lastHistory = deliveryOrder.history[deliveryOrder.history.length - 1]

  return (
    <div style={{ flexGrow: 1, height: '100%' }}>
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` }) + (deliveryOrder.void ? formatMessage({ id: 'button.void' }) : '') + formatMessage({ id: 'deliveryOrder.name' })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: `${formName}.table.detail.note` })}
        action={dialogData.action}
      />}

      <Box p={0}>
        <ProgressStep activeStep={deliveryOrder.history.length} steps={steps} />
        <EnhancedTable
          size="small"
          defaultOrder="asc"
          defaultOrderField="code"
          headerCells={headerCells}
          rowCells={rowCells}
          getExpandContent={merchandise =>
            merchandise.warehousing ? <MerchandiseDetail items={merchandise.items} /> : (null)
          }
          tableData={deliveryOrder.merchandises.map(m => formatData(m))}
          extRows={
            formName === 'deliveryOrder' ?
              <>
                <TableRow>
                  <TableCell rowSpan={3} colSpan={6}/>
                  <TableCell align="right"><Typography variant="subtitle2"><FormattedMessage id="invoice.subtotal" /></Typography></TableCell>
                  <TableCell align="right">{invoiceSubtotal}</TableCell>
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell align="right"><Typography variant="subtitle2"><FormattedMessage id="invoice.tax" /></Typography></TableCell>
                  <TableCell align="right">{invoiceTaxes}</TableCell>
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell align="right"><Typography variant="subtitle2"><FormattedMessage id="invoice.total" /></Typography></TableCell>
                  <TableCell align="right">{invoiceTotal}</TableCell>
                  <TableCell />
                </TableRow>
              </> : null
          }
        />
        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: `${formName}.table.detail.note` })}
              value={deliveryOrder.note}
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
          <Grid item key="buttons" xs={12} sm={12} md={12}>
            <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
              {!deliveryOrder.lock && customerMapping[deliveryOrder.source].sales.deliveryOrder.includes(currentUser.key) &&
                <LoadingButton
                  color="error"
                  onClick={abandon}
                  disabled={loadingVoid}
                  loading={loadingVoid}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.void" />
                </LoadingButton>
              }
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

// DeliveryOrderView.defaultProps = {

// }

DeliveryOrderView.propTypes = {
  deliveryOrder: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
};

export default DeliveryOrderView;
