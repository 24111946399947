const i18n = {
  'scrapForm': {
    'tab': {
      'pending': '等候中',
      'done': '已完成',
      'void': '已作廢',
    },
    'name': '報廢出庫單',
    'table': {
      'pending': {
        'title': '報廢出庫單列表 - 待簽核',
      },
      'title': '報廢出庫單列表',
      'selected': '已選擇 {count} 筆報廢出庫單',
      'header': {
        'sn': '報廢出庫單號',
        'source': '報廢單位',
        'sourceName': '報廢單位',
        'createdBy': '建立人',
        'createdAt': '建立日期',
        'date': '報廢日期',
      },
      'detail': {
        'sn': '報廢出庫單號',
        'source': '報廢單位',
        'sourceName': '報廢單位',
        'createdBy': '建立人',
        'createdAt': '建立日期',
        'date': '建立日期',
        'note': '備註',
        'merchandise': '商品',
      },
    },
    'dialog': {
      'title': {
        'add': '新增報廢出庫單',
        'edit': '編輯報廢出庫單',
      }
    },
    'merchandise': {
      'code': '品號',
      'name': '品名',
      'unitPrice': '單價',
      'stock': '庫存數量',
      'scrap': '報廢數量',
      'amount': '報廢數量',
      'sku': '單位',
      'price': '金額',
      'note': '備註',
      'statusText': '狀態',
    }
  },
  'editScrapForm': {
    'table': {
      'title': '報廢出庫商品清單',
      'selected': '已選擇 {count} 個項目',
    },
    'selectStockItems': '選擇庫存',
    'addMerchandise': '新增商品',
    'removeMerchandise': '移除商品',
  }
}
export default i18n
