import React, { useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import DelectIconButton from './DelectIconButton';
import EnhancedTable from './EnhancedTable';
import SelectMultiSupplierDialog from './SelectMultiSupplierDialog';
import SelectCustomerDialog from './SelectCustomerDialog';
import { useFirestoreDataAndMapping, useSupplierDataAndMapping, sortByField } from '../modules/uitls';

const supplierFields = ['moq', 'price']

const EditNewMerchandiseView = forwardRef((props, ref) => {
  const { ignoreFields = [], requiredFields = [], disabledFields = [], merchandise } = props
  const { formatMessage } = useIntl()

  const [customerMapping] = useFirestoreDataAndMapping('customers')
  const [supplierMapping] = useSupplierDataAndMapping()

  const customers = Object.keys(merchandise.customers || []).filter(c => customerMapping[c])
  const orderBySku = Object.keys(merchandise.orderBySku || []).filter(c => customerMapping[c])
  const warehousing = Object.keys(merchandise.warehousing || []).filter(c => customerMapping[c])

  // const suppliers = Object.keys(merchandise.suppliers || [])

  const suppliers = Object.keys(merchandise.suppliers || [])
    .filter(id => supplierMapping[id])
    .map(id => ({
      ...merchandise.suppliers[id],
      id,
      code: supplierMapping[id].code,
      name: supplierMapping[id].name,
      nickname: supplierMapping[id].nickname
    }))

  const defaultState = {
    name: merchandise.name,
    nickname: merchandise.nickname,
    type: merchandise.type,
    category: merchandise.category,
    orderUnit: merchandise.orderUnit,
    sku: merchandise.sku,
    ou2sku: merchandise.ou2sku,
    customers: customers.map(s => customerMapping[s]),
    orderBySku: orderBySku.map(s => customerMapping[s]),
    warehousing: warehousing.map(s => customerMapping[s]),
    suppliers: suppliers.sort(sortByField('code')),
    note: merchandise.note,
  }

  const [openSupplierDialog, setOpenSupplierDialog] = useState(false);
  const [openCustomerDialog, setOpenCustomerDialog] = useState('');

  const [merchandiseData, setMerchandiseData] = useState(defaultState);
  // const [loading, setLoading] = useState(false);

  useImperativeHandle(
    ref, () => ({
      getContent: () => {
        return _getContent()
      }
    }),
  )

  // const phoneRule = /[^0-9#]/g
  const numberRule = /[^0-9]/g

  const fields = [
    { name: 'name', type: 'text', sm: 6 },
    { name: 'nickname', type: 'text', sm: 6 },
    { name: 'type', type: 'select', sm: 6 },
    { name: 'category', type: 'select', sm: 6 },
    { name: 'ou', type: '-' },
    { name: 'orderUnit', type: 'text', sm: 6 },
    { name: 'sku', type: 'text', sm: 6 },
    { name: 'ou2sku', type: 'text', sm: 6, allowCharacter: numberRule },
    { name: 'customers', type: 'popup', sm: 12, md: 6 },
    { name: 'orderBySku', type: 'popup', sm: 12, md: 6 },
    { name: 'ou2', type: '-' },
    { name: 'warehousing', type: 'popup', sm: 12, md: 6 },
    { name: 'note', type: 'multiline', sm: 12 },
  ].map(field => {
    field.required = requiredFields.includes(field.name)
    field.disabled = disabledFields.includes(field.name)
    field.md = field.md || 3
    return field
  })

  const headerCells = [
    { text: 'code' },
    { text: 'name' },
    { text: 'moq', align: 'right' }, //最小訂貨量
    { text: 'price', align: 'right' },
  ].map(c => { c.text = formatMessage({ id: `merchandise.table.detail.suppiler.${c.text}` }); return c })

  const rowCells = [
    { field: 'code' },
    { field: 'nickname', tooltip: 'name' },
    {
      field: 'moq',
      align: 'right',
      type: 'input-number',
      label: formatMessage({ id: 'merchandise.table.detail.suppiler.moq' }),
      onValueChanged: onCellValueChanged
    },
    {
      field: 'price',
      align: 'right',
      type: 'input-floating-point-number',
      label: formatMessage({ id: 'merchandise.table.detail.suppiler.price' }),
      onValueChanged: onCellValueChanged
    }
  ]

  function onCellValueChanged(id, field, value) {
    for (let s of merchandiseData.suppliers) {
      if (s.id === id) {
        if (field === 'moq') {
          s.moq = value
          if (isNaN(value) || value === '' || value === '0') {
            if (!s.errors) s.errors = {}
            s.errors.moq = '數量錯誤'
          } else {
            if (s.errors && s.errors.moq) delete s.errors.moq
          }
        } else if (field === 'price') {
          s.price = value
          if (isNaN(value) || value === '' || value === '0') {
            if (!s.errors) s.errors = {}
            s.errors.price = '數值錯誤'
          } else {
            if (s.errors && s.errors.price) delete s.errors.price
          }
        }
        break
      }
    }
    updateMerchandiseData({ name: 'suppliers' }, [...merchandiseData.suppliers]);
  }

  function getMenuItem(field) {
    if (field === 'type') {
      return [
        {
          value: 'G',
          label: formatMessage({ id: 'merchandise.type.g' }),
        },
        {
          value: 'I',
          label: formatMessage({ id: 'merchandise.type.i' }),
        },
        {
          value: 'B',
          label: formatMessage({ id: 'merchandise.type.b' }),
        },
      ]
    } else if (field === 'category') {
      return [
        { value: 'M', header: true },
        { value: 'DPO' },
        { value: 'DOI' },
        { value: 'DIV' },
        { value: 'DSC' },
        { value: 'I', header: true },
        { value: 'CIN' },
        { value: 'CPR' },
        { value: 'CBD' },
        { value: 'CIP' },
        { value: 'CCO' },
        { value: 'CWI' },
        { value: 'COT' },
      ].map(i => {
        i.label = i.value ? formatMessage({ id: `merchandise.category.${i.value}` }) : formatMessage({ id: 'merchandise.category.all' })
        return i
      })
    }
    return []
  }

  const onCustomerChanged = (customers) => {
    updateMerchandiseData({ name: openCustomerDialog }, customers);
  }

  const onSupplierChanged = (suppliers) => {
    if (suppliers.length) {
      for (let s of suppliers) {
        s.moq = ''
        s.price = ''
      }
      updateMerchandiseData({ name: 'suppliers' }, merchandiseData.suppliers.concat(suppliers));
    }
  }

  const onSupplierRemoved = (supplier) => {
    const suppliers = merchandiseData.suppliers.filter(s => s.id !== supplier.id)
    updateMerchandiseData({ name: 'suppliers' }, [...suppliers]);
  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }
    return ''
  }

  function updateMerchandiseData(field, value) {
    let newValue = value
    if (field.allowCharacter) {
      newValue = newValue.replace(field.allowCharacter, '')
    }
    if (field.maxLength) {
      newValue = newValue.substring(0, field.maxLength)
    }

    if (newValue === undefined || merchandiseData[field.name] === newValue) return;

    let err = validateField(field, value)

    let newData = { ...merchandiseData, [field.name]: newValue, [`${field.name}_err`]: err }

    setMerchandiseData(newData)
  }

  function createField(field) {
    if (ignoreFields.includes(field.name)) return null
    if (field.type === 'text') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          disabled={field.disabled}
          required={field.required}
          type="text"
          size="small"
          label={formatMessage({ id: `merchandise.edit.${field.name}` })}
          variant="outlined"
          onChange={e => { updateMerchandiseData(field, e.target.value) }}
          value={merchandiseData[field.name]}
          error={merchandiseData[`${field.name}_err`] ? true : false}
          helperText={merchandiseData[`${field.name}_err`]}
          fullWidth
        />
      </Grid>
    } else if (field.type === 'checkbox') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={3}>
        <FormControlLabel
          control={
            <Checkbox
              checked={merchandiseData[field.name]}
              onChange={e => { updateMerchandiseData(field, e.target.checked) }}
              color="primary"
            />
          }
          label={formatMessage({ id: `merchandise.edit.${field.name}` })}
        />
      </Grid>
    } else if (field.type === 'popup') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          disabled={field.disabled}
          required={field.required}
          type="text"
          size="small"
          label={formatMessage({ id: `merchandise.edit.${field.name}` })}
          variant="outlined"
          onClick={() => setOpenCustomerDialog(field.name)} //open popup
          value={merchandiseData[field.name].map(s => s.nickname).join(', ')}
          error={merchandiseData[`${field.name}_err`] ? true : false}
          helperText={merchandiseData[`${field.name}_err`]}
          fullWidth
        />
      </Grid>
    } else if (field.type === 'select') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={3}>
        <TextField
          disabled={field.disabled}
          required={field.required}
          select
          type="text"
          size="small"
          label={formatMessage({ id: `merchandise.edit.${field.name}` })}
          variant="outlined"
          onChange={e => { updateMerchandiseData(field, e.target.value) }}
          value={merchandiseData[field.name]}
          error={merchandiseData[`${field.name}_err`] ? true : false}
          helperText={merchandiseData[`${field.name}_err`]}
          fullWidth
        >
          {getMenuItem(field.name).map((option) => {
            return option.header ?
              (<ListSubheader disabled key={option.value}>{option.label}</ListSubheader>) :
              (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)
          })}
        </TextField>
      </Grid>
    } else if (field.type === 'multiline') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={12}>
        <TextField
          multiline
          required={field.required}
          type="text"
          size="small"
          label={formatMessage({ id: `merchandise.edit.${field.name}` })}
          variant="outlined"
          onChange={e => { updateMerchandiseData(field, e.target.value) }}
          value={merchandiseData[field.name]}
          fullWidth
        />
      </Grid>
    } else if (field.type === '-') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
    }
  }

  function _getContent() {
    // setLoading(true);

    let err = {}
    for (let field of fields) {
      if (field.required && merchandiseData[field.name] === '') {
        err[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
      }
    }

    let suppliersError = false
    if (requiredFields.includes('suppliers')) {
      if (!merchandiseData.suppliers.length) {
        err.suppliers_err = '必須有供應商'
      }
    }

    for (const s of merchandiseData.suppliers) {
      for (const field of supplierFields) {
        if (s[field] === '') {
          if (!s.errors) s.errors = {}
          s.errors[field] = formatMessage({ id: 'form.field.isRequired' })
          suppliersError = true
        }
      }
    }

    let newData = merchandiseData
    if (suppliersError) {
      newData = { ...merchandiseData, suppliers: [...merchandiseData.suppliers] }
    }
    if (Object.keys(err).length > 0) {
      newData = { ...merchandiseData, ...err }
    }
    if (Object.keys(err).length > 0 || suppliersError) {
      setMerchandiseData(newData)
    }

    for (const s of newData.suppliers) {
      for (const field of supplierFields) {
        if (s.errors && s.errors[field] !== undefined && s.errors[field] !== '') {
          // setLoading(false);
          return
        }
      }
    }

    const allFields = requiredFields.includes('suppliers') ? [...fields, { name: 'suppliers' }] : fields
    for (let field of allFields) {
      if (newData[`${field.name}_err`] !== undefined && newData[`${field.name}_err`] !== '') {
        // setLoading(false);
        return
      }
    }
    let data = {}
    for (let field of fields) {
      if (field.type === '-') continue
      data[field.name] = newData[field.name]
    }

    data.suppliers = newData.suppliers.reduce((acc, cur) => {
      acc[cur.id] = {
        moq: parseInt(cur.moq),
        price: parseFloat(cur.price),
      };
      return acc;
    }, {})

    data.customers = data.customers.reduce((acc, cur) => { acc[cur.id] = true; return acc; }, {})
    data.orderBySku = data.orderBySku.reduce((acc, cur) => { acc[cur.id] = true; return acc; }, {})
    data.warehousing = data.warehousing.reduce((acc, cur) => { acc[cur.id] = true; return acc; }, {})
    data.ou2sku = parseInt(data.ou2sku || '1')
    return { ...data }
  }

  return (
    <div style={{ flexGrow: 1 }}>
      {openSupplierDialog && <SelectMultiSupplierDialog
        defaultSelectedItems={merchandiseData.suppliers}
        handleClose={() => setOpenSupplierDialog(false)}
        handleSave={onSupplierChanged}
        ignoreIds={merchandiseData.suppliers.map(m => m.id)}
      />}
      {openCustomerDialog && <SelectCustomerDialog
        defaultSelectedItems={merchandiseData[openCustomerDialog]}
        handleClose={() => setOpenCustomerDialog('')}
        handleSave={onCustomerChanged}
        ignoreIds={[]}
      />}
      <Grid container spacing={3}>
        {fields.map(field => createField(field))}
      </Grid>
      {!ignoreFields.includes('suppliers') && <>
        <Divider style={{ margin: '8px 0px' }} />
        <Toolbar sx={{ pl: 2, pr: 1 }}>
          <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
            <FormattedMessage id="editMerchandise.table.title" />*
            {merchandiseData.suppliers_err && <span style={{ color: '#f44336', fontSize: '0.75rem' }}>{merchandiseData.suppliers_err}</span>}
          </Typography>
          <Button
            sx={{ m: 1, whiteSpace: 'nowrap' }}
            variant="contained"
            color="primary"
            onClick={() => setOpenSupplierDialog(true)}
          >
            <FormattedMessage id="editMerchandise.addSupplier" />
          </Button>
        </Toolbar>
        <EnhancedTable
          headerCells={headerCells}
          rowCells={rowCells}
          tableData={merchandiseData.suppliers}
          getActionIcons={supplier =>
            <DelectIconButton text={formatMessage({ id: 'editMedicine.remove' })} onClick={() => onSupplierRemoved(supplier)} />
          }
        />
      </>}
    </div>
  );
})

EditNewMerchandiseView.displayName = 'EditNewMerchandiseView'

EditNewMerchandiseView.propTypes = {
  merchandise: PropTypes.object,
  ignoreFields: PropTypes.arrayOf(PropTypes.string),
  requiredFields: PropTypes.arrayOf(PropTypes.string),
  disabledFields: PropTypes.arrayOf(PropTypes.string),
};

export default EditNewMerchandiseView;
