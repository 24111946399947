import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import ContextStore from '../../modules/context';
import SimpleTableToolbar from '../../components/SimpleTableToolbar';
import SelectUserDialog from './SelectUserDialog';
import { useFirestoreDataAndMapping } from '../../modules/uitls';

const StepRow = styled('div')(() => ({
  width: '100%',
  height: '80px',
  backgroundColor: '#fff',
  padding: '0px 20px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}));

const fields = ['query', 'inventory', 'deliveryOrder', 'returnForm']

function EditWarehouseStaff() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const { customerId } = useParams()

  const [userMapping, users] = useFirestoreDataAndMapping('users')
  const [customerMapping, customers] = useFirestoreDataAndMapping('customers')

  const [userDialogData, setUserDialogData] = useState(null);
  const [loading, setLoading] = useState(false);

  // const [approveSteps, setApproveSteps] = useState([]);
  const [workflow, setWorkflow] = useState({ query: [], inventory: [], deliveryOrder: [], returnForm: [] });

  useEffect(() => {
    setBreadcrumbs([{
      link: '/vendor',
      text: formatMessage({ id: 'sideMenu.vendor' })
    },{
      text: formatMessage({ id: 'supplier.editWarehouseStaff.edit' })
    }])
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    if (customers.length) {
      const customer = customerMapping[customerId]
      if (!customer) {
        navigate('/vendor');
        return
      }
      //
      const { query, inventory } = customer.stock
      const { deliveryOrder, returnForm } = customer.sales
      const workflow = {
        query: query.map(u => ({ id: u, name: userMapping[u] ? userMapping[u].displayName : '' })),
        inventory: inventory.map(u => ({ id: u, name: userMapping[u] ? userMapping[u].displayName : '' })),
        deliveryOrder: deliveryOrder.map(u => ({ id: u, name: userMapping[u] ? userMapping[u].displayName : '' })),
        returnForm: returnForm.map(u => ({ id: u, name: userMapping[u] ? userMapping[u].displayName : '' })),
      }
      setWorkflow(workflow)
    }
  }, [customers.length, users.length]);

  function onWorkflowUsersChanged(index, users) {
    updateWorkflowUsers(fields[index], users)
  }

  function updateWorkflowUsers(field, value) {
    const newData = { ...workflow }

    if (!value.length && field !== 'deliveryOrder') {
      newData[`${field}_err`] = formatMessage({ id: 'form.field.isRequired' })
    } else {
      newData[`${field}_err`] = ''
    }
    newData[field] = value
    setWorkflow(newData)
  }

  function showSelectUserPopup(index) {
    const users = workflow[fields[index]]
    setUserDialogData({ index, users })
  }

  async function handleSave() {
    setLoading(true);

    try {
      await (firebase.functions().httpsCallable('updateWorkflow'))({
        id: customerId,
        stock: {
          query: workflow.query.map(s => s.id),
          inventory: workflow.inventory.map(s => s.id),
        },
        sales: {
          deliveryOrder: workflow.deliveryOrder.map(s => s.id),
          returnForm: workflow.returnForm.map(s => s.id)
        },
      })
    } catch (ex) {
      console.log(ex)
    }
    handleClose()
  }

  function handleClose() {
    navigate('/vendor');
  }

  return (
    <div style={{ flexGrow: 1 }}>
      {userDialogData && <SelectUserDialog
        handleClose={() => setUserDialogData(null)}
        handleSave={onWorkflowUsersChanged}
        index={userDialogData.index}
        defaultSelectedItems={userDialogData.users}
      />}
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <SimpleTableToolbar
          title="editWarehouseStaff.table.title"
          note={`(${customerMapping[customerId].nickname})`}
        />
        <Paper sx={{ overflow: 'hidden' }}>
          {fields.map((field, index) => (<StepRow key={field}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  disabled
                  type="text"
                  label={formatMessage({ id: 'editWarehouseStaff.table.detail.name' })}
                  variant="outlined"
                  value={formatMessage({ id: `editWarehouseStaff.table.${field}` })}
                  fullWidth
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  required={field !== 'deliveryOrder'}
                  type="text"
                  label={formatMessage({ id: 'editWarehouseStaff.table.detail.user' })}
                  variant="outlined"
                  fullWidth
                  value={workflow[field].map(s => s.name).join(', ')}
                  onClick={() => showSelectUserPopup(index)}
                  error={workflow[`${field}_err`] ? true : false}
                  helperText={workflow[`${field}_err`]}
                />
              </Grid>
            </Grid>
          </StepRow>))}
        </Paper>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            disabled={currentUser.userRights['vendor-edit'] !== true || loading}
            loading={loading}
            loadingPosition="start"
            loadingIndicator={<CircularProgress size={24} />}
            startIcon={<div />}
            variant="contained"
          >
            <FormattedMessage id="button.submit" />
          </LoadingButton>
        </Stack>
      </Box>
    </div>
  );
}

// EditWarehouseStaff.defaultProps = {

// }

export default EditWarehouseStaff;
