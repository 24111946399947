import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import ContextStore from '../../modules/context';
import { lockSubmitButton, unwrap } from '../../modules/uitls';
import EditNewProductView from '../../components/EditNewProductView'

function EditProductPage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const urProductCreate = currentUser.userRights['product-create']
  const urProductEdit = currentUser.userRights['product-edit']

  const childRef = useRef()
  const { productId } = useParams()
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(productId === 'new' ? true : false);

  const [productData, setProductData] = useState({
    id: productId,
    type: '',
    tags: [],
    name: '',
    cost: '',
    staffprice: '',
    staffRelativesPrice: '',
    vipprice: '',
    consumable: false,
    customers: [],
    note: '',
  });

  useEffect(() => {
    const redirect = productId === 'new' ? urProductCreate !== true : urProductEdit !== true
    if (redirect) {
      navigate('/');
    }
  }, [urProductCreate, urProductEdit]);

  useEffect(() => {
    const breadcrumbs = [{
      link: '/products/warehouse',
      text: formatMessage({ id: 'sideMenu.product.warehouse' })
    }]
    if (productId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: 'product.dialog.title.add' }) })
    } else {
      breadcrumbs.push({ text: formatMessage({ id: 'product.dialog.title.edit' }) })
    }
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    if (productId !== 'new') { // edit
      const unsubscribe = firebase.firestore().collection('products').doc(productId)
        .onSnapshot(snapshot => {
          const data = unwrap(snapshot.data())
          if (!data) {
            navigate('/products/warehouse');
            return
          }

          setProductData({
            id: productId,
            type: data.type,
            tags: data.tags ?? [],
            name: data.name,
            cost: data.cost,
            staffprice: data.staffprice,
            staffRelativesPrice: data.staffRelativesPrice,
            consumable: data.consumable,
            vipprice: data.vipprice,
            customers: data.customers,
            note: data.note ?? '',
          })

          setReady(true)
        }, err => {})
      return () => unsubscribe()
    } else {
      return () => {};
    }
  }, []);

  function getDisabledFields() {
    const fields = ['staffprice', 'staffRelativesPrice'];
    if (productId !== 'new') {
      fields.push('type')
    }
    return fields
  }

  const handleSave = async () => {
    lockSubmitButton(true)
    setLoading(true);
    const data = childRef.current.getContent()
    if (data) {
      try {
        await (firebase.functions().httpsCallable('saveProduct'))({ id: productData.id, ...data })
      } catch (ex) {
        console.log(ex)
      }
      lockSubmitButton(false)
      handleClose()
    } else {
      lockSubmitButton(false)
      setLoading(false);
    }
  }

  const handleClose = () => {
    navigate('/products/warehouse');
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        {ready && <EditNewProductView
          ref={childRef}
          product={productData}
          disabledFields={getDisabledFields()}
          requiredFields={['name', 'type', 'cost']}
        />}
        <Divider style={{ margin: '8px 0px' }} />
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            disabled={loading}
            loading={loading}
            loadingPosition="start"
            loadingIndicator={<CircularProgress size={24} />}
            startIcon={<div />}
            variant="contained"
          >
            <FormattedMessage id="button.save" />
          </LoadingButton>
        </Stack>
      </Box>
    </div>
  );
}

export default EditProductPage;
