import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import ContextStore from '../../modules/context';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableToolbar from '../../components/EnhancedTableToolbar';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { useFirestoreDataAndMapping, lockSubmitButton } from '../../modules/uitls';
import { getComparator, stableSort } from '../../modules/sort';
import SelectReceiptDialog from './SelectReceiptDialog';

function MerchandiseDetail({ merchandise, onItemValueChanged }) {
  const { formatMessage } = useIntl()
  const headerCells = [
    { text: 'receiptDate' },
    { text: 'expectedDate' },
    { text: 'unitPrice' },
    { text: 'lotNumber' },
    { text: 'receiptAmount', align: 'right' },
    { text: 'currentAmount', align: 'right' },
    { text: 'amount', align: 'right' },
    { text: 'note', align: 'right' },
  ].map(c => {c.text = formatMessage({ id: `editReturnForm.merchandise.${c.text}` });return c})

  const rowCells = [
    { field: 'receiptDate' },
    { field: 'expectedDate' },
    { field: 'unitPrice' },
    { field: 'lotNumber' },
    { field: 'receiptAmount', align: 'right' },
    { field: 'currentAmount', align: 'right' },
    { field: 'amount', align: 'right', type: 'input-number', required: false, params: ['idx', 'receiptAmount', 'maxAmount', 'currentAmount', 'returnAmount'], label: '欲退貨數量', onValueChanged: onCellValueChanged },
    { field: 'note', align: 'right', type: 'input', required: false, params: ['idx', 'currentAmount'], label: '備註', onValueChanged: onCellValueChanged },
  ]

  function onCellValueChanged(id, field, value, params) {
    if (params) {
      // const iid = params[0]
      const m = merchandise.items.find((m, idx) => params[0] === idx)
      if (m) {
        if (field === 'amount') {
          m[field] = value
          if (value === '' || parseInt(value) === 0) {
            if (m.errors && m.errors.amount) {
              delete m.errors.amount
            }
          } else if (isNaN(value)) {
            if (!m.errors) {
              m.errors = {}
            }
            m.errors.amount = '數量錯誤'
          } else if (params[3] === 'N/A' && parseInt(value) > params[1] - params[4]) { // 沒入庫的 item, 不可以超過 進貨量-已退貨量
            if (!m.errors) {
              m.errors = {}
            }
            m.errors.amount = '超過剩餘數量'
          } else if (params[3] !== 'N/A' && parseInt(value) > params[3]) { // 有入庫的 item, 不可以超過 剩餘數量
            if (!m.errors) {
              m.errors = {}
            }
            m.errors.amount = '超過主倉庫剩餘數量'
          } else {
            if (m.errors && m.errors.amount) {
              delete m.errors.amount
            }
          }
        } else {
          m[field] = value
        }
        onItemValueChanged(id, field)
      }
    }
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <EnhancedTableHead
            headerCells={headerCells}
            numSelected={0}
            rowCount={merchandise.items.length}
          />
          <TableBody>
            {merchandise.items.map((m, idx) => ({ ...m, idx })).map((m, idx) => (
              <EnhancedTableRow
                key={`${m.id}@${idx}`}
                rowCells={rowCells}
                cellData={m}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

MerchandiseDetail.propTypes = {
  merchandise: PropTypes.object.isRequired,
  onItemValueChanged: PropTypes.func.isRequired,
};

function addReceiptItems(receipt, inventoryMapping, m, mappingItem, unitPrice, extra) {
  const ou = mappingItem.orderBySku ? mappingItem.sku : mappingItem.orderUnit
  if (m.items) {
    for (const idx in m.items) {
      const i = m.items[idx]
      let currentAmount = 'N/A'
      let amountInExtWareroom = 0
      if (i.inventoryId && inventoryMapping[i.inventoryId]) {
        const inventory = inventoryMapping[i.inventoryId]
        Object.keys(inventory.wareroom || {}).forEach(k => {
          amountInExtWareroom += inventory.wareroom[k]
        })
        currentAmount = ou !== mappingItem.sku ? Math.floor(inventory.currentAmount / mappingItem.ou2sku) : inventory.currentAmount
      }
      mappingItem.items.push({
        id: mappingItem.id,
        returnAmount: i.returnAmount ?? 0,
        receiptDate: receipt.approveDate,
        expectedDate: i.expectedDate,
        lotNumber: i.lotNumber,
        note: '',
        receiptAmount: i.amount,
        maxAmount: i.amount - amountInExtWareroom,
        currentAmount: currentAmount - amountInExtWareroom,
        amount: 0,
        unitPrice,
        index: parseInt(idx),
        extra,
      })
    }
  } else {
    let currentAmount = 'N/A'
    let amountInExtWareroom = 0
    if (m.inventoryId && inventoryMapping[m.inventoryId]) {
      const inventory = inventoryMapping[m.inventoryId]
      Object.keys(inventory.wareroom || {}).forEach(k => {
        amountInExtWareroom += inventory.wareroom[k]
      })
      currentAmount = ou !== mappingItem.sku ? Math.floor(inventory.currentAmount / mappingItem.ou2sku) : inventory.currentAmount
    }
    mappingItem.items.push({
      id: mappingItem.id,
      returnAmount: m.returnAmount ?? 0,
      receiptDate: receipt.approveDate,
      expectedDate: m.expectedDate,
      lotNumber: m.lotNumber,
      note: '',
      receiptAmount: m.amount,
      maxAmount: m.amount - amountInExtWareroom,
      currentAmount: currentAmount - amountInExtWareroom,
      amount: 0,
      unitPrice,
      index: 0,
      extra,
    })
  }
}

function EditReturnFormPage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()

  const [customerMapping, customers] = useFirestoreDataAndMapping('customers')

  const filteredSources = customers.filter(c => currentUser.returnFormSource.includes(c.id))
  const [merchandiseMapping] = useFirestoreDataAndMapping('merchandises')
  const [userMapping] = useFirestoreDataAndMapping('users')

  const [loading, setLoading] = useState(false);
  const [lockSource, setLockSource] = useState(false);
  const [selector, setSelector] = useState(false);

  const [selectedReceipt, setSelectedReceipt] = useState({ id: '' });
  const [merchandises, setMerchandises] = useState([]);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');

  const [returnForm, setReturnForm] = useState({
    id: 'new',
    source: '',
    date: dayjs().format('YYYY-MM-DD'),
    createdBy: currentUser.key,
    note: '',
  });

  useEffect(() => {
    if (filteredSources.length === 1) {
      updateReturnFormData({ name: 'source' }, filteredSources[0].id);
    }
  }, [filteredSources.length]);

  useEffect(() => {
    setBreadcrumbs([{
      link: '/purchase/returnForm',
      text: formatMessage({ id: 'sideMenu.purchase.returnForm' })
    },{
      text: formatMessage({ id: 'returnForm.dialog.title.add' })
    }])
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    const onSnapshot = snapshot => {
      const inventoryMapping = {}
      const mapping = {}
      snapshot.forEach(doc => {
        inventoryMapping[doc.id] = { ...doc.data() }
      });

      const keys = Object.keys(selectedReceipt.merchandises)
      const extraKeys = Object.keys(selectedReceipt.extraMerchandises || {})

      for (const key of keys) {
        const m = selectedReceipt.merchandises[key]
        if (!mapping[key]) {
          mapping[key] = {
            id: key,
            returnAmount: 0,
            amount: m.amount,
            orderBySku: m.orderBySku,
            orderUnit: merchandiseMapping[key].orderUnit,
            sku: merchandiseMapping[key].sku,
            ou2sku: merchandiseMapping[key].ou2sku,
            items: [],
          }
        } else {
          mapping[key].amount += m.amount
        }
        addReceiptItems(selectedReceipt, inventoryMapping, m, mapping[key], m.unitPrice, false)
      }
      for (const key of extraKeys) {
        const m = selectedReceipt.extraMerchandises[key]
        if (!mapping[key]) {
          mapping[key] = {
            id: key,
            returnAmount: 0,
            amount: m.amount,
            orderBySku: m.orderBySku,
            orderUnit: merchandiseMapping[key].orderUnit,
            sku: merchandiseMapping[key].sku,
            ou2sku: merchandiseMapping[key].ou2sku,
            items: [],
          }
        } else {
          mapping[key].amount += m.amount
        }
        addReceiptItems(selectedReceipt, inventoryMapping, m, mapping[key], 0, true)
      }
      setMerchandises(Object.keys(mapping).map(key => ({ ...mapping[key], id: key })))
    }

    // 根據選擇的進貨單, 把該進貨單的庫存都撈出來.
    const unsubscribe = selectedReceipt.id !== '' ? firebase.firestore().collection('wh').doc(returnForm.source).collection('inventories').where('receiptId', '==', selectedReceipt.id).onSnapshot(onSnapshot, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, [selectedReceipt.id]);

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'name' },
    { text: 'amount', align: 'right' },
    { text: 'returnAmount', align: 'right' },
    { text: 'orderUnit', align: 'right' },
  ].map(c => {c.text = formatMessage({ id: `returnForm.merchandise.${c.text}` });return c})

  const rowCells = [
    { field: 'code' },
    { field: 'nickname', tooltip: 'name' },
    { field: 'amount', align: 'right' },
    { field: 'returnAmount', align: 'right' },
    { field: 'orderUnit', align: 'right' },
  ]

  const handleRequestTable1Sort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function formatData(merchandise) {
    const m = merchandiseMapping[merchandise.id]
    const newData = {
      // ...merchandise,
      id: merchandise.id,
      items: merchandise.items,
      code: m.code,
      name: m.name,
      nickname: m.nickname,
      amount: merchandise.amount,
      returnAmount: merchandise.returnAmount,
      // note: m.note,
      orderUnit: merchandise.orderBySku ? m.sku : m.orderUnit
    }
    return newData
  }

  function onItemValueChanged(mId, field) {
    if (field === 'amount') {
      for (const m of merchandises) {
        if (m.id === mId) {
          m.returnAmount = m.items.reduce((acc, cur) => acc + parseInt(cur.amount || 0), 0)
          break
        }
      }
    }
    setMerchandises([...merchandises])
  }

  function onSelectReceipt(receipt) {
    setSelectedReceipt(receipt)
  }

  function validateField(field, value) {
    // if (field.required && value === '') {
    //   return formatMessage({id: 'form.field.isRequired'})
    // }
    if (field.name === 'merchandises') {
      for (const m of value) {
        if (m.errors && m.errors.amount) {
          return m.errors.amount
        }
      }
    } else if (field.name === 'expectedDate' && value === '') {
      return formatMessage({ id: 'form.date.formatError' })
    } else if (field.name === 'invoiceNumber' && value && value !== '  -        ' && value.trim().length !== 11) {
      return formatMessage({ id: 'form.invoiceNumber.formatError' })
    }
    return ''
  }

  function updateReturnFormData(field, value) {
    let newValue = value
    if (field.uppercase) {
      newValue = newValue.toUpperCase()
    }

    // if (field.allowCharacter) {
    //   newValue = newValue.replace(field.allowCharacter, '')
    // }
    // if (field.maxLength) {
    //   newValue = newValue.substring(0, field.maxLength)
    // }

    // if (supplierData[field.name] === newValue) return;

    let err = validateField(field, value)

    let newData = { ...returnForm, [field.name]: newValue, [`${field.name}_err`]: err }
    setReturnForm(newData)
  }

  async function handleSave() {
    lockSubmitButton(true)
    setLoading(true);

    const fields = [
      { name: 'source' },
      { name: 'date' },
      // {name: 'merchandises'},
      { name: 'note' },
      { name: 'createdBy' }
    ]

    let newData = returnForm
    // TODO: 檢查看看有沒有紅字, 有的話就跳出去, 不用儲存
    for (const m of merchandises) {
      for (const i of m.items) {
        if (i.errors && Object.keys(i.errors).length > 0) {
          lockSubmitButton(false)
          setLoading(false)
          return
        }
      }
    }

    let data = {}
    for (let field of fields) {
      if (field.type === '-') continue
      data[field.name] = newData[field.name]
    }

    data.merchandises = merchandises.filter(m => getMerchandiseReturnCount(m) > 0).reduce((acc, cur) => {
      const orderBySku = cur.orderBySku // Object.keys(merchandiseMapping[cur.id].orderBySku || {}).includes(newData.source)
      acc[cur.id] = {
        items: cur.items.filter(i => i.amount > 0).map(i => {
          const item = {
            amount: parseInt(i.amount),
            note: i.note
          }
          item.index = i.index
          item.extra = i.extra
          return item
        }),
      };
      if (orderBySku) {
        acc[cur.id].orderBySku = true
      }

      return acc;
    }, {})

    data.receiptId = selectedReceipt.id

    if (returnForm.id === 'new') {
      try {
        await (firebase.functions().httpsCallable('saveReturnForm'))(data)
      } catch (ex) {
        console.log(ex)
      }
    }
    lockSubmitButton(false)
    handleClose()
  }

  function handleClose() {
    navigate('/purchase/returnForm');
  }

  function getSourceSelector() {
    if (currentUser.returnFormSource.length === 1) {
      return (<TextField
        disabled
        type="text"
        label={formatMessage({ id: 'returnForm.table.detail.source' })}
        variant="outlined"
        value={customerMapping[currentUser.returnFormSource[0]].nickname}
        fullWidth
        size="small"
      />)
    } else {
      return (<TextField
        select
        required
        disabled={lockSource}
        type="text"
        label={formatMessage({ id: 'returnForm.table.detail.source' })}
        variant="outlined"
        value={returnForm.source}
        onChange={e => updateReturnFormData({ name: 'source' }, e.target.value)}
        fullWidth
        size="small"
        error={returnForm.source_err ? true : false}
        helperText={returnForm.source_err}
      >
        {filteredSources.map(c => <MenuItem key={c.id} value={c.id}>
          {c.nickname}
        </MenuItem>)}
      </TextField>)
    }
  }

  function getReturnCount() {
    // TODO: 計算退貨量
    let total = 0
    for (const m of merchandises) {
      total += getMerchandiseReturnCount(m)
    }
    return total
  }

  function getMerchandiseReturnCount(merchandise) {
    let total = 0
    for (const i of merchandise.items) {
      if (isNaN(i.amount) || i.amount === '' || parseInt(i.amount) === 0) {
      } else {
        total += parseInt(i.amount)
      }
    }
    return total
  }

  // const invoiceSubtotal = deliveryOrder.merchandises.reduce((acc, cur) => {
  //   acc += cur.unitPrice * cur.amount
  //   return acc;
  // }, 0)
  // const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  // const invoiceTotal = invoiceTaxes + invoiceSubtotal;

  return (
    <div style={{ flexGrow: 1 }}>
      {selector && <SelectReceiptDialog
        customer={returnForm.source}
        handleClose={() => setSelector(false)}
        handleSave={onSelectReceipt}
      />}
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            {getSourceSelector()}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'returnForm.table.detail.createdBy' })}
              variant="outlined"
              value={userMapping[returnForm.createdBy] ? userMapping[returnForm.createdBy].displayName : ''}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'returnForm.table.detail.date' })}
              variant="outlined"
              value={dayjs(returnForm.date).format('YYYY-MM-DD')}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: '8px 0px' }} />
        <EnhancedTableToolbar
          title="editReturnForm.table.title"
          selectdMessage="editReturnForm.table.selected"
          numSelected={0}
          toolbox={
            <Button
              disabled={returnForm.source === ''}
              sx={{ m: 1, whiteSpace: 'nowrap' }}
              variant="contained"
              color="primary"
              onClick={() => {
                setLockSource(true)
                setSelector(true)
              }}
            >
              <FormattedMessage id="editReturnForm.selectReceipt" />
            </Button>
          }
          // toolboxForSelection={
          //   <Button
          //     variant="contained"
          //     color="primary"
          //     startIcon={<DeleteIcon />}
          //     style={{whiteSpace: 'nowrap', marginRight: '8px'}}
          //     onClick={onDeleteItems}
          //   >
          //     <FormattedMessage id="editReturnForm.removeMerchandise" />
          //   </Button>
          // }
        />
        <TableContainer component={Paper}>
          <Table>
            <EnhancedTableHead
              headerCells={headerCells}
              rowCount={merchandises.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestTable1Sort}
            />
            <TableBody>
              {stableSort(merchandises.map(m => formatData(m)), getComparator(order, orderBy)).map(merchandise => (
                <EnhancedTableRow
                  key={merchandise.id}
                  rowCells={rowCells}
                  cellData={merchandise}
                  lock={merchandise.lock}
                  expanded
                  expandContent={<MerchandiseDetail merchandise={merchandise} onItemValueChanged={onItemValueChanged} />}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'returnForm.table.detail.note' })}
              variant="outlined"
              value={returnForm.note}
              onChange={e => updateReturnFormData({ name: 'note' }, e.target.value)}
              fullWidth
              size="small"
              multiline
            />
          </Grid>
        </Grid>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            disabled={loading || getReturnCount() === 0}
            loading={loading}
            loadingPosition="start"
            loadingIndicator={<CircularProgress size={24} />}
            startIcon={<div />}
            variant="contained"
          >
            <FormattedMessage id="button.submit" />
          </LoadingButton>
        </Stack>
      </Box>
    </div>
  );
}

export default EditReturnFormPage;
