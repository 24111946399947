import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import ContextStore from '../../modules/context';
import { lockSubmitButton, unwrap, anyIntersection } from '../../modules/uitls';
import EditNewProductView from '../../components/EditNewProductView'
import EditNewProductPackageView from '../../components/EditNewProductPackageView'

function EditProductPackagePage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const urProductCreate = currentUser.userRights['product-create']
  const urProductEdit = currentUser.userRights['product-edit']

  const childRef = useRef()
  const packageChildRef = useRef()
  const [packageError, setPackageError] = useState(null)
  const { productId, pageName } = useParams()
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(productId === 'new' ? true : false);
  const [productMapping, setProductMapping] = useState(null)
  const [productData, setProductData] = useState({
    id: productId,
    type: '',
    tags: [],
    name: '',
    cost: '',
    staffprice: '',
    staffRelativesPrice: '',
    vipprice: '',
    consumable: false,
    customers: [],
    note: '',
    package: []
  });

  useEffect(() => {
    const redirect = productId === 'new' ? urProductCreate !== true : urProductEdit !== true
    if (redirect) {
      navigate('/');
    }
  }, [urProductCreate, urProductEdit]);

  useEffect(() => {
    const breadcrumbs = [{
      link: '/products/warehouse',
      text: formatMessage({ id: 'sideMenu.product.warehouse' })
    }]
    if (productId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: `product.dialog.${pageName}Title.add` }) })
    } else {
      breadcrumbs.push({ text: formatMessage({ id: `product.dialog.${pageName}Title.edit` }) })
    }
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('products').onSnapshot(snapshot => {
      const productMapping = {}
      snapshot.forEach(doc => {
        productMapping[doc.id] = unwrap(doc.data())
      })
      setProductMapping(productMapping)
    }, err => { })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    if (productId !== 'new') { // edit
      const unsubscribe = firebase.firestore().collection('products').doc(productId)
        .onSnapshot(snapshot => {
          const data = unwrap(snapshot.data())

          if (!data) {
            navigate('/products/warehouse');
            return
          }

          const packageData = [];
          Object.keys(data.package).forEach(p => {
            packageData.push({ id: p, ...data.package[p] })
          })

          setProductData({
            id: productId,
            type: data.type,
            category: data.category,
            tags: data.tags || [],
            name: data.name,
            cost: data.cost,
            staffprice: data.staffprice,
            staffRelativesPrice: data.staffRelativesPrice,
            consumable: data.consumable,
            vipprice: data.vipprice,
            customers: data.customers,
            note: data.note,
            package: packageData
          })

          setReady(true)
        }, err => { })
      return () => unsubscribe()
    } else {
      return () => { };
    }
  }, []);

  function getDisabledFields() {
    const fields = ['staffprice', 'staffRelativesPrice', 'consumable'];
    if (productId !== 'new') {
      fields.push('type')
    }

    return fields
  }

  const handleSave = async () => {
    lockSubmitButton(true);
    setLoading(true);
    setPackageError(null);
    const data = childRef.current.getContent()
    const packageData = packageChildRef.current.getContent()
    let packageMapping = {}
    let costCount = 0
    let consumable = false

    if (data && packageData) {
      let error = false

      if (packageData.package.length === 0) {
        error = true
        setPackageError({ name: 'package', err: error })
      }

      for (const p of packageData.package) {
        if (productMapping[p.id].consumable) {
          consumable = true
        }

        if (pageName !== 'folder') {
          packageMapping[p.id] = { amount: parseInt(p.amount) }
          if (p.ratio !== undefined) {
            packageMapping[p.id].ratio = parseInt(p.ratio)
          }
          if (pageName === 'package') {
            packageMapping[p.id].independent = p.independent
          }
        } else {
          packageMapping[p.id] = { amount: parseInt(p.amount), price: parseInt(p.price), lowestPrice: parseInt(p.lowestPrice) }
        }

        costCount = Number(p.cost) + costCount
        if (Object.keys(data.customers).length > 0) {
          if (!anyIntersection(Object.keys(p.customers || {}), Object.keys(data.customers))) {
            error = true
            setPackageError({ name: 'customer', err: error })
          }
        }
      }

      const newData = {
        ...data,
        cost: costCount,
        package: packageMapping,
        treatments: packageData.treatments
      }

      newData.consumable = consumable

      if (!error) {
        try {
          await (firebase.functions().httpsCallable('saveProduct'))({ id: productData.id, ...newData })
        } catch (ex) {
          console.log(ex)
        }
        lockSubmitButton(false)
        handleClose()
      } else {
        lockSubmitButton(false)
        setLoading(false);
      }
    } else {
      lockSubmitButton(false)
      setLoading(false);
    }
  }

  const handleClose = () => {
    navigate('/products/warehouse');
  }

  function editParentData(field, value) {
    childRef.current.setEmployeePirce(field, value)
  }

  return (
    <div sx={{ flexGrow: 1 }}>
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        {ready && <EditNewProductView
          ref={childRef}
          product={productData}
          disabledFields={getDisabledFields()}
          requiredFields={['name', 'type']}
          ignoreFields={['cost']}
        />}
        {packageError &&
          <div style={{ color: 'red', fontSize: '14px', marginBottom: '-20px', marginTop: '20px' }}>
            {formatMessage({ id: `form.${packageError.name}.error` })}
          </div>
        }
        {ready && <EditNewProductPackageView
          ref={packageChildRef}
          product={productData}
          pageName={pageName}
          editParentData={editParentData}
          productMapping={productMapping}
        />}
        <Divider style={{ margin: '8px 0px' }} />
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            disabled={loading}
            loading={loading}
            loadingPosition="start"
            loadingIndicator={<CircularProgress size={24} />}
            startIcon={<div />}
            variant="contained"
          >
            <FormattedMessage id="button.save" />
          </LoadingButton>
        </Stack>
      </Box>
    </div>
  );
}

export default EditProductPackagePage;
