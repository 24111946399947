import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import ContextStore from '../../modules/context';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableToolbar from '../../components/EnhancedTableToolbar';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { useFirestoreDataAndMapping, lockSubmitButton } from '../../modules/uitls';
import { getComparator, stableSort } from '../../modules/sort';
import SelectMerchandiseDialog from '../../components/SelectMerchandiseDialog';

function MerchandiseDetail({ merchandise }) {
  const { formatMessage } = useIntl()
  const headerCells = [
    { text: 'expectedDate', sort: 'expectedDate' },
    { text: 'lotNumber' },
    { text: 'amount', align: 'right' },
  ].map(c => {c.text = formatMessage({ id: `selectStockDialog.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'expectedDate' },
    { field: 'lotNumber' },
    { field: 'amount', align: 'right' },
  ]

  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="collapsible table">
          <EnhancedTableHead
            headerCells={headerCells}
            numSelected={0}
            rowCount={merchandise.items.length}
          />
          <TableBody>
            {merchandise.items.map(m => (
              <EnhancedTableRow
                key={m.id}
                rowCells={rowCells}
                cellData={m}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

MerchandiseDetail.propTypes = {
  merchandise: PropTypes.object.isRequired,
};

function EditStockRequisitionPage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const { stockRequisitionId } = useParams()

  const [customerMapping, customers] = useFirestoreDataAndMapping('customers')

  const filteredCustomers = customers.filter(c => currentUser.stockRequisitionSource.includes(c.id))
  const [merchandiseMapping] = useFirestoreDataAndMapping('merchandises')
  const [userMapping] = useFirestoreDataAndMapping('users')

  const [selectedItems, setSelectedItems] = useState({})
  const [openDialog, setOpenDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [lockSource, setLockSource] = useState(false)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('code')
  const [requisitionSource, setRequisitionSource] = useState('')
  const [stockMapping, setStockMapping] = useState({})

  const [rawStockRequisition, setRawStockRequisition] = useState({
    id: stockRequisitionId,
    source: '',
    createdBy: currentUser.key,
    createdAt: { seconds: Math.floor(Date.now() / 1000) },
    date: dayjs().format('YYYY-MM-DD'),
    note: '',
    // merchandises: [],
  })

  const [owStockRequisition, setOwStockRequisition] = useState({
  });
  let stockRequisition = {}

  const disableSubmitButton = stockRequisitionId === 'new' ?
    (currentUser.userRights['stockRequisition-create'] !== true) :
    (currentUser.userRights['stockRequisition-edit'] !== true);

  useEffect(() => {
    if (filteredCustomers.length === 1 && stockRequisitionId === 'new') {
      updateStockRequisitionData({ name: 'source' }, filteredCustomers[0].id);
    }
  }, [filteredCustomers.length]);

  useEffect(() => {
    const breadcrumbs = [{
      link: '/stock/stockRequisition/pending',
      text: formatMessage({ id: 'sideMenu.stock.stockRequisition' })
    }]
    if (stockRequisitionId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: 'stockRequisition.dialog.title.add' }) })
    } else {
      breadcrumbs.push({ text: formatMessage({ id: 'stockRequisition.dialog.title.edit' }) })
    }
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    if (stockRequisitionId !== 'new') { // edit
      const unsubscribe = firebase.firestore().collection('stockRequisitions').doc(stockRequisitionId)
        .onSnapshot(snapshot => {
          setRawStockRequisition({ id: snapshot.id, ...snapshot.data() })
        }, err => {})
      return () => unsubscribe()
    } else {
      return () => {};
    }
  }, []);

  useEffect(() => {
    const unsubscribe = requisitionSource ? firebase.firestore().collection('wh').doc(requisitionSource).collection('inventories').where('empty', '==', false)
      .onSnapshot( snapshot => {
        const stockMapping = {}
        snapshot.forEach(doc => {
          const m = doc.data()
          if (!stockMapping[m.id]) stockMapping[m.id] = []
          stockMapping[m.id].push({
            id: doc.id,
            date: m.date,
            expectedDate: m.expectedDate,
            lotNumber: m.lotNumber,
            amount: m.currentAmount,
          })
        });
        const keys = Object.keys(stockMapping)
        for (const key of keys) {
          stockMapping[key] = stockMapping[key].sort((a, b) => {
            // if (a.expectedDate === '' && b.expectedDate === '') {
            //   return a.date < b.date
            // }
            if (a.expectedDate === '' && b.expectedDate !== '') return 1
            if (a.expectedDate !== '' && b.expectedDate === '') return -1

            if (a.expectedDate === b.expectedDate) {
              if (a.date > b.date) return 1
              if (a.date < b.date) return -1
              if (a.date === b.date) return 0
            }
            if (a.expectedDate > b.expectedDate) return 1
            return -1
          })
        }
        setStockMapping(stockMapping)
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, [requisitionSource]);

  if (stockRequisitionId !== 'new' && !rawStockRequisition.merchandises) {
    return ('Loading...')
  }

  const merchandiseKeys = Object.keys(rawStockRequisition.merchandises || {}).filter(m => merchandiseMapping[m])
  stockRequisition = { ...{
    id: stockRequisitionId,
    source: rawStockRequisition.source,
    createdBy: rawStockRequisition.createdBy,
    createdAt: rawStockRequisition.createdAt.seconds * 1000,
    date: rawStockRequisition.date,
    note: rawStockRequisition.note,
    merchandises: merchandiseKeys.map(m => merchandiseMapping[m]).map(m => ({
      code: m.code,
      orderUnit: m.orderUnit,
      name: m.name,
      nickname: m.nickname,
      id: m.id,
      // note: rawStockRequisition.merchandises[m.id].note,
      amount: rawStockRequisition.merchandises[m.id].amount,
    }))
  }, ...owStockRequisition }

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'name' },
    { text: 'stock', align: 'right' },
    { text: 'amount', align: 'right' },
    { text: 'sku', align: 'right' },
  ].map(c => {c.text = formatMessage({ id: `stockRequisition.merchandise.${c.text}` });return c})

  const rowCells = [
    { field: 'code' },
    { field: 'nickname', tooltip: 'name' },
    { field: 'stock', align: 'right' },
    { field: 'amount', align: 'right', type: 'input-number', required: true, params: ['maxAmount'], label: '數量', onValueChanged: onCellValueChanged },
    { field: 'sku', align: 'right' },
  ]

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function formatData(merchandise) {
    const newData = { ...merchandise }
    newData.stock = `${newData.maxAmount}${newData.sku}`
    return newData
  }

  function onCellValueChanged(id, field, value, params) {
    for (let m of stockRequisition.merchandises) {
      if (m.id === id) {
        if (field === 'amount') {
          m.amount = value
          m.items = []
          if (isNaN(value) || value === '' || parseInt(value) === 0) {
            if (!m.errors) {
              m.errors = {}
            }
            m.errors.amount = '數量錯誤'
          } else if (parseInt(value) > params[0]) {
            if (!m.errors) {
              m.errors = {}
            }
            m.errors.amount = '超過庫存數量'
          } else {
            if (m.errors && m.errors.amount) {
              delete m.errors.amount
            }
            let req = parseInt(value)
            let items = []
            for (const i of stockMapping[id]) {
              const n = Math.min(i.amount, req)
              items.push({ ...i, amount: n })
              req -= n
              if (req === 0) break
            }
            m.items = items
          }
        } else {
          m[field] = value
        }
        break
      }
    }
    updateStockRequisitionData({ name: 'merchandises' }, stockRequisition.merchandises);
  }

  function onMerchandiseChanged(merchandises) {
    if (merchandises.length) {
      for (let m of merchandises) {
        m.amount = '0'
        m.maxAmount = stockMapping[m.id]?.reduce((acc, cur) => acc + cur.amount, 0) ?? 0
        m.items = []
      }
      updateStockRequisitionData({ name: 'merchandises' }, stockRequisition.merchandises.concat(merchandises));
    }
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = stockRequisition.merchandises.reduce((acc, cur) => {if (!cur.lock) {acc[cur.id] = true};return acc}, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  }

  function handleCheckboxClick(id) {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  function onDeleteItems() {
    const merchandises = stockRequisition.merchandises.filter(m => !selectedItems[m.id])
    if (merchandises.length !== stockRequisition.merchandises.length) {
      updateStockRequisitionData({ name: 'merchandises' }, merchandises);
      setSelectedItems({});
    }
  }

  function validateField(field, value) {
    // if (field.required && value === '') {
    //   return formatMessage({id: 'form.field.isRequired'})
    // }
    if (field.name === 'merchandises') {
      for (const m of value) {
        if (m.errors && m.errors.amount) {
          return m.errors.amount
        }
      }
    }
    return ''
  }

  function updateStockRequisitionData(field, value) {
    let newValue = value
    // if (field.allowCharacter) {
    //   newValue = newValue.replace(field.allowCharacter, '')
    // }
    // if (field.maxLength) {
    //   newValue = newValue.substring(0, field.maxLength)
    // }

    // if (supplierData[field.name] === newValue) return;

    let err = validateField(field, value)

    let newData = { ...stockRequisition, [field.name]: newValue, [`${field.name}_err`]: err }
    if (field.name === 'source') {
      setRequisitionSource(value)
    }
    setOwStockRequisition(newData)
  }

  async function handleSave() {
    lockSubmitButton(true)
    setLoading(true);

    const fields = [
      { name: 'source' },
      { name: 'date' },
      { name: 'merchandises' },
      { name: 'note' },
    ]

    // let err = {}
    let newData = stockRequisition
    // if (Object.keys(err).length > 0) {
    //   newData = {...stockRequisition, ...err}
    //   setOwStockRequisition(newData)
    // }
    for (let field of fields) {
      if (newData[`${field.name}_err`] !== undefined && newData[`${field.name}_err`] !== '') {
        lockSubmitButton(false)
        setLoading(false);
        return
      }
    }

    let data = {}
    for (let field of fields) {
      if (field.type === '-') continue
      data[field.name] = newData[field.name]
    }

    data.merchandises = data.merchandises.reduce((acc, cur) => {
      acc[cur.id] = {
        amount: parseInt(cur.amount),
        items: cur.items,
      };
      return acc;
    }, {})

    try {
      await (firebase.functions().httpsCallable('saveStockRequisition'))({ id: stockRequisition.id, ...data })
    } catch (ex) {
      console.log(ex)
    }
    lockSubmitButton(false)
    handleClose()
  }

  function handleClose() {
    navigate('/stock/stockRequisition/pending');
  }

  function getToolbox() {
    return <Button
      disabled={stockRequisition.source === ''}
      sx={{ m: 1, whiteSpace: 'nowrap' }}
      variant="contained"
      color="primary"
      startIcon={<AddShoppingCartIcon />}
      onClick={() => {
        setLockSource(true)
        setOpenDialog(true)
      }}
    >
      <FormattedMessage id="editStockRequisition.addMerchandise" />
    </Button>
  }

  function getToolboxForSelection() {
    return <Button
      variant="contained"
      color="primary"
      startIcon={<DeleteIcon />}
      style={{ whiteSpace: 'nowrap', marginRight: '8px' }}
      onClick={onDeleteItems}
    >
      <FormattedMessage id="editStockRequisition.removeMerchandise" />
    </Button>
  }

  function getSourceSelector() {
    if (currentUser.stockRequisitionSource.length === 1 || stockRequisitionId !== 'new') {
      const value = stockRequisitionId !== 'new' ?
        (customerMapping[stockRequisition.source] ? customerMapping[stockRequisition.source].nickname : '') :
        (customerMapping[currentUser.stockRequisitionSource[0]] ? customerMapping[currentUser.stockRequisitionSource[0]].nickname : '')

      return (<TextField
        disabled
        type="text"
        label={formatMessage({ id: 'stockRequisition.table.detail.source' })}
        variant="outlined"
        value={value}
        fullWidth
        size="small"
      />)
    } else {
      return (<TextField
        select
        required
        disabled={lockSource}
        type="text"
        label={formatMessage({ id: 'stockRequisition.table.detail.source' })}
        variant="outlined"
        value={stockRequisition.source}
        onChange={e => updateStockRequisitionData({ name: 'source' }, e.target.value)}
        fullWidth
        size="small"
        error={stockRequisition.source_err ? true : false}
        helperText={stockRequisition.source_err}
      >
        {filteredCustomers.map(c => <MenuItem key={c.id} value={c.id}>
          {c.nickname}
        </MenuItem>)}
      </TextField>)
    }
  }

  // const invoiceSubtotal = stockRequisition.merchandises.reduce((acc, cur) => {
  //   acc += cur.unitPrice * cur.amount
  //   return acc;
  // }, 0)
  // const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  // const invoiceTotal = invoiceTaxes + invoiceSubtotal;
  // const showVoidButton = (stockRequisition.id !== 'new' && stockRequisition.merchandises.length === 0)

  return (
    <div style={{ flexGrow: 1 }}>
      {openDialog && <SelectMerchandiseDialog
        customer={stockRequisition.source}
        defaultSelectedItems={[]}
        handleClose={() => setOpenDialog(false)}
        handleSave={onMerchandiseChanged}
        ignoreIds={stockRequisition.merchandises.map(m => m.id)}
        defaultCategoryFilter={{ type: 'i', warehousing: 'type1' }}
        loadStockMapping={true}
        hideFields={['orderUnit']}
        mainWareroomStock
        size="lg"
      />}
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            {getSourceSelector()}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'stockRequisition.table.detail.createdBy' })}
              variant="outlined"
              value={userMapping[stockRequisition.createdBy] ? userMapping[stockRequisition.createdBy].displayName : ''}
              fullWidth
              size="small"
            />
          </Grid>
          {stockRequisition.lock && <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'stockRequisition.table.detail.createdAt' })}
              variant="outlined"
              value={dayjs(stockRequisition.createdAt).format('YYYY-MM-DD')}
              fullWidth
              size="small"
            />
          </Grid>}
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'stockRequisition.table.detail.date' })}
              variant="outlined"
              value={stockRequisition.date}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: '8px 0px' }} />
        <EnhancedTableToolbar
          title="editStockRequisition.table.title"
          selectdMessage="editStockRequisition.table.selected"
          numSelected={Object.keys(selectedItems).length}
          toolbox={getToolbox()}
          toolboxForSelection={getToolboxForSelection()}
        />
        <TableContainer component={Paper}>
          <Table>
            <EnhancedTableHead
              headerCells={headerCells}
              numSelected={Object.keys(selectedItems).length}
              onCheckboxClick={handleSelectAllClick}
              rowCount={stockRequisition.merchandises.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              expandable
            />
            <TableBody>
              {stableSort(stockRequisition.merchandises.map(m => formatData(m)), getComparator(order, orderBy)).map(merchandise => (
                <EnhancedTableRow
                  key={merchandise.id}
                  rowCells={rowCells}
                  cellData={merchandise}
                  onCheckboxClick={(e) => handleCheckboxClick(merchandise.id)}
                  selected={selectedItems[merchandise.id] || false}
                  lock={merchandise.lock}
                  expandable
                  expandContent={<MerchandiseDetail merchandise={merchandise} />}
                />
              ))}
              {/* <TableRow>
                <TableCell colSpan={5}/>
                <TableCell align="right"><Typography variant="subtitle2"><FormattedMessage id="stockRequisition.table.detail.subtotal" /></Typography></TableCell>
                <TableCell align="right">{invoiceSubtotal}</TableCell>
                <TableCell />
              </TableRow> */}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'stockRequisition.table.detail.note' })}
              variant="outlined"
              value={stockRequisition.note}
              onChange={e => updateStockRequisitionData({ name: 'note' }, e.target.value)}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            disabled={disableSubmitButton || loading || stockRequisition.merchandises.length === 0}
            loading={loading}
            loadingPosition="start"
            loadingIndicator={<CircularProgress size={24} />}
            startIcon={<div />}
            variant="contained"
          >
            <FormattedMessage id="button.submit" />
          </LoadingButton>
        </Stack>
      </Box>
    </div>
  );
}

// EditStockRequisitionPage.defaultProps = {

// }

export default EditStockRequisitionPage;
