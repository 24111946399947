import inventoryCheck from './inventoryCheck';
import requisition from './requisition';
import purchaseOrder from './purchaseOrder';
import arrangeStockForm from './arrangeStockForm';
import receipt from './receipt';
import borrowingReceipt from './borrowingReceipt';
import returnBorrowingReceipt from './returnBorrowingReceipt';
import stockRequisition from './stockRequisition';
import deliveryOrder from './deliveryOrder';
import borrowingDelivery from './borrowingDelivery';
import returnForm from './returnForm';
import salesReturnForm from './salesReturnForm';
import borrowingOrder from './borrowingOrder';
import returnBorrowingForm from './returnBorrowingForm';
import editWorkflowSteps from './editWorkflowSteps';
import product from './product';
import supplier from './supplier';
import merchandise from './merchandise';
import newProfile from './newProfile';
import user from './user';
import sideMenu from './sideMenu';
import header from './header';
import login from './login';
import shoppingCart from './shoppingCart';
import dialogs from './dialogs';
import doctorBonus from './doctorBonus'
import salesBonus from './salesBonus'
import form from './form';
import stepActions from './stepActions';
import button from './button';
import report from './report';
import medicine from './medicine'
import scrapForm from './scrapForm'

function flattenObject(ob, prefix = '') {
  const toReturn = {}
  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue

    if ((typeof ob[i]) === 'object') {
      const flatObject = flattenObject(ob[i], prefix + i + '.');
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue
        toReturn[x] = flatObject[x];
      }
    } else {
      toReturn[prefix + i] = ob[i]
    }
  }
  return toReturn
}

const i18n = {
  'filter': '過濾條件',
  'search': {
    'startDate': '搜尋範圍-開始日期',
    'endDate': '搜尋範圍-結束日期',
  },
  'system': {
    'title': '庫存管理系統',
  },
  'vendor': {
    'tab': {
      'customer': '客戶',
      'supplier': '供應商',
    }
  },
  'searchbox': {
    'label': '依{filter}搜尋',
  },
  'invoice': {
    'subtotal': '小計',
    'tax': '營業稅',
    'total': '總金額',
    'discount': '現金折扣',
    'shippingFee': '運費',
  },
  ...button,
  ...stepActions,
  ...user,
  ...header,
  ...login,
  ...sideMenu,
  ...product,
  ...supplier,
  ...newProfile,
  ...inventoryCheck,
  ...requisition,
  ...purchaseOrder,
  ...arrangeStockForm,
  ...receipt,
  ...borrowingReceipt,
  ...returnBorrowingReceipt,
  ...stockRequisition,
  ...deliveryOrder,
  ...borrowingDelivery,
  ...returnForm,
  ...scrapForm,
  ...salesReturnForm,
  ...borrowingOrder,
  ...returnBorrowingForm,
  ...editWorkflowSteps,
  ...shoppingCart,
  ...merchandise,
  ...dialogs,
  ...form,
  ...doctorBonus,
  ...salesBonus,
  ...report,
  ...medicine
};

export default flattenObject(i18n)
