import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';

import EnhancedTable from '../../components/EnhancedTable';
import SimpleTableToolbar from '../../components/SimpleTableToolbar';
import SearchBox from '../../components/SearchBox';
import ExpandButton from '../../components/ExpandButton';
import ReceiptView from './ReceiptView';
import ReceiptReport from './ReceiptReport';
import SelectSupplierDialog from '../../components/SelectSupplierDialog';
import { useFirestoreDataAndMapping, useSupplierDataAndMapping } from '../../modules/uitls';

function ReceiptDetail({ userMapping, receipt, ...props }) {
  const r = { ...receipt }
  // r.createdBy = { id: r.createdBy, name: userMapping[r.createdBy]?.displayName || '系統' }
  return (
    <div style={{ padding: 15 }}>
      <ReceiptView userMapping={userMapping} receipt={r} {...props} />
    </div>
  )
}

ReceiptDetail.propTypes = {
  receipt: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
  supplierMapping: PropTypes.object.isRequired,
};

function ReceiptList({ formName, receipts, title, extFilter = false, report = false }) {
  const { formatMessage } = useIntl()
  const [userMapping] = useFirestoreDataAndMapping('users')
  const [customerMapping, customers] = useFirestoreDataAndMapping('customers')
  const [supplierMapping] = useSupplierDataAndMapping()
  const [merchandiseMapping] = useFirestoreDataAndMapping('merchandises')

  const [currentFilter, setCurrentFilter] = useState(null)
  const [expand, setExpand] = useState(true);
  const [sourceFilter, setSourceFilter] = useState('')
  const [supplierFilter, setSupplierFilter] = useState('')
  const [openSupplierDialog, setOpenSupplierDialog] = useState(false);

  const filteredReceipts = filterReceipts()

  const headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'supplierName', sort: 'supplierName' },
    { text: 'sourceName', sort: 'sourceName' },
    { text: 'createdBy', sort: 'createdBy' },
    { text: 'createdAt', sort: 'createdAt' },
    { text: 'date', sort: 'date' },
  ].map(c => {c.text = formatMessage({ id: `${formName}.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'sn' },
    { field: 'supplierName' },
    { field: 'sourceName' },
    { field: 'createdBy' },
    { field: 'createdAt' },
    { field: 'date' },
  ]

  function filterReceipts() {
    let items = [...receipts]
    if (sourceFilter) {
      items = items.filter(i => i.source === sourceFilter)
    }
    if (supplierFilter) {
      items = items.filter(i => i.shippingOut ? i.shippingOut === supplierFilter : i.supplier === supplierFilter)
    }
    if (currentFilter && currentFilter.text) {
      const lowerCaseText = currentFilter.text.toLowerCase()
      if (currentFilter.name === 'sourceName') {
        return items.filter(s => customerMapping[s.source]?.nickname.toLowerCase().includes(lowerCaseText))
      } else if (currentFilter.name === 'createdBy') {
        return items.filter(s => userMapping[s.approvedBy]?.displayName.toLowerCase().includes(lowerCaseText))
      } else if (currentFilter.name === 'supplier') {
        return items.filter(
          s => supplierMapping[s.supplier]?.nickname.toLowerCase().includes(lowerCaseText) ||
          supplierMapping[s.supplier]?.name.toLowerCase().includes(lowerCaseText)
        )
      } else if (currentFilter.name === 'merchandiseName') {
        return items.filter(s =>
          Object.keys(s.merchandises || {}).filter(c => merchandiseMapping[c])
            .filter(c => merchandiseMapping[c].name.toLowerCase().includes(lowerCaseText) ||
            merchandiseMapping[c].nickname.toLowerCase().includes(lowerCaseText)).length +
            Object.keys(s.extraMerchandises || {}).filter(c => merchandiseMapping[c])
              .filter(c => merchandiseMapping[c].name.toLowerCase().includes(lowerCaseText) ||
              merchandiseMapping[c].nickname.toLowerCase().includes(lowerCaseText)).length
        )
      } else if (currentFilter.name === 'merchandiseCode') {
        return items.filter(s =>
          Object.keys(s.merchandises || {}).filter(c => merchandiseMapping[c])
            .filter(c => merchandiseMapping[c].code.toLowerCase().includes(lowerCaseText)).length +
            Object.keys(s.extraMerchandises || {}).filter(c => merchandiseMapping[c])
              .filter(c => merchandiseMapping[c].code.toLowerCase().includes(lowerCaseText)).length
        )
      } else {
        return items.filter(s => s[currentFilter.name].toLowerCase().includes(lowerCaseText))
      }
    }
    return items
  }

  const formatData = (receipt) => {
    const newData = { ...receipt }
    newData.sourceName = customerMapping[newData.source]?.nickname
    newData.supplierName = supplierMapping[newData.supplier]?.nickname
    newData.createdBy = receipt.lock ? userMapping[newData.approvedBy]?.displayName : 'N/A'
    newData.createdAt = dayjs(receipt.createdAt.seconds * 1000).format('YYYY-MM-DD')
    newData.date = receipt.lock ? receipt.approveDate : 'N/A'

    const keys = receipt.merchandises ? Object.keys(receipt.merchandises) : []
    const extKeys = receipt.extraMerchandises ? Object.keys(receipt.extraMerchandises) : []
    newData.merchandises = keys.map(k => {
      return { ...receipt.merchandises[k], id: k }
    }).concat(extKeys.map(k => {
      return { ...receipt.extraMerchandises[k], purchaseOrderSn: 'N/A', purchaseOrderId: 'N/A', id: k }
    }))
    delete newData.extraMerchandises

    return newData
  }

  const filterItems = [
    { name: 'sn' },
    { name: 'sourceName', type: 'customer' },
    { name: 'supplier' },
    { name: 'createdBy' },
    { name: 'date' },
    { name: 'merchandiseName' },
    { name: 'merchandiseCode' },
    { name: 'note' },
  ].map(i => {i.text = formatMessage({ id: `${formName}.table.detail.${i.name}` });return i})

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  function getToolbox() {
    if (extFilter) {
      return <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              select
              type="text"
              label={formatMessage({ id: 'receipt.table.detail.source' })}
              variant="outlined"
              value={sourceFilter}
              onChange={e => setSourceFilter(e.target.value)}
              fullWidth
              size="small"
            >
              {[{ id: '', nickname: '全部' }, ...customers].map(c => <MenuItem key={c.id || 'all'} value={c.id}>
                {c.nickname}
              </MenuItem>)}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              type="text"
              size="small"
              label={formatMessage({ id: 'receipt.table.detail.shippingOut' })}
              variant="outlined"
              onKeyDown={e => {if (e.code === 'Backspace') {setSupplierFilter('')}}}
              onClick={() => setOpenSupplierDialog(true)} //open popup
              value={supplierFilter ? supplierMapping[supplierFilter].nickname : ''}
              fullWidth
            />
          </Grid>
        </Grid>
        <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />
      </>
    } else {
      return <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />
    }
  }

  return (
    <div>
      {openSupplierDialog && <SelectSupplierDialog
        defaultSelectedItem={supplierFilter}
        handleClose={() => setOpenSupplierDialog(false)}
        handleSave={value => setSupplierFilter(value)}
        ignoreIds={[]}
      />}
      {report && <ReceiptReport
        merchandiseMapping={merchandiseMapping}
        receipts={filteredReceipts.map(r => formatData(r))}
        formName={formName}
      />}
      <SimpleTableToolbar
        title={title}
        buttons={<ExpandButton open={expand} onExpandChange={setExpand} />}
        toolbox={getToolbox()}
      />
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <EnhancedTable
          defaultOrder="desc"
          defaultOrderField="sn"
          headerCells={headerCells}
          rowCells={rowCells}
          getExpandContent={receipt =>
            <ReceiptDetail
              userMapping={userMapping}
              merchandiseMapping={merchandiseMapping}
              supplierMapping={supplierMapping}
              receipt={receipt}
              formName={formName}
            />
          }
          tableData={filteredReceipts.map(r => formatData(r))}
        />
      </Collapse>
    </div>
  );
}

ReceiptList.propTypes = {
  receipts: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  title: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  extFilter: PropTypes.bool,
  report: PropTypes.bool,
};

export default ReceiptList;
