import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import ReceiptTab from '../Purchase/ReceiptTab';
import ContextStore from '../../modules/context';

function ReceiptReport() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const location = useLocation()

  useEffect(() => {
    setBreadcrumbs([{
      text: `${formatMessage({ id: 'sideMenu.reports.receipt' })}${formatMessage({ id: 'sideMenu.reports.root' })}`
    }])
    return () => {
    };
  }, [location.pathname]);

  return (
    <div style={{ padding: '24px 24px 60px 24px' }}>
      <ReceiptTab formName="receipt" statusFilter="done" extFilter report/>
    </div>
  );
}

export default ReceiptReport;
