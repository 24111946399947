import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';
import dayjs from 'dayjs'

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import ContextStore from '../../modules/context';
import { lockSubmitButton, unwrap, useFirestoreDataAndMapping } from '../../modules/uitls';
import EditNewSyncProductView from '../../components/EditNewSyncProductView'
import EditNewProductView from '../../components/EditNewProductView'
import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableRow from '../../components/EnhancedTableRow';

function EditSyncProductPage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const urProductCreate = currentUser.userRights['product-create']
  const urProductEdit = currentUser.userRights['product-edit']

  const childRef = useRef()
  const productChildRef = useRef()
  const { productId, customerId } = useParams()
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(null)
  const [productSync, setProductSync] = useState(null)
  const [productMapping, setProductMapping] = useState(null)
  const [customerMapping] = useFirestoreDataAndMapping('customers')
  const [commonPriceTemplates, setCommonPriceTemplates] = useState([])
  const [salesBonusTemplates, setSalesBonusTemplates] = useState([])
  let openCode = ''
  let productData = {}

  useEffect(() => {
    const redirect = productId === 'new' ? urProductCreate !== true : urProductEdit !== true
    if (redirect) {
      navigate('/');
    }
  }, [urProductCreate, urProductEdit]);

  useEffect(() => {
    const breadcrumbs = [{
      link: `/products/customer/${customerId}`,
      text: formatMessage({ id: 'sideMenu.product.customer' })
    }]
    if (productId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: 'product.dialog.title.add' }) })
    } else {
      breadcrumbs.push({ text: formatMessage({ id: 'product.dialog.title.edit' }) })
    }
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('products').onSnapshot(snapshot => {
      const productMapping = {}
      snapshot.forEach(doc => {
        productMapping[doc.id] = unwrap(doc.data())
      })
      setProductMapping(productMapping)
    }, err => { })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('configuration').doc('commonPriceTemplates').onSnapshot(snapshot => {
      const snapshots = snapshot.data()
      let commonPrice = []
      for (const p of Object.keys(snapshots || {})) {
        commonPrice.push({
          id: p,
          name: snapshots[p]
        })
      }
      setCommonPriceTemplates(commonPrice)
    }, err => { })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('configuration').doc('salesBonusTemplates').onSnapshot(snapshot => {
      const snapshots = snapshot.data()
      const templates = []
      for (const s of Object.keys(snapshots)) {
        templates.push({ id: s, ...snapshots[s] })
      }

      setSalesBonusTemplates(templates)
    }, err => { })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    if (productId !== 'new') { // edit
      const unsubscribe = firebase.firestore().collection('products').doc(productId)
        .onSnapshot(snapshot => {
          const data = unwrap(snapshot.data())

          if (!data) {
            navigate(`/products/customer/${customerId}`);
            return
          }

          setProduct({ ...data, note: data.note ?? '' })
        }, err => { })
      return () => unsubscribe()
    } else {
      return () => { };
    }
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('syncProducts').doc(customerId).collection('productMapping').doc(productId).onSnapshot(snapshot => {
      const data = unwrap(snapshot.data())

      if (!data) {
        navigate(`/products/customer/${customerId}`);
        return
      }
      data.merchandiseType = merchandiseConcat(data)
      let newPrice = [];
      let newConvertibleProduct = [];

      if (data.commonPrice) {
        for (const key of Object.keys(data.commonPrice)) {
          newPrice.push({
            ...data.commonPrice[key],
            id: key,
            offShelfDate: data.commonPrice[key].offShelfDate ? dayjs(data.commonPrice[key].offShelfDate) : null,
            onShelfDate: data.commonPrice[key].onShelfDate ? dayjs(data.commonPrice[key].onShelfDate) : null,
            fixedAmount: data.commonPrice[key].fixedAmount || ''
          })
        }

        data.commonPrice = newPrice
      }

      if (data.convertibleProducts) {
        for (const p of Object.keys(data.convertibleProducts)) {
          newConvertibleProduct.push({
            id: p,
            points: data.convertibleProducts[p],
          })
        }

        data.convertibleProducts = newConvertibleProduct
      }

      setProductSync(data)
    }, err => { })

    return () => unsubscribe()
  }, []);

  if (!productSync || !product) {
    return ('Loading...')
  } else {
    openCode = product.cat.substr(0, 1)
    productData = {
      id: productId,
      ...product,
      ...productSync
    }
  }

  const handleSave = async () => {
    lockSubmitButton(true)
    setLoading(true);
    const data = childRef.current.getContent()

    if (data) {
      try {
        await (firebase.functions().httpsCallable('saveSyncProduct'))({ id: productData.id, customerId, ...data })
      } catch (ex) {
        console.log(ex)
      }
      lockSubmitButton(false)
      handleClose()
    } else {
      lockSubmitButton(false)
      setLoading(false);
    }
  }

  const handleClose = () => {
    navigate(`/products/customer/${customerId}`);
  }

  function formatData(data) {
    const newData = { ...data }

    if (product.package) {
      const count = Object.keys(product.package)
        .reduce((acc, cur) => acc + (product.package[cur].price ? Number(product.package[cur].price) : 0), 0)

      if (!newData.price) {
        newData.price = count
      }
    }

    return newData
  }

  function merchandiseConcat(product) {

    const merchandises = Object.keys(product.merchandises || {}).map(key => ({
      ...product.merchandises[key],
      id: key,
      type: 'merchandises'
    }))

    const adjustables = Object.keys(product.adjustables || {}).map(key => ({
      ...product.adjustables[key],
      id: key,
      type: 'adjustables'
    }))

    return merchandises.concat(adjustables)
  }

  const _packageHeaderCells = [
    { text: 'name', order: 0 },
    { text: 'amount', order: 1 },
    { text: 'customers', order: 4 },
    { text: 'cost', order: 5 },
  ]

  const _packageRowCells = [
    { field: 'name', order: 0 },
    { field: 'amount', order: 1 },
    { field: 'customers', order: 4 },
    { field: 'cost', order: 5 },
  ]

  if (openCode !== '7') {
    _packageHeaderCells.push({ text: 'ratio', order: 2 })
    _packageRowCells.push({ field: 'ratio', order: 2 })
  } else {
    _packageHeaderCells.push({ text: 'price', order: 2 }, { text: 'lowestPrice', order: 3 })
    _packageRowCells.push({ field: 'price', order: 2 }, { field: 'lowestPrice', order: 3 })
  }

  const packageHeaderCells = _packageHeaderCells.map(c => { c.text = formatMessage({ id: `product.product.${c.text}` }); return c }).sort((a, b) => a.order - b.order)
  const packageRowCells = _packageRowCells.sort((a, b) => a.order - b.order)

  const packageFormatData = (product) => {
    const newData = { ...product }
    const customers = Object.keys(product.customers || []).filter(c => customerMapping[c])
    newData.customers = customers.map(s => customerMapping[s]).map(s => s.nickname).join(', ')

    return newData
  }

  function formatProductPackage(data) {
    const newData = []
    for (const p of Object.keys(data)) {
      newData.push({
        id: p,
        ...data[p],
        name: productMapping[p].name,
        customers: productMapping[p].customers,
        cost: productMapping[p].cost
      })
    }

    return newData
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        {<EditNewProductView
          ref={productChildRef}
          product={product}
          requiredFields={['name', 'type']}
          ignoreFields={['customers']}
          disabledFields={['name', 'type', 'cost', 'staffprice', 'staffRelativesPrice', 'vipprice', 'note', 'consumable']}
          customerId={customerId}
        />}
        {openCode !== '1' && <>
          <div style={{ marginBottom: '8px', marginTop: '8px' }}>
            <Typography variant="button" component="div">
              <FormattedMessage id="product.products" />:
            </Typography>
          </div>
          <TableContainer style={{ marginBottom: '8px' }} component={Paper}>
            <Table size="small" aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={packageHeaderCells}
                rowCount={formatProductPackage(product.package).length}
              />
              <TableBody>
                {formatProductPackage(product.package).map(m => packageFormatData(m)).map(packageProduct => (
                  <EnhancedTableRow
                    key={packageProduct.id}
                    rowCells={packageRowCells}
                    cellData={packageProduct}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>}
        {<EditNewSyncProductView
          ref={childRef}
          product={formatData(productSync)}
          consumable={product.consumable}
          requiredFields={[]}
          disabledFields={[]}
          customerId={customerId}
          cat={product.cat}
          productMapping={productMapping}
          openCode={openCode}
          commonPriceData={commonPriceTemplates}
          salesBonusTemplates={salesBonusTemplates}
        />}
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            disabled={loading}
            loading={loading}
            loadingPosition="start"
            loadingIndicator={<CircularProgress size={24} />}
            startIcon={<div />}
            variant="contained"
          >
            <FormattedMessage id="button.save" />
          </LoadingButton>
        </Stack>
      </Box>
    </div>
  );
}

export default EditSyncProductPage;
