import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { getComparator, stableSort } from '../../modules/sort';
import { unwrap } from '../../modules/uitls';

// 用於調撥單選擇庫存
function SelectStockDialog({ handleClose, handleSave, customer, merchandise }) {
  const { formatMessage } = useIntl()
  const wareroom = useSelector(state => unwrap(state.firestore.data.config?.wareroom || {}))
  const wareroomMapping = (wareroom[customer] || []).reduce((acc, cur, idx) => {acc[`#${idx + 1}`] = cur; return acc}, { '#default': '主倉庫' })

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('expectedDate');
  const [merchandises, setMerchandises] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('wh').doc(customer).collection('inventories').where('id', '==', merchandise.id).where('empty', '==', false)
      .onSnapshot( snapshot => {
        const stocks = []
        snapshot.forEach(doc => {
          const m = doc.data()
          const sku = merchandise.sku
          const amount = m.currentAmount
          const maxAmount = amount
          let amountInMainWareroom = maxAmount

          const item = {
            id: doc.id,
            date: m.date,
            expectedDate: m.expectedDate,
            lotNumber: m.lotNumber,
            arrangementAmount: 0,
          }
          // 這邊根據倉庫欄位把庫存分開
          if (m.wareroom) { // 有貨物分散在其他倉庫
            for (const i of Object.keys(m.wareroom).map(key => ({ wareroom: key, amount: m.wareroom[key] }))) {
              const newItems = {
                ...item,
                wareroom: i.wareroom,
                wareroomName: wareroomMapping[i.wareroom],
                amountInStocks: i.amount,
                stocksWithUnit: i.amount + sku,
                maxAmount: i.amount
              }
              stocks.push(newItems)
              amountInMainWareroom -= i.amount
            }
          }
          if (amountInMainWareroom > 0) {
            const newItem = {
              ...item,
              wareroom: '#default',
              wareroomName: wareroomMapping['#default'],
              amountInStocks: amountInMainWareroom,
              stocksWithUnit: amountInMainWareroom + sku,
              maxAmount: amountInMainWareroom
            }
            stocks.push(newItem)
          }
        });
        const sortedStocks = stocks.sort((a, b) => {
          // if (a.expectedDate === '' && b.expectedDate === '') {
          //   return 0
          // }
          if (a.expectedDate === '' && b.expectedDate !== '') return 1
          if (a.expectedDate !== '' && b.expectedDate === '') return -1

          if (a.expectedDate === b.expectedDate) {
            if (a.date > b.date) return 1
            if (a.date < b.date) return -1
            if (a.date === b.date) return 0
          }
          if (a.expectedDate > b.expectedDate) return 1
          return -1
        })
        if (merchandise.items.length > 0) {
          const tmp = merchandise.items.reduce((acc, cur) => {acc[cur.id] = cur; return acc}, {})
          for (const i of sortedStocks) {
            if (tmp[i.id] && tmp[i.id].wareroom === i.wareroom) {
              i.arrangementAmount = tmp[i.id].arrangementAmount
              i.targetWareroom = tmp[i.id].targetWareroom
            }
          }
        }
        setMerchandises(sortedStocks)
      }, err => {})
    return () => unsubscribe()
  }, []);


  function getWareroomList(merchandise) {
    const items = (wareroom[customer] || []).map((cur, idx) => ({
      label: cur,
      value: `#${idx + 1}`,
    }))

    if (merchandise.wareroom === '#default') {
      return items
    } else {
      items.unshift({
        label: '主倉庫',
        value: '#default',
      })
      return items.filter(i => i.value !== merchandise.wareroom)
    }
  }

  const headerCells = [
    { text: 'date', sort: 'date' },
    { text: 'expectedDate', sort: 'expectedDate' },
    { text: 'lotNumber' },
    { text: 'wareroomName' },
    { text: 'stocksWithUnit' },
    { text: 'arrangementAmount', align: 'right' },
    { text: 'targetWareroom', align: 'right' },
  ].map(c => {c.text = formatMessage({ id: `selectStockDialog.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'date' },
    { field: 'expectedDate' },
    { field: 'lotNumber' },
    { field: 'wareroomName' },
    { field: 'stocksWithUnit' },
    { field: 'arrangementAmount',
      align: 'right',
      type: 'input-number',
      required: true,
      params: ['maxAmount', 'wareroom'],
      label: '調撥數量',
      onValueChanged: onCellValueChanged
    },
    { field: 'targetWareroom',
      align: 'right',
      type: 'input-select',
      required: true,
      params: ['maxAmount', 'wareroom'],
      label: '目標倉庫',
      fullWidth: true,
      width: '160px',
      onValueChanged: onCellValueChanged,
      getMenuItems: getWareroomList
    },
  ]

  function onCellValueChanged(id, field, value, params) {
    // console.log(params)
    if (params) {
      for (let m of merchandises) {
        if (m.id === id && m.wareroom === params[1]) {
          if (field === 'arrangementAmount') {
            m.arrangementAmount = value
            if (value === '') {
              if (m.errors && m.errors.arrangementAmount) {
                delete m.errors.arrangementAmount
              }
            } else if (isNaN(value)) {
              if (!m.errors) {
                m.errors = {}
              }
              m.errors.arrangementAmount = '數量錯誤'
            } else if (value > params[0]) {
              if (!m.errors) {
                m.errors = {}
              }
              m.errors.arrangementAmount = '超過庫存數量'
            } else {
              if (m.errors && m.errors.arrangementAmount) {
                delete m.errors.arrangementAmount
              }
            }
          } else if (field === 'targetWareroom') {
            m.targetWareroom = value
          }
          break
        }
      }
      // console.log(merchandises)
      setMerchandises([...merchandises]);
    }
  }

  function _totalArrangementAmount() {
    let t = 0
    for (const merchandise of merchandises) {
      if ((merchandise.errors && merchandise.errors.arrangementAmount !== undefined) || merchandise.arrangementAmount === '' || !merchandise.targetWareroom) {
        continue
      }
      t += parseInt(merchandise.arrangementAmount)
    }
    return t
  }
  const totalArrangementAmount = _totalArrangementAmount()

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onApply = () => {
    const m = {
      merchandiseId: merchandise.id,
    }
    for (const merchandise of merchandises) {
      if (merchandise.errors && merchandise.errors.arrangementAmount !== undefined) {
        return
      }
      if (merchandise.arrangementAmount !== '' && merchandise.arrangementAmount > 0) {
        if (!m.items) m.items = []
        m.items.push({
          id: merchandise.id,
          expectedDate: merchandise.expectedDate,
          lotNumber: merchandise.lotNumber,
          wareroom: merchandise.wareroom,
          wareroomName: merchandise.wareroomName,
          targetWareroom: merchandise.targetWareroom,
          targetWareroomName: wareroomMapping[merchandise.targetWareroom],
          arrangementAmount: parseInt(merchandise.arrangementAmount),
        })
      }
    }
    handleSave(m)
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title"><FormattedMessage id="selectStockDialog.title" /></DialogTitle>
      <DialogContent dividers={true}>
        <div style={{ flexGrow: 1 }}>
          <Toolbar sx={{ pl: 2, pr: 1 }}>
            <Typography sx={{ flex: '1 1 100%', flexShrink: 2 }} variant="h6" id="tableTitle" component="div">
              {merchandise.name} <FormattedMessage id="selectStockDialog.table.title" />
            </Typography>
          </Toolbar>
          <TableContainer component={Paper}>
            <Table>
              <EnhancedTableHead
                headerCells={headerCells}
                numSelected={0}
                order={order}
                orderBy={orderBy}
                // onCheckboxClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={merchandises.length}
              />
              <TableBody>
                {stableSort(merchandises, getComparator(order, orderBy)).map(m => (
                  <EnhancedTableRow
                    key={`${m.id}${m.wareroom}`}
                    rowCells={rowCells}
                    cellData={m}
                    // onCheckboxClick={(e) => handleCheckboxClick(merchandise.id)}
                    // selected={selectedItems[merchandise.id] || false}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button variant="contained" disabled={totalArrangementAmount === 0} onClick={onApply} color="primary">
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SelectStockDialog.propTypes = {
  customer: PropTypes.string.isRequired,
  merchandise: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default SelectStockDialog;
