import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function DeliveryAmountDialog({ handleClose, handleSave, merchandise }) {
  const { formatMessage } = useIntl()

  const [text, setText] = useState(merchandise.maxAmount);
  const [errorMessage, setErrorMessage] = useState('');

  function handleAction() {
    const m = {
      merchandiseId: merchandise.id,
      amount: (isNaN(text) ? 0 : parseInt(text))
    }
    handleSave(m)
    handleClose()
  }

  function onTextChange(e) {
    const value = e.target.value.replace(/[^0-9]/g, '')
    setText(value)
    if (isNaN(value) || value === '' || parseInt(value) === 0) {
      setErrorMessage('數量錯誤')
    } else if (value > merchandise.maxAmount) {
      setErrorMessage('超過需求數量')
    } else if (errorMessage !== ''){
      setErrorMessage('')
    }
  }

  return (
    <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title"><FormattedMessage id='deliveryAmountDialog.title' /></DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          variant="outlined"
          label={formatMessage({ id: 'deliveryAmountDialog.amount' })}
          type="text"
          value={text}
          onChange={onTextChange}
          fullWidth
          error={errorMessage ? true : false}
          helperText={errorMessage}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button variant="contained" disabled={!!errorMessage} onClick={handleAction} color="primary">
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeliveryAmountDialog.propTypes = {
  merchandise: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default DeliveryAmountDialog;
