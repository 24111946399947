import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Toolbar from '@mui/material/Toolbar';

import EditSupplierDialog from './EditSupplierDialog';
import EnhancedTable from '../../components/EnhancedTable';
import SearchBox from '../../components/SearchBox';
import FabAdd from '../../components/FabAdd';
import { useFirestoreDataAndMapping } from '../../modules/uitls';
import ContextStore from '../../modules/context';
import VendorView from './VendorView';
import dayjs from 'dayjs';

function SupplierDetail({ supplier, ...props }) {
  const r = { ...supplier }
  return (
    <div style={{ padding: 15 }}>
      <VendorView vendor={r} {...props} />
    </div>
  )
}

SupplierDetail.propTypes = {
  supplier: PropTypes.object.isRequired,
};

function VendorList({ workflow }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const [supplierMapping, suppliers] = useFirestoreDataAndMapping('suppliers', { extData: true })
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [flagFilter, setFlagFilter] = useState({});

  const filteredSuppliers = filterSuppliers() //currentFilter && currentFilter.text ? filterByText() : suppliers

  const filterItems = [
    { name: 'name' },
    { name: 'nickname' },
    { name: 'note' },
    { name: 'businessNumber' },
    { name: 'contactName' },
    { name: 'code' },
  ].map(i => { i.text = formatMessage({ id: `supplier.table.detail.${i.name}` }); return i })

  const flagFilterItems = (currentUser.userRights.debugging ? [
    { name: 'supplier' },
    { name: 'internal' },
    { name: 'active' },
  ] : [{ name: 'internal' }]).map(i => { i.text = formatMessage({ id: `supplier.table.detail.${i.name}` }); return i })

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.vendor' })
    }])
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    const sp = new URLSearchParams(location.search)

    let needUpdate = false
    let newFilter = {}
    for (const f of flagFilterItems) {
      const value = sp.get(f.name)
      if (value && value !== flagFilter[f.name]) {
        newFilter[f.name] = value
        needUpdate = true
      } else if (!value && flagFilter[f.name]) {
        needUpdate = true
      }
    }
    if (needUpdate) {
      setFlagFilter(newFilter)
    }
  }, [location.search]);

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'nickname' },
    { text: 'contact.name' },
    { text: 'contact.phone' },
    { text: 'contact.mobile' },
  ].map(c => { c.text = formatMessage({ id: `supplier.table.header.${c.text}` }); return c })

  const rowCells = [
    { field: 'code' },
    { field: 'nickname' },
    { field: 'contactName' },
    { field: 'contactPhone' },
    { field: 'contactMobile' },
  ]

  const onEditClick = async (supplier) => {
    const newData = { ...supplier }
    newData.shippingOut = Object.keys(newData.shippingOut || {}).map(k =>  ({
      name: supplierMapping[k].name,
      nickname: supplierMapping[k].nickname,
      id: supplierMapping[k].id,
    }))
    setSelectedSupplier(newData)
  }

  const formatData = (supplier) => {
    const newData = { ...supplier }
    newData.phone = newData.phone?.replace(/\s/g, '').replace(/#$/, '') || ''
    newData.fax = newData.fax?.replace(/\s/g, '').replace(/#$/, '') || ''
    newData.contactName = newData.contacts && newData.contacts.length ? newData.contacts[0].contactName : ''
    newData.contactPhone = (newData.contacts && newData.contacts.length ? newData.contacts[0].contactPhone : '').replace(/\s/g, '').replace(/#$/, '')
    newData.contactMobile = newData.contacts && newData.contacts.length ? newData.contacts[0].contactMobile : ''
    newData.ownerPhone = newData.ownerPhone?.replace(/\s/g, '').replace(/#$/, '') || ''
    // newData.shippingOut = Object.keys(newData.shippingOut || {}).map(k => supplierMapping[k].name).join(', ')
    return newData
  }

  const getNewSupplier = () => {
    return {
      id: 'new',
      // code: (String(suppliers.length+1)).padStart(6, 'S000000'),
      name: '',
      nickname: '',
      businessNumber: '', // 04595257
      phone: '',
      fax: '',
      address: '',
      shippingOut: [],
      email: '',
      basicPurchaseAmount: '',
      freeShippingAmount: '',
      ownerName: '',
      ownerPhone: '',
      ownerMobile: '',
      billRule: '',
      note: '',
      contacts: [],
      supplier: true,
      internal: false,
      active: true,
    }
  }
  function filterSuppliers() {
    if (!currentFilter &&
      !flagFilter.supplier &&
      !flagFilter.internal &&
      !flagFilter.active) {
      return suppliers
    }
    let items = [...suppliers]

    if (flagFilter.supplier) {
      const type = flagFilter.supplier === 'type1'
      items = items.filter(i => i.supplier === type)
    }
    if (flagFilter.internal) {
      const type = flagFilter.internal === 'type1'
      items = items.filter(i => i.internal === type)
    }
    if (flagFilter.active) {
      const type = flagFilter.active === 'type1'
      items = items.filter(i => i.active === type)
    }
    if (currentFilter) {
      if (currentFilter.name === 'contactName') {
        items = items.filter(s => s.contacts.find(c => c.contactName.toLowerCase().includes(currentFilter.text.toLowerCase())))
      } else {
        items = items.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))
      }
    }
    return items
  }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  function getMenuItem(name) {
    return [{ value: '' }, { value: 'type1' }, { value: 'type2' }].map(i => {
      i.label = i.value ? formatMessage({ id: `supplier.${name}.${i.value}` }) : formatMessage({ id: 'supplier.flag.all' })
      return i
    })
  }

  function updateFlagFilter(name, value) {
    const newFilter = { ...flagFilter }
    newFilter[name] = value
    setFlagFilter(newFilter)
    const sp = new URLSearchParams(location.search)
    const str1 = sp.toString()
    if (value === '') {
      sp.delete(name)
    } else {
      sp.set(name, value)
    }
    const str2 = sp.toString()
    if (str1 !== str2) {
      navigate({ pathname: location.pathname, search: `?${str2}` }, { replace: true });
    }
  }

  const getFilter = (filter) => {
    return <Grid item key={filter.name} xs={3} sm={3} md={3}>
      <TextField
        select
        type="text"
        size="small"
        label={filter.text}
        variant="outlined"
        onChange={e => { updateFlagFilter(filter.name, e.target.value) }}
        value={flagFilter[filter.name] || ''}
        fullWidth
      >
        {getMenuItem(filter.name).map((option, idx) => {
          return option.header ?
            (<ListSubheader key={`${option.value}-${idx}`}>{option.label}</ListSubheader>) :
            (<MenuItem key={`${option.value}-${idx}`} value={option.value}>{option.label}</MenuItem>)
        })}
      </TextField>
    </Grid>
  }

  // function exportData() {

  //   const _formatData = (supplier) => {
  //     const newData = { ...supplier }
  //     newData.phone = newData.phone?.replace(/\s/g, '').replace(/#$/, '') || ''
  //     newData.fax = newData.fax?.replace(/\s/g, '').replace(/#$/, '') || ''
  //     // newData.contactName = newData.contacts && newData.contacts.length ? newData.contacts[0].contactName : ''
  //     // newData.contactPhone = (newData.contacts && newData.contacts.length ? newData.contacts[0].contactPhone : '').replace(/\s/g, '').replace(/#$/, '')
  //     // newData.contactMobile = newData.contacts && newData.contacts.length ? newData.contacts[0].contactMobile : ''
  //     // newData.ownerPhone = newData.ownerPhone?.replace(/\s/g, '').replace(/#$/, '') || ''
  //     newData.shippingOut = Object.keys(newData.shippingOut || {}).map(k => supplierMapping[k].name).join(', ')
  //     newData.note = newData.note.replace(/(?:\r\n|\r|\n)/g, ' ').replace(/,/gi, '')
  //     newData.internal = newData.internal ? '內部廠商' : '外部廠商'
  //     return newData
  //   }

  //   const rows = []
  //   const _rows = []

  //   const _fields = [
  //     { name: 'internal' },
  //     { name: 'code', sm: 6 },
  //     { name: 'name', sm: 6 },
  //     { name: 'nickname', sm: 6 },
  //     { name: 'businessNumber', sm: 6 },
  //     { name: 'phone', sm: 6 },
  //     { name: 'fax', sm: 6 },
  //     // { name: 'billRule', type: 'select', sm: 6 },
  //     { name: 'basicPurchaseAmount', type: 'text', sm: 6, md: 2 },
  //     { name: 'freeShippingAmount', type: 'text', sm: 6, md: 2 },
  //     { name: 'email', type: 'text', sm: 12, md: 6 },
  //     { name: 'address', type: 'text', sm: 12, md: 6 },
  //     // { name: 'contact', type: '-', reference: 'contacts' },
  //     // { name: 'contacts', type: 'array' },
  //     // { name: 'owner', type: '-' },
  //     { name: 'contactName' },
  //     { name: 'contactPhone' },
  //     { name: 'contactMobile' },
  //     { name: 'ownerName', type: 'text', sm: 6 },
  //     { name: 'ownerPhone', type: 'text', sm: 6 },
  //     { name: 'ownerMobile', type: 'text', sm: 6 },
  //     // { name: 'shipping', type: '-' },
  //     { name: 'shippingOut', type: 'text', sm: 12, md: 12 },
  //     // { name: 'other', type: '-' },
  //     { name: 'note', type: 'multiline', multiline: true, sm: 6, md: 6 },
  //   ].map(c => { c.text = formatMessage({ id: `supplier.table.detail.${c.name}` }); return c })


  //   const newData = filteredSuppliers.map(i => _formatData(i))

  //   for (const h of _fields) {
  //     _rows.push(h.text)
  //   }

  //   rows.push(_rows.join(','))

  //   for (const n of newData) {
  //     let _rows2 = []
  //     for (const r of _fields) {
  //       if (['contactName', 'contactPhone', 'contactMobile'].includes(r.name)) {
  //         if (n.contacts.length > 0) {
  //           _rows2.push(n.contacts[0][r.name])
  //         }
  //       } else {
  //         _rows2.push(n[r.name])
  //       }
  //     }
  //     rows.push(_rows2.join(','))
  //   }

  //   exportFields(rows, `vonder_${dayjs().format('YYYY-MM-DD')}.csv`)
  // }

  // function exportFields(rows, path) {
  //   try {
  //     const content = rows.join('\n')
  //     const csvData = new Blob(['\uFEFF' + content], { type: 'text/csv' })
  //     const csvUrl = URL.createObjectURL(csvData)
  //     const aExport = document.createElement('a')
  //     aExport.href = csvUrl
  //     aExport.target = '_blank'
  //     aExport.download = path
  //     aExport.click()
  //   } catch (error) {
  //     console.error(error) // eslint-disable-line
  //     alert('An error occurred. Please refresh and try again.')
  //   }
  // }

  return (
    <div style={{ padding: '12px 24px 80px 24px' }}>
      {selectedSupplier &&
        <EditSupplierDialog
          supplier={selectedSupplier}
          handleClose={() => setSelectedSupplier(null)}
        />
      }
      {currentUser.userRights['vendor-create'] === true && <FabAdd onClick={() => setSelectedSupplier(getNewSupplier())} />}
      <Toolbar sx={{ pl: 2, pr: 1 }}>
        <Typography sx={{ flex: '1 1 100%', flexShrink: 2 }} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id="supplier.table.title" />
        </Typography>
        <Grid container spacing={3}>
          {flagFilterItems.map(f => getFilter(f))}
        </Grid>
        <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} updateUrlParams />
      </Toolbar>
      {/* <button onClick={() => exportData()}>aaaaa</button> */}
      <EnhancedTable
        defaultOrder="asc"
        defaultOrderField="code"
        headerCells={headerCells}
        rowCells={rowCells}
        getExpandContent={supplier =>
          <SupplierDetail
            supplier={supplier}
            supplierMapping={supplierMapping}
            workflow={workflow}
            handleEditClick={() => onEditClick(supplier)}
          />
        }
        tableData={filteredSuppliers.map(i => formatData(i))}
      />
    </div>
  );
}

VendorList.propTypes = {
  workflow: PropTypes.shape({
    lock: PropTypes.bool,
  }).isRequired,
};

export default VendorList;
