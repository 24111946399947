import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import Divider from '@mui/material/Divider';

import DateRangePickerField from '../../components/DateRangePickerField';
import NewProfileList from './NewProfileList';
import { unwrap } from '../../modules/uitls'
import ContextStore from '../../modules/context';

function NewProfileTab({ profile }) {
  const { currentUser } = useContext(ContextStore)
  let tableName = ''
  let fieldName = ''
  if (profile === 'supplier') {
    tableName = 'newSupplier'
    fieldName = 'supplierId'
  } else if (profile === 'merchandise') {
    tableName = 'newMerchandise'
    fieldName = 'merchandiseId'
  } else if (profile === 'product') {
    tableName = 'newProduct'
    fieldName = 'productId'
  } else if (profile === 'folder') {
    tableName = 'newFolderProduct'
    fieldName = 'productId'
  } else if (profile === 'package') {
    tableName = 'newPackageProduct'
    fieldName = 'productId'
  } else if (profile === 'combination') {
    tableName = 'newCombinationProduct'
    fieldName = 'productId'
  }

  const { tabName } = useParams()

  const [newProfiles, setNewProfiles] = useState([])
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [productMapping, setProductMapping] = useState({})

  useEffect(() => {
    const unsubscribe = (['package', 'combination', 'folder'].includes(profile) ) ? firebase.firestore().collection('products').onSnapshot(snapshot => {
      const productMapping = {}
      snapshot.forEach(doc => {
        productMapping[doc.id] = { id: doc.id, ...unwrap(doc.data()) }
      })

      setProductMapping(productMapping)
    }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, []);

  useEffect(() => {
    setNewProfiles([])
    const onSnapshot = snapshot => {
      const newProfiles = []
      snapshot.forEach(doc => {
        newProfiles.push({ ...unwrap(doc.data()), id: doc.id })
      });
      setNewProfiles(newProfiles)
    }
    const unsubscribe = tabName === 'pending' ?
      firebase.firestore().collection('newProfiles').where('profile', '==', profile).where('status', '==', tabName).onSnapshot(onSnapshot, err => {}) :
      firebase.firestore().collection('newProfiles').where('profile', '==', profile).where('status', '==', tabName).where('date', '>=', startDate).where('date', '<=', endDate).onSnapshot(onSnapshot, err => {})
    return () => unsubscribe()
  }, [startDate, endDate, profile]);

  const newProfilesForTab = newProfiles.filter(r =>
    currentUser.userRights.debugging ||
    currentUser[`${tableName}Source`].includes(r.source) ||
    currentUser[`${tableName}Review`].includes(r.source) ||
    currentUser[`${tableName}Overwrite`].includes(r.source)
  );

  const reqWaitingForProcess = waitingForProcess()

  function waitingForProcess() {
    if (tabName === 'pending') {
      return newProfilesForTab.filter(r => {
        const history = r.history || []
        const currentStep = history.length > 0 ? history[history.length - 1].step : 0
        if (isNaN(currentStep) || currentStep === r.steps.length) return false
        return r.steps[currentStep].users.includes(currentUser.key)
      })
    } else if (tabName === 'done') {
      return newProfilesForTab.filter(r => {
        return r.steps[0].overwrite.includes(currentUser.key) && !r[fieldName]
      })
    } else {
      return []
    }
  }

  return (
    <div style={{ paddingBottom: '60px' }}>
      {tabName !== 'pending' && <DateRangePickerField
        startDate={startDate}
        endDate={endDate}
        onStartDateChanged={setStartDate}
        onEndDateChanged={setEndDate}
      />}
      {reqWaitingForProcess.length > 0 && <>
        <NewProfileList newProfiles={reqWaitingForProcess} title={`newProfile.table.${tableName}.${tabName}`} productMapping={productMapping}/>
        <Divider sx={{ mx: '0px', my: '10px' }} />
      </>}

      <NewProfileList newProfiles={newProfilesForTab} title={`newProfile.table.${tableName}.title`} productMapping={productMapping}/>
    </div>
  );
}

NewProfileTab.propTypes = {
  profile: PropTypes.string.isRequired,
};

export default NewProfileTab;
