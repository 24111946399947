import React from 'react';
import PropTypes from 'prop-types';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

export default function WorkflowSteps({ steps, small }) {
  return (
    <Stepper activeStep={0} sx={{ p: small ? '0px' : '24px' }} alternativeLabel>
      {steps.map((step, idx) => (
        <Step key={`${step.name}_${idx}`} active={idx !== steps.length -1}>
          <StepLabel sx={{ whiteSpace: 'pre-line' }}>
            {`${step.name}\n${step.users.map(s => s.name).join(', ')}`}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

WorkflowSteps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  })).isRequired,
  small: PropTypes.bool,
};
