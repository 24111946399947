import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import i18n from '../../i18n/i18n'

const categories = ['DPO', 'DOI', 'DIV', 'DSC', 'CIN', 'CPR', 'CBD', 'CIP', 'CCO', 'CWI', 'COT']
  .map(i => ({ id: i, name: i18n[`merchandise.category.${i}`] }))

export default function SelectCategoryDialog({ index, defaultSelectedItems, handleClose, handleSave }) {
  const [selectedItems, setSelectedItems] = useState(defaultSelectedItems.reduce((acc, cur) => {acc[cur] = true; return acc;}, {}));

  const handleCheckboxClick = (id) => {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  const onApply = () => {
    const selectedCategories = categories.filter(c => selectedItems[c.id]).map(c => c.id)
    handleSave(index, selectedCategories)
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title"><FormattedMessage id="selectCategoryDialog.title" /></DialogTitle>
      <DialogContent dividers={true}>
        <div style={{ flexGrow: 1 }}>
          {categories.map(category => (<FormControlLabel
            key={category.id}
            control={
              <Checkbox
                checked={selectedItems[category.id] || false}
                onChange={() => handleCheckboxClick(category.id)}
                color="primary"
              />
            }
            label={category.name}
          />))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button variant="contained" onClick={onApply} color="primary">
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SelectCategoryDialog.propTypes = {
  index: PropTypes.number.isRequired,
  defaultSelectedItems: PropTypes.arrayOf(PropTypes.string),
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};
