const collections = ['suppliers']
const unwraper = store => next => action => {
  if (action.type === '@@reduxFirestore/LISTENER_RESPONSE' && collections.includes(action.meta.collection)) {
    const payload = action.payload
    const keys = Object.keys(payload.data)
    for (const key of keys) {
      const { extData, ...rootData } = payload.data[key]
      payload.data[key] = { ...rootData, ...extData }
    }
    payload.ordered = payload.ordered.map(data => {
      const { extData, ...rootData } = data
      return { ...rootData, ...extData }
    })
  } else if (['@@reduxFirestore/DOCUMENT_MODIFIED', '@@reduxFirestore/DOCUMENT_ADDED'].includes(action.type) && collections.includes(action.meta.collection)) {
    const payload = action.payload
    const { extData, ...rootData } = payload.data
    payload.data = { ...rootData, ...extData }
  }
  return next(action);
}

export default unwraper
