import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';

import EnhancedTableHead from '../../components/EnhancedTableHead';
import SimpleTableToolbar from '../../components/SimpleTableToolbar';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { getComparator, stableSort } from '../../modules/sort';
import ExpandButton from '../../components/ExpandButton';
import SearchBox from '../../components/SearchBox';
import SalesReturnFormView from './SalesReturnFormView';
import { useFirestoreDataAndMapping, useSupplierDataAndMapping } from '../../modules/uitls';

function SalesReturnFormDetail({ userMapping, salesReturnForm, ...props }) {
  const r = { ...salesReturnForm }
  const keys = salesReturnForm.merchandises ? Object.keys(salesReturnForm.merchandises) : []
  r.merchandises = keys.map(k => {
    return {
      ...r.merchandises[k],
      id: k,
      price: r.merchandises[k].items.reduce((acc, cur) => acc + (cur.unitPrice * cur.amount), 0)
    }
  })

  r.createdBy = { id: r.createdBy, name: userMapping[r.createdBy]?.displayName }
  return (
    <div style={{ padding: 15 }}>
      <SalesReturnFormView userMapping={userMapping} salesReturnForm={r} {...props} />
    </div>
  )
}

SalesReturnFormDetail.propTypes = {
  salesReturnForm: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
};

function SalesReturnFormList({ salesReturnForms, title }) {
  const { formatMessage } = useIntl()
  const [userMapping] = useFirestoreDataAndMapping('users')
  const [customerMapping] = useFirestoreDataAndMapping('customers')
  const [supplierMapping] = useSupplierDataAndMapping()
  const [merchandiseMapping] = useFirestoreDataAndMapping('merchandises')

  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('sn')
  const [currentFilter, setCurrentFilter] = useState(null)
  const [expand, setExpand] = useState(true);

  const filteredSalesReturnForms = currentFilter && currentFilter.text ? filterByText() : salesReturnForms

  const headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'sourceName', sort: 'sourceName' },
    { text: 'supplierName', sort: 'supplierName' },
    // {text: 'createdBy', sort: 'createdBy'},
    { text: 'date', sort: 'date' },
  ].map(c => {c.text = formatMessage({ id: `salesReturnForm.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'sn' },
    { field: 'sourceName' },
    { field: 'supplierName' },
    // {field: 'createdBy'},
    { field: 'date' },
  ]

  function filterByText() {
    if (currentFilter.name === 'sourceName') {
      return salesReturnForms.filter(s => customerMapping[s.source]?.nickname.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'supplierName') {
      return salesReturnForms.filter(s => supplierMapping[s.supplier]?.displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'createdBy') {
      return salesReturnForms.filter(s => userMapping[s.createdBy]?.displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else {
      return salesReturnForms.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
  }

  const formatData = (salesReturnForm) => {
    const newData = { ...salesReturnForm }
    newData.sourceName = customerMapping[newData.source]?.nickname
    newData.supplierName = supplierMapping[newData.supplier]?.nickname
    newData.createdBy = userMapping[newData.createdBy]?.displayName
    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filterItems = [
    { name: 'sn' },
    { name: 'sourceName', type: 'customer' },
    { name: 'supplierName' },
    { name: 'createdBy' },
    { name: 'note' },
  ].map(i => {i.text = formatMessage({ id: `salesReturnForm.table.detail.${i.name}` });return i})

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  return (
    <div>
      <SimpleTableToolbar
        title={title}
        buttons={<ExpandButton open={expand} onExpandChange={setExpand} />}
        toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
      />
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: '650px' }}>
            <EnhancedTableHead
              headerCells={headerCells}
              order={order}
              orderBy={orderBy}
              expandable
              onRequestSort={handleRequestSort}
              rowCount={filteredSalesReturnForms.length}
            />
            <TableBody>
              {stableSort(filteredSalesReturnForms.map(r => formatData(r)), getComparator(order, orderBy)).map(salesReturnForm => (
                <EnhancedTableRow
                  key={salesReturnForm.id}
                  rowCells={rowCells}
                  cellData={salesReturnForm}
                  expandable
                  expandContent={<SalesReturnFormDetail userMapping={userMapping} merchandiseMapping={merchandiseMapping} salesReturnForm={salesReturnForm} />}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </div>
  );
}

SalesReturnFormList.propTypes = {
  salesReturnForms: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  title: PropTypes.string.isRequired,
};

export default SalesReturnFormList;
