import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import { reducer as firebaseStateReducer } from 'react-redux-firebase';
import { reduxFirestore, firestoreReducer } from 'redux-firestore';
import middleware from './middleware';
import firebase from 'firebase/app';
import 'firebase/auth'
// import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

if (process.env.NODE_ENV === 'production') {
  firebase.initializeApp({
    apiKey: 'AIzaSyAwCLRCRF9msS6TOA1lckyaHx4oAInWH5I',
    authDomain: 'warehouse-dc56d.firebaseapp.com',
    databaseURL: 'https://warehouse-dc56d.firebaseio.com',
    projectId: 'warehouse-dc56d',
    storageBucket: 'warehouse-dc56d.appspot.com',
    messagingSenderId: '58976674760',
    appId: '1:58976674760:web:4875ed48434dd7ee0416fd',
    measurementId: 'G-5CCB8RFG03'
  });
} else {
  firebase.initializeApp({
    apiKey: 'AIzaSyD_ya-IGxxAvsoTZGIDUXgxmlf6BRfC9EE',
    authDomain: 'warehousedev-4aadf.firebaseapp.com',
    databaseURL: 'https://warehousedev-4aadf.firebaseio.com',
    projectId: 'warehousedev-4aadf',
    storageBucket: 'warehousedev-4aadf.appspot.com',
    messagingSenderId: '564702340091',
    appId: '1:564702340091:web:04130ae8e6cd5654e3215e',
    measurementId: 'G-K87L68BEG6'
  });
}

// firebase.database()
firebase.firestore()
firebase.storage()
firebase.functions()

const reducer = combineReducers({
  firebase: firebaseStateReducer,
  firestore: firestoreReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const createStoreWithFirebase = compose(
  reduxFirestore(firebase, {}), // firebase instance as first argument, rfConfig as optional second
)(createStore);

const configStore = (initialState = {}) => {
  const store = createStoreWithFirebase(reducer, initialState, composeEnhancer(applyMiddleware(...middleware)));
  // const store = createStore(
  //   reducer,
  //   // reduxFirestore(firebase, {}),
  //   initialState,
  //   composeEnhancer(applyMiddleware(...middleware)),
  // );

  // sagaMiddleware.run(rootSaga);

  return {
    store,
  };
};

const { store } = configStore();

global.store = store;

export { store, firebase };
