import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import EnhancedTable from '../../components/EnhancedTable';
import ActionDialog from '../../components/ActionDialog';
import ProgressStep from '../../components/ProgressStep';
import ContextStore from '../../modules/context';

const _headerCells = [
  { field: 'code', sort: 'code' },
  { field: 'name' },
  { field: 'amount', align: 'right' },
  { field: 'sku', align: 'right' },
]

const _rowCells = [
  { field: 'code' },
  { field: 'nickname', tooltip: 'name' },
  { field: 'amount', align: 'right' },
  { field: 'sku', align: 'right' },
]

function MerchandiseDetail({ items }) {
  const { formatMessage } = useIntl()
  const headerCells = [
    { field: 'amount' },
    { field: 'expectedDate' },
    { field: 'lotNumber' },
  ].map(c => {c.text = formatMessage({ id: `deliveryOrder.merchandise.${c.field}` });return c})

  const rowCells = [
    { field: 'amount' },
    { field: 'expectedDate' },
    { field: 'lotNumber' },
  ]

  return (
    <EnhancedTable
      size="small"
      headerCells={headerCells}
      rowCells={rowCells}
      tableData={items}
    />
  )
}

MerchandiseDetail.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object.isRequired),
};

function StockRequisitionView({ stockRequisition, userMapping, merchandiseMapping }) {
  const { formatMessage } = useIntl()
  const { currentUser } = useContext(ContextStore)
  const [dialogData, setDialogData] = useState(null);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [loadingVoid, setLoadingVoid] = useState(false);

  const stockRequisitionHistory = (stockRequisition.history || []).map(h => ({ ...h }))
  const currentStep = stockRequisitionHistory.length > 0 ? stockRequisitionHistory[stockRequisitionHistory.length - 1].step : 0

  const headerCells = _headerCells.map(c => {c.text = formatMessage({ id: `stockRequisition.merchandise.${c.field}` });return c})
  const rowCells = _rowCells

  function formatData(merchandise) {
    const statusText = merchandise.purchaseOrder ?
      (formatMessage({ id: `stockRequisition.merchandise.status.${merchandise.purchaseOrder.status}` })) :
      '未處理';
    return {
      ...merchandise,
      code: merchandiseMapping[merchandise.id].code,
      name: merchandiseMapping[merchandise.id].name,
      nickname: merchandiseMapping[merchandise.id].nickname,
      sku: merchandiseMapping[merchandise.id].sku,
      statusText,
      purchaseOrderSn: `採購單號: ${merchandise.purchaseOrder?.sn || ''}`
    }
  }

  function abandon() {
    setDialogData({ action: 'abandon', title: '' })
  }

  function reject() {
    setDialogData({ action: 'reject', title: '' })
  }

  function approve() {
    setDialogData({ action: 'approve', title: '' })
  }

  function confirm() {
    setDialogData({ action: 'confirm', title: '' })
  }

  function handleClose() {
    setDialogData(null)
  }

  async function handleExecute(data) {
    const { action, text } = data
    setDialogData(null)
    if (['approve', 'reject', 'confirm', 'abandon'].includes(action)) {
      if (['approve', 'confirm'].includes(action)) {
        setLoadingApprove(true)
      } else if (action === 'reject') {
        setLoadingReject(true)
      } else { // void
        setLoadingVoid(true)
      }
      try {
        await (firebase.functions().httpsCallable('reviewStockRequisition'))({
          id: stockRequisition.id,
          action,
          note: text
        })
        if (action === 'reject') {
          setLoadingReject(false)
        }
      } catch (ex) {
        setLoadingApprove(false)
        setLoadingReject(false)
        console.log(ex)
      }
    }
  }

  function decodeModifyLog(log) {
    log = log.replace(/f{modify}/g, formatMessage({ id: 'step.action.modify' }))
    log = log.replace(/f{update}/g, formatMessage({ id: 'step.action.update' }))
    log = log.replace(/f{add}/g, formatMessage({ id: 'step.action.add' }))
    log = log.replace(/f{remove}/g, formatMessage({ id: 'step.action.remove' }))
    log = log.replace(/f{expectedDate}/g, formatMessage({ id: 'stockRequisition.table.detail.expectedDate' }))
    log = log.replace(/f{note}/g, formatMessage({ id: 'stockRequisition.table.detail.note' }))
    log = log.replace(/f{merchandise}/g, formatMessage({ id: 'stockRequisition.table.detail.merchandise' }))
    log = log.replace(/f{unitPrice}/g, formatMessage({ id: 'stockRequisition.merchandise.unitPrice' }))
    log = log.replace(/f{amount}/g, formatMessage({ id: 'stockRequisition.merchandise.amount' }))

    let matchs = [...new Set((log.match(/i{.+?}/g) || []).map(i => i.substring(2, i.length - 1)))]
    for (const m of matchs) {
      log = log.replace((new RegExp(`i{${m}}`, 'g')), merchandiseMapping[m].name)
    }
    return log
  }

  const ls = stockRequisition.status !== 'void'
    ? (stockRequisition.status === 'pending' && stockRequisition.void
      ? [stockRequisition.steps[0]].map(s => ({ ...s }))
      : stockRequisition.steps.slice(currentStep, stockRequisition.steps.length).map(s => ({ ...s })))
    : []
  if (stockRequisition.status !== 'void' && stockRequisition.lock && currentStep === 0) {
    ls[0].name = '確認通知'
  }

  const steps = [...stockRequisitionHistory].concat(ls)
  for (const step of steps) {
    if (step.action === 'modify') {
      step.detail = decodeModifyLog(step.note)
    }
    if (step.dateTime) {
      const s = step.dateTime.split(' ')
      step.text = step.name + `\n${userMapping[step.user]?.displayName}[${formatMessage({ id: 'step.action.' + step.action })}]`
      step.text += `\n日期: ${s[0]}`
      step.text += `\n時間: ${s[1]}`
      if (step.action === 'modify') {
        step.text += `${step.detail ? '\n' : ''}`
      } else {
        step.text += `${step.note ? '\n備註: ' + step.note : ''}`
      }
    } else {
      step.text = step.name
      if (step.users) {
        step.hint = step.users.map(u => userMapping[u]?.displayName || '').join(' / ')
      }
    }
    if (['reject', 'void', 'abandon'].includes(step.action)) {
      // step.stepProps = {completed: false}
      step.labelProps = { error: true }
    }
  }
  // const lastHistory = stockRequisition.history[stockRequisition.history.length - 1]

  return (
    <div style={{ flexGrow: 1, height: '100%' }}>
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` }) + (stockRequisition.void ? formatMessage({ id: 'button.void' }) : '') + formatMessage({ id: 'stockRequisition.name' })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'stockRequisition.table.detail.note' })}
        action={dialogData.action}
      />}

      <Box p={0}>
        <ProgressStep activeStep={stockRequisition.history.length} steps={steps} />
        <EnhancedTable
          size="small"
          defaultOrder="asc"
          defaultOrderField="code"
          headerCells={headerCells}
          rowCells={rowCells}
          getExpandContent={merchandise => <MerchandiseDetail items={merchandise.items} />}
          tableData={stockRequisition.merchandises.map(m => formatData(m))}
        />
        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label="備註"
              value={stockRequisition.note}
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
          <Grid item key="buttons" xs={12} sm={12} md={12}>
            <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
              {currentStep === 1 && stockRequisition.steps[0].users.includes(currentUser.key) &&
                <LoadingButton
                  color="error"
                  onClick={abandon}
                  disabled={loadingApprove || loadingReject || loadingVoid}
                  loading={loadingVoid}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.abandon" />
                </LoadingButton>
              }
              {currentStep > 0 && currentStep < stockRequisition.steps.length && stockRequisition.steps[currentStep].users.includes(currentUser.key) && stockRequisition.status !== 'void' && !stockRequisition.lock &&
                <LoadingButton
                  color="error"
                  onClick={reject}
                  disabled={loadingApprove || loadingReject || loadingVoid}
                  loading={loadingReject}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.reject" />
                </LoadingButton>
              }
              {currentStep > 0 && currentStep < stockRequisition.steps.length && stockRequisition.steps[currentStep].users.includes(currentUser.key) && stockRequisition.status !== 'void' && !stockRequisition.lock &&
                <LoadingButton
                  color="success"
                  onClick={approve}
                  disabled={loadingApprove || loadingReject || loadingVoid}
                  loading={loadingApprove}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.approve" />
                </LoadingButton>
              }
              {currentStep < stockRequisition.steps.length && stockRequisition.steps[currentStep].users.includes(currentUser.key) && stockRequisition.lock &&
                <LoadingButton
                  color="success"
                  onClick={confirm}
                  disabled={loadingApprove || loadingReject || loadingVoid}
                  loading={loadingApprove}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.confirm" />
                </LoadingButton>
              }
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

// StockRequisitionView.defaultProps = {

// }

StockRequisitionView.propTypes = {
  stockRequisition: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
};

export default StockRequisitionView;
