import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import { useFirestoreDataAndMapping } from '../modules/uitls';

const Root = styled('div')(() => ({
  padding: '2px 4px',
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  width: 250,
}));

function SearchBox({ filterItems, onFilterChanged, updateUrlParams, autoFocus = false }) {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(filterItems[filterItems.findIndex(i => i.default) === -1 ? 0 : filterItems.findIndex(i => i.default)]);
  const [filterText, setFilterText] = useState('');
  const [customerFilter, setCustomerFilter] = useState('');
  const [customerMapping, customers] = useFirestoreDataAndMapping('customers')
  const menuOpen = Boolean(anchorEl);

  useEffect(() => {
    if (updateUrlParams) {
      const sp = new URLSearchParams(location.search)
      const sb = sp.get('sb')
      const st = sp.get('st')
      if (sb && st) {
        for (const f of filterItems) {
          if (f.name === sb && st !==filterText) {
            setCurrentFilter(f)
            setFilterText(st)
            onFilterChanged(sb, st)
            break
          }
        }
      }
    }
  }, [location.search]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function updateURL(name, text) {
    const sp = new URLSearchParams(location.search)
    const str1 = sp.toString()
    if (text === '') {
      sp.delete('sb')
      sp.delete('st')
    } else {
      sp.set('sb', name)
      sp.set('st', text)
    }
    const str2 = sp.toString()
    if (str1 !== str2) {
      navigate({ pathname: location.pathname, search: `?${str2}` }, { replace: true });
    }
  }

  const onFilterChange = (name, text) => {
    onFilterChanged(name, text)
    if (updateUrlParams) updateURL(name, text)
  }

  const handleFilterTextChange = (text) => {
    setFilterText(text)
    onFilterChange(currentFilter.name, text)
    if (updateUrlParams) updateURL(currentFilter.name, text)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Root>
      <Tooltip title={formatMessage({ id: 'filter' })}>
        <IconButton
          aria-label="menu"
          sx={{ p: '10px' }}
          onClick={handleMenu}
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        {filterItems.map(f =>
          <MenuItem
            key={f.name}
            onClick={() => {
              setCurrentFilter(f);
              handleClose();
              onFilterChange(f.name, filterText);
              if (f.type === 'customer') {
                setFilterText('')
                setCustomerFilter('')
                handleFilterTextChange('')
              }
            }}
          >
            {f.text}
            {/* <FormattedMessage id={'searchbox.label'} values={{filter: f.text}} /> */}
          </MenuItem>
        )}
      </Menu>
      {currentFilter.type !== 'customer' && <TextField
        autoFocus={autoFocus}
        type="text"
        size="small"
        label={formatMessage({ id: 'searchbox.label' }, { filter: currentFilter.text })}
        variant="outlined"
        onChange={e => handleFilterTextChange(e.target.value)}
        value={filterText}
        fullWidth
      />}
      {currentFilter.type === 'customer' && <TextField
        select
        type="text"
        label={formatMessage({ id: 'searchbox.label' }, { filter: currentFilter.text })}
        variant="outlined"
        value={customerFilter}
        onChange={e => {setCustomerFilter(e.target.value);handleFilterTextChange(customerMapping[e.target.value]?.nickname || '')}}
        fullWidth
        size="small"
      >
        <MenuItem value="">全部</MenuItem>
        {customers.map(c => <MenuItem key={c.id} value={c.id}>
          {c.nickname}
        </MenuItem>)}
      </TextField>}
    </Root>
  );
}

SearchBox.propTypes = {
  filterItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  onFilterChanged: PropTypes.func.isRequired,
  updateUrlParams: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default SearchBox;
