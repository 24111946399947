const i18n = {
  'report': {
    'stock': {
      'title': '進銷存列表',
      'header': {
        'date': '列表日期',
        'createdDate': '建立日期',
        'sourceName': '診所',
      },
      'table': {
        'date': '列表日期',
        'createdDate': '建立日期',
      },
      'detail': {
        'name': '商品名稱',
        'orderUnit': '單位',
        'bi': {
          'root': '期初庫存',
          'amount': '數量',
          'unitPrice': '單價',
          'price': '金額',
        },
        'pim': {
          'root': '本月進貨',
          'amount': '數量',
          'unitPrice': '單價',
          'price': '金額',
        },
        'pimPM': {
          'root': '調整(+)(-)',
          'amount': '數量',
          'unitPrice': '單價',
          'price': '金額',
        },
        'dim': {
          'root': '本月銷貨',
          'amount': '數量',
          'unitPrice': '單價',
          'price': '金額',
        },
        'dimPM': {
          'root': '調整(+)(-)',
          'amount': '數量',
          'unitPrice': '單價',
          'price': '金額',
        },
        'cos': {
          'root': '銷貨成本',
          'amount': '數量',
          'unitPrice': '單價',
          'price': '金額',
        },
        'ei': {
          'root': '期末庫存',
          'amount': '數量',
          'unitPrice': '單價',
          'price': '金額',
        },
        'total': {
          'root': '合計'
        }
      }
    }
  }
}
export default i18n