const i18n = {
  'borrowingOrder': {
    // 'tab': {
    //   'pending': '等候中',
    //   'done': '已完成',
    //   'void': '已作廢',
    // },
    'name': '借貨單',
    'table': {
      // 'pending': {
      //   'title': '請購單列表 - 待簽核',
      // },
      // 'done': {
      //   'title': '請購單列表 - 待採購',
      // },
      'title': '借貨單列表',
      'selected': '已選擇 {count} 筆借貨單',
      'header': {
        'sn': '借貨單號',
        'source': '借貨單位',
        'sourceName': '借貨單位',
        'supplier': '供應商',
        'supplierName': '供應商',
        'createdBy': '借貨人',
        'date': '借貨日期',
        'expectedDate': '需求日期',
      },
      'detail': {
        'sn': '借貨單號',
        'source': '借貨單位',
        'sourceName': '借貨單位',
        'supplier': '供應商',
        'supplierName': '供應商',
        'createdBy': '借貨人',
        'date': '借貨日期',
        'expectedDate': '需求日期',
        'note': '備註',
        'merchandise': '商品',
      },
    },
    'dialog': {
      'title': {
        'add': '新增借貨單',
        'edit': '編輯借貨單',
      }
    },
    'merchandise': {
      'code': '品號',
      'name': '品名',
      'unitPrice': '單價',
      'amount': '數量',
      'sku': '庫存單位',
      'orderUnit': '單位',
      'price': '金額',
      'note': '備註',
    }
  }
}
export default i18n
