import React, { useState, useEffect }  from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

import { useFirestoreDataAndMapping, sortByField } from '../../modules/uitls';

export default function DeliveryDetail(props) {
  const { info, onClose, refId } = props;
  const { refId: sourceOrderId, merchandiseId, customerId, defaultMessage } = info
  const [text, setText] = useState(defaultMessage);
  const [merchandiseMapping] = useFirestoreDataAndMapping('merchandises')

  useEffect(() => {
    const unsubscribe = customerId ? firebase.firestore().collection('wh').doc(customerId)
      .collection('inventories').where(refId, '==', sourceOrderId).where('id', '==', merchandiseId)
      .onSnapshot( snapshot => {
        let newText = ''
        let allData = []
        snapshot.forEach(doc => {
          allData.push(doc.data())
        });
        allData = allData.sort(sortByField('date'))
        for (const m of allData) {
          if (newText !== '') newText += '\n'
          newText += `${m.date} 進貨 [${merchandiseMapping[m.id].name}] ${m.amount} ${merchandiseMapping[m.id].sku}${m.free ? '(贈品)' : ''}`
        }
        setText(newText || '無進貨紀錄')
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, []);

  return (
    <Dialog onClose={() => onClose()} open={true}>
      <Box p={3}>
        <div style={{ whiteSpace: 'pre-line' }}>
          {text}
        </div>
      </Box>
    </Dialog>
  );
}

DeliveryDetail.propTypes = {
  info: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  refId: PropTypes.string.isRequired,
};
