const i18n = {
  'supplier': {
    'table': {
      'title': '廠商列表',
      'selected': '已選擇 {count} 筆廠商資料',
      'header': {
        'code': '編號',
        'nickname': '簡稱',
        'contact.name': '聯絡人',
        'contact.phone': '電話',
        'contact.mobile': '行動電話',
      },
      'detail': {
        'code': '編號',
        'nickname': '簡稱',
        'businessNumber': '統編',
        'name': '全名',
        'phone': '電話',
        'fax': '傳真',
        'address': '地址',
        'email': '電子郵件地址',
        'contactName': '聯絡人-姓名',
        'contactPhone': '聯絡人-專線#分機',
        'contactMobile': '聯絡人-行動電話',
        'contactNote': '聯絡人-備註',
        'ownerName': '負責人-姓名',
        'ownerPhone': '負責人-專線#分機',
        'ownerMobile': '負責人-行動電話',
        'billRule': '付款結帳規則',
        'basicPurchaseAmount': '最低採購金額',
        'freeShippingAmount': '免運金額',
        'shippingOut': '出貨廠商',
        'note': '備註',
        'supplierType': '供應商',
        'internalType': '內部廠商',
        'activeType': '啟用',
        'supplier': '類型',
        'internal': '內/外部廠商',
        'active': '狀態',
      }
    },
    'contact': {
      'contactName': '聯絡人-姓名',
      'contactPhone': '專線#分機',
      'contactMobile': '行動電話',
      'contactNote': '備註',
    },
    'dialog': {
      'title': {
        'add': '新增廠商',
        'edit': '編輯廠商資料',
      }
    },
    'billRule': {
      'rule1': '月結30天',
      'rule2': '月結60天',
      'rule3': '月結90天',
      'rule4': '貨到付款',
      'rule5': '付款後出貨',
    },
    'supplier': {
      'type1': '供應商',
      'type2': '非供應商',
    },
    'internal': {
      'type1': '內部廠商',
      'type2': '外部廠商',
    },
    'active': {
      'type1': '啟用',
      'type2': '停用',
    },
    'flag': {
      'all': '全部'
    },
    'showWorkflow': '顯示流程權限',
    'requisitionSteps': {
      'title': '請購流程',
      'edit': '編輯請購流程',
    },
    'purchaseOrderSteps': {
      'title': '採購流程',
      'edit': '編輯採購流程',
    },
    'receiptSteps': {
      'title': '進貨流程',
      'edit': '編輯進貨流程',
    },
    'inventoryCheckSteps': {
      'title': '盤點流程',
      'edit': '編輯盤點流程',
    },
    'stockRequisitionSteps': {
      'title': '沙貨領用流程',
      'edit': '編輯沙貨領用流程',
    },
    'scrapFormSteps': {
      'title': '報廢出庫流程',
      'edit': '編輯報廢出庫流程',
    },
    'newSupplierSteps': {
      'title': '新供應商申請流程',
      'edit': '編輯新供應商申請流程',
    },
    'newMerchandiseSteps': {
      'title': '新商品申請流程',
      'edit': '編輯新商品申請流程',
    },
    'newProductSteps': {
      'title': '新療程申請流程',
      'edit': '編輯新療程申請流程',
    },
    'editBorrowingStaff': {
      'title': '借貨流程',
      'edit': '編輯借貨流程',
    },
    'editWarehouseStaff': {
      'title': '倉管人員',
      'edit': '編輯倉管人員',
    },
  }
}
export default i18n
