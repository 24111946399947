import React, { useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';

import TabContainer from '../../containers/TabContainer/TabContainer';
import ContextStore from '../../modules/context';
import { useFirestoreData, sortByField } from '../../modules/uitls';
import Stock from './Stock';

const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
}));

function StockList() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const { customerId } = useParams()
  const customers = useFirestoreData('customers').slice().sort(sortByField('code'))
  const filteredCustomers = customers.filter(c => currentUser.userRights.debugging || currentUser.whQuerySource.includes(c.id))

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.stock.query' })
    }])
    return () => {
    };
  }, [location.pathname]);

  const onTabSelected = (tabIndex) => {
    if (filteredCustomers.length > tabIndex) {
      navigate(`/stock/query/${filteredCustomers[tabIndex].id}`);
    }
  };

  const tabs = filteredCustomers.map(c => ({ label: c.nickname, component: <Stock customerId={c.id} /> }))
  const ti = filteredCustomers.findIndex(c => c.id === customerId)
  const defaultSelect = ti === -1 ? 0 : ti

  return (
    <Root>
      {filteredCustomers.length ?
        <TabContainer tabs={tabs} defaultSelect={defaultSelect} onTabSelected={onTabSelected} /> : null
      }
    </Root>
  );
}

export default StockList;
