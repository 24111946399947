import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import EnhancedTable from './EnhancedTable';

function SelectCommonPriceDialog({ defaultSelectedItems, handleClose, handleSave, hideFields = [], ignoreIds, allowIds, size = 'xl', commonPriceData }) {
  const { formatMessage } = useIntl()
  const prices = commonPriceData
    .filter(m => !allowIds || allowIds.includes(m.id))
    .filter(m => !ignoreIds.includes(m.id)).map(m => {
      return {
        ...m,
      }
    })

  const [selectedItems, setSelectedItems] = useState(defaultSelectedItems.reduce((acc, cur) => { acc[cur.id] = true; return acc; }, {}));
  const filteredselection = prices.filter(s => selectedItems[s.id]).reduce((acc, cur) => { acc[cur.id] = true; return acc; }, {})

  const _headerCells = [
    { text: 'name', sort: 'name' },
  ]

  const _rowCells = [
    { field: 'name', tooltip: 'name' },
  ]

  const headerCells = _headerCells.filter(i => !hideFields.includes(i.text))
    .map(c => { c.text = formatMessage({ id: `selectCommonPriceDialog.table.detail.${c.text}` }); return c })

  const rowCells = _rowCells.filter(i => !hideFields.includes(i.field))

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = prices.reduce((acc, cur) => { acc[cur.id] = true; return acc }, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  };

  const handleCheckboxClick = ({ id }) => {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  const onApply = () => {
    const selectedPrices = prices.filter(s => selectedItems[s.id]).map(s => ({
      name: s.name,
      id: s.id,
      fixedAmount: '',
      useAmount: 0,
      price: 0,
      onShelfDate: null,
      offShelfDate: null
    }))
    handleSave(selectedPrices)
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={size}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title"><FormattedMessage id="selectCommonPriceDialog.title" /></DialogTitle>
      <DialogContent dividers={true}>
        <div style={{ flexGrow: 1 }}>
          <EnhancedTable
            defaultOrder="asc"
            defaultOrderField="code"
            headerCells={headerCells}
            rowCells={rowCells}
            tableData={prices}
            onHeaderCheckboxClick={handleSelectAllClick}
            onRowCheckboxClick={handleCheckboxClick}
            getSelectionCount={() => Object.keys(filteredselection).length}
            getRowCheckBoxStatus={p => selectedItems[p.id] || false}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button variant="contained" onClick={onApply} color="primary">
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SelectCommonPriceDialog.propTypes = {
  ignoreIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  allowIds: PropTypes.arrayOf(PropTypes.string),
  // ignoreSuppliers: PropTypes.arrayOf(PropTypes.string),
  // highPrioritySuppliers: PropTypes.arrayOf(PropTypes.string),
  defaultSelectedItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    nickname: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })),
  hideFields: PropTypes.arrayOf(PropTypes.string),
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  size: PropTypes.string,
  commonPriceData: PropTypes.arrayOf(PropTypes.object)
};

export default SelectCommonPriceDialog;
