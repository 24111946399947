const i18n = {
  'deliveryAmountDialog': {
    'title': '設定出貨量',
    'amount': '出貨量',
  },
  'selectStockDialog': {
    'title': '選擇庫存',
    'table': {
      'title': '庫存列表',
      'header': {
        'date': '入庫日期',
        'expectedDate': '效期',
        'lotNumber': '批號',
        'wareroomName': '倉庫',
        'amountInStocks': '庫存數量',
        'stocksWithUnit': '庫存數量',
        'amount': '數量',
        'arrangementAmount': '調撥數量',
        'targetWareroom': '目標倉庫',
        'targetWareroomName': '目標倉庫',
      }
    }
  },
  'selectPurchaseOrderDialog': {
    'title': '待核對的採購單',
    'merchandise': {
      'code': '品號',
      'nickname': '簡稱',
      'name': '品名',
      'require': '需求量',
      'unitPrice': '單價',
      'orderUnit': '單位',
      'amount': '採購數量',
      'delivery': '已到貨數量',
      'price': '金額',
    }
  },
  'SelectReceiptDialog': {
    'title': '選擇要退貨的進貨單',
  },
  'selectBorrowingOrderDialog': {
    'title': '待核對的借貨單',
    'merchandise': {
      'code': '品號',
      'nickname': '簡稱',
      'name': '品名',
      'require': '需求量',
      'unitPrice': '單價',
      'orderUnit': '單位',
      'amount': '借貨數量',
      'delivery': '已到貨數量',
      'price': '金額',
    }
  },
  'selectMerchandiseDialog': {
    'title': '新增商品',
    'table': {
      'detail': {
        'code': '品號',
        'nickname': '簡稱',
        'price': '售價',
        'note': '備註',
        'orderUnit': '訂貨單位',
        'mainWareroomStock': '主倉庫庫存',
        'stock': '庫存',
      },
    },
  },
  'selectProductDialog': {
    'title': '新增療程',
    'table': {
      'detail': {
        'code': '品號',
        'name': '名稱',
        'cost': '成本',
        'note': '備註',
        'customers': '客戶'
      },
    },
  },
  'selectCommonPriceDialog': {
    'title': '新增常用價格',
    'table': {
      'detail': {
        'name': '名稱',
      },
    },
  },
  'selectSupplierDialog': {
    'title': '選擇廠商',
  },
  'selectCustomerDialog': {
    'title': '選擇客戶',
  },
  'selectTagDialog': {
    'title': '選擇標籤',
    'selectedTags': '已選擇的標籤: ',
    'search': '搜尋標籤',
    'addTag': '新增標籤',
    'noMatchedTag': '沒有符合的標籤',
  },
  'selectUserDialog': {
    'title': '選擇使用者',
  },
  'selectCategoryDialog': {
    'title': '選擇商品類別',
  },
  'newBonusTableDialog': {
    'title': '新增獎金表單',
  },
  'newStockReportDialog': {
    'title': '新增進銷存表單',
  },
  'recordDialog': {
    'title': {
      'merchandise': '商品修改紀錄',
      'product': '療程修改紀錄'
    },
    'table': {
      'create': '{name} 於 {time} 建立了 {item} ',
      'edit': '{name} 於 {time} 將 {item} 的{type}從 [{old}] 修改為 [{new}]',
      'type': {
        'merchandise': {
          'name': '名稱',
          'nickname': '簡稱',
          'note': '備註',
          'type': '分類1'
        },
        'product': {
          'cost': '成本',
          'name': '名稱',
          'note': '備註',
          'staffRelativesPrice': '員工眷屬價',
          'staffprice': '員工價',
          'vipprice': 'VIP價'
        }
      }

    }
  }
}
export default i18n
