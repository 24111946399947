import React, { useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';

import TabContainer from '../../containers/TabContainer/TabContainer';
import ContextStore from '../../modules/context';
import FabAdd from '../../components/FabAdd';
import PurchaseOrderTab from './PurchaseOrderTab';

const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
}));

function PurchaseOrder() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const { tabName } = useParams()

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.purchase.purchaseOrder' })
    }])
    return () => {
    };
  }, [location.pathname]);

  const onTabSelected = (tabIndex) => {
    if (tabIndex === 0) {
      navigate('/purchase/purchaseOrder/pending');
    } else if (tabIndex === 1) {
      navigate('/purchase/purchaseOrder/done');
    } else {
      navigate('/purchase/purchaseOrder/void');
    }
  };

  const tabs = [
    { label: formatMessage({ id: 'purchaseOrder.tab.pending' }), component: <PurchaseOrderTab /> },
    { label: formatMessage({ id: 'purchaseOrder.tab.done' }), component: <PurchaseOrderTab /> },
    { label: formatMessage({ id: 'purchaseOrder.tab.void' }), component: <PurchaseOrderTab /> },
  ]
  const defaultSelect = tabName === 'void' ? 2 : (tabName === 'done' ? 1 : 0)

  return (
    <Root>
      {currentUser.userRights['purchaseOrder-create'] === true && <FabAdd to="/purchase/purchaseOrder/edit/new" />}
      <TabContainer defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} />
    </Root>
  );
}

export default PurchaseOrder;
