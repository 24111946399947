import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import ContextStore from '../../modules/context';
import DatePickerField from '../../components/DatePickerField';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableToolbar from '../../components/EnhancedTableToolbar';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { useFirestoreDataAndMapping, useSupplierDataAndMapping, useFirestoreData, lockSubmitButton } from '../../modules/uitls';
import { getComparator, stableSort } from '../../modules/sort';
import SelectCartItemDialog from './SelectCartItemDialog';
import SelectSupplierDialog from '../../components/SelectSupplierDialog';
import SelectMerchandiseDialog from '../../components/SelectMerchandiseDialog';
import { TAX_RATE } from '../../constants/index';

function EditPurchaseOrderPage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser, purchaseOrderCache: cache } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const { purchaseOrderId } = useParams()

  const _shoppingCart = useSelector(state => state.firestore.data.shoppingCart) || {}

  const [customerMapping, customers] = useFirestoreDataAndMapping('customers')
  const filteredCustomers = customers.filter(c => currentUser.deliveryOrderSource.includes(c.id))

  const [merchandiseMapping, merchandises] = useFirestoreDataAndMapping('merchandises')
  const [userMapping] = useFirestoreDataAndMapping('users')
  const [supplierMapping, suppliers] = useSupplierDataAndMapping()
  const externalSupplierMapping = useFirestoreData('suppliers').filter(s => !s.internal).reduce((acc, cur) => {acc[cur.id] = cur; return acc;}, {})

  const [selectedItems, setSelectedItems] = useState({});
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cartItems, setCartItems] = useState({});
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const [dateError, setDateError] = useState({})
  const [openSupplierDialog, setOpenSupplierDialog] = useState(false);
  const [lockSupplier, setLockSupplier] = useState(false);

  const handleDateChange = (date) => {
    updatePurchaseOrderData({ name: 'expectedDate' }, date);
  };

  const [rawPurchaseOrder, setRawPurchaseOrder] = useState({
    id: purchaseOrderId,
    source: '',
    createdBy: currentUser.key,
    date: dayjs().format('YYYY-MM-DD'),
    expectedDate: new Date(),
    supplier: '',
    basicPurchaseAmount: '',
    freeShippingAmount: '',
    note: '',
    shipping: '',
    // merchandises: [],
  });

  const [owPurchaseOrder, setOwPurchaseOrder] = useState({
  });
  let purchaseOrder = {}

  const disableSubmitButton = purchaseOrderId.startsWith('cache-') ?
    (currentUser.userRights['purchaseOrder-create'] !== true) :
    (currentUser.userRights['purchaseOrder-edit'] !== true);

  useEffect(() => {
    if (filteredCustomers.length === 1 && purchaseOrderId === 'new') {
      updatePurchaseOrderData({ name: 'supplier' }, filteredCustomers[0].id)
    }
  }, [filteredCustomers.length]);

  useEffect(() => {
    const breadcrumbs = [{
      link: '/purchase/purchaseOrder/pending',
      text: formatMessage({ id: 'sideMenu.purchase.purchaseOrder' })
    }]
    if (purchaseOrderId.startsWith('cache-') || purchaseOrderId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: 'purchaseOrder.dialog.title.add' }) })
    } else {
      breadcrumbs.push({ text: formatMessage({ id: 'purchaseOrder.dialog.title.edit' }) })
    }
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    if (purchaseOrderId.startsWith('cache-') && merchandises.length && suppliers.length) { // from shopping cart
      // load from cache
      const hash = purchaseOrderId.substr(6)
      if (!cache[hash]) {
        navigate('/purchase/purchaseOrder/pending');
        return
      }

      const merchandises = Object.keys(cache[hash].merchandises).filter(m => merchandiseMapping[m])
      const supplier = cache[hash].supplier
      const source = cache[hash].source
      setRawPurchaseOrder({
        id: 'new',
        source,
        shipping: source,
        supplier,
        createdBy: currentUser.key,
        date: dayjs().format('YYYY-MM-DD'),
        expectedDate: cache[hash].expectedDate,
        note: '',
        basicPurchaseAmount: supplierMapping[supplier] ? (supplierMapping[supplier].basicPurchaseAmount || 0) : 0,
        freeShippingAmount: supplierMapping[supplier] ? (supplierMapping[supplier].freeShippingAmount || 0) : 0,
        merchandises: merchandises.map(m => merchandiseMapping[m]).map(m => {
          const currentSupplier = m.suppliers[supplier]
          const orderBySku = cache[hash].merchandises[m.id].orderBySku || false
          const unitPrice = orderBySku ? currentSupplier.price / m.ou2sku : currentSupplier.price
          const moq = orderBySku ? 0 : currentSupplier.moq
          return {
            id: m.id,
            keys: cache[hash].merchandises[m.id].keys,
            requisitions: cache[hash].merchandises[m.id].requisitions,
            orderUnit: orderBySku ? m.sku : m.orderUnit,
            unitPrice,
            orderBySku,
            note: cache[hash].merchandises[m.id].note || '',
            amount: Math.max(cache[hash].merchandises[m.id].amount, moq),
          }
        }).reduce((acc, cur) => {acc[cur.id] = cur; return acc;}, {})
      })
      setCartItems(cache[hash].itemKeys)
    }
  }, [merchandises.length, suppliers.length]);

  useEffect(() => {
    if (purchaseOrderId !== 'new' && !purchaseOrderId.startsWith('cache-')) { // edit
      const unsubscribe = firebase.firestore().collection('purchaseOrders').doc(purchaseOrderId)
        .onSnapshot(snapshot => {
          const data = snapshot.data()
          const shoppingCartMerchandises = Object.keys(_shoppingCart)
            .map(i => ({ ...(_shoppingCart[i]), key: i }))
            .filter(i => i.source === data.source)
          setRawPurchaseOrder({
            id: snapshot.id,
            basicPurchaseAmount: supplierMapping[data.supplier].basicPurchaseAmount,
            freeShippingAmount: supplierMapping[data.supplier].freeShippingAmount,
            ...data
          })
          const cartItems = {}
          for (const ci of shoppingCartMerchandises) {
            if (ci.purchaseOrders && ci.purchaseOrders[purchaseOrderId]) {
              cartItems[ci.key] = ci.purchaseOrders[purchaseOrderId]
            }
          }
          setCartItems(cartItems)
        }, err => {})
      return () => unsubscribe()
    } else {
      return () => {};
    }
  }, []);

  if (purchaseOrderId !== 'new' && !purchaseOrderId.startsWith('cache-') && !rawPurchaseOrder.merchandises) {
    return ('Loading...')
  }

  const merchandiseKeys = Object.keys(rawPurchaseOrder.merchandises || {}).filter(m => merchandiseMapping[m])
  purchaseOrder = { ...{
    id: rawPurchaseOrder.id,
    source: rawPurchaseOrder.source,
    shipping: rawPurchaseOrder.shipping,
    createdBy: rawPurchaseOrder.createdBy,
    date: rawPurchaseOrder.date,
    expectedDate: dayjs(rawPurchaseOrder.expectedDate).toDate(),
    supplier: rawPurchaseOrder.supplier,
    basicPurchaseAmount: rawPurchaseOrder.basicPurchaseAmount,
    freeShippingAmount: rawPurchaseOrder.freeShippingAmount,
    note: rawPurchaseOrder.note,
    merchandises: merchandiseKeys.map(m => merchandiseMapping[m]).map(m => ({
      code: m.code,
      name: m.name,
      nickname: m.nickname,
      id: m.id,
      orderBySku: rawPurchaseOrder.merchandises[m.id].orderBySku,
      keys: rawPurchaseOrder.merchandises[m.id].keys || [],
      requisitions: rawPurchaseOrder.merchandises[m.id].requisitions,
      unitPrice: rawPurchaseOrder.merchandises[m.id].unitPrice,
      note: rawPurchaseOrder.merchandises[m.id].note,
      orderUnit: rawPurchaseOrder.merchandises[m.id].orderUnit,
      amount: rawPurchaseOrder.merchandises[m.id].amount,
    }))
  }, ...owPurchaseOrder }

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'name' },
    { text: 'amount', align: 'right' },
    { text: 'orderUnit', align: 'right' },
    { text: 'unitPrice', align: 'right' },
    { text: 'price', align: 'right' },
    { text: 'note', align: 'right' },
  ].map(c => {c.text = formatMessage({ id: `purchaseOrder.merchandise.${c.text}` });return c})

  const rowCells = [
    { field: 'code' },
    { field: 'nickname', tooltip: 'name' },
    { field: 'amount', align: 'right' },
    { field: 'orderUnit', align: 'right' },
    { field: 'unitPrice', align: 'right', type: 'input-floating-point-number', required: true, label: '單價', onValueChanged: onCellValueChanged },
    { field: 'price', align: 'right', type: 'calculate', calculate: 'unitPrice*amount' },
    { field: 'note', align: 'right', type: 'input', required: false, label: '備註', onValueChanged: onCellValueChanged },
  ]

  const rowCellsEx = [
    { field: 'code' },
    { field: 'nickname', tooltip: 'name' },
    // {field: 'amount', align: 'right'},
    { field: 'amount', align: 'right', type: 'input-number', required: true, label: '數量', onValueChanged: onCellValueChanged },
    { field: 'orderUnit', align: 'right' },
    { field: 'unitPrice', align: 'right', type: 'input-floating-point-number', required: true, label: '單價', onValueChanged: onCellValueChanged },
    { field: 'price', align: 'right', type: 'calculate', calculate: 'unitPrice*amount' },
    { field: 'note', align: 'right', type: 'input', required: false, label: '備註', onValueChanged: onCellValueChanged },
  ]

  const formatData = (merchandise) => {
    const newData = { ...merchandise }
    newData.require = newData.amount + newData.orderUnit
    if (newData.moq && newData.amount < newData.moq) newData.amount = newData.moq
    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function onCellValueChanged(id, field, value) {
    for (let m of purchaseOrder.merchandises) {
      if (m.id === id) {
        if (field === 'unitPrice') {
          m.unitPrice = value
          if (isNaN(value) || value === '') {
            if (!m.errors) {
              m.errors = {}
            }
            m.errors.unitPrice = '價格錯誤'
          } else {
            if (m.errors && m.errors.unitPrice) {
              delete m.errors.unitPrice
            }
          }
        } else if (field === 'amount') {
          m.amount = value
          if (isNaN(value) || value === '' || parseInt(value) === 0) {
            if (!m.errors) {
              m.errors = {}
            }
            m.errors.amount = '數量錯誤'
          } else {
            if (m.errors && m.errors.amount) {
              delete m.errors.amount
            }
          }
        } else {
          m[field] = value
        }
        break
      }
    }
    updatePurchaseOrderData({ name: 'merchandises' }, purchaseOrder.merchandises);
  }

  function onMerchandiseAdded(merchandises) {
    if (merchandises.length) {
      for (let m of merchandises) {
        const merchandise = merchandiseMapping[m.id]
        const currentSupplier = merchandise.suppliers[purchaseOrder.supplier]
        const orderBySku = m.orderBySku || false
        const unitPrice = orderBySku ? currentSupplier.price / m.ou2sku : currentSupplier.price
        m.unitPrice = unitPrice
        m.amount = '1'
        m.note = ''
        m.requisitions = []
      }
      updatePurchaseOrderData({ name: 'merchandises' }, purchaseOrder.merchandises.concat(merchandises));
    }
  }

  function onMerchandiseChanged({ items, itemKeys }) {
    const mapping = purchaseOrder.merchandises.reduce((acc, cur) => {acc[cur.id] = cur; return acc}, {})
    for (const item of items) {
      if (mapping[item.id]) {
        const merchandise = mapping[item.id]
        merchandise.amount += item.amount
        for (const key of item.keys) {
          if (!merchandise.keys.includes(key)) {
            merchandise.keys.push(key)
          }
        }
        for (const requisition of item.requisitions) {
          if (!merchandise.requisitions.includes(requisition)) {
            merchandise.requisitions.push(requisition)
          }
        }
      } else {
        const merchandise = merchandiseMapping[item.id]
        const currentSupplier = merchandise.suppliers[purchaseOrder.supplier]

        const orderBySku = item.orderBySku || false
        const unitPrice = orderBySku ? currentSupplier.price / merchandise.ou2sku : currentSupplier.price
        const moq = orderBySku ? 0 : currentSupplier.moq

        const data = {
          amount: Math.max(item.amount, moq),
          code: merchandise.code,
          id: item.id,
          keys: item.keys,
          requisitions: item.requisitions,
          name: merchandise.name,
          orderUnit: item.orderBySku ? merchandise.sku : merchandise.orderUnit,
          note: item.note,
          nickname: merchandise.nickname,
          unitPrice,
          orderBySku,
        }
        mapping[item.id] = data
        purchaseOrder.merchandises.push(data)
      }
    }
    const keys = Object.keys(itemKeys)
    for (const key of keys) {
      if (cartItems[key]) {
        cartItems[key] += itemKeys[key]
      } else {
        cartItems[key] = itemKeys[key]
      }
    }
    setCartItems({ ...cartItems })
    updatePurchaseOrderData({ name: 'merchandises' }, purchaseOrder.merchandises);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = purchaseOrder.merchandises.reduce((acc, cur) => {acc[cur.id] = true;return acc}, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  }

  function handleCheckboxClick(id) {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  function onDeleteItems() {
    const deletedItems = purchaseOrder.merchandises.filter(m => selectedItems[m.id])
    const merchandises = purchaseOrder.merchandises.filter(m => !selectedItems[m.id])
    if (merchandises.length !== purchaseOrder.merchandises.length) {
      updatePurchaseOrderData({ name: 'merchandises' }, merchandises);
      setSelectedItems({});
    }
    const deleteKeys = deletedItems.reduce((acc, cur) => acc.concat(cur.keys), [])
    const newKeys = { ...cartItems }
    for (const i of deleteKeys) {
      delete newKeys[i]
    }
    setCartItems(newKeys)
  }

  function validateField(field, value) {
    // if (field.required && value === '') {
    //   return formatMessage({id: 'form.field.isRequired'})
    // }
    if (field.name === 'expectedDate') {
      if (value === null) {
        return formatMessage({ id: 'form.field.isRequired' })
      } else if (value.toString() === 'Invalid Date') {
        return formatMessage({ id: 'form.date.formatError' })
      } else {
        if (dayjs(value).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD')) {
          return formatMessage({ id: 'form.date.beforeToday' })
        }
      }
    }
    return ''
  }

  function updatePurchaseOrderData(field, value) {
    let newValue = value
    // if (field.allowCharacter) {
    //   newValue = newValue.replace(field.allowCharacter, '')
    // }
    // if (field.maxLength) {
    //   newValue = newValue.substring(0, field.maxLength)
    // }

    // if (supplierData[field.name] === newValue) return;

    let err = validateField(field, value)

    if (field.name === 'expectedDate') {
      if (err) {
        setDateError({ error: true, helperText: err })
      } else {
        setDateError({})
      }
    }

    let newData = { ...purchaseOrder, [field.name]: newValue, [`${field.name}_err`]: err }
    setOwPurchaseOrder(newData)
  }

  async function handleSave() {
    lockSubmitButton(true)
    setLoading(true);

    const fields = [
      { name: 'source' },
      { name: 'date' },
      { name: 'expectedDate' },
      { name: 'supplier' },
      { name: 'merchandises' },
      { name: 'note' },
      { name: 'shipping' },
    ]

    // let err = {}
    let newData = purchaseOrder
    // if (Object.keys(err).length > 0) {
    //   newData = {...purchaseOrder, ...err}
    //   setOwPurchaseOrder(newData)
    // }
    for (let field of fields) {
      if (newData[`${field.name}_err`] !== undefined && newData[`${field.name}_err`] !== '') {
        lockSubmitButton(false)
        setLoading(false);
        return
      }
    }

    let data = {}
    for (let field of fields) {
      if (field.type === '-') continue
      data[field.name] = newData[field.name]
    }

    data.merchandises = data.merchandises.reduce((acc, cur) => {
      acc[cur.id] = {
        amount: parseInt(cur.amount),
        unitPrice: parseFloat(cur.unitPrice),
        orderUnit: cur.orderUnit,
        note: cur.note,
        requisitions: cur.requisitions
      };
      if (cur.orderBySku) {
        acc[cur.id].orderBySku = true
      }
      return acc;
    }, {})
    data.expectedDate = dayjs(newData.expectedDate).format('YYYY-MM-DD')

    try {
      await (firebase.functions().httpsCallable('savePurchaseOrder'))({
        id: purchaseOrder.id,
        deliveryOrder: supplierMapping[purchaseOrder.supplier].internal || false,
        cartItems,
        ...data
      })
    } catch (ex) {
      console.log(ex)
    }
    lockSubmitButton(false)
    handleClose()
  }

  function handleClose() {
    navigate('/purchase/purchaseOrder/pending');
  }

  function getToolbox() {
    // if (lockSupplier) {
    return <Button
      disabled={purchaseOrder.source === '' || purchaseOrder.supplier === ''}
      sx={{ m: 1, whiteSpace: 'nowrap' }}
      variant="contained"
      color="primary"
      startIcon={<AddShoppingCartIcon />}
      onClick={() => {
        if (purchaseOrderId === 'new') {
          setLockSupplier(true)
        }
        setOpenDialog(true)
      }}
    >
      <FormattedMessage id="editPurchaseOrder.addMerchandise" />
    </Button>
  }

  function getToolboxForSelection() {
    return <Button
      variant="contained"
      color="primary"
      startIcon={<DeleteIcon />}
      style={{ whiteSpace: 'nowrap', marginRight: '8px' }}
      onClick={onDeleteItems}
    >
      <FormattedMessage id="editPurchaseOrder.removeMerchandise" />
    </Button>
  }

  function getSourceSelector() {
    // 如果是 purchaseOrderId === 'new', 就是要新建 '外部廠商' 的採購單,
    // source 就要列出外部廠商讓使用者選
    if (purchaseOrderId === 'new') {
      return <TextField
        required
        // disabled={lockSource}
        type="text"
        size="small"
        label={formatMessage({ id: 'purchaseOrder.table.detail.source' })}
        variant="outlined"
        onClick={() => setOpenSupplierDialog(true)} //open popup
        value={purchaseOrder.source ? externalSupplierMapping[purchaseOrder.source].nickname : ''}
        error={purchaseOrder.source_err ? true : false}
        helperText={purchaseOrder.source_err}
        fullWidth
      />
    } else {
      return <TextField
        disabled
        type="text"
        label={formatMessage({ id: 'purchaseOrder.table.detail.source' })}
        variant="outlined"
        value={customerMapping[purchaseOrder.source] ? customerMapping[purchaseOrder.source].nickname : ''}
        fullWidth
        size="small"
      />
    }
  }

  function getSupplierSelector() {
    // 如果是 purchaseOrderId === 'new', 就是要新建 '外部廠商' 的採購單
    // supplier 就要列出內部廠商(customer) 讓使用者選

    if (purchaseOrderId === 'new') {
      if (currentUser.deliveryOrderSource.length === 1) {
        return (<TextField
          disabled
          type="text"
          label={formatMessage({ id: 'purchaseOrder.table.detail.supplier' })}
          variant="outlined"
          value={customerMapping[currentUser.deliveryOrderSource[0]].nickname}
          fullWidth
          size="small"
        />)
      } else {
        return (<TextField
          select
          required
          disabled={lockSupplier}
          type="text"
          label={formatMessage({ id: 'purchaseOrder.table.detail.supplier' })}
          variant="outlined"
          value={purchaseOrder.supplier}
          onChange={e => updatePurchaseOrderData({ name: 'supplier' }, e.target.value)}
          fullWidth
          size="small"
          error={purchaseOrder.supplier_err ? true : false}
          helperText={purchaseOrder.supplier_err}
        >
          {filteredCustomers.map(c => <MenuItem key={c.id} value={c.id}>
            {c.nickname}
          </MenuItem>)}
        </TextField>)
      }
    } else {
      return <TextField
        disabled
        type="text"
        label={formatMessage({ id: 'purchaseOrder.table.detail.supplier' })}
        variant="outlined"
        value={supplierMapping[purchaseOrder.supplier] ? supplierMapping[purchaseOrder.supplier].nickname : ''}
        fullWidth
        size="small"
      />
    }
  }

  const invoiceSubtotal = purchaseOrder.merchandises.reduce((acc, cur) => {
    acc += cur.unitPrice * cur.amount
    return acc;
  }, 0)
  const invoiceTaxes = Math.round(TAX_RATE * invoiceSubtotal)
  const invoiceTotal = invoiceTaxes + invoiceSubtotal
  const showVoidButton = (!purchaseOrderId.startsWith('cache-') && purchaseOrderId !== 'new' && purchaseOrder.merchandises.length === 0)

  return (
    <div style={{ flexGrow: 1 }}>
      {openDialog && purchaseOrderId !== 'new' && <SelectCartItemDialog
        customer={purchaseOrder.source}
        supplier={purchaseOrder.supplier}
        handleClose={() => setOpenDialog(false)}
        handleSave={onMerchandiseChanged}
        adjustItems={cartItems}
        purchaseOrderId={purchaseOrderId === 'new' || purchaseOrderId.startsWith('cache-') ? 'new' : purchaseOrderId}
      />}
      {openDialog && purchaseOrderId === 'new' && <SelectMerchandiseDialog
        customer={purchaseOrder.supplier}
        defaultSelectedItems={[]}
        handleClose={() => setOpenDialog(false)}
        handleSave={onMerchandiseAdded}
        ignoreIds={purchaseOrder.merchandises.map(m => m.id)}
        hideFields={[]}
        loadStockMapping={true}
      />}
      {openSupplierDialog && <SelectSupplierDialog
        defaultSelectedItem={purchaseOrder.source}
        handleClose={() => setOpenSupplierDialog(false)}
        handleSave={value => updatePurchaseOrderData({ name: 'source' }, value)}
        ignoreIds={[]}
        external
        // ignoreIds={borrowingOrder.source && customerMapping[borrowingOrder.source].supplierId ? [customerMapping[borrowingOrder.source].supplierId] : []}
      />}
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            {getSourceSelector()}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'purchaseOrder.table.detail.createdBy' })}
              variant="outlined"
              value={userMapping[purchaseOrder.createdBy] ? userMapping[purchaseOrder.createdBy].displayName : ''}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'purchaseOrder.table.detail.date' })}
              variant="outlined"
              value={purchaseOrder.date}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePickerField
              required
              fullWidth
              label={formatMessage({ id: 'purchaseOrder.table.detail.expectedDate' })}
              value={purchaseOrder.expectedDate}
              onChange={handleDateChange}
              {...dateError}
              minDateMessage={formatMessage({ id: 'form.date.beforeToday' })}
              invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
              minDate={new Date()}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {getSupplierSelector()}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'purchaseOrder.table.detail.basicPurchaseAmount' })}
              variant="outlined"
              value={purchaseOrder.basicPurchaseAmount}
              fullWidth
              error={purchaseOrder.basicPurchaseAmount > invoiceSubtotal}
              helperText={purchaseOrder.basicPurchaseAmount > invoiceSubtotal ?
                formatMessage({ id: 'form.basicPurchaseAmount.lowerThanBasicPurchaseAmount' }) : ''
              }
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'purchaseOrder.table.detail.freeShippingAmount' })}
              variant="outlined"
              value={purchaseOrder.freeShippingAmount}
              fullWidth
              error={purchaseOrder.freeShippingAmount > invoiceSubtotal}
              helperText={purchaseOrder.freeShippingAmount > invoiceSubtotal ?
                formatMessage({ id: 'form.freeShippingAmount.lowerThanFreeShippingAmount' }) : ''
              }
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              select
              disabled={purchaseOrderId === 'new'}
              type="text"
              label={formatMessage({ id: 'purchaseOrder.table.detail.shipping' })}
              variant="outlined"
              value={purchaseOrder.shipping}
              onChange={e => updatePurchaseOrderData({ name: 'shipping' }, e.target.value)}
              fullWidth
              size="small"
              error={purchaseOrder.shipping_err ? true : false}
              helperText={purchaseOrder.shipping_err}
            >
              {customers.map(c => <MenuItem key={c.id} value={c.id}>
                {c.nickname}
              </MenuItem>)}
            </TextField>
          </Grid>
        </Grid>
        <Divider style={{ margin: '8px 0px' }} />
        <EnhancedTableToolbar
          title="editPurchaseOrder.table.title"
          selectdMessage="editPurchaseOrder.table.selected"
          numSelected={Object.keys(selectedItems).length}
          toolbox={getToolbox()}
          toolboxForSelection={getToolboxForSelection()}
        />
        <TableContainer component={Paper}>
          <Table>
            <EnhancedTableHead
              headerCells={headerCells}
              numSelected={Object.keys(selectedItems).length}
              onCheckboxClick={handleSelectAllClick}
              rowCount={purchaseOrder.merchandises.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(purchaseOrder.merchandises.map(m => formatData(m)), getComparator(order, orderBy)).map(merchandise => (
                <EnhancedTableRow
                  key={merchandise.id}
                  rowCells={purchaseOrderId === 'new' ? rowCellsEx : rowCells}
                  cellData={merchandise}
                  onCheckboxClick={(e) => handleCheckboxClick(merchandise.id)}
                  selected={selectedItems[merchandise.id] || false}
                />
              ))}
              <TableRow>
                <TableCell rowSpan={3} colSpan={5}/>
                <TableCell align="right"><Typography variant="subtitle2"><FormattedMessage id="invoice.subtotal" /></Typography></TableCell>
                <TableCell align="right">{invoiceSubtotal}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell align="right"><Typography variant="subtitle2"><FormattedMessage id="invoice.tax" /></Typography></TableCell>
                <TableCell align="right">{invoiceTaxes}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell align="right"><Typography variant="subtitle2"><FormattedMessage id="invoice.total" /></Typography></TableCell>
                <TableCell align="right">{invoiceTotal}</TableCell>
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'purchaseOrder.table.detail.note' })}
              variant="outlined"
              value={purchaseOrder.note}
              onChange={e => updatePurchaseOrderData({ name: 'note' }, e.target.value)}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>

          {showVoidButton &&
            <LoadingButton
              color="error"
              onClick={handleSave}
              disabled={loading}
              loading={loading}
              loadingPosition="start"
              loadingIndicator={<CircularProgress size={24} />}
              startIcon={<div />}
              variant="contained"
            >
              <FormattedMessage id="button.void" />
            </LoadingButton>
          }
          {!showVoidButton &&
            <LoadingButton
              color="primary"
              onClick={handleSave}
              disabled={disableSubmitButton || (purchaseOrder.basicPurchaseAmount > invoiceSubtotal) || loading || purchaseOrder.merchandises.length === 0}
              loading={loading}
              loadingPosition="start"
              loadingIndicator={<CircularProgress size={24} />}
              startIcon={<div />}
              variant="contained"
            >
              <FormattedMessage id="button.submit" />
            </LoadingButton>
          }
        </Stack>
      </Box>
    </div>
  );
}

// EditPurchaseOrderPage.defaultProps = {

// }

export default EditPurchaseOrderPage;
