import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import ContextStore from '../../modules/context';

function Dashboard() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const location = useLocation()

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'system.title' })
    }])
    return () => {
    };
  }, [location.pathname]);

  return (
    <div>Dashboard</div>
  );
}

export default Dashboard;
