import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '@mui/material/Switch';

import ContextStore from '../../modules/context';
import EditNewSupplierView from '../../components/EditNewSupplierView';
import EditNewMerchandiseView from '../../components/EditNewMerchandiseView';
import EditNewProductView from '../../components/EditNewProductView';
import EditNewProductPackageView from '../../components/EditNewProductPackageView';
import { lockSubmitButton, anyIntersection } from '../../modules/uitls';

const SwitchContainer = styled('div')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: 1.75,
  letterSpacing: '0.00938em',
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(2),
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
}));

export default function EditProfileDialog({ content, newProfileId, handleClose, productMapping }) {
  const { formatMessage } = useIntl()
  const { currentUser } = useContext(ContextStore)
  const childRef = useRef()
  const packageChildRef = useRef()
  const [loading, setLoading] = useState(false)
  const [systemFlags, setSystemFlags] = useState(false)
  const [customerError, setCustomerError] = useState(false)
  const { profile } = content
  let profileName = ['package', 'combination', 'folder'].includes(profile) ? 'product' : profile
  let tableTitle = ['package', 'combination', 'folder'].includes(profile) ? `${profile}Title` : 'title'

  if (['package', 'combination', 'folder'].includes(profile)) {
    const newPackage = []

    for (const p of Object.keys(content.package)) {
      newPackage.push({ id: p, ...content.package[p] })
    }
    content.package = newPackage
  }

  function getDisabledFields() {
    const fields = ['staffprice', 'staffRelativesPrice'];
    if (['package', 'combination', 'folder'].includes(profile)) {
      fields.push('consumable')
    }
    return fields
  }

  function editParentData(field, value) {
    childRef.current.setEmployeePirce(field, value)
  }

  function getDialogContent() {
    if (profile === 'supplier') {
      return <EditNewSupplierView ref={childRef} supplier={content} ignoreFields={systemFlags ? [] : ['supplier', 'internal', 'active']} />
    } else if (profile === 'merchandise') {
      return (
        <EditNewMerchandiseView
          ref={childRef}
          merchandise={content}
          requiredFields={['name', 'nickname', 'type', 'category', 'orderUnit', 'sku', 'ou2sku', 'suppliers']}
        />
      )
    } else if (profile === 'product') {
      return (
        <EditNewProductView
          ref={childRef}
          product={content}
          requiredFields={['name', 'type', 'cost', 'staffprice', 'staffRelativesPrice']}
        />
      )
    } else if (['package', 'combination', 'folder'].includes(profile)) {
      return (
        <>
          <EditNewProductView
            ref={childRef}
            product={content}
            disabledFields={getDisabledFields()}
            ignoreFields={['cost']}
            requiredFields={['name', 'type', 'staffprice', 'staffRelativesPrice']}
          />
          {customerError && <div>{formatMessage({ id: 'form.customer.error' })}</div>}
          <EditNewProductPackageView
            ref={packageChildRef}
            product={content}
            pageName={profile}
            editParentData={editParentData}
            productMapping={productMapping}
          />
        </>
      )
    }
  }

  const handleSave = async () => {
    lockSubmitButton(true)
    setLoading(true);
    const data = childRef.current.getContent()
    if (data) {
      try {
        if (profile === 'supplier') {
          await (firebase.functions().httpsCallable('saveSupplier'))({ newProfileId: newProfileId, id: content.id, ...data })
        } else if (profile === 'merchandise') {
          await (firebase.functions().httpsCallable('saveMerchandise'))({ newProfileId: newProfileId, id: content.id, ...data })
        } else if (profile === 'product') {
          await (firebase.functions().httpsCallable('saveProduct'))({ newProfileId: newProfileId, id: content.id, ...data })
        } else if (['package', 'combination', 'folder'].includes(profile)) {
          const packageData = packageChildRef.current.getContent()
          if (packageData) {
            let error = true
            let newProductMapping = {}
            let costCount = 0

            for (const p of packageData.package) {
              if (profile !== 'folder') {
                newProductMapping[p.id] = { amount: parseInt(p.amount) }
                if (p.ratio !== undefined) {
                  newProductMapping[p.id].ratio = parseInt(p.ratio)
                }
              } else {
                newProductMapping[p.id] = { amount: parseInt(p.amount), price: parseInt(p.price), lowestPrice: parseInt(p.lowestPrice) }
              }

              costCount = Number(p.cost) + costCount
              error = error && !anyIntersection(Object.keys(p.customers || {}), Object.keys(data.customers))
            }
            setCustomerError(error)

            const newData = {
              ...data,
              cost: costCount,
              package: newProductMapping,
              treatments: packageData.treatments
            }

            if (!customerError) {
              await (firebase.functions().httpsCallable('saveProduct'))({ newProfileId: newProfileId, id: content.id, ...newData })
            } else {
              lockSubmitButton(false)
              setLoading(false);
              setCustomerError(false)
            }
          } else {
            lockSubmitButton(false)
            setLoading(false);
            return
          }
        }
      } catch (ex) {
        console.log(ex)
      }
    } else {
      lockSubmitButton(false)
      setLoading(false);
      return
    }
    lockSubmitButton(false)
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <FormattedMessage id={`${profileName}.dialog.${tableTitle}.${content.id === 'new' ? 'add' : 'edit'}`} />
        {profile === 'supplier' && currentUser.userRights.debugging &&
          <SwitchContainer>
            <div>簡易</div>
            <Switch
              checked={systemFlags}
              onChange={e => setSystemFlags(e.target.checked)}
              color="primary"
              name="editmode"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <div>進階</div>
          </SwitchContainer>
        }
      </DialogTitle>
      <DialogContent dividers={true}>
        {getDialogContent()}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          color="primary"
          onClick={handleSave}
          disabled={loading}
          loading={loading}
          loadingPosition="start"
          loadingIndicator={<CircularProgress size={24} />}
          startIcon={<div />}
          variant="contained"
        >
          <FormattedMessage id="button.save" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

EditProfileDialog.propTypes = {
  content: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  newProfileId: PropTypes.string.isRequired,
  productMapping: PropTypes.object
};
