import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';

import TabContainer from '../../containers/TabContainer/TabContainer';
import ContextStore from '../../modules/context';
import FabAdd from '../../components/FabAdd';
import NewProfileTab from './NewProfileTab';

const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
}));

function NewProfile({ profile }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const [activeTabIndex, setActiveTabIndex] = useState(undefined)
  const { tabName } = useParams()

  let tableName = ''
  let userRightName = ''
  if (profile === 'supplier') {
    userRightName = 'newSupplier'
    tableName = 'newSupplier'
  } else if (profile === 'merchandise') {
    userRightName = 'newMerchandise'
    tableName = 'newMerchandise'
  } else if (profile === 'product') {
    userRightName = 'newProduct'
    tableName = 'newProduct'
  } else if (profile === 'folder') {
    userRightName = 'newProduct'
    tableName = 'newFolderProduct'
  } else if (profile === 'package') {
    userRightName = 'newProduct'
    tableName = 'newPackageProduct'
  } else if (profile === 'combination') {
    userRightName = 'newProduct'
    tableName = 'newCombinationProduct'
  }

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: `sideMenu.profile.${tableName}` })
    }])
    const pathname = location.pathname
    if (pathname === `/profile/${tableName}/pending` && activeTabIndex !== 0) {
      setActiveTabIndex(0)
    } else if (pathname === `/profile/${tableName}/done` && activeTabIndex !== 1) {
      setActiveTabIndex(1)
    } else if (pathname === `/profile/${tableName}/void` && activeTabIndex !== 2) {
      setActiveTabIndex(2)
    }
    return () => {
    };
  }, [location.pathname, profile]);

  const onTabSelected = (tabIndex) => {
    if (tabIndex === 0) {
      navigate(`/profile/${tableName}/pending`);
    } else if (tabIndex === 1) {
      navigate(`/profile/${tableName}/done`);
    } else {
      navigate(`/profile/${tableName}/void`);
    }
  };

  const tabs = [
    { label: formatMessage({ id: 'newProfile.tab.pending' }), component: <NewProfileTab profile={profile} /> },
    { label: formatMessage({ id: 'newProfile.tab.done' }), component: <NewProfileTab profile={profile} /> },
    { label: formatMessage({ id: 'newProfile.tab.void' }), component: <NewProfileTab profile={profile} /> },
  ]
  const defaultSelect = tabName === 'void' ? 2 : (tabName === 'done' ? 1 : 0)

  return (
    <Root>
      {currentUser.userRights[`${userRightName}-create`] === true && <FabAdd to={`/profile/${tableName}/edit/new`} />}
      <TabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} />
    </Root>
  );
}

NewProfile.propTypes = {
  profile: PropTypes.string.isRequired,
};

export default NewProfile;
