import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';

import { unwrap } from '../../modules/uitls';
import ContextStore from '../../modules/context';

export default function Notification() {
  const { currentUser } = useContext(ContextStore)
  const shoppingCart = useSelector(state => state.firestore.data.shoppingCart) || {}
  const merchandises = Object.keys(shoppingCart)

  const [pendingRequisitionCount, setPendingRequisitionCount] = useState(0);
  const [waitingForPurchaseCount, setWaitingForPurchaseCount] = useState(0);
  const [pendingPurchaseOrderCount, setPendingPurchaseOrderCount] = useState(0);
  const [pendingReceiptCount, setPendingReceiptCount] = useState(0);
  const [pendingSalesReturnFormCount, setPendingSalesReturnFormCount] = useState(0);
  const [pendingStockRequisitionCount, setPendingStockRequisitionCount] = useState(0);

  const [pendingNewProfile, setPendingNewProfile] = useState({
    pendingNewSupplier: 0,
    pendingNewMerchandise: 0,
    pendingNewProduct: 0,
    pendingNewPackageProduct: 0,
    pendingNewCombinationProduct: 0,
    waitingCreateSupplier: 0,
    waitingCreateMerchandise: 0,
    waitingCreateProduct: 0,
    waitingCreatePackageProduct: 0,
    waitingCreateCombinationProduct: 0,
  });

  useEffect(() => {
    const unsubscribe = currentUser.userRights['requisition-view'] ? firebase.firestore().collection('requisitions').where('status', '==', 'pending')
      .onSnapshot( snapshot => {
        let count = 0
        snapshot.forEach(doc => {
          const requisition = unwrap(doc.data())
          const history = requisition.history || []
          const currentStep = history.length > 0 ? history[history.length - 1].step : 0
          if (isNaN(currentStep) || currentStep === requisition.steps.length) return
          if (requisition.steps[currentStep].users.includes(currentUser.key)) count++
        });
        setPendingRequisitionCount(count)
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, []);

  useEffect(() => {
    const unsubscribe = currentUser.userRights['purchaseOrder-view'] ? firebase.firestore().collection('purchaseOrders').where('status', '==', 'pending')
      .onSnapshot( snapshot => {
        let count = 0
        snapshot.forEach(doc => {
          const purchaseOrder = doc.data()
          const history = purchaseOrder.history || []
          const currentStep = history.length > 0 ? history[history.length - 1].step : 0
          if (isNaN(currentStep) || currentStep === purchaseOrder.steps.length) return
          if (purchaseOrder.steps[currentStep].users.includes(currentUser.key)) count++
        });
        setPendingPurchaseOrderCount(count)
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, []);

  useEffect(() => {
    const date = dayjs().subtract(2, 'months').format('YYYY-MM')
    const unsubscribe = currentUser.purchaseOrderSource.length > 0 ? firebase.firestore().collection('requisitions').where('status', '==', 'done').where('date', '>=', date)
      .onSnapshot( snapshot => {
        let count = 0
        snapshot.forEach(doc => {
          const requisition = unwrap(doc.data())
          if (currentUser.purchaseOrderSource.includes(requisition.source)) {
            const merchandises = Object.keys(requisition.merchandises).map(k => ({ id: k, ...(requisition.merchandises[k]) }))
            if (merchandises.some(m => {
              if (m.shoppingCart || (!m.shoppingCart && !m.purchaseOrders)) return true
              const poIds = Object.keys(m.purchaseOrders || {})
              let unPurchasedAmount = m.amount
              for (const i of poIds) {
                if (m.purchaseOrders[i].status === 'done') unPurchasedAmount -= m.purchaseOrders[i].amount
              }
              return unPurchasedAmount > 0
            })) count++
          }
        });
        setWaitingForPurchaseCount(count)
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, []);

  useEffect(() => {
    const unsubscribe = currentUser.userRights['receipt-view'] ? firebase.firestore().collection('receipts').where('status', '==', 'pending')
      .onSnapshot( snapshot => {
        let count = 0
        snapshot.forEach(doc => {
          const receipt = doc.data()
          const history = receipt.history || []
          const currentStep = history.length > 0 ? history[history.length - 1].step : 0
          if (isNaN(currentStep) || currentStep === receipt.steps.length) return
          if (receipt.steps[currentStep].users.includes(currentUser.key)) count++
        });
        setPendingReceiptCount(count)
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, []);

  useEffect(() => {
    const unsubscribe = currentUser.userRights['deliveryOrder-view'] ? firebase.firestore().collection('salesReturnForms').where('status', '==', 'pending')
      .onSnapshot( snapshot => {
        let count = 0
        snapshot.forEach(doc => {
          const salesReturnForm = doc.data()
          const history = salesReturnForm.history || []
          const currentStep = history.length > 0 ? history[history.length - 1].step : 0
          if (isNaN(currentStep) || currentStep === salesReturnForm.steps.length) return
          if (salesReturnForm.steps[currentStep].users.includes(currentUser.key)) count++
        });
        setPendingSalesReturnFormCount(count)
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, []);

  useEffect(() => {
    const unsubscribe = currentUser.userRights['stockRequisition-view'] ? firebase.firestore().collection('stockRequisitions').where('status', '==', 'pending')
      .onSnapshot( snapshot => {
        let count = 0
        snapshot.forEach(doc => {
          const stockRequisition = doc.data()
          const history = stockRequisition.history || []
          const currentStep = history.length > 0 ? history[history.length - 1].step : 0
          if (isNaN(currentStep) || currentStep === stockRequisition.steps.length) return
          if (stockRequisition.steps[currentStep].users.includes(currentUser.key)) count++
        });
        setPendingStockRequisitionCount(count)
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, []);

  useEffect(() => {
    const date = dayjs().subtract(1, 'months').format('YYYY-MM')
    const unsubscribe = currentUser.userRights['newSupplier-view'] ? firebase.firestore().collection('newProfiles').where('date', '>=', date)
      .onSnapshot( snapshot => {
        // let count = 0
        let pendingNewSupplier = 0
        let pendingNewMerchandise = 0
        let pendingNewProduct = 0
        let pendingNewPackageProduct = 0
        let pendingNewCombinationProduct = 0
        let waitingCreateSupplier = 0
        let waitingCreateMerchandise = 0
        let waitingCreateProduct = 0
        let waitingCreatePackageProduct = 0
        let waitingCreateCombinationProduct = 0

        snapshot.forEach(doc => {
          const data = unwrap(doc.data())
          const history = data.history || []
          const currentStep = history.length > 0 ? history[history.length - 1].step : 0
          if (data.profile === 'supplier') {
            if (data.status === 'pending') {
              if (isNaN(currentStep) || currentStep === data.steps.length) return
              if (data.steps[currentStep].users.includes(currentUser.key)) pendingNewSupplier++
            } else if (data.status === 'done' && !data.supplierId) {
              if (data.steps[0].overwrite.includes(currentUser.key)) waitingCreateSupplier++
            }
          } else if (data.profile === 'merchandise') {
            if (data.status === 'pending') {
              if (isNaN(currentStep) || currentStep === data.steps.length) return
              if (data.steps[currentStep].users.includes(currentUser.key)) pendingNewMerchandise++
            } else if (data.status === 'done' && !data.merchandiseId) {
              if (data.steps[0].overwrite.includes(currentUser.key)) waitingCreateMerchandise++
            }
          } else if (data.profile === 'product') {
            if (data.status === 'pending') {
              if (isNaN(currentStep) || currentStep === data.steps.length) return
              if (data.steps[currentStep].users.includes(currentUser.key)) pendingNewProduct++
            } else if (data.status === 'done' && !data.productId) {
              if (data.steps[0].overwrite.includes(currentUser.key)) waitingCreateProduct++
            }
          } else if (data.profile === 'package') {
            if (data.status === 'pending') {
              if (isNaN(currentStep) || currentStep === data.steps.length) return
              if (data.steps[currentStep].users.includes(currentUser.key)) pendingNewPackageProduct++
            } else if (data.status === 'done' && !data.productId) {
              if (data.steps[0].overwrite.includes(currentUser.key)) waitingCreatePackageProduct++
            }
          } else if (data.profile === 'combination') {
            if (data.status === 'pending') {
              if (isNaN(currentStep) || currentStep === data.steps.length) return
              if (data.steps[currentStep].users.includes(currentUser.key)) pendingNewCombinationProduct++
            } else if (data.status === 'done' && !data.productId) {
              if (data.steps[0].overwrite.includes(currentUser.key)) waitingCreateCombinationProduct++
            }
          }
        });
        setPendingNewProfile({
          pendingNewSupplier,
          pendingNewMerchandise,
          pendingNewProduct,
          pendingNewPackageProduct,
          pendingNewCombinationProduct,
          waitingCreateSupplier,
          waitingCreateMerchandise,
          waitingCreateProduct,
          waitingCreatePackageProduct,
          waitingCreateCombinationProduct
        })
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, []);

  const notifications = [
    { name: 'pendingRequisition', link: '/purchase/requisition/pending', count: pendingRequisitionCount },
    { name: 'pendingPurchaseOrder', link: '/purchase/purchaseOrder/pending', count: pendingPurchaseOrderCount },
    { name: 'waitingForPurchase', link: '/purchase/requisition/done', count: waitingForPurchaseCount },
    { name: 'pendingReceipt', link: '/purchase/receipt/pending', count: pendingReceiptCount },
    { name: 'pendingSalesReturnForm', link: '/sales/salesReturnForm/pending', count: pendingSalesReturnFormCount },
    { name: 'pendingStockRequisition', link: '/stock/stockRequisition/pending', count: pendingStockRequisitionCount },
    { name: 'pendingNewSupplier', link: '/profile/newSupplier/pending', count: pendingNewProfile.pendingNewSupplier },
    { name: 'pendingNewMerchandise', link: '/profile/newMerchandise/pending', count: pendingNewProfile.pendingNewMerchandise },
    { name: 'pendingNewProduct', link: '/profile/newProduct/pending', count: pendingNewProfile.pendingNewProduct },
    { name: 'pendingNewPackageProduct', link: '/profile/newPackageProduct/pending', count: pendingNewProfile.pendingNewPackageProduct },
    { name: 'pendingNewCombinationProduct', link: '/profile/newCombinationProduct/pending', count: pendingNewProfile.pendingNewCombinationProduct },
    { name: 'waitingCreateSupplier', link: '/profile/newSupplier/done', count: pendingNewProfile.waitingCreateSupplier },
    { name: 'waitingCreateMerchandise', link: '/profile/newMerchandise/done', count: pendingNewProfile.waitingCreateMerchandise },
    { name: 'waitingCreateProduct', link: '/profile/newProduct/done', count: pendingNewProfile.waitingCreateProduct },
    { name: 'waitingCreatePackageProduct', link: '/profile/newPackageProduct/done', count: pendingNewProfile.waitingCreatePackageProduct },
    { name: 'waitingCreateCombinationProduct', link: '/profile/newCombinationProduct/done', count: pendingNewProfile.waitingCreateCombinationProduct },
  ]

  return (
    <>
      {merchandises.length > 0 && <Badge badgeContent={merchandises.length} color="error" variant="dot">
        <Link to="/user/shoppingCart" style={{
          textDecoration: 'none',
          color: '#FFF',
          marginRight: '4px',
          marginLeft: '20px'
        }}>
          <Typography variant="button" sx={{ flexGrow: 1 }} noWrap>
            <FormattedMessage id="header.menu.shoppingCart" />
          </Typography>
        </Link>
      </Badge>}
      {notifications.map(i => i.count > 0 ? (<Badge key={i.name} badgeContent={i.count} color="error" variant="dot">
        <Link to={i.link} style={{
          textDecoration: 'none',
          color: '#FFF',
          marginRight: '4px',
          marginLeft: '20px'
        }}>
          <Typography variant="button" sx={{ flexGrow: 1 }} noWrap>
            <FormattedMessage id={`header.message.${i.name}`} />
          </Typography>
        </Link>
      </Badge>) : null)}
    </>
  );
}
