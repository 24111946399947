import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ActionDialog(props) {
  const [text, setText] = useState('');

  const { title, content, handleClose, handleExecute, textFieldLabel, action, data } = props;

  function handleAction() {
    if (data) {
      handleExecute({ ...data, action, text })
    } else {
      handleExecute({ action, text })
    }
    handleClose()
  }

  return (
    <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {content && <DialogContentText>{content}</DialogContentText>}
        {textFieldLabel && <TextField
          autoFocus
          margin="dense"
          variant="outlined"
          label={textFieldLabel}
          type="text"
          value={text}
          size="small"
          onChange={e => setText(e.target.value)}
          fullWidth
        />}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button variant="contained" onClick={handleAction} color="primary">
          <FormattedMessage id={`button.${action}`} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ActionDialog.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleExecute: PropTypes.func.isRequired,
  textFieldLabel: PropTypes.string,
  data: PropTypes.object,
  action: PropTypes.string.isRequired
};
