import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Backdrop from '@mui/material/Backdrop';
import MuiSpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import BookIcon from '@mui/icons-material/Book';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarksIcon from '@mui/icons-material/Bookmarks';

import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { getComparator, stableSort } from '../../modules/sort';
import SearchBox from '../../components/SearchBox';
import ContextStore from '../../modules/context';
import RecordDialog from '../../components/RecordDialog';
import { useFirestoreDataAndMapping, unwrap } from '../../modules/uitls';
import { ProductCategories as productCategories } from '../../constants/index';

const SpeedDial = styled(MuiSpeedDial)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 12 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const fields = [
  { name: 'type', type: 'select', sm: 6 },
  { name: 'tags', sm: 6 },
  { name: 'cost', sm: 6 },
  // {name: 'sku', sm: 6},
  { name: 'staffprice', sm: 6 },
  { name: 'staffRelativesPrice', sm: 6 },
  { name: 'vipprice', sm: 6 },
  { name: 'consumable', sm: 6 },
  // {name: 'safetyStock', sm: 6},
  { name: 'customers', sm: 12, md: 12 },
  { name: 'note', type: 'multiline', multiline: true, sm: 12, md: 12 },
].map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

function ProductDetail({ allowEditing, customerMapping, product }) {
  const { formatMessage } = useIntl()
  const [openRecordDialog, setRecordDialog] = useState(false)
  const customers = Object.keys(product.customers || []).filter(c => customerMapping[c]).map(c => customerMapping[c])
  const openCode = product.cat.substr(0, 1)
  let pageName = 'package'
  if (openCode === '9') pageName = 'combination';
  else if (openCode === '7') pageName = 'folder';
  const selectMapping = {
    type: productCategories.reduce((acc, cur) => { acc[cur] = formatMessage({ id: `product.type.${cur}` }); return acc }, {}),
  }

  const _headerCells = [
    { text: 'name', order: 0 },
    { text: 'amount', order: 1 },
    { text: 'customers', order: 4 },
    { text: 'cost', order: 5 },
  ]

  const _rowCells = [
    { field: 'name', order: 0 },
    { field: 'amount', order: 1 },
    { field: 'customers', order: 4 },
    { field: 'cost', order: 5 },
  ]

  if (openCode !== '7') {
    _headerCells.push({ text: 'ratio', order: 2 })
    _rowCells.push({ field: 'ratio', order: 2 })
  } else {
    _headerCells.push({ text: 'price', order: 2 }, { text: 'lowestPrice', order: 3 })
    _rowCells.push({ field: 'price', order: 2 }, { field: 'lowestPrice', order: 3 })
  }

  const headerCells = _headerCells.map(c => { c.text = formatMessage({ id: `product.product.${c.text}` }); return c }).sort((a, b) => a.order - b.order)
  const rowCells = _rowCells.sort((a, b) => a.order - b.order)

  const formatData = (product) => {
    const newData = { ...product }
    const customers = Object.keys(product.customers || []).filter(c => customerMapping[c])
    newData.customers = customers.map(s => customerMapping[s]).map(s => s.nickname).join(', ')
    newData.tags = (product.tags || []).join(', ')
    return newData
  }

  function createField(field, value, product) {
    let newValue = value ?? ''
    if (field.type === 'select') {
      if (field.name === 'type') {
        newValue = selectMapping[field.name][value]
      }
    }

    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={formatMessage({ id: `product.table.detail.${field.name}` })}
        value={field.name === 'customers' ? customers.map(s => s.nickname).join(', ') : field.name === 'consumable' ? value ? '療程消耗' : '直接銷售' : newValue}
        fullWidth
        size="small"
        variant="standard"
        readOnly
      />
    </Grid>
  }

  return (
    <div style={{ padding: 15 }}>
      {openRecordDialog && <RecordDialog
        handleClose={() => setRecordDialog(false)}
        currentMapping={{ id: product.id, type: 'product' }}
      />}
      <Grid container spacing={2}>
        {fields.map(field => createField(field, product[field.name], product))}
        {openCode !== '1' && <>
          <div style={{ paddingLeft: '8px', marginTop: '8px' }}>
            <Typography variant="button" component="div">
              <FormattedMessage id="product.products" />:
            </Typography>
          </div>
          <TableContainer style={{ marginBottom: '8px' }} component={Paper}>
            <Table size="small" aria-label="collapsible table">
              <EnhancedTableHead
                headerCells={headerCells}
                rowCount={product.package.length}
              />
              <TableBody>
                {product.package.map(m => formatData(m)).map(packageProduct => (
                  <EnhancedTableRow
                    key={packageProduct.id}
                    rowCells={rowCells}
                    cellData={packageProduct}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>}
        {allowEditing && <Grid item key="buttons" xs={12} sm={12} md={12}>
          <div style={{ height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
            <Button variant="contained" color="primary" sx={{ mr: '20px' }} onClick={() => setRecordDialog(true)}>
              <FormattedMessage id="button.record" />
            </Button>
            <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
              <Link
                to={openCode === '1' ? `/products/${product.id}/warehouse` : `/products/${product.id}/warehouse/${pageName}`}
                style={{ textDecoration: 'none', color: '#000' }}
              >
                <Button variant="contained" color="primary">
                  <FormattedMessage id="button.edit" />
                </Button>
              </Link>
            </Stack>
          </div>
        </Grid>}
      </Grid>
    </div>
  )
}

ProductDetail.propTypes = {
  product: PropTypes.object.isRequired,
  customerMapping: PropTypes.object.isRequired,
  allowEditing: PropTypes.bool.isRequired,
};

function Product() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.product.warehouse' })
    }])
    return () => {
    };
  }, [location.pathname]);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const [currentFilter, setCurrentFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState({ tags: [] });
  const [customerMapping] = useFirestoreDataAndMapping('customers')
  const [products, setProducts] = useState([])
  const [productMapping, setProductMapping] = useState(null)
  const [open, setOpen] = useState(false);

  const filteredproducts = filterproducts()
  const filteredTags = [...new Set(
    (categoryFilter.type ? products.filter(p => p.type.toLowerCase().includes(categoryFilter.type.toLowerCase())) : products).reduce((acc, cur) => acc.concat(cur.tags || []), [])
  )].sort()

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'name' },
    { text: 'cost', sort: 'cost' },
  ].map(c => { c.text = formatMessage({ id: `product.table.header.${c.text}` }); return c })

  const rowCells = [
    { field: 'code' },
    { field: 'name' },
    { field: 'cost' },
  ]

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('products').onSnapshot(snapshot => {
      const products = []
      const productMapping = {}
      snapshot.forEach(doc => {
        const newData = unwrap(doc.data())
        productMapping[doc.id] = { ...newData }
        const packageData = [];

        if (newData.package) {
          Object.keys(newData.package).forEach(p => {
            packageData.push({ id: p, ...newData.package[p] })
          })

          newData.package = packageData
        }

        products.push({ id: doc.id, ...newData })
      })

      setProductMapping(productMapping)
      setProducts(products)
    }, err => { })

    return () => unsubscribe()
  }, []);

  const formatData = (product) => {
    const newData = { ...product }
    const newPackage = []

    if (newData.package) {
      for (const p of newData.package) {
        newPackage.push({
          ...p,
          name: productMapping[p.id]?.name ?? '',
          customers: productMapping[p.id]?.customers ?? {},
          cost: productMapping[p.id]?.cost ?? 0
        })
      }

      newData.package = newPackage
    }

    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function handleClick(type) {
    if (type === 'product') {
      navigate('/products/new/warehouse')
    } else if (type === 'package') {
      navigate('/products/new/warehouse/package')
    } else if (type === 'folder') {
      navigate('/products/new/warehouse/folder')
    } else {
      navigate('/products/new/warehouse/combination')
    }
  }
  const actions = [
    {
      icon: <BookmarkIcon />,
      name: formatMessage({ id: 'product.dialog.title.add' }),
      type: 'product'
    },
    {
      icon: <BookmarksIcon />,
      name: formatMessage({ id: 'product.dialog.folderTitle.add' }),
      type: 'folder'
    },
    {
      icon: <BookIcon />,
      name: formatMessage({ id: 'product.dialog.packageTitle.add' }),
      type: 'package'
    },
    {
      icon: <BookmarkAddIcon />,
      name: formatMessage({ id: 'product.dialog.combinationTitle.add' }),
      type: 'combination'
    },
  ];

  const filterItems = [
    { name: 'name' },
    { name: 'code' },
    { name: 'note' },
  ].map(i => { i.text = formatMessage({ id: `product.table.detail.${i.name}` }); return i })

  const categoryFilterItems = [
    { name: 'type' },
    { name: 'tags' },
  ].map(i => { i.text = formatMessage({ id: `product.table.detail.${i.name}` }); return i })

  function filterproducts() {
    if (!currentFilter && !categoryFilter.type && categoryFilter.tags.length === 0) {
      return products
    }
    let items = [...products]
    if (categoryFilter.type) {
      const type = categoryFilter.type.toUpperCase()
      items = items.filter(i => i.type === type)
    }
    if (categoryFilter.tags.length > 0) {
      items = items.filter(i => categoryFilter.tags.every(tag => (i.tags || []).includes(tag)))
    }
    if (currentFilter) {
      items = items.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
    return items
  }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  function getMenuItem(name) {
    if (name === 'type') {
      return [
        { value: '' },
        { value: 'beauty', header: true }, { value: 'AM' }, { value: 'OP' }, { value: 'SK' }, { value: 'DP' },
        { value: 'preventative', header: true }, { value: 'CN' }, { value: 'EX' }, { value: 'TT' }, { value: 'GE' }, { value: 'SU' },
        { value: 'ND' }, { value: 'CU' },
        { value: 'other', header: true },
        { value: 'OT' }
      ].map(i => {
        i.label = i.value ? formatMessage({ id: `product.type.${i.value}` }) : formatMessage({ id: 'product.category.all' })
        return i
      })
    }
    return []
  }

  function updateCategoryFilter(name, value) {
    const newFilter = { ...categoryFilter }
    newFilter[name] = value
    setCategoryFilter(newFilter)
  }

  const getFilter = (filter) => {
    if (filter.name === 'tags') {
      return <Grid item key={filter.name} xs={3} sm={3} md={6}>
        <FormControl size="small" fullWidth>
          <InputLabel id="demo-multiple-checkbox-label">{formatMessage({ id: 'product.table.detail.tags' })}</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={categoryFilter.tags}
            onChange={e => { updateCategoryFilter(filter.name, e.target.value) }}
            input={<OutlinedInput label={formatMessage({ id: 'product.table.detail.tags' })} />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {filteredTags.map((tag) => (
              <MenuItem key={tag} value={tag}>
                <Checkbox checked={categoryFilter.tags.includes(tag)} />
                <ListItemText primary={tag} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    } else {
      return <Grid item key={filter.name} xs={3} sm={3} md={3}>
        <TextField
          select
          type="text"
          size="small"
          label={filter.text}
          variant="outlined"
          onChange={e => { updateCategoryFilter(filter.name, e.target.value) }}
          value={categoryFilter[filter.name] || ''}
          fullWidth
        >
          {getMenuItem(filter.name).map((option, idx) => {
            return option.header ?
              (<ListSubheader key={`${option.value}-${idx}`}>{option.label}</ListSubheader>) :
              (<MenuItem key={`${option.value}-${idx}`} value={option.value}>{option.label}</MenuItem>)
          })}
        </TextField>
      </Grid>
    }
  }

  return (
    <div style={{ paddingBottom: '100px' }}>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        icon={<SpeedDialIcon />}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => handleClick(action.type)}
          />
        ))}
      </SpeedDial>
      <Toolbar sx={{ pl: 2, pr: 1 }}>
        <Typography sx={{ flex: '1 1 100%', flexShrink: 2 }} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id="product.table.title" />
        </Typography>
        <Grid container spacing={3}>
          {categoryFilterItems.map(f => getFilter(f))}
        </Grid>
        <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />
      </Toolbar>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: '650px' }}>
          <EnhancedTableHead
            headerCells={headerCells}
            order={order}
            orderBy={orderBy}
            expandable
            onRequestSort={handleRequestSort}
            rowCount={filteredproducts.length}
          />
          <TableBody>
            {stableSort(filteredproducts, getComparator(order, orderBy)).map(product => (
              <EnhancedTableRow
                key={product.id}
                rowCells={rowCells}
                cellData={product}
                expandable
                expandContent={<ProductDetail
                  allowEditing={!!currentUser.userRights['product-edit']}
                  customerMapping={customerMapping}
                  product={formatData(product)}
                />}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Product;
