const i18n = {
  'button': {
    'cancel': '取消',
    'close': '關閉',
    'add': '新增',
    'edit': '編輯',
    'save': '儲存',
    'apply': '套用',
    'ok': '確定',
    'submit': '送出',
    'approve': '批准',
    'reject': '否決',
    'void': '作廢',
    'confirm': '確認',
    'closeout': '結單',
    'accept': '驗收',
    'abandon': '撤銷',
    'unlock': '解鎖',
    'print': '列印',
    'export': '匯出',
    'record': '查看歷史紀錄'
  },
}
export default i18n
