import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import firebase from 'firebase/app';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

import { unwrap } from '../../modules/uitls';

function StockInfoDiablog({ customer, merchandiseId, sku, onClose }) {
  const wareroom = useSelector(state => unwrap(state.firestore.data.config?.wareroom || {}))
  const wareroomMapping = (wareroom[customer] || []).reduce((acc, cur, idx) => {acc[`#${idx + 1}`] = cur; return acc}, { '#default': '主倉庫' })
  const [info, setInfo] = useState({})

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('wh').doc(customer).collection('inventories').where('closeout', '==', false).where('id', '==', merchandiseId)
      .onSnapshot( snapshot => {
        const info = { 'default': 0 }
        snapshot.forEach(doc => {
          const m = doc.data()
          let currentAmount = m.currentAmount
          Object.keys(m.wareroom || {}).forEach(k => {
            currentAmount -= m.wareroom[k]
            if (!info[k]) {
              info[k] = m.wareroom[k]
            } else {
              info[k] += m.wareroom[k]
            }
          })
          info['default'] += currentAmount
        })
        setInfo(info)
      }, err => {})
    return () => unsubscribe()
  }, [customer, merchandiseId]);

  const { default: mainWareroomCount, ...otherWarerooms } = info
  const warerooms = Object.keys(otherWarerooms).map(k => `${wareroomMapping[k]}: ${otherWarerooms[k]}${sku}`)
  const infoText = mainWareroomCount !== undefined ? [`${wareroomMapping['#default']}: ${mainWareroomCount}${sku}`, ...warerooms ].join('\n') : ''

  return <Dialog maxWidth="md" onClose={onClose} open>
    <Box p={3}>
      <div style={{ whiteSpace: 'pre' }}>
        {infoText}
      </div>
    </Box>
  </Dialog>
}

StockInfoDiablog.propTypes = {
  customer: PropTypes.string.isRequired,
  merchandiseId: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default StockInfoDiablog;
