import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';
import { useReactToPrint } from 'react-to-print';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import EnhancedTable from '../../components/EnhancedTable';
import ProgressStep from '../../components/ProgressStep';
import ActionDialog from '../../components/ActionDialog';
import { lockSubmitButton } from '../../modules/uitls';
import ContextStore from '../../modules/context';

const PrintSteps = styled('div')(({ theme }) => ({
  display: 'flex',
  '& > *': {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    width: theme.spacing(20),
    height: theme.spacing(11),
    whiteSpace: 'pre-line',
  }
}));

const _headerCells = [
  { field: 'code', sort: 'code' },
  { field: 'name' },
  // {field: 'unitPrice', align: 'right'},
  { field: 'amount', align: 'right' },
  { field: 'orderUnit', align: 'right' },
  // {field: 'price', align: 'right'},
  { field: 'note', align: 'right' },
]

const _rowCells = [
  { field: 'code' },
  { field: 'nickname', tooltip: 'name' },
  // {field: 'unitPrice', align: 'right'},
  { field: 'amount', align: 'right' },
  { field: 'orderUnit', align: 'right' },
  // {field: 'price', align: 'right', type: 'calculate', calculate: 'unitPrice*amount'},
  { field: 'note', align: 'right' },
]

const printSourceFields = [
  { text: 'sn', size: 4 },
  { text: 'sourceName', size: 4 },
  { text: 'createdBy', size: 4 },
  { text: 'date', size: 4 },
  { text: 'expectedDate', size: 4 },
]

function RequisitionView({ requisition, userMapping, merchandiseMapping }) {
  const { formatMessage } = useIntl()
  const { currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const [dialogData, setDialogData] = useState(null);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [loadingShoppingCart, setLoadingShoppingCart] = useState(false);
  const [snackPack, setSnackPack] = React.useState([]);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState(undefined);
  const [statusInfo, setStatusInfo] = useState('');
  const componentRef = useRef();

  const requisitionHistory = (requisition.history || []).map(h => ({ ...h }))
  const currentStep = requisitionHistory.length > 0 ? requisitionHistory[requisitionHistory.length - 1].step : 0

  const headerCells = (requisition.status === 'done' ?
    [..._headerCells, { field: 'statusText', align: 'right' }] :
    _headerCells
  ).map(c => { c.text = formatMessage({ id: `requisition.merchandise.${c.field}` }); return c })

  const rowCells = (requisition.status === 'done' ?
    [..._rowCells, { field: 'statusText', align: 'right', type: 'info', onButtonClick: showItemStatus }] :
    _rowCells
  )

  const printHeaderCells = ([
    ..._headerCells,
  ]).map(c => ({
    ...c,
    text: formatMessage({ id: `requisition.print.merchandise.${c.field}` })
  })).sort((a, b) => a.order - b.order)

  const printRowCells = ([
    ..._rowCells,
  ]).sort((a, b) => a.order - b.order)

  // const invoiceSubtotal = requisition.merchandises.reduce((acc, cur) => {
  //   acc += cur.unitPrice * cur.amount
  //   return acc;
  // }, 0)
  // const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  // const invoiceTotal = invoiceTaxes + invoiceSubtotal;

  function needPurchase() {
    for (const m of requisition.merchandises) {
      if (!m.shoppingCart &&
        (!m.purchaseOrders || Object.keys(m.purchaseOrders).reduce((acc, cur) => acc - m.purchaseOrders[cur].amount, m.amount) > 0)) {
        return true
      }
    }
    return false
  }

  function showItemStatus(merchandiseId, field) {
    let text = []
    for (const merchandise of requisition.merchandises) {
      if (merchandise.id === merchandiseId) {
        let unPurchasedAmount = merchandise.amount
        if (merchandise.purchaseOrders) {
          const poIds = Object.keys(merchandise.purchaseOrders)
          for (const i of poIds) {
            unPurchasedAmount -= merchandise.purchaseOrders[i].amount
            if (merchandise.purchaseOrders[i].status === 'done') {
              text.push(`在採購單中, 單號[${merchandise.purchaseOrders[i].sn}], 數量[${merchandise.purchaseOrders[i].amount}], 已簽核`)
            } else {
              text.push(`在採購單中, 單號[${merchandise.purchaseOrders[i].sn}], 數量[${merchandise.purchaseOrders[i].amount}], 待簽核`)
            }
          }
        }
        if (merchandise.shoppingCart) {
          text.push(`在[${userMapping[merchandise.shoppingCart].displayName}]的採購清單中, 數量[${unPurchasedAmount}]`)
        }
        break
      }
    }
    if (text.length) {
      setStatusInfo(text.join('\n'))
    } else {
      setStatusInfo('未處理')
    }
  }

  function formatData(merchandise) {
    let statusText = ''
    if (!merchandise.shoppingCart && !merchandise.purchaseOrders) {
      statusText = '未處理'
    } else if (merchandise.shoppingCart) {
      statusText = '採購中'
    } else if (merchandise.purchaseOrders) {
      let allDone = true
      const poIds = Object.keys(merchandise.purchaseOrders || {})
      if (poIds.length === 0) {
        statusText = '未處理'
      } else {
        for (const i of poIds) {
          if (merchandise.purchaseOrders[i].status !== 'done') allDone = false
        }
        if (allDone) {
          statusText = '已採購'
        } else {
          statusText = '採購中'
        }
      }
    }
    return {
      ...merchandise,
      code: merchandiseMapping[merchandise.id].code,
      name: merchandiseMapping[merchandise.id].name,
      nickname: merchandiseMapping[merchandise.id].nickname,
      orderUnit: merchandise.orderBySku ? merchandiseMapping[merchandise.id].sku : merchandiseMapping[merchandise.id].orderUnit,
      statusText,
      purchaseOrderSn: `採購單號: ${merchandise.purchaseOrder?.sn || ''}`
    }
  }

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpenMessage(true);
    } else if (snackPack.length && messageInfo && openMessage) {
      // Close an active snack when a new one is added
      setOpenMessage(false);
    }
  }, [snackPack, messageInfo, openMessage]);

  function edit() {
    navigate(`/purchase/requisition/edit/${requisition.id}`);
  }

  function reject() {
    setDialogData({ action: 'reject', title: '' })
  }

  function approve() {
    setDialogData({ action: 'approve', title: '' })
  }

  function confirm() {
    setDialogData({ action: 'confirm', title: '' })
  }

  function handleClose() {
    setDialogData(null)
  }

  async function handleExecute(data) {
    const { action, text } = data
    setDialogData(null)
    if (['approve', 'reject', 'confirm'].includes(action)) {
      if (['approve', 'confirm'].includes(action)) {
        setLoadingApprove(true)
      } else {
        setLoadingReject(true)
      }
      try {
        await (firebase.functions().httpsCallable('reviewRequisition'))({
          id: requisition.id,
          action: action === 'reject' ? 'reject' : 'approve',
          note: text
        })
        if (['approve', 'confirm'].includes(action)) {
          if (currentStep < requisition.steps.length - 1) {
            setLoadingApprove(false)
          }
        } else {
          setLoadingReject(false)
        }
      } catch (ex) {
        setLoadingApprove(false)
        setLoadingReject(false)
        console.log(ex)
      }
    }
  }

  function decodeModifyLog(log) {
    log = log.replace(/f{modify}/g, formatMessage({ id: 'step.action.modify' }))
    log = log.replace(/f{update}/g, formatMessage({ id: 'step.action.update' }))
    log = log.replace(/f{add}/g, formatMessage({ id: 'step.action.add' }))
    log = log.replace(/f{remove}/g, formatMessage({ id: 'step.action.remove' }))
    log = log.replace(/f{expectedDate}/g, formatMessage({ id: 'requisition.table.detail.expectedDate' }))
    log = log.replace(/f{note}/g, formatMessage({ id: 'requisition.table.detail.note' }))
    log = log.replace(/f{merchandise}/g, formatMessage({ id: 'requisition.table.detail.merchandise' }))
    log = log.replace(/f{unitPrice}/g, formatMessage({ id: 'requisition.merchandise.unitPrice' }))
    log = log.replace(/f{amount}/g, formatMessage({ id: 'requisition.merchandise.amount' }))

    let matchs = [...new Set((log.match(/i{.+?}/g) || []).map(i => i.substring(2, i.length - 1)))]
    for (const m of matchs) {
      log = log.replace((new RegExp(`i{${m}}`, 'g')), merchandiseMapping[m].name)
    }
    return log
  }

  const ls = requisition.status !== 'void' ? requisition.steps.slice(currentStep, requisition.steps.length).map(s => ({ ...s })) : []
  if (requisition.status !== 'void' && requisition.lock && currentStep === 0) {
    ls[0].name = '確認修改內容'
  }
  const steps = [...requisitionHistory].concat(ls)
  for (const step of steps) {
    if (step.action === 'modify') {
      step.detail = decodeModifyLog(step.note)
    }
    if (step.dateTime) {
      const s = step.dateTime.split(' ')
      step.text = step.name + `\n${userMapping[step.user]?.displayName}[${formatMessage({ id: 'step.action.' + step.action })}]`
      step.text += `\n日期: ${s[0]}`
      step.text += `\n時間: ${s[1]}`
      if (step.action === 'modify') {
        step.text += `${step.detail ? '\n' : ''}`
      } else {
        step.text += `${step.note ? '\n備註: ' + step.note : ''}`
      }
    } else {
      step.text = step.name
      if (step.users) {
        step.hint = step.users.map(u => userMapping[u]?.displayName || '').join(' / ')
      }
    }
    if (['reject', 'void'].includes(step.action)) {
      // step.stepProps = {completed: false}
      step.labelProps = { error: true }
    }
  }
  const lastHistory = requisition.history[requisition.history.length - 1]

  function allowEditing() {
    if (requisition.lock) {
      if ((currentStep === 0 || requisition.status === 'done') && requisition.lock && requisition.steps[0].overwrite.includes(currentUser.key)) {
        return true
      }
    } else {
      if (((currentStep === 1 && lastHistory.action !== 'reject') || currentStep === 0) && requisition.steps[0].users.includes(currentUser.key)) {
        return true
      }
    }
    return false
  }

  async function addToShoppingCart() {
    lockSubmitButton(true)
    setLoadingShoppingCart(true)
    try {
      await (firebase.functions().httpsCallable('addToShoppingCart'))({ id: requisition.id })
    } catch (ex) {
      console.log(ex)
      lockSubmitButton(false)
      setLoadingShoppingCart(false)
      return
    }

    lockSubmitButton(false)
    setLoadingShoppingCart(false)

    setSnackPack((prev) => [
      ...prev,
      {
        message: formatMessage({ id: 'requisition.message.addToShoppingCart' }, { id: requisition.sn }),
        key: new Date().getTime()
      }
    ]);
  }

  const handleMessageClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMessage(false);
  };

  const handleMessageExited = () => {
    setMessageInfo(undefined);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const printSteps = []
  for (const step of steps) {
    if (printSteps.length === 0 || step.action === 'reject' || step.action === 'approve' || (step.action === undefined && step.name !== printSteps[0].name)) {
      const s = { ...step }
      if (s.dateTime) {
        const dt = s.dateTime.split(' ')
        s.text = `${printSteps.length === 0 ? '申請人' : '簽核人'}: ${userMapping[s.user]?.displayName}`
        s.text += `\n\n日期: ${dt[0]}`
      } else {
        s.text = '簽核人: \n\n日期:'
      }

      if (printSteps.length === 0) {
        printSteps[0] = s
      } else if (s.action === 'approve') {
        printSteps[s.step] = s
      } else if (s.action === 'reject') {
        printSteps.length = s.step
      } else if (s.action === undefined) {
        printSteps[printSteps.length] = s
      }
    }
  }

  return (
    <div style={{ flexGrow: 1, height: '100%' }}>
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` }) + (requisition.void ? formatMessage({ id: 'button.void' }) : '') + formatMessage({ id: 'requisition.name' })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'requisition.table.detail.note' })}
        action={dialogData.action}
      />}
      {statusInfo !== '' && <Dialog onClose={() => setStatusInfo('')} open={true}>
        <Box p={3}>
          <div style={{ whiteSpace: 'pre-line' }}>
            {statusInfo}
          </div>
        </Box>
      </Dialog>}

      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openMessage}
        autoHideDuration={2000}
        onClose={handleMessageClose}
        TransitionProps={{ onExited: handleMessageExited }}
        message={messageInfo ? messageInfo.message : undefined}
      />

      <div style={{ display: 'none' }}>
        <Box p={1} ref={componentRef}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h6" style={{ textAlign: 'center' }}>
                {requisition.sourceFullName}<FormattedMessage id="requisition.print.title" />
              </Typography>
            </Grid>
            {printSourceFields.map(f => <Grid item key={f.text} xs={f.size} sm={f.size} md={f.size}>
              <FormattedMessage id={`requisition.print.${f.text}`} />: {f.text === 'createdBy' ? requisition[f.text].id : requisition[f.text]}
            </Grid>)}
            <Grid item xs={12} sm={12} md={12}>
              <Divider />
            </Grid>
          </Grid>
          <Divider style={{ margin: '8px 0px' }} />
          <EnhancedTable
            size="small"
            defaultOrder="asc"
            defaultOrderField="code"
            headerCells={printHeaderCells}
            rowCells={printRowCells}
            tableData={requisition.merchandises.map(m => formatData(m))}
          />
          <PrintSteps>
            {printSteps.map((step, idx) => (
              <Paper key={`${step.name}_${idx}`} variant="outlined" square >
                {step.text}
              </Paper>
            ))}
          </PrintSteps>
          <Divider style={{ margin: '8px 0px' }} />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                type="text"
                label={formatMessage({ id: 'purchaseOrder.table.detail.note' })}
                value={requisition.note}
                fullWidth
                size="small"
                variant="standard"
                readOnly
              />
            </Grid>
          </Grid>
        </Box>
      </div>

      <Box p={0}>
        <ProgressStep activeStep={requisition.history.length} steps={steps} />
        <EnhancedTable
          size="small"
          defaultOrder="asc"
          defaultOrderField="code"
          headerCells={headerCells}
          rowCells={rowCells}
          tableData={requisition.merchandises.map(m => formatData(m))}
        />
        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label="備註"
              value={requisition.note}
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
          <Grid item key="buttons" xs={12} sm={12} md={12}>
            <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                color='primary'
                onClick={handlePrint}
              >
                <FormattedMessage id="button.print" />
              </Button>
              {currentUser.userRights['purchaseOrder-create'] && requisition.status === 'done' && currentUser.purchaseOrderSource.includes(requisition.source) && needPurchase() &&
                <LoadingButton
                  color="primary"
                  onClick={addToShoppingCart}
                  disabled={loadingShoppingCart}
                  loading={loadingShoppingCart}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="requisition.table.addToShoppingCart" />
                </LoadingButton>
              }
              {allowEditing() && !requisition.void &&
                <Button
                  variant="contained"
                  color="primary"
                  onClick={edit}
                  disabled={loadingApprove || loadingReject || loadingShoppingCart}
                >
                  <FormattedMessage id="button.edit" />
                </Button>
              }
              {currentStep > 0 && currentStep < requisition.steps.length && requisition.steps[currentStep].users.includes(currentUser.key) && !requisition.void && !requisition.lock &&
                <LoadingButton
                  color="error"
                  onClick={reject}
                  disabled={loadingApprove || loadingReject || loadingShoppingCart}
                  loading={loadingReject}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.reject" />
                </LoadingButton>
              }
              {currentStep > 0 && currentStep < requisition.steps.length && requisition.steps[currentStep].users.includes(currentUser.key) && requisition.status !== 'void' && !requisition.lock &&
                <LoadingButton
                  color="success"
                  onClick={approve}
                  disabled={loadingApprove || loadingReject || loadingShoppingCart}
                  loading={loadingApprove}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.approve" />
                </LoadingButton>
              }
              {currentStep < requisition.steps.length && requisition.steps[currentStep].users.includes(currentUser.key) && requisition.lock &&
                <LoadingButton
                  color="success"
                  onClick={confirm}
                  disabled={loadingApprove || loadingReject || loadingShoppingCart}
                  loading={loadingApprove}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.confirm" />
                </LoadingButton>
              }
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

// RequisitionView.defaultProps = {

// }

RequisitionView.propTypes = {
  requisition: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
};

export default RequisitionView;
