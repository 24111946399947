import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import ContextStore from '../../modules/context';

function Notification() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)

  const location = useLocation();

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.notification' })
    }])
    return () => {
    };
  }, [location.pathname]);

  return (
    <div></div>
  );
}

export default Notification;
