import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import EnhancedTableHead from './EnhancedTableHead';
import CustomTableRow from './CustomTableRow';
import SearchBox from './SearchBox';
import { getComparator, stableSort } from '../modules/sort';
import { useFirestoreDataAndMapping } from '../modules/uitls';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 12 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function SelectProductDialog({ defaultSelectedItems, handleClose, handleSave, customer, defaultCategoryFilter = {}, loadStockMapping, hideFields = [], ignoreIds, allowIds, size = 'xl', productData }) {
  const { formatMessage } = useIntl()
  const products = productData
    .filter(m => !allowIds || allowIds.includes(m.id))
    .filter(m => !ignoreIds.includes(m.id)).map(m => {
      return {
        ...m,
      }
    })

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const [selectedItems, setSelectedItems] = useState(defaultSelectedItems.reduce((acc, cur) => {acc[cur.id] = true; return acc;}, {}));

  const [currentFilter, setCurrentFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState({ ...defaultCategoryFilter, tags: defaultCategoryFilter.tags || [] });
  const [customerMapping] = useFirestoreDataAndMapping('customers')

  const filteredTags = [...new Set(
    (categoryFilter.type ?  products.filter(p => p.type.toLowerCase().includes(categoryFilter.type.toLowerCase())) : products).reduce((acc, cur) => acc.concat(cur.tags || []), [])
  )].sort()

  const filteredProducts = filterProducts()
  const filteredselection = filteredProducts.filter(s => selectedItems[s.id]).reduce((acc, cur) => {acc[cur.id] = true;return acc;}, {})

  const _headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'name', sort: 'name' },
    { text: 'customers', sort: 'customers' },
    { text: 'cost' },
  ]

  const _rowCells = [
    { field: 'code' },
    { field: 'name', tooltip: 'name' },
    { field: 'customers' },
    { field: 'cost' },
  ]

  const headerCells = _headerCells.filter(i => !hideFields.includes(i.text))
    .map(c => {c.text = formatMessage({ id: `selectProductDialog.table.detail.${c.text}` });return c})

  const rowCells = _rowCells.filter(i => !hideFields.includes(i.field))

  const filterItems = [
    { name: 'name' },
    { name: 'code' },
    { name: 'cost' },
  ].map(i => {i.text = formatMessage({ id: `product.table.detail.${i.name}` });return i})

  const categoryFilterItems = [
    { name: 'type' },
    { name: 'tags' },
  ].map(i => {i.text = formatMessage({ id: `product.table.detail.${i.name}` });return i})

  const formatData = (product) => {
    const newData = { ...product }
    const customers = Object.keys(product.customers || []).filter(c => customerMapping[c])
    newData.customers = customers.map(s => customerMapping[s]).map(s => s.nickname).join(', ')

    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredProducts.reduce((acc, cur) => {acc[cur.id] = true;return acc}, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  };

  const handleCheckboxClick = useCallback(id => {
    setSelectedItems(selectedItems => {
      if (!!selectedItems[id]) {
        const newSelecteds = { ...selectedItems }
        delete newSelecteds[id]
        return newSelecteds
      } else {
        return { ...selectedItems, [id]: true }
      }
    })
  }, [])

  function filterProducts() {
    if (!currentFilter && !categoryFilter.type && categoryFilter.tags.length === 0) {
      return products
    }
    let items = [...products]
    if (categoryFilter.type) {
      const type = categoryFilter.type.toUpperCase()
      items = items.filter(i => i.type === type)
    }
    if (categoryFilter.tags.length > 0) {
      items = items.filter(i => categoryFilter.tags.every(tag => (i.tags || []).includes(tag)))
    }
    if (currentFilter) {
      items = items.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
    return items
  }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  function getMenuItem(name) {
    if (name === 'type') {
      return [
        { value: '' },
        { value: 'beauty', header: true }, { value: 'AM' }, { value: 'OP' }, { value: 'SK' }, { value: 'DP' },
        { value: 'preventative', header: true }, { value: 'CN' }, { value: 'EX' }, { value: 'TT' }, { value: 'GE' }, { value: 'SU' },
        { value: 'ND' }, { value: 'CU' },
        { value: 'other', header: true }, { value: 'OT' }].map(i => {
        i.label = i.value ? formatMessage({ id: `product.type.${i.value}` }) : formatMessage({ id: 'product.category.all' })
        return i
      })
    }
    return []
  }

  function updateCategoryFilter(name, value) {
    const newFilter = { ...categoryFilter }
    newFilter[name] = value
    setCategoryFilter(newFilter)
  }

  const getFilter = (filter) => {
    if (filter.name === 'tags') {
      return <Grid item key={filter.name} xs={3} sm={3} md={6}>
        <FormControl size="small" fullWidth>
          <InputLabel>{formatMessage({ id: 'product.table.detail.tags' })}</InputLabel>
          <Select
            multiple
            value={categoryFilter.tags}
            onChange={e => { updateCategoryFilter(filter.name, e.target.value) }}
            input={<OutlinedInput label={formatMessage({ id: 'product.table.detail.tags' })} />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {filteredTags.map((tag) => (
              <MenuItem key={tag} value={tag}>
                <Checkbox checked={categoryFilter.tags.includes(tag)} />
                <ListItemText primary={tag} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    } else {
      return <Grid item key={filter.name} xs={3} sm={3} md={3}>
        <TextField
          select
          type="text"
          size="small"
          label={filter.text}
          variant="outlined"
          onChange={e => {updateCategoryFilter(filter.name, e.target.value)}}
          value={categoryFilter[filter.name] || ''}
          fullWidth
        >
          {getMenuItem(filter.name).map((option, idx) => {
            return option.header ?
              (<ListSubheader key={`${option.value}-${idx}`}>{option.label}</ListSubheader>) :
              (<MenuItem key={`${option.value}-${idx}`} value={option.value}>{option.label}</MenuItem>)
          })}
        </TextField>
      </Grid>
    }
  }

  const onApply = () => {
    const selectedProducts = products.filter(s => selectedItems[s.id]).map(s => ({
      code: s.code,
      cost: s.cost,
      name: s.name,
      customers: s.customers,
      id: s.id,
    }))
    handleSave(selectedProducts)
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={size}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title"><FormattedMessage id="selectProductDialog.title" /></DialogTitle>
      <DialogContent dividers={true}>
        <div style={{ flexGrow: 1 }}>
          <Toolbar sx={{ pl: 2, pr: 1 }}>
            <Typography sx={{ flex: '1 1 100%', flexShrink: 2 }} variant="h6" id="tableTitle" component="div">
              <FormattedMessage id="product.table.title" />
            </Typography>
            <Grid container spacing={3}>
              {categoryFilterItems.map(f => getFilter(f))}
            </Grid>
            <SearchBox autoFocus filterItems={filterItems} onFilterChanged={onFilterChanged} />
          </Toolbar>
          <TableContainer component={Paper}>
            <Table>
              <EnhancedTableHead
                headerCells={headerCells}
                numSelected={Object.keys(filteredselection).length}
                order={order}
                orderBy={orderBy}
                onCheckboxClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={filteredProducts.length}
              />
              <TableBody>
                {stableSort(filteredProducts.map(m => formatData(m)), getComparator(order, orderBy)).map(product => (
                  <CustomTableRow
                    key={product.id}
                    rowCells={rowCells}
                    cellData={product}
                    onCheckboxClick={(e) => handleCheckboxClick(product.id)}
                    selected={selectedItems[product.id] || false}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button variant="contained" onClick={onApply} color="primary">
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SelectProductDialog.propTypes = {
  customer: PropTypes.string.isRequired,
  ignoreIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  allowIds: PropTypes.arrayOf(PropTypes.string),
  // ignoreSuppliers: PropTypes.arrayOf(PropTypes.string),
  // highPrioritySuppliers: PropTypes.arrayOf(PropTypes.string),
  defaultCategoryFilter: PropTypes.object,
  defaultSelectedItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    nickname: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })),
  hideFields: PropTypes.arrayOf(PropTypes.string),
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  loadStockMapping: PropTypes.bool,
  size: PropTypes.string,
  productData: PropTypes.arrayOf(PropTypes.object)
};

export default SelectProductDialog;
