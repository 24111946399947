import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';

import { styled } from '@mui/material/styles';

import TabContainer from '../../containers/TabContainer/TabContainer';
import ContextStore from '../../modules/context';
import SyncProductView from './SyncProductView'
import { useFirestoreData, sortByField, unwrap } from '../../modules/uitls';

const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
}));

function SyncProduct() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const customers = useFirestoreData('customers').slice().sort(sortByField('code'))
  const filteredCustomers = customers.filter(c => c.code !== 'S000001')

  const { customerId } = useParams()
  const [productMapping, setProductMapping] = useState({})

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('products').onSnapshot(snapshot => {
      const productMapping = {}
      snapshot.forEach(doc => {
        productMapping[doc.id] = { id: doc.id, ...unwrap(doc.data()) }
      })

      setProductMapping(productMapping)
    }, err => { })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.product.customer' })
    }])
    return () => {
    };
  }, [location.pathname]);


  const onTabSelected = (tabIndex) => {
    if (filteredCustomers.length > tabIndex) {
      navigate(`/products/customer/${filteredCustomers[tabIndex].id}`);
    }
  };

  const tabs = filteredCustomers.sort((a, b) => {
    if (a.nickname > b.nickname) {
      return 1
    } else if (a.nickname < b.nickname) {
      return -1
    } else {
      return 0
    }
  }).map(c => ({ label: c.nickname, component: <SyncProductView customerId={c.id} productMapping={productMapping} /> }))
  const ti = filteredCustomers.findIndex(c => c.id === customerId)
  const defaultSelect = ti === -1 ? 0 : ti

  return (
    <Root>
      <TabContainer defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} />
    </Root>
  );
}

export default SyncProduct;
