const i18n = {
  'stockRequisition': {
    'tab': {
      'pending': '等候中',
      'done': '已完成',
      'void': '已作廢',
    },
    'name': '沙貨領用單',
    'table': {
      'pending': {
        'title': '沙貨領用單列表 - 待簽核',
      },
      'title': '沙貨領用單列表',
      'selected': '已選擇 {count} 筆沙貨領用單',
      'header': {
        'sn': '領用單號',
        'source': '領用單位',
        'sourceName': '領用單位',
        'createdBy': '領用人',
        'createdAt': '建立日期',
        'date': '領用日期',
      },
      'detail': {
        'sn': '領用單號',
        'source': '領用單位',
        'sourceName': '領用單位',
        'createdBy': '領用人',
        'createdAt': '建立日期',
        'date': '領用日期',
        'note': '備註',
        'merchandise': '商品',
      },
    },
    'dialog': {
      'title': {
        'add': '新增沙貨領用單',
        'edit': '編輯沙貨領用單',
      }
    },
    'merchandise': {
      'code': '品號',
      'name': '品名',
      'unitPrice': '單價',
      'stock': '庫存數量',
      'amount': '領用數量',
      'sku': '單位',
      'price': '金額',
      'note': '備註',
      'statusText': '狀態',
    }
  },
  'editStockRequisition': {
    'table': {
      'title': '領用商品清單',
      'selected': '已選擇 {count} 個項目',
    },
    'selectStockItems': '選擇庫存',
    'addMerchandise': '新增商品',
    'removeMerchandise': '移除商品',
  }
}
export default i18n
