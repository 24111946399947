import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import EnhancedTable from '../../components/EnhancedTable';
import ActionDialog from '../../components/ActionDialog';
import ProgressStep from '../../components/ProgressStep';
import DeliveryDetail from './DeliveryDetail';
import ContextStore from '../../modules/context';
import { TAX_RATE } from '../../constants/index';

const PrintSteps = styled('div')(({ theme }) => ({
  display: 'flex',
  '& > *': {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    width: theme.spacing(20),
    height: theme.spacing(11),
    whiteSpace: 'pre-line',
  }
}));

const printSourceFields = [
  { text: 'address', size: 6 },
  { text: 'phone', size: 3 },
  { text: 'businessNumber', size: 3 },
]
const printFormFields = [
  { text: 'shippingAddress', size: 6 },
  { text: 'contactPhone', size: 3 },
  { text: 'contactName', size: 3 },
  { text: 'sn', size: 3 },
  { text: 'date', size: 3 },
  { text: 'expectedDate', size: 3 },
  { text: 'createdBy', size: 3 },
]
const printSupplierFields = [
  { text: 'supplierFullName', size: 3 },
  { text: 'billRule', size: 3 },
  { text: 'supplierPhone', size: 3 },
  { text: 'supplierContactName', size: 3 },
]

function PurchaseOrderView({ purchaseOrder, userMapping, merchandiseMapping }) {
  const { formatMessage } = useIntl()
  const { currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const componentRef = useRef();
  const [dialogData, setDialogData] = useState(null);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [deliveryInfo, setDeliveryInfo] = useState(null);
  const [relatedReceipts, setRelatedReceipts] = useState(null);

  const purchaseOrderHistory = purchaseOrder.history || []
  const currentStep = purchaseOrderHistory.length > 0 ? purchaseOrderHistory[purchaseOrderHistory.length - 1].step : 0

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('receipts').where('purchaseOrderId', '==', purchaseOrder.id).onSnapshot(snapshot => {
      const relatedReceipts = []
      snapshot.forEach(doc => {
        const data = doc.data()
        if (data.status !== 'void') {
          relatedReceipts.push(doc.id)
        }
      });
      setRelatedReceipts(relatedReceipts)
    }, err => {})
    return () => unsubscribe()
  }, []);

  const _headerCells = [
    // {text: 'code', sort: 'code', order: 0},
    { field: 'name', order: 1 },
    { field: 'amount', align: 'right', order: 2 },
    { field: 'orderUnit', align: 'right', order: 4 },
    { field: 'unitPrice', align: 'right', order: 5 },
    { field: 'price', align: 'right', order: 6 },
    { field: 'note', align: 'right', order: 7 },
  ]

  const _rowCells = [
    // {field: 'code', order: 0, type: 'info', onButtonClick: showDeliveryDetailText},
    // {field: 'nickname', tooltip: 'name', order: 1},
    { field: 'amount', align: 'right', order: 2 },
    { field: 'orderUnit', align: 'right', order: 4 },
    { field: 'unitPrice', align: 'right', order: 5 },
    { field: 'price', align: 'right', type: 'calculate', calculate: 'unitPrice*amount->Round', order: 6 },
    { field: 'note', align: 'right', order: 7 },
  ]

  const headerCells = (purchaseOrder.status === 'done' ?
    [..._headerCells,
      { field: 'code', sort: 'code', order: 0 },
      { field: 'delivery', align: 'right', order: 3 }
    ] :
    [..._headerCells,
      { field: 'code', sort: 'code', order: 0 }]
  ).map(c => {c.text = formatMessage({ id: `purchaseOrder.merchandise.${c.field}` });return c}).sort((a, b) => a.order - b.order)

  const rowCells = (purchaseOrder.status === 'done' ?
    [..._rowCells,
      { field: 'code', order: 0, type: 'info', onButtonClick: showDeliveryDetailText },
      { field: 'nickname', tooltip: 'name', order: 1 },
      { field: 'delivery', align: 'right', order: 3 }
    ] :
    [..._rowCells,
      { field: 'code', order: 0, type: 'info', onButtonClick: showDeliveryDetailText },
      { field: 'nickname', tooltip: 'name', order: 1 },
    ]
  ).sort((a, b) => a.order - b.order)

  const printHeaderCells = ([
    ..._headerCells,
  ]).map(c => ({
    ...c,
    text: formatMessage({ id: `purchaseOrder.print.merchandise.${c.field}` })
  })).sort((a, b) => a.order - b.order)

  const printRowCells = ([
    ..._rowCells,
    { field: 'name', order: 1 },
  ]).sort((a, b) => a.order - b.order)

  const invoiceSubtotal = purchaseOrder.merchandises.reduce((acc, cur) => {
    acc += Math.round(cur.unitPrice * Math.max(cur.moq || 0, cur.amount))
    return acc;
  }, 0)

  const invoiceTaxes = purchaseOrder.taxIncluded ? 0 : Math.round(TAX_RATE * invoiceSubtotal);
  const invoiceTotal = invoiceTaxes + invoiceSubtotal;

  const formatData = (merchandise, delivery) => {
    const newData = {
      ...merchandise,
      code: merchandiseMapping[merchandise.id].code,
      name: merchandiseMapping[merchandise.id].name,
      nickname: merchandiseMapping[merchandise.id].nickname
    }
    newData.require = newData.amount + newData.orderUnit
    if (newData.moq && newData.amount < newData.moq) newData.amount = newData.moq
    if (newData.eid.endsWith('@extra')) {
      newData.delivery = newData.amount
    } else {
      newData.delivery = delivery ? (delivery[merchandise.id] || 0) : 0
    }
    return newData
  }

  function showDeliveryDetailText(merchandiseId, field) {
    if (merchandiseMapping[merchandiseId].warehousing) {
      setDeliveryInfo({
        refId: purchaseOrder.id,
        merchandiseId,
        customerId: purchaseOrder.source,
      })
    } else {
      setDeliveryInfo({
        refId: purchaseOrder.id,
        merchandiseId,
        defaultMessage: '非入庫商品',
      })
    }
  }

  function edit() {
    navigate(`/purchase/purchaseOrder/edit/${purchaseOrder.id}`);
  }

  function reject() {
    setDialogData({ action: 'reject', title: '' })
  }

  function approve() {
    setDialogData({ action: 'approve', title: '' })
  }

  function closeout() {
    setDialogData({ action: 'closeout', title: '' })
  }

  function abandon() {
    setDialogData({ action: 'void', title: '' })
  }

  function confirm() {
    setDialogData({ action: 'confirm', title: '' })
  }

  function handleClose() {
    setDialogData(null)
  }

  async function handleExecute(data) {
    const { action, text } = data
    setDialogData(null)
    if (['approve', 'reject', 'closeout', 'void', 'confirm'].includes(action)) {
      if (['approve', 'closeout', 'void', 'confirm'].includes(action)) {
        setLoadingApprove(true)
      } else {
        setLoadingReject(true)
      }
      try {
        await (firebase.functions().httpsCallable('reviewPurchaseOrder'))({
          id: purchaseOrder.id,
          action: action === 'confirm' ? 'approve' : action,
          note: text
        })
        if (['approve', 'closeout', 'confirm'].includes(action)) {
          if (currentStep < purchaseOrder.steps.length - 1) {
            setLoadingApprove(false)
          }
        } else {
          setLoadingReject(false)
        }
      } catch (ex) {
        console.log(ex)
        setLoadingApprove(false)
        setLoadingReject(false)
      }
    }
  }

  function decodeModifyLog(log) {
    log = log.replace(/f{modify}/g, formatMessage({ id: 'step.action.modify' }))
    log = log.replace(/f{update}/g, formatMessage({ id: 'step.action.update' }))
    log = log.replace(/f{add}/g, formatMessage({ id: 'step.action.add' }))
    log = log.replace(/f{remove}/g, formatMessage({ id: 'step.action.remove' }))
    log = log.replace(/f{expectedDate}/g, formatMessage({ id: 'purchaseOrder.table.detail.expectedDate' }))
    log = log.replace(/f{note}/g, formatMessage({ id: 'purchaseOrder.table.detail.note' }))
    log = log.replace(/f{merchandise}/g, formatMessage({ id: 'purchaseOrder.table.detail.merchandise' }))
    log = log.replace(/f{unitPrice}/g, formatMessage({ id: 'purchaseOrder.merchandise.unitPrice' }))
    log = log.replace(/f{amount}/g, formatMessage({ id: 'purchaseOrder.merchandise.amount' }))
    log = log.replace(/f{extraMerchandises}/g, formatMessage({ id: 'purchaseOrder.table.detail.extraMerchandises' }))

    let matchs = [...new Set((log.match(/i{.+?}/g) || []).map(i => i.substring(2, i.length - 1)))]
    for (const m of matchs) {
      log = log.replace((new RegExp(`i{${m}}`, 'g')), merchandiseMapping[m].name)
    }
    return log
  }

  const steps = [...purchaseOrder.history].concat(purchaseOrder.status !== 'void' ? purchaseOrder.steps.slice(currentStep, purchaseOrder.steps.length) : [])
  const printSteps = []
  for (const step of steps) {
    if (printSteps.length === 0 || step.action === 'reject' || step.action === 'approve' || (step.action === undefined && step.name !== printSteps[0].name)) {
      const s = { ...step }
      if (s.dateTime) {
        const dt = s.dateTime.split(' ')
        s.text = `${printSteps.length === 0 ? '申請人' : '簽核人'}: ${userMapping[s.user]?.displayName}`
        s.text += `\n\n日期: ${dt[0]}`
      } else {
        s.text = '簽核人: \n\n日期:'
      }

      if (printSteps.length === 0) {
        printSteps[0] = s
      } else if (s.action === 'approve') {
        printSteps[s.step] = s
      } else if (s.action === 'reject') {
        printSteps.length = s.step
      } else if (s.action === undefined) {
        printSteps[printSteps.length] = s
      }
    }
  }

  for (const step of steps) {
    if (step.action === 'modify') {
      step.detail = decodeModifyLog(step.note)
    }
    if (step.dateTime) {
      const s = step.dateTime.split(' ')
      step.text = step.name + `\n${userMapping[step.user]?.displayName || '系統自動'}[${formatMessage({ id: 'step.action.' + step.action })}]`
      step.text += `\n日期: ${s[0]}`
      step.text += `\n時間: ${s[1]}`
      if (step.action === 'modify') {
        step.text += `${step.detail ? '\n' : ''}`
      } else {
        step.text += `${step.note ? '\n備註: ' + step.note : ''}`
      }
    } else {
      step.text = step.name
      if (step.users) {
        step.hint = step.users.map(u => userMapping[u]?.displayName || '').join(' / ')
      }
    }
    if (['reject', 'void'].includes(step.action)) {
      // step.stepProps = {completed: false}
      step.labelProps = { error: true }
    }
  }
  const lastHistory = purchaseOrder.history[purchaseOrder.history.length - 1]

  function allowEditing() {
    if (!purchaseOrder.lock) {
      if (((currentStep === 1 && lastHistory.action !== 'reject') || currentStep === 0) && purchaseOrder.steps[0].users.includes(currentUser.key)) {
        return true
      }
    }
    return false
  }

  function allowVoid() {
    //條件:
    // 1. 已完成簽核
    // 2. 還未作廢且還未結單
    // 3. 如果完全沒有進貨或出貨
    // 4. 使用者有權限
    if (purchaseOrder.status === 'done' &&
      !purchaseOrder.closeout && !purchaseOrder.void &&
      purchaseOrder.steps[0].overwrite.includes(currentUser.key) &&
      relatedReceipts && relatedReceipts.length === 0) {
      return true
    }
    return false
  }

  return (
    <div style={{ flexGrow: 1, height: '100%' }}>
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` }) + (purchaseOrder.void ? formatMessage({ id: 'button.void' }) : '') + formatMessage({ id: 'purchaseOrder.name' })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'purchaseOrder.table.detail.note' })}
        action={dialogData.action}
      />}

      {deliveryInfo &&
        <DeliveryDetail info={deliveryInfo} refId="purchaseOrderId" onClose={() => setDeliveryInfo(null)} />
      }
      <div style={{ display:'none' }}>
        <Box p={1} ref={componentRef}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h6" style={{ textAlign: 'center' }}>
                {purchaseOrder.sourceFullName}<FormattedMessage id="purchaseOrder.print.title" />
              </Typography>
            </Grid>
            {printSourceFields.map(f => <Grid item key={`print.source.${f.text}`} xs={f.size} sm={f.size} md={f.size}>
              <FormattedMessage id={`purchaseOrder.print.${f.text}`} />: {purchaseOrder[f.text]}
            </Grid>)}
            <Grid item xs={12} sm={12} md={12}>
              <Divider />
            </Grid>
            {printFormFields.map(f => <Grid item key={`print.${f.text}`} xs={f.size} sm={f.size} md={f.size}>
              <FormattedMessage id={`purchaseOrder.print.${f.text}`} />: {purchaseOrder[f.text]}
            </Grid>)}
            <Grid item xs={12} sm={12} md={12}>
              <Divider />
            </Grid>
            {printSupplierFields.map(f => <Grid item key={`print.supplier.${f.text}`} xs={f.size} sm={f.size} md={f.size}>
              <FormattedMessage id={`purchaseOrder.print.${f.text}`} />: {purchaseOrder[f.text]}
            </Grid>)}
          </Grid>
          <Divider style={{ margin: '8px 0px' }} />
          <EnhancedTable
            size="small"
            defaultOrder="asc"
            defaultOrderField="code"
            headerCells={printHeaderCells}
            rowCells={printRowCells}
            tableData={purchaseOrder.merchandises.map(m => formatData(m, purchaseOrder.delivery))}
            extRows={
              <>
                <TableRow>
                  <TableCell rowSpan={3} colSpan={3}/>
                  <TableCell align="right">
                    <Typography variant="subtitle2">
                      <FormattedMessage id="invoice.subtotal" />
                    </Typography>
                  </TableCell>
                  <TableCell align="right">{invoiceSubtotal}</TableCell>
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell align="right">
                    <Typography variant="subtitle2">
                      <FormattedMessage id="invoice.tax" />
                    </Typography>
                  </TableCell>
                  <TableCell align="right">{invoiceTaxes}</TableCell>
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell align="right">
                    <Typography variant="subtitle2">
                      <FormattedMessage id="invoice.total" />
                    </Typography>
                  </TableCell>
                  <TableCell align="right">{invoiceTotal}</TableCell>
                  <TableCell />
                </TableRow>
              </>
            }
          />
          <PrintSteps>
            {printSteps.map((step, idx) => (
              <Paper key={`${step.name}_${idx}`} variant="outlined" square >
                {step.text}
              </Paper>
            ))}
          </PrintSteps>
          <Divider style={{ margin: '8px 0px' }} />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                type="text"
                label={formatMessage({ id: 'purchaseOrder.table.detail.note' })}
                value={purchaseOrder.note}
                fullWidth
                size="small"
                variant="standard"
                readOnly
              />
            </Grid>
          </Grid>
        </Box>
      </div>

      <Box p={0}>
        <ProgressStep activeStep={purchaseOrder.history.length} steps={steps} />
        <EnhancedTable
          size="small"
          defaultOrder="asc"
          defaultOrderField="code"
          headerCells={headerCells}
          rowCells={rowCells}
          tableData={purchaseOrder.merchandises.map(m => formatData(m, purchaseOrder.delivery))}
          extRows={
            <>
              <TableRow>
                <TableCell rowSpan={3} colSpan={purchaseOrder.status === 'done' ? 5 : 4}/>
                <TableCell align="right">
                  <Typography variant="subtitle2">
                    <FormattedMessage id="invoice.subtotal" />
                  </Typography>
                </TableCell>
                <TableCell align="right">{invoiceSubtotal}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell align="right">
                  <Typography variant="subtitle2">
                    <FormattedMessage id="invoice.tax" />
                  </Typography>
                </TableCell>
                <TableCell align="right">{invoiceTaxes}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell align="right">
                  <Typography variant="subtitle2">
                    <FormattedMessage id="invoice.total" />
                  </Typography>
                </TableCell>
                <TableCell align="right">{invoiceTotal}</TableCell>
                <TableCell />
              </TableRow>
            </>
          }
        />
        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              type="text"
              label={formatMessage({ id: 'purchaseOrder.table.detail.shipping' })}
              value={purchaseOrder.shipping}
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
          <Grid item xs={12} sm={6} md={9}>
            <TextField
              type="text"
              label={formatMessage({ id: 'purchaseOrder.table.detail.note' })}
              value={purchaseOrder.note}
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
          <Grid item key="buttons" xs={12} sm={12} md={12}>
            <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
              {!purchaseOrder.void && <Button
                variant="contained"
                color="primary"
                onClick={handlePrint}
                disabled={loadingApprove || loadingReject || relatedReceipts === null}
              >
                <FormattedMessage id="button.print" />
              </Button>}
              {allowEditing() && !purchaseOrder.void &&
                <Button
                  variant="contained"
                  color="primary"
                  onClick={edit}
                  disabled={loadingApprove || loadingReject || relatedReceipts === null}
                >
                  <FormattedMessage id="button.edit" />
                </Button>
              }
              {currentStep > 0 && currentStep < purchaseOrder.steps.length && purchaseOrder.steps[currentStep].users.includes(currentUser.key) && !purchaseOrder.void &&
                <LoadingButton
                  color="error"
                  onClick={reject}
                  disabled={loadingApprove || loadingReject || relatedReceipts === null}
                  loading={loadingReject}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.reject" />
                </LoadingButton>
              }
              {currentStep > 0 && currentStep < purchaseOrder.steps.length && purchaseOrder.steps[currentStep].users.includes(currentUser.key) && purchaseOrder.status !== 'void' && !purchaseOrder.void &&
                <LoadingButton
                  color="success"
                  onClick={approve}
                  disabled={loadingApprove || loadingReject || relatedReceipts === null}
                  loading={loadingApprove}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.approve" />
                </LoadingButton>
              }
              {purchaseOrder.steps[0].overwrite.includes(currentUser.key) && purchaseOrder.status === 'done' && !purchaseOrder.closeout &&
                <LoadingButton
                  color="error"
                  onClick={closeout}
                  disabled={loadingApprove || loadingReject || relatedReceipts === null}
                  loading={loadingReject}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.closeout" />
                </LoadingButton>
              }
              {allowVoid() &&
                <LoadingButton
                  color="error"
                  onClick={abandon}
                  disabled={loadingApprove || loadingReject || relatedReceipts === null}
                  loading={loadingApprove}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.void" />
                </LoadingButton>
              }
              {currentStep < purchaseOrder.steps.length && purchaseOrder.steps[currentStep].users.includes(currentUser.key) && purchaseOrder.void &&
                <LoadingButton
                  color="success"
                  onClick={confirm}
                  disabled={loadingApprove || loadingReject || relatedReceipts === null}
                  loading={loadingApprove}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.confirm" />
                </LoadingButton>
              }
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

// PurchaseOrderView.defaultProps = {

// }

PurchaseOrderView.propTypes = {
  purchaseOrder: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
};

export default PurchaseOrderView;
