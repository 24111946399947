import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Toolbar from '@mui/material/Toolbar';

import EditUserDialog from './EditUserDialog';
import EnhancedTable from '../../components/EnhancedTable';
import SearchBox from '../../components/SearchBox';
import FabAdd from '../../components/FabAdd';
import { useFirestoreDataAndMapping } from '../../modules/uitls';
import ContextStore from '../../modules/context';
import UserView from './UserView';

function UserDetail({ user, ...props }) {
  const r = { ...user }
  return (
    <div style={{ padding: 15 }}>
      <UserView user={r} {...props} />
    </div>
  )
}

UserDetail.propTypes = {
  user: PropTypes.object.isRequired,
};

function UserList() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const [, users] = useFirestoreDataAndMapping('users')
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [flagFilter, setFlagFilter] = useState({});

  const filteredUsers = filterUsers()

  const filterItems = [
    { name: 'displayName' },
    { name: 'email' }
  ].map(i => {i.text = formatMessage({ id: `user.table.detail.${i.name}` });return i})

  const flagFilterItems = (currentUser.userRights.debugging ? [
    { name: 'status' },
  ] : [{ name: 'status' }]).map(i => {i.text = formatMessage({ id: `user.table.detail.${i.name}` });return i})

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.user' })
    }])
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    const sp = new URLSearchParams(location.search)

    let needUpdate = false
    let newFilter = {}
    for (const f of flagFilterItems) {
      const value = sp.get(f.name)
      if (value && value !== flagFilter[f.name]) {
        newFilter[f.name] = value
        needUpdate = true
      } else if (!value && flagFilter[f.name]) {
        needUpdate = true
      }
    }
    if (needUpdate) {
      setFlagFilter(newFilter)
    }
  }, [location.search]);

  const headerCells = [
    { text: 'name' },
    { text: 'email' },
    { text: 'employeeId' },
    { text: 'status' },
  ].map(c => {c.text = formatMessage({ id: `user.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'displayName' },
    { field: 'email' },
    { field: 'employeeId' },
    { field: 'activeStatus' },
  ]

  const onEditClick = async (user) => {
    const newData = { ...user }
    newData.userRights = { ...newData.userRights }
    setSelectedUser(newData)
  }

  const formatData = (user) => {
    const newData = { ...user }
    newData.userRights = { ...newData.userRights }

    if (newData.userRights) {
      const rights = Object.keys(newData.userRights);
      for (const r of rights) {
        if (r.endsWith('-*')) {
          let prefix = r.substring(0, r.indexOf('-')) + '-'
          if (newData.userRights[prefix + 'create'] !== false) {
            newData.userRights[prefix + 'create'] = true
          }
          if (newData.userRights[prefix + 'edit'] !== false) {
            newData.userRights[prefix + 'edit'] = true
          }
          if (newData.userRights[prefix + 'view'] !== false) {
            newData.userRights[prefix + 'view'] = true
          }
          delete newData.userRights[r]
        }
      }
    }


    newData.activeStatus = formatMessage({ id: `user.status.${newData.active ? 'enabled' : 'disabled'}` }  )
    return newData
  }

  const getNewUser = () => {
    return {
      id: 'new',
      name: '',
      email: '',
      employeeId: '',
      active: true,
    }
  }
  function filterUsers() {
    if (!currentFilter &&
        !flagFilter.status) {
      return users
    }
    let items = [...users]

    if (flagFilter.status) {
      const type = flagFilter.status === 'enabled'
      items = items.filter(i => i.active === type)
    }
    if (currentFilter) {
      items = items.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
    return items
  }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  function getMenuItem(name) {
    return [{ value: '' }, { value: 'enabled' }, { value: 'disabled' }].map(i => {
      i.label = i.value ? formatMessage({ id: `user.${name}.${i.value}` }) : formatMessage({ id: 'user.status.all' })
      return i
    })
  }

  function updateFlagFilter(name, value) {
    const newFilter = { ...flagFilter }
    newFilter[name] = value
    setFlagFilter(newFilter)
    const sp = new URLSearchParams(location.search)
    const str1 = sp.toString()
    if (value === '') {
      sp.delete(name)
    } else {
      sp.set(name, value)
    }
    const str2 = sp.toString()
    if (str1 !== str2) {
      navigate({ pathname: location.pathname, search: `?${str2}` }, { replace: true });
    }
  }

  const getFilter = (filter) => {
    return <Grid item key={filter.name} xs={3} sm={3} md={3}>
      <TextField
        select
        type="text"
        size="small"
        label={filter.text}
        variant="outlined"
        onChange={e => {updateFlagFilter(filter.name, e.target.value)}}
        value={flagFilter[filter.name] || ''}
        fullWidth
      >
        {getMenuItem(filter.name).map((option, idx) => {
          return option.header ?
            (<ListSubheader key={`${option.value}-${idx}`}>{option.label}</ListSubheader>) :
            (<MenuItem key={`${option.value}-${idx}`} value={option.value}>{option.label}</MenuItem>)
        })}
      </TextField>
    </Grid>
  }

  return (
    <div style={{ padding: '12px 24px 80px 24px' }}>
      {selectedUser &&
        <EditUserDialog
          user={selectedUser}
          handleClose={() => setSelectedUser(null)}
        />
      }
      {currentUser.userRights['vendor-create'] === true && <FabAdd onClick={() => setSelectedUser(getNewUser())} />}
      <Toolbar sx={{ pl: 2, pr: 1 }}>
        <Typography sx={{ flex: '1 1 100%', flexShrink: 2 }} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id="user.table.title" />
        </Typography>
        <Grid container spacing={3}>
          {flagFilterItems.map(f => getFilter(f))}
        </Grid>
        <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} updateUrlParams />
      </Toolbar>
      <EnhancedTable
        defaultOrder="asc"
        defaultOrderField="code"
        headerCells={headerCells}
        rowCells={rowCells}
        getExpandContent={user =>
          <UserDetail
            user={user}
            handleEditClick={() => onEditClick(user)}
          />
        }
        tableData={filteredUsers.map(i => formatData(i))}
      />
    </div>
  );
}

export default UserList;
