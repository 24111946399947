import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import ActionDialog from '../../components/ActionDialog';
import ProgressStep from '../../components/ProgressStep';
import { useFirestoreDataAndMapping } from '../../modules/uitls';
import { getComparator, stableSort } from '../../modules/sort';
import ContextStore from '../../modules/context';

const _headerCells = [
  { field: 'code', sort: 'code' },
  { field: 'name' },
  { field: 'amount', align: 'right' },
  { field: 'orderUnit', align: 'right' },
  { field: 'note', align: 'right' },
]

const _rowCells = [
  { field: 'code' },
  { field: 'nickname', tooltip: 'name' },
  { field: 'amount', align: 'right' },
  { field: 'orderUnit', align: 'right' },
  { field: 'note', align: 'right' },
]

function BorrowingOrderView({ borrowingOrder, userMapping, merchandiseMapping }) {
  const { formatMessage } = useIntl()
  const { currentUser } = useContext(ContextStore)
  const [dialogData, setDialogData] = useState(null);
  const [loadingVoid, setLoadingVoid] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');

  const [customerMapping] = useFirestoreDataAndMapping('customers')
  const borrowingOrderHistory = (borrowingOrder.history || []).map(h => ({ ...h }))
  // const currentStep = borrowingOrderHistory.length > 0 ? borrowingOrderHistory[borrowingOrderHistory.length - 1].step : 0

  const headerCells = _headerCells.map(c => {c.text = formatMessage({ id: `borrowingOrder.merchandise.${c.field}` });return c})
  const rowCells = _rowCells

  function formatData(merchandise) {
    return {
      ...merchandise,
      code: merchandiseMapping[merchandise.id].code,
      name: merchandiseMapping[merchandise.id].name,
      nickname: merchandiseMapping[merchandise.id].nickname,
      orderUnit: merchandise.orderUnit,
    }
  }

  function abandon() {
    setDialogData({ action: 'void', title: '' })
  }

  function handleClose() {
    setDialogData(null)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  async function handleExecute(data) {
    const { action, text } = data
    setDialogData(null)
    setLoadingVoid(true)
    try {
      await (firebase.functions().httpsCallable('reviewBorrowingOrder'))({
        id: borrowingOrder.id,
        action,
        note: text
      })
      setLoadingVoid(false)
    } catch (ex) {
      setLoadingVoid(false)
      console.log(ex)
    }
  }

  function decodeModifyLog(log) {
    log = log.replace(/f{modify}/g, formatMessage({ id: 'step.action.modify' }))
    log = log.replace(/f{update}/g, formatMessage({ id: 'step.action.update' }))
    log = log.replace(/f{add}/g, formatMessage({ id: 'step.action.add' }))
    log = log.replace(/f{remove}/g, formatMessage({ id: 'step.action.remove' }))
    log = log.replace(/f{expectedDate}/g, formatMessage({ id: 'borrowingOrder.table.detail.expectedDate' }))
    log = log.replace(/f{note}/g, formatMessage({ id: 'borrowingOrder.table.detail.note' }))
    log = log.replace(/f{merchandise}/g, formatMessage({ id: 'borrowingOrder.table.detail.merchandise' }))
    log = log.replace(/f{unitPrice}/g, formatMessage({ id: 'borrowingOrder.merchandise.unitPrice' }))
    log = log.replace(/f{amount}/g, formatMessage({ id: 'borrowingOrder.merchandise.amount' }))

    let matchs = [...new Set((log.match(/i{.+?}/g) || []).map(i => i.substring(2, i.length - 1)))]
    for (const m of matchs) {
      log = log.replace((new RegExp(`i{${m}}`, 'g')), merchandiseMapping[m].name)
    }
    return log
  }

  // const ls = borrowingOrder.status !== 'void' ? borrowingOrder.steps.slice(currentStep, borrowingOrder.steps.length).map(s => ({...s})) : []
  // if (borrowingOrder.status !== 'void' && borrowingOrder.lock && currentStep === 0) {
  //   ls[0].name = '確認修改內容'
  // }
  const steps = [...borrowingOrderHistory]
  for (const step of steps) {
    if (step.action === 'modify') {
      step.detail = decodeModifyLog(step.note)
    }
    if (step.dateTime) {
      const s = step.dateTime.split(' ')
      step.text = step.name + `\n${userMapping[step.user]?.displayName || '系統自動'}[${formatMessage({ id: 'step.action.' + step.action })}]`
      step.text += `\n日期: ${s[0]}`
      step.text += `\n時間: ${s[1]}`
      if (step.action === 'modify') {
        step.text += `${step.detail ? '\n' : ''}`
      } else {
        step.text += `${step.note ? '\n備註: ' + step.note : ''}`
      }
    } else {
      step.text = step.name
      if (step.users) {
        step.hint = step.users.map(u => userMapping[u]?.displayName || '').join(' / ')
      }
    }
    if (['reject', 'void'].includes(step.action)) {
      // step.stepProps = {completed: false}
      step.labelProps = { error: true }
    }
  }
  // const lastHistory = borrowingOrder.history[borrowingOrder.history.length - 1]

  // function allowEditing() {
  //   if (borrowingOrder.lock) {
  //     if ((currentStep === 0 || borrowingOrder.status === 'done') && borrowingOrder.lock && borrowingOrder.steps[0].overwrite.includes(currentUser.key)) {
  //       return true
  //     }
  //   } else {
  //     if (((currentStep === 1 && lastHistory.action !== 'reject') || currentStep === 0) && borrowingOrder.steps[0].users.includes(currentUser.key)) {
  //       return true
  //     }
  //   }
  //   return false
  // }

  return (
    <div style={{ flexGrow: 1, height: '100%' }}>
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` }) + (borrowingOrder.void ? formatMessage({ id: 'button.void' }) : '') + formatMessage({ id: 'borrowingOrder.name' })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'borrowingOrder.table.detail.note' })}
        action={dialogData.action}
      />}
      <Box p={0}>
        <ProgressStep activeStep={borrowingOrder.history.length} steps={steps} />
        <TableContainer component={Paper}>
          <Table size="small" aria-label="collapsible table">
            <EnhancedTableHead
              headerCells={headerCells}
              rowCount={borrowingOrder.merchandises.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(borrowingOrder.merchandises.map(m => formatData(m)), getComparator(order, orderBy)).map(merchandise => (
                <EnhancedTableRow
                  key={merchandise.id}
                  rowCells={rowCells}
                  cellData={merchandise}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'borrowingOrder.table.detail.note' })}
              value={borrowingOrder.note}
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
          <Grid item key="buttons" xs={12} sm={12} md={12}>
            <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
              {!borrowingOrder.lock && customerMapping[borrowingOrder.source].borrowing.borrowingOrder.includes(currentUser.key) &&
                <LoadingButton
                  color="error"
                  onClick={abandon}
                  disabled={loadingVoid}
                  loading={loadingVoid}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.void" />
                </LoadingButton>
              }
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

// BorrowingOrderView.defaultProps = {

// }

BorrowingOrderView.propTypes = {
  borrowingOrder: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
};

export default BorrowingOrderView;
