import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import WorkflowView from './WorkflowView';
import ContextStore from '../../modules/context';

const _fields = [
  // {name: 'code', sm: 6},
  { name: 'name', sm: 6 },
  { name: 'nickname', sm: 6 },
  { name: 'businessNumber', sm: 6 },
  { name: 'phone', sm: 6 },
  { name: 'fax', sm: 6 },
  { name: 'billRule', type: 'select', sm: 6 },
  { name: 'basicPurchaseAmount', type: 'text', sm: 6, md: 2 },
  { name: 'freeShippingAmount', type: 'text', sm: 6, md: 2 },
  { name: 'email', type: 'text', sm: 12, md: 6 },
  { name: 'address', type: 'text', sm: 12, md: 6 },
  { name: 'contact', type: '-', reference: 'contacts' },
  { name: 'contacts', type: 'array' },
  { name: 'owner', type: '-' },
  { name: 'ownerName', type: 'text', sm: 6 },
  { name: 'ownerPhone', type: 'text', sm: 6 },
  { name: 'ownerMobile', type: 'text', sm: 6 },
  { name: 'shipping', type: '-' },
  { name: 'shippingOut', type: 'text', sm: 12, md: 12 },
  { name: 'other', type: '-' },
  { name: 'note', type: 'multiline', multiline: true, sm: 6, md: 6 },
].map(field => {field.multiline = field.multiline || false; field.md = field.md || 3; return field})

function VendorView({ vendor, supplierMapping, handleEditClick, workflow }) {
  const { formatMessage } = useIntl()
  const { currentUser } = useContext(ContextStore)
  const [workflowView, setWorkflowView] = useState(false)

  const billRuleMapping = {
    '30': formatMessage({ id: 'supplier.billRule.rule1' }),
    '60': formatMessage({ id: 'supplier.billRule.rule2' }),
    '90': formatMessage({ id: 'supplier.billRule.rule3' }),
    'cashOnDelivery': formatMessage({ id: 'supplier.billRule.rule4' }),
    'ttInAdvance': formatMessage({ id: 'supplier.billRule.rule5' }),
  }

  const shippingOut = Object.keys(vendor.shippingOut || []).filter(c => supplierMapping[c]).map(c => supplierMapping[c]?.name || '').join(', ')

  const fields = []
  for (const field of _fields) {
    if (field.name === 'contacts') {
      for (const i in vendor.contacts) {
        fields.push({ name: 'contacts', subField: 'contactName', type: 'text', index: parseInt(i), sm: 6, md: 3 })
        fields.push({ name: 'contacts', subField: 'contactPhone', type: 'phone', index: parseInt(i), sm: 6, md: 3, mask: '(999)9999-9999#99999' })
        fields.push({ name: 'contacts', subField: 'contactMobile', type: 'phone', index: parseInt(i), sm: 6, md: 3, mask: '9999-999-999' })
        fields.push({ name: 'contacts', subField: 'contactNote', type: 'text', index: parseInt(i), sm: 6, md: 3 })
      }
    } else {
      fields.push({ ...field, required: field.required || false, md: field.md || 3 })
    }
  }

  function createField(field) {
    const value = field.subField ? (vendor[field.name][field.index][field.subField]) : vendor[field.name]
    let newValue = value
    if (field.name === 'billRule') {
      newValue = billRuleMapping[value]
    }

    if (field.type === '-') {
      return field.reference && vendor[field.reference].length === 0 ? null : <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
    }

    if (['phone', 'fax', 'contactPhone', 'ownerPhone'].includes(field.name)) {
      newValue = newValue.replace(/\s/g, '').replace(/#$/,'')
    }

    return <Grid item key={`${field.name}.${field.index || 0}.${field.subField || 0}`} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={formatMessage({ id: `supplier.table.detail.${field.subField || field.name}` })}
        value={field.name === 'shippingOut' ? shippingOut : newValue}
        fullWidth
        size="small"
        variant="standard"
        readOnly
      />
    </Grid>
  }

  return (
    <div style={{ flexGrow: 1, height: '100%' }}>
      <Grid container spacing={2}>
        {fields.map(field => createField(field))}
        {currentUser.userRights['vendor-edit'] === true && <Grid item key="buttons" xs={12} sm={6} md={6}>
          <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
            {vendor.internal && !workflowView &&
              <Button
                variant="contained"
                onClick={() => setWorkflowView(true)}
                color="primary"
              >
                <FormattedMessage id="supplier.showWorkflow" />
              </Button>
            }
            <Button variant="contained" onClick={() => handleEditClick()} color="primary">
              <FormattedMessage id="button.edit" />
            </Button>
          </Stack>
        </Grid>}
        {vendor.internal && workflowView && <WorkflowView vendor={vendor} editable={!workflow.lock && currentUser.userRights['vendor-edit']} />}
      </Grid>
    </div>
  )
}

VendorView.propTypes = {
  vendor: PropTypes.object.isRequired,
  supplierMapping: PropTypes.object.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  workflow: PropTypes.shape({
    lock: PropTypes.bool,
  }).isRequired,
};

export default VendorView;
