import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import Collapse from '@mui/material/Collapse';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import EnhancedTable from '../../components/EnhancedTable';
import SimpleTableToolbar from '../../components/SimpleTableToolbar';
import ExpandButton from '../../components/ExpandButton';
import SearchBox from '../../components/SearchBox';
import DoctorBonusView from './DoctorBonusView';
import SalesBonusView from './SalesBonusView';
import { unwrap, useFirestoreDataAndMapping } from '../../modules/uitls';
import ContextStore from '../../modules/context';
import SelectYMCustomerDialog from '../../components/SelectYMCustomerDialog';
import FabAdd from '../../components/FabAdd';

function BonusDetail({ bonusData, formName, ...props }) {
  if (formName === 'doctorBonus') {
    return (
      <div style={{ padding: 15 }}>
        <DoctorBonusView tableId={bonusData.id} {...props} />
      </div>
    )
  } else if (formName === 'salesBonus') {
    return (
      <div style={{ padding: 15 }}>
        <SalesBonusView tableId={bonusData.id} {...props} />
      </div>
    )
  }
  return null
}

BonusDetail.propTypes = {
  bonusData: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
};

function BonusList({ formName }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const location = useLocation()

  const [customerMapping] = useFirestoreDataAndMapping('customers')

  const [currentFilter, setCurrentFilter] = useState(null)
  const [expand, setExpand] = useState(true);
  const [bonusTables, setBonusTables] = useState([])
  const [bonusTableDialog, setBonusTableDialog] = useState(null)
  const [productMapping, setProductMapping] = useState({})
  const [userMapping, setUserMapping] = useState({})
  const [, _customers] = useFirestoreDataAndMapping('customers')
  const customers = _customers.filter(c => c.code !== 'S000001')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: `sideMenu.finance.${formName}` })
    }])
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    setBonusTables([])
    const onSnapshot = snapshot => {
      const tables = []
      snapshot.forEach(doc => {
        tables.push({ ...doc.data(), id: doc.id })
      });
      setBonusTables(tables)
    }
    const unsubscribe = firebase.firestore().collection('finance').where('type', '==', formName).onSnapshot(onSnapshot, err => { })
    return () => unsubscribe()
  }, [formName]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('products').onSnapshot(snapshot => {
      const productMapping = {}
      snapshot.forEach(doc => {
        const newData = unwrap(doc.data())
        productMapping[doc.id] = newData
        // const packageData = [];

        // if (newData.package) {
        //   Object.keys(newData.package).map(p => {
        //     packageData.push({ id: p, ...newData.package[p] })
        //   })

        //   newData.package = packageData
        // }
      })
      setProductMapping(productMapping)
    }, err => { })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const onSnapshot = snapshot => {
      setUserMapping(unwrap(snapshot.data()))
    }
    const unsubscribe = firebase.firestore().collection('configuration').doc('userMapping').onSnapshot(onSnapshot, err => { })
    return () => unsubscribe()
  }, []);

  const filteredBonusTables = filterBonusTables()

  const headerCells = [
    { text: 'date', sort: 'yearMonth' },
    { text: 'createdDate', sort: 'createdDate' },
    { text: 'sourceName', sort: 'sourceName' },
  ].map(c => { c.text = formatMessage({ id: `${formName}.table.header.${c.text}` }); return c })

  const rowCells = [
    { field: 'yearMonth' },
    { field: 'createdDate' },
    { field: 'sourceName' },
  ]

  function showBonusTableDialog() {
    setBonusTableDialog({
      action: formName
    })
  }

  function filterBonusTables() {
    let items = [...bonusTables]

    if (currentFilter && currentFilter.text) {
      const lowerCaseText = currentFilter.text.toLowerCase()
      if (currentFilter.name === 'sourceName') {
        return items.filter(s => customerMapping[s.source]?.nickname.toLowerCase().includes(lowerCaseText))
      } else {
        return items.filter(s => s[currentFilter.name].toLowerCase().includes(lowerCaseText))
      }
    }
    return items
  }

  const formatData = (bonusTable) => {
    const newData = { ...bonusTable }
    newData.sourceName = customerMapping[newData.source]?.nickname
    newData.createdDate = dayjs(newData.createdAt.seconds * 1000).format('YYYY-MM-DD')
    return newData
  }

  const filterItems = [
    { name: 'date' },
    { name: 'createdDate' },
    { name: 'sourceName', type: 'customer' },
  ].map(i => { i.text = formatMessage({ id: `${formName}.table.detail.${i.name}` }); return i })

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  async function handleExecute(data) {
    // 呼叫 cloud function, 開始撈資料作計算
    setLoading(true)
    try {
      await (firebase.functions().httpsCallable('createFinanceTable'))(data)
    } catch (ex) {
      console.log(ex)
    }
    setLoading(false)
    setBonusTableDialog(null)
  }

  return (
    <div style={{ padding: '24px 24px 80px 24px' }}>
      {loading && <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open
      >
        <CircularProgress color="inherit" />
      </Backdrop>}
      {!!currentUser.userRights['finance-create'] && <FabAdd onClick={showBonusTableDialog} />}
      {bonusTableDialog && <SelectYMCustomerDialog
        title={formatMessage({ id: 'newBonusTableDialog.title' })}
        handleClose={() => setBonusTableDialog(null)}
        handleExecute={handleExecute}
        action={bonusTableDialog.action}
        customers={customers}
      />}
      <SimpleTableToolbar
        title={`${formName}.table.title`}
        buttons={<ExpandButton open={expand} onExpandChange={setExpand} />}
        toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
      />
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <EnhancedTable
          defaultOrder="desc"
          defaultOrderField="date"
          headerCells={headerCells}
          rowCells={rowCells}
          getExpandContent={bonusData =>
            <BonusDetail
              formName={formName}
              productMapping={productMapping}
              userMapping={userMapping}
              bonusData={bonusData}
              yearMonth={bonusData.yearMonth}
              customerName={bonusData.sourceName}
            />
          }
          tableData={filteredBonusTables.map(r => formatData(r))}
        />
      </Collapse>
    </div>
  );
}

BonusList.propTypes = {
  formName: PropTypes.string.isRequired,
};

export default BonusList;
