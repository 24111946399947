import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';

import EditNewSupplierView from '../../components/EditNewSupplierView';
import ContextStore from '../../modules/context';

const SwitchContainer = styled('div')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: 1.75,
  letterSpacing: '0.00938em',
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(2),
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
}));

function EditSupplierDialog({ supplier, handleClose }) {
  const { currentUser } = useContext(ContextStore)
  const childRef = useRef()
  const [loading, setLoading] = useState(false)
  const [systemFlags, setSystemFlags] = useState(false)

  const handleSave = async () => {
    setLoading(true);
    const data = childRef.current.getContent()
    if (data) {
      try {
        await (firebase.functions().httpsCallable('saveSupplier'))({ id: supplier.id, ...data })
      } catch (ex) {
        console.log(ex)
      }
    } else {
      setLoading(false);
      return
    }
    // setLoading(false);
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <FormattedMessage id={`supplier.dialog.title.${supplier.id === 'new' ? 'add' : 'edit'}`} />
        {currentUser.userRights.debugging &&
          <SwitchContainer>
            <div>簡易</div>
            <Switch
              checked={systemFlags}
              onChange={e => setSystemFlags(e.target.checked)}
              color="primary"
              name="editmode"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <div>進階</div>
          </SwitchContainer>
        }
      </DialogTitle>
      <DialogContent dividers={true}>
        <EditNewSupplierView ref={childRef} supplier={supplier} ignoreFields={systemFlags ? [] : ['supplier', 'internal', 'active']} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          color="primary"
          onClick={handleSave}
          loading={loading}
          loadingPosition="start"
          loadingIndicator={<CircularProgress size={24} />}
          startIcon={<div />}
          variant="contained"
        >
          <FormattedMessage id="button.save" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

EditSupplierDialog.propTypes = {
  supplier: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};

export default EditSupplierDialog;
