const i18n = {
  'medicine': {
    'table': {
      'title': '藥品列表',
      'header': {
        'code': '編號',
        'category': '分類',
        'name': '名稱',
        'effect': '功效',
        'appearance': '外觀',
        'unit': '單位',
        'controlledDrug': '管制類別',
      },
      'detail': {
        'code': '編號',
        'name': '名稱',
        'controlledDrug': '管制類別',
        'category': '分類',
        'effect': '功效',
        'appearance': '外觀',
        'customers': '客戶',
        'note': '備註',
        'unit': '單位',
        'merchandise': {
          'code': '品號',
          'name': '名稱',
          'amount': '數量',
          'sku': '庫存單位(SKU)'
        }
      },
    },
    'edit': {
      'unit': '單位',
      'category': '分類',
      'controlledDrug': '管制性藥品',
      'cost': '成本',
      'name': '名稱',
      'customers': '客戶',
      'effect': '功效',
      'appearance': '外觀',
      'note': '備註',
    },
    'dialog': {
      'title': {
        'add': '新增藥品',
        'edit': '編輯藥品',
      }
    },
    'category': {
      'all': '全部',
      'PO': '口服藥',
      'OI': '藥膏',
      'IV': '針劑',
    },
    'controlledDrug': {
      'type1': '管制性藥品',
      'type2': '非管制性藥品',
    }
  },
  'editMedicine': {
    'title': '編輯藥品',
    'table': {
      'title': '消耗商品',
    },
    'add': '新增商品',
    'remove': '移除商品',
  }
}
export default i18n
