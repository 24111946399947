import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, Routes, Route, Navigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ArchiveIcon from '@mui/icons-material/Archive';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import NotificationsIcon from '@mui/icons-material/Notifications';
import BallotIcon from '@mui/icons-material/Ballot';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NoteAddRoundedIcon from '@mui/icons-material/NoteAddRounded';
import SyncAltRoundedIcon from '@mui/icons-material/SyncAltRounded';
import DevicesIcon from '@mui/icons-material/Devices';

import Dashboard from './routes/Dashboard/Dashboard';
import UserList from './routes/User/UserList';
import VendorList from './routes/Vendor/VendorList';
import ShoppingCartList from './routes/Purchase/ShoppingCartList';
import Requisition from './routes/Purchase/Requisition';
import EditRequisitionPage from './routes/Purchase/EditRequisitionPage';
import EditBorrowingStaff from './routes/Vendor/EditBorrowingStaff';
import EditWarehouseStaff from './routes/Vendor/EditWarehouseStaff';
import EditWorkflow from './routes/Vendor/EditWorkflow';
import PurchaseOrder from './routes/Purchase/PurchaseOrder';
import EditPurchaseOrderPage from './routes/Purchase/EditPurchaseOrderPage';
import Receipt from './routes/Purchase/Receipt';
import EditReceiptPage from './routes/Purchase/EditReceiptPage';
import ReturnForm from './routes/Purchase/ReturnForm';
import EditReturnFormPage from './routes/Purchase/EditReturnFormPage';
import ArrangeStockForm from './routes/Stock/ArrangeStockForm';
import EditArrangeStockFormPage from './routes/Stock/EditArrangeStockFormPage';
import StockRequisition from './routes/Stock/StockRequisition';
import EditStockRequisitionPage from './routes/Stock/EditStockRequisitionPage';
import ScrapForm from './routes/Stock/ScrapForm';
import EditScrapFormPage from './routes/Stock/EditScrapFormPage';
import StockList from './routes/Stock/StockList';
import EditInventoryCheckPage from './routes/Stock/EditInventoryCheckPage';
import InventoryCheck from './routes/Stock/InventoryCheck';
import InventoryChanges from './routes/Stock/InventoryChanges';
import BorrowingOrder from './routes/Stock/BorrowingOrder';
import EditBorrowingOrderPage from './routes/Stock/EditBorrowingOrderPage';
import DeliveryOrder from './routes/Sales/DeliveryOrder';
import SalesReturnForm from './routes/Sales/SalesReturnForm';
import EditDeliveryOrderPage from './routes/Sales/EditDeliveryOrderPage';
import NewProfile from './routes/Profile/NewProfile';
import EditNewProfilePage from './routes/Profile/EditNewProfilePage';
import Notification from './routes/Notification/Notification';
import FixedAssets from './routes/FixedAssets/FixedAssets';
import Merchandise from './routes/Merchandise/Merchandise';
import EditMerchandisePage from './routes/Merchandise/EditMerchandisePage';
import Medicine from './routes/Medicine/Medicine';
import EditMedicinePage from './routes/Medicine/EditMedicinePage';
import EditProductPage from './routes/Product/EditProductPage';
import EditSyncProductPage from './routes/Product/EditSyncProductPage';
import EditProductPackagePage from './routes/Product/EditProductPackagePage'
import Product from './routes/Product/Product';
import SyncProduct from './routes/Product/SyncProduct';
import ReceiptReport from './routes/Report/ReceiptReport';
import BonusList from './routes/Finance/BonusList';
import StockReport from './routes/Report/StockReport';
import AppNavBar from './containers/AppNavBar/AppNavBar';

import ContextStore from './modules/context';
import { APP_VERSION, DRAWER_WIDTH } from './constants/index';
import './App.css';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${DRAWER_WIDTH}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

function getSideMenu(currentUser) {
  function filterByUserRights(items) {
    return items.filter(i => !i.userRight || currentUser.userRights.debugging || currentUser.userRights[i.userRight] === true || (i.userRight2 && currentUser.userRights[i.userRight2] === true))
  };
  return filterByUserRights([
    { text: 'user', icon: <AccountBoxIcon />, path: 'user', userRight: 'user-view' },
    { text: 'vendor', icon: <ContactMailIcon />, path: 'vendor', userRight: 'vendor-view' },
    { text: 'purchase', icon: <ShoppingCartIcon />, path: 'purchase', userRight: 'purchase-view', subMenuItems: filterByUserRights([
      { text: 'purchase.requisition', path: 'requisition/pending', userRight: 'requisition-view' },
      { text: 'purchase.purchaseOrder', path: 'purchaseOrder/pending', userRight: 'purchaseOrder-view' },
      { text: 'purchase.receipt', path: 'receipt/pending', userRight: 'receipt-view' },
      { text: 'purchase.returnForm', path: 'returnForm', userRight: 'returnForm-view' },
    ]) },
    { text: 'sales', icon: <LocalShippingIcon />, path: 'sales', userRight: 'sales-view', subMenuItems: filterByUserRights([
      { text: 'sales.deliveryOrder', path: 'deliveryOrder', userRight: 'deliveryOrder-view' },
      { text: 'sales.salesReturnForm', path: 'salesReturnForm/pending', userRight: 'deliveryOrder-view' },
    ]) },
    { text: 'stock', icon: <ArchiveIcon />, path: 'stock', userRight: 'stock-view', subMenuItems: filterByUserRights([
      { text: 'stock.query', path: 'query', userRight: 'whQuery-view' },
      { text: 'stock.inventoryCheck', path: 'inventoryCheck/processing', userRight: 'whInventory-view' },
      { text: 'stock.inventoryChanges', path: 'inventoryChanges', userRight: 'whInventory-view' },
      { text: 'stock.arrangeStockForm', path: 'arrangeStockForm', userRight: 'whInventory-view' },
      { text: 'stock.scrapForm', path: 'scrapForm/pending', userRight: 'scrapForm-view' },
      { text: 'stock.stockRequisition', path: 'stockRequisition/pending', userRight: 'stockRequisition-view' },
    ]) },
    { text: 'borrowing', icon: <SyncAltRoundedIcon />, path: 'borrowing', userRight: 'borrowing-view', subMenuItems: filterByUserRights([
      { text: 'borrowing.borrowingOrder', path: 'borrowingOrder', userRight: 'borrowingOrder-view' },
      { text: 'borrowing.borrowingDelivery', path: 'borrowingDelivery', userRight: 'borrowingDelivery-view' },
      { text: 'borrowing.borrowingReceipt', path: 'borrowingReceipt/pending', userRight: 'borrowingReceipt-view' },
      { text: 'borrowing.returnBorrowingForm', path: 'returnBorrowingForm', userRight: 'returnBorrowingForm-view' },
      { text: 'borrowing.returnBorrowingReceipt', path: 'returnBorrowingReceipt/pending', userRight: 'returnBorrowingReceipt-view' },
    ]) },
    { text: 'notification', icon: <NotificationsIcon />, path: 'notification' },
    { text: 'fixed-assets', icon: <DevicesIcon />, path: 'fixed-assets', userRight: 'fixedAsset-view' },
    { text: 'reports', icon: <BallotIcon />, path: 'reports', userRight: 'report-view', subMenuItems: filterByUserRights([
      { text: 'reports.receipt', path: 'receipt' },
      { text: 'reports.stock', path: 'stock' },
    ]) },
    { text: 'finance', icon: <BallotIcon />, path: 'finance', userRight: 'finance-view', subMenuItems: filterByUserRights([
      { text: 'finance.doctorBonus', path: 'doctorBonus' },
      { text: 'finance.salesBonus', path: 'salesBonus' },
    ]) },
    { text: '-', userRight: 'merchandise-view', userRight2: 'product-view' },
    { text: 'merchandise', icon: <AssignmentIcon />, path: 'merchandise', userRight: 'merchandise-view' },
    { text: 'medicine', icon: <AssignmentIcon />, path: 'medicine', userRight: 'medicine-view' },
    { text: 'product', icon: <AssignmentIcon />, path: 'products', userRight: 'product-view', subMenuItems: filterByUserRights([
      { text: 'product.warehouse', path: 'warehouse' },
      { text: 'product.customer', path: 'customer' },
    ]) },
    { text: '-', userRight: 'newProfile-view' },
    { text: 'profile', icon: <NoteAddRoundedIcon />, path: 'profile', userRight: 'newProfile-view', subMenuItems: filterByUserRights([
      { text: 'profile.newSupplier', path: 'newSupplier/pending', userRight: 'newSupplier-view' },
      { text: 'profile.newMerchandise', path: 'newMerchandise/pending', userRight: 'newMerchandise-view' },
      { text: 'profile.newProduct', path: 'newProduct/pending', userRight: 'newProduct-view' },
      { text: 'profile.newFolderProduct', path: 'newFolderProduct/pending', userRight: 'newProduct-view' },
      { text: 'profile.newPackageProduct', path: 'newPackageProduct/pending', userRight: 'newProduct-view' },
      { text: 'profile.newCombinationProduct', path: 'newCombinationProduct/pending', userRight: 'newProduct-view' },
    ]) }
  ]);
}

function App({ user, config }) {
  const currentUser = user;
  const appVersion = config.version.app
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { formatMessage } = useIntl()
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [purchaseOrderCache, setPurchaseOrderCache] = useState({});

  const contextValue = useMemo(
    () => ({ breadcrumbs, setBreadcrumbs, purchaseOrderCache, setPurchaseOrderCache, currentUser: user }),
    [breadcrumbs, purchaseOrderCache, user]
  );

  useEffect(() => {
    if (appVersion > APP_VERSION) {
      window.location.reload()
    }
  }, [appVersion]);

  const menuItems = getSideMenu(currentUser)

  const [expend, setExpend] = useState({ purchase: false, product: false });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getMenuItem = ({ text, icon, path, subMenuItems }, index) => {
    if (text === '-') {
      return <Divider key={`-${index}`} />
    } else {
      return (
        !!subMenuItems ? (
          <div key={text}>
            <ListItem button onClick={() => setExpend({ ...expend, [text]: !expend[text] })}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={formatMessage({ id: `sideMenu.${text}.root` })} />
              {expend[text] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse key={`${text}+`} in={expend[text]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {subMenuItems.map(({ text, path: subPath }) => (
                  <Link key={`/${path}/${subPath}`} to={`/${path}/${subPath}`} style={{ textDecoration: 'none', color: '#000' }}>
                    <ListItem button>
                      {/* <ListItemIcon><StarBorder /></ListItemIcon> */}
                      <ListItemText primary={formatMessage({ id: `sideMenu.${text}` })} />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Collapse>
          </div>
        ) : (
          <Link key={path} to={`/${path}`} style={{ textDecoration: 'none', color: '#000' }}>
            <ListItem button key={text}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={formatMessage({ id: `sideMenu.${text}` })} />
            </ListItem>
          </Link>
        )

      )
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <CssBaseline />
      <ContextStore.Provider value={{ ...contextValue }}>
        <AppNavBar isDrawerOpen={open} handleDrawerOpen={handleDrawerOpen} />
        <Drawer
          sx={{
            width: DRAWER_WIDTH,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: DRAWER_WIDTH,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <span style={{ paddingLeft: '4px' }}>版本 {APP_VERSION}</span>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {menuItems.map((item, index) => getMenuItem(item, index))}
          </List>
        </Drawer>

        <Main open={open}>
          <DrawerHeader />
          <Routes>
            <Route
              path="/"
              exact
              element={<Dashboard />}
            />
            {(currentUser.userRights.debugging || currentUser.userRights['user-view'] === true) && <Route
              path="/user"
              exact
              element={<UserList />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['vendor-view'] === true) && <Route
              path="/vendor"
              exact
              element={<VendorList workflow={config.workflow} />}
            />}
            {currentUser.userRights['vendor-edit'] === true && config.workflow.lock !== true && <Route
              path="/vendor/workflow/:workflowName/:customerId"
              exact
              element={<EditWorkflow />}
            />}
            {currentUser.userRights['vendor-edit'] === true && config.workflow.lock !== true && <Route
              path="/vendor/editBorrowingStaff/:customerId"
              exact
              element={<EditBorrowingStaff />}
            />}
            {currentUser.userRights['vendor-edit'] === true && config.workflow.lock !== true && <Route
              path="/vendor/editWarehouseStaff/:customerId"
              exact
              element={<EditWarehouseStaff />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['requisition-view'] === true) && <Route
              path="/purchase/requisition/:tabName"
              exact
              element={<Requisition />}
            />}
            <Route
              path="/purchase/requisition/edit/:requisitionId"
              exact
              element={<EditRequisitionPage />}
            />
            {(currentUser.userRights.debugging || currentUser.userRights['purchaseOrder-view'] === true) && <Route
              path="/purchase/purchaseOrder/:tabName"
              exact
              element={<PurchaseOrder />}
            />}
            <Route
              path="/purchase/purchaseOrder/edit/:purchaseOrderId"
              exact
              element={<EditPurchaseOrderPage />}
            />
            {(currentUser.userRights.debugging || currentUser.userRights['receipt-view']) && <Route
              path="/purchase/receipt/:tabName"
              exact
              element={<Receipt formName={'receipt'} />}
            />}
            <Route
              path="/purchase/receipt/edit/:receiptId"
              exact
              element={<EditReceiptPage formName={'receipt'} />}
            />
            {(currentUser.userRights.debugging || currentUser.userRights['returnForm-create']) && <Route
              path="/purchase/returnForm/edit/new"
              exact
              element={<EditReturnFormPage />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['returnForm-view']) && <Route
              path="/purchase/returnForm"
              exact
              element={<ReturnForm />}
            />}
            <Route
              path="/stock/arrangeStockForm"
              exact
              element={<ArrangeStockForm />}
            />
            <Route
              path="/stock/arrangeStockForm/edit/new"
              exact
              element={<EditArrangeStockFormPage />}
            />
            {(currentUser.userRights.debugging || currentUser.userRights['stockRequisition-view']) && <Route
              path="/stock/stockRequisition/:tabName"
              exact
              element={<StockRequisition />}
            />}
            {currentUser.userRights['stockRequisition-edit'] === true && <Route
              path="/stock/stockRequisition/edit/:stockRequisitionId"
              exact
              element={<EditStockRequisitionPage />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['scrapForm-view']) && <Route
              path="/stock/scrapForm/:tabName"
              exact
              element={<ScrapForm />}
            />}
            {currentUser.userRights['scrapForm-edit'] === true && <Route
              path="/stock/scrapForm/edit/:scrapFormId"
              exact
              element={<EditScrapFormPage />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['whQuery-view'] === true) && <Route
              path="/stock/query"
              exact
              element={<StockList />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['whQuery-view'] === true) && <Route
              path="/stock/query/:customerId"
              exact
              element={<StockList />}
            />}
            <Route
              path="/stock/inventoryCheck/edit/:inventoryCheckId"
              exact
              element={<EditInventoryCheckPage checkpoint={config.checkpoint} />}
            />
            {(currentUser.userRights.debugging || currentUser.userRights['whInventory-view'] === true) && <Route
              path="/stock/inventoryCheck/:tabName"
              exact
              element={<InventoryCheck />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['whInventory-view'] === true) && <Route
              path="/stock/inventoryChanges"
              exact
              element={<InventoryChanges />}
            />}
            <Route
              path="/borrowing/borrowingOrder/edit/:borrowingOrderId"
              exact
              element={<EditBorrowingOrderPage />}
            />
            {(currentUser.userRights.debugging || currentUser.userRights['borrowingOrder-view'] === true) && <Route
              path="/borrowing/borrowingOrder"
              exact
              element={<BorrowingOrder />}
            />}
            <Route
              path="/borrowing/borrowingDelivery/edit/:deliveryOrderId"
              exact
              element={<EditDeliveryOrderPage formName={'borrowingDelivery'} />}
            />
            {(currentUser.userRights.debugging || currentUser.userRights['borrowingDelivery-view'] === true) && <Route
              path="/borrowing/borrowingDelivery"
              exact
              element={<DeliveryOrder formName={'borrowingDelivery'} />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['borrowingReceipt-view']) && <Route
              path="/borrowing/borrowingReceipt/:tabName"
              exact
              element={<Receipt formName={'borrowingReceipt'} />}
            />}
            <Route
              path="/borrowing/borrowingReceipt/edit/:receiptId"
              exact
              element={<EditReceiptPage formName={'borrowingReceipt'} />}
            />
            <Route
              path="/borrowing/returnBorrowingForm/edit/:deliveryOrderId"
              exact
              element={<EditDeliveryOrderPage formName={'returnBorrowingForm'} />}
            />
            {(currentUser.userRights.debugging || currentUser.userRights['returnBorrowingForm-view'] === true) && <Route
              path="/borrowing/returnBorrowingForm"
              exact
              element={<DeliveryOrder formName={'returnBorrowingForm'} />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['returnBorrowingReceipt-view']) && <Route
              path="/borrowing/returnBorrowingReceipt/:tabName"
              exact
              element={<Receipt formName={'returnBorrowingReceipt'} />}
            />}
            <Route
              path="/sales/deliveryOrder/edit/:deliveryOrderId"
              exact
              element={<EditDeliveryOrderPage formName={'deliveryOrder'} />}
            />
            {(currentUser.userRights.debugging || currentUser.userRights['deliveryOrder-view'] === true) && <Route
              path="/sales/deliveryOrder"
              exact
              element={<DeliveryOrder formName={'deliveryOrder'} />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['deliveryOrder-view'] === true) && <Route
              path="/sales/salesReturnForm/:tabName"
              exact
              element={<SalesReturnForm />}
            />}
            <Route
              path="/notification"
              exact
              element={<Notification />}
            />
            <Route
              path="/fixed-assets"
              exact
              element={<FixedAssets />}
            />
            {(currentUser.userRights.debugging || currentUser.userRights['merchandise-view'] === true) && <Route
              path="/merchandise"
              exact
              element={<Merchandise />}
            />}
            {currentUser.userRights['merchandise-edit'] === true && <Route
              path="/merchandise/:merchandiseId"
              exact
              element={<EditMerchandisePage />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['medicine-view'] === true) && <Route
              path="/medicine"
              exact
              element={<Medicine />}
            />}
            {currentUser.userRights['medicine-edit'] === true && <Route
              path="/medicine/:medicineId"
              exact
              element={<EditMedicinePage />}
            />}
            <Route
              path="/user/shoppingCart"
              exact
              element={<ShoppingCartList />}
            />
            {currentUser.userRights['product-edit'] === true && <Route
              path="/products/:productId/warehouse"
              exact
              element={<EditProductPage />}
            />}
            {currentUser.userRights['product-edit'] === true && <Route
              path="/products/:productId/customer/:customerId"
              exact
              element={<EditSyncProductPage />}
            />}
            {currentUser.userRights['product-edit'] === true && <Route
              path="/products/:productId/warehouse/:pageName"
              exact
              element={<EditProductPackagePage />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['product-view'] === true) && <Route
              path="/products/warehouse"
              element={<Product />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['product-view'] === true) && <Route
              path="/products/customer"
              exact
              element={<SyncProduct />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['product-view'] === true) && <Route
              path="/products/customer/:customerId"
              exact
              element={<SyncProduct />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['newSupplier-view'] === true) && <Route
              path="/profile/newSupplier/:tabName"
              exact
              element={<NewProfile profile={'supplier'} />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['newMerchandise-view'] === true) && <Route
              path="/profile/newMerchandise/:tabName"
              exact
              element={<NewProfile profile={'merchandise'} />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['newProduct-view'] === true) && <Route
              path="/profile/newProduct/:tabName"
              exact
              element={<NewProfile profile={'product'} />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['newProduct-view'] === true) && <Route
              path="/profile/newFolderProduct/:tabName"
              exact
              element={<NewProfile profile={'folder'} />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['newProduct-view'] === true) && <Route
              path="/profile/newPackageProduct/:tabName"
              exact
              element={<NewProfile profile={'package'} />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['newProduct-view'] === true) && <Route
              path="/profile/newCombinationProduct/:tabName"
              exact
              element={<NewProfile profile={'combination'} />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['report-view'] === true) && <Route
              path="/reports/receipt"
              exact
              element={<ReceiptReport profile={'product'} />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['report-view'] === true) && <Route
              path="/reports/stock"
              exact
              element={<StockReport profile={'product'} />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['finance-view'] === true) && <Route
              path="/finance/doctorBonus"
              exact
              element={<BonusList formName={'doctorBonus'} />}
            />}
            {(currentUser.userRights.debugging || currentUser.userRights['finance-view'] === true) && <Route
              path="/finance/salesBonus"
              exact
              element={<BonusList formName={'salesBonus'} />}
            />}
            <Route
              path="/profile/newSupplier/edit/:newProfileId"
              exact
              element={<EditNewProfilePage profile={'supplier'} />}
            />
            <Route
              path="/profile/newMerchandise/edit/:newProfileId"
              exact
              element={<EditNewProfilePage profile={'merchandise'} />}
            />
            <Route
              path="/profile/newProduct/edit/:newProfileId"
              exact
              element={<EditNewProfilePage profile={'product'} />}
            />
            <Route
              path="/profile/newFolderProduct/edit/:newProfileId"
              exact
              element={<EditNewProfilePage profile={'folder'} />}
            />
            <Route
              path="/profile/newPackageProduct/edit/:newProfileId"
              exact
              element={<EditNewProfilePage profile={'package'} />}
            />
            <Route
              path="/profile/newCombinationProduct/edit/:newProfileId"
              exact
              element={<EditNewProfilePage profile={'combination'} />}
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Main>
      </ContextStore.Provider>
    </div>
  );
}

App.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    userRights: PropTypes.object.isRequired,
  }),
  config: PropTypes.shape({
    version: PropTypes.object.isRequired,
    checkpoint: PropTypes.object.isRequired,
    workflow: PropTypes.object.isRequired,
    // wareroom: PropTypes.object.isRequired,
  }),
};

export default App;
