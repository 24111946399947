const i18n = {
  'doctorBonus': {
    'name': '醫師獎金',
    'table': {
      'title': '醫師獎金列表',
      'header': {
        'date': '列表日期',
        'createdDate': '建立日期',
        'sourceName': '診所',
      },
      'detail': {
        'date': '列表日期',
        'createdDate': '建立日期',
        'sourceName': '診所',
      },
    },
    'detail': {
      'kardexDate': '日期',
      'kardexId': 'Kardex ID',
      'doctorName': '醫師',
      'patientName': '客戶名',
      'productCode': '品號',
      'productName': '品名',
      'quantity': '數量',
      'price': '金額',
      'bonusType': '類別',
      'cost': '耗材成本',
      'bonus': '計績點數',
    }
  }
}
export default i18n
