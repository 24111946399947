import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';

import ContextStore from '../../modules/context';
import DatePickerField from '../../components/DatePickerField';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableToolbar from '../../components/EnhancedTableToolbar';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { useFirestoreDataAndMapping, sortByField, unwrap } from '../../modules/uitls';
import { getComparator, stableSort } from '../../modules/sort';
import StockLogDetails from './StockLogDetails';


function WareroomInfoDiablog({ info, onClose }) {
  const infoText = info.length  ? info.map(i => `${i.name}: ${i.count}`).join('\n') : '無資料'

  return <Dialog maxWidth="md" onClose={onClose} open>
    <Box p={3}>
      <div style={{ whiteSpace: 'pre' }}>
        {infoText}
      </div>
    </Box>
  </Dialog>
}

WareroomInfoDiablog.propTypes = {
  info: PropTypes.arrayOf(PropTypes.object.isRequired),
  onClose: PropTypes.func.isRequired,
};

function MerchandiseDetail({ merchandiseId, items, onAdjustAmount, showWareroomInfo }) {
  const { formatMessage } = useIntl()
  function onCellValueChanged(id, field, value) {
    onAdjustAmount(merchandiseId, id, value)
  }

  const headerCells = [
    { field: 'date' },
    { field: 'lotNumber' },
    { field: 'expectedDate' },
    { field: 'expectedAmount' },
    { field: 'mainAmount' },
    { field: 'wareroomAmount' },
  ].map(c => {c.text = formatMessage({ id: `inventoryCheck.merchandise.${c.field}` });return c})

  const rowCells = [
    { field: 'date' },
    { field: 'lotNumber' },
    { field: 'expectedDate' },
    { field: 'expectedAmount' },
    { field: 'mainAmount', type: 'input-number', required: true, label: '數量', onValueChanged: onCellValueChanged },
    { field: 'wareroomAmount', type: 'info', onButtonClick: showWareroomInfo },
  ]

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="collapsible table">
        <EnhancedTableHead
          headerCells={headerCells}
          rowCount={items.length}
        />
        <TableBody>
          {items.map(i => (
            <EnhancedTableRow
              key={i.id}
              rowCells={rowCells}
              cellData={i}
              lock={i.ignore}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

MerchandiseDetail.propTypes = {
  merchandiseId: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object.isRequired),
  onAdjustAmount: PropTypes.func.isRequired,
  showWareroomInfo: PropTypes.func.isRequired,
};

function EditInventoryCheckPage({ checkpoint }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const { inventoryCheckId } = useParams()

  const wareroom = useSelector(state => unwrap(state.firestore.data.config?.wareroom || {}))
  const [customerMapping, customers] = useFirestoreDataAndMapping('customers')

  const filteredCustomers = customers.filter(c => currentUser.inventoryCheckSource.includes(c.id))
  const [merchandiseMapping, merchandises] = useFirestoreDataAndMapping('merchandises')
  const [userMapping] = useFirestoreDataAndMapping('users')

  const [loading, setLoading] = useState(false)
  const [loadingInventory, setLoadingInventory] = useState(false)
  const [lockSource, setLockSource] = useState(inventoryCheckId !== 'new')
  const [customerId, setCustomerId] = useState('')
  const [logs, setLogs] = useState([]);
  const [dailySnapshot, setDailySnapshot] = useState({});
  const [stockLogs, setStockLogs] = useState(null);
  const [wareroomInfo, setWareroomInfo] = useState(null);
  const [modification, setModification] = useState(false);

  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('code')
  const [dateError, setDateError] = useState({})

  const handleDateChange = (date) => {
    updateInventoryCheckData({ name: 'baseDate' }, date)
  };

  const [rawInventoryCheck, setRawInventoryCheck] = useState({
    id: inventoryCheckId,
    source: '',
    createdBy: currentUser.key,
    date: dayjs().format('YYYY-MM-DD'),
    // baseDateTime: dayjs().format('YYYY-MM-DD HH:mm'),
    baseDate: new Date(),
    note: '',
    // merchandises: [],
  });

  const [owInventoryCheck, setOwInventoryCheck] = useState({
  });
  let inventoryCheck = {}

  const disableSubmitButton = inventoryCheckId === 'new' ?
    (currentUser.userRights['inventoryCheck-create'] !== true) :
    (currentUser.userRights['inventoryCheck-edit'] !== true);

  useEffect(() => {
    const today = dayjs().format('YYYY-MM-DD')
    const unsubscribe = customerId !== '' ? firebase.firestore().collection('wh').doc(customerId).collection('logs').where('date', '==', today)
      .onSnapshot( snapshot => {
        const logs = []
        snapshot.forEach(doc => {
          const data = doc.data()
          if (data.valid !== false) {
            logs.push(data)
          }
        });
        setLogs(logs)
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, [customerId]);

  useEffect(() => {
    const yesterday = dayjs().subtract(1, 'days').format('YYYY-MM-DD')
    const unsubscribe = customerId !== '' ? firebase.firestore().collection('wh').doc(customerId).collection('dailySnapshot').doc(yesterday)
      .onSnapshot( snapshot => {
        setDailySnapshot(snapshot?.data()?.extData || {})
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, [customerId]);

  useEffect(() => {
    if (filteredCustomers.length === 1 && inventoryCheckId === 'new') {
      updateInventoryCheckData({ name: 'source' }, filteredCustomers[0].id);
    }
  }, [filteredCustomers.length]);

  useEffect(() => {
    const breadcrumbs = [{
      link: '/stock/inventoryCheck/processing',
      text: formatMessage({ id: 'sideMenu.stock.inventoryCheck' })
    }]
    if (inventoryCheckId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: 'inventoryCheck.dialog.title.add' }) })
    } else {
      breadcrumbs.push({ text: formatMessage({ id: 'inventoryCheck.dialog.title.edit' }) })
    }
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    if (inventoryCheckId !== 'new') { // edit
      const unsubscribe = firebase.firestore().collection('inventoryChecks').doc(inventoryCheckId)
        .onSnapshot(snapshot => {
          const data = snapshot.data()
          if (data.lock && data.status === 'done') {
            navigate('/stock/inventoryChanges');
          } else {
            setCustomerId(data.source)
            setRawInventoryCheck({ id: snapshot.id, ...data })
            //把上次盤點的結果讀出來, call loadInventory 時傳入, 以保留盤點結果
            const amountMapping = {}
            const mIds = Object.keys(data.merchandises)
            for (const mId of mIds) {
              for (const item of data.merchandises[mId].items) {
                amountMapping[item.id] = item.currentAmount
              }
            }
            loadInventory(data.source, data.baseDate, amountMapping)
          }
        }, err => {})
      return () => unsubscribe()
    } else {
      return () => {};
    }
  }, []);

  if (inventoryCheckId !== 'new' && !rawInventoryCheck.merchandises) {
    return ('Loading...')
  }

  const merchandiseDailySnapshot = merchandises.reduce((acc, cur) => {
    if (!acc[cur.id]) acc[cur.id] = 0
    return acc
  }, { ...dailySnapshot })

  const realtimeStock = logs.reduce((acc, cur) => {
    if (acc[cur.merchandiseId] === undefined) return acc
    acc[cur.merchandiseId] += cur.quantity
    return acc
  }, { ...merchandiseDailySnapshot })
  // newData.stock = `${realtimeStock[merchandise.id]}${merchandise.sku}`

  const merchandiseKeys = Object.keys(rawInventoryCheck.merchandises || {}).filter(m => merchandiseMapping[m])
  inventoryCheck = { ...{
    id: inventoryCheckId,
    source: rawInventoryCheck.source,
    createdBy: rawInventoryCheck.createdBy,
    date: rawInventoryCheck.date,
    baseDate: dayjs(rawInventoryCheck.baseDate),
    note: rawInventoryCheck.note,
    status: rawInventoryCheck.status,
    merchandises: merchandiseKeys.map(m => merchandiseMapping[m]).map(m => ({
      code: m.code,
      // orderUnit: m.orderUnit,
      name: m.name,
      nickname: m.nickname,
      id: m.id,
      note: rawInventoryCheck.merchandises[m.id].note,
      items: rawInventoryCheck.merchandises[m.id].items,
      // unitPrice: rawInventoryCheck.merchandises[m.id].unitPrice,
      // amount: rawInventoryCheck.merchandises[m.id].amount,
      // lock: (rawInventoryCheck.merchandises[m.id].purchaseOrder ? true : false),
      // purchaseOrder: rawInventoryCheck.merchandises[m.id].purchaseOrder,
    }))
  }, ...owInventoryCheck }

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'name' },
    { text: 'amount', align: 'right' },
    { text: 'stock', align: 'right' },
    { text: 'balance', align: 'right' },
    { text: 'note', align: 'right' },
  ].map(c => {c.text = formatMessage({ id: `inventoryCheck.merchandise.${c.text}` });return c})

  const rowCells = [
    { field: 'code' },
    { field: 'nickname', tooltip: 'name' },
    { field: 'amount', align: 'right', type: 'info', onButtonClick: showStockLogs },
    { field: 'stock', align: 'right', type: 'info', onButtonClick: showStockLogs },
    { field: 'balance', align: 'right', textColor: true },
    { field: 'note', align: 'right', type: 'input', required: false, label: '備註', onValueChanged: onCellValueChanged },
  ]

  function showWareroomInfo(inventoryId, field, inventory) {
    setWareroomInfo(inventory.wareroomData)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function showStockLogs(merchandiseId, field) {
    if (field === 'stock') {
      const data = {
        merchandiseId,
        customerId,
        startDateTime: dayjs(inventoryCheck.baseDate).add(1, 'days').format('YYYY-MM-DD') + ' 00:00:00',
        // endDateTime: inventoryCheck.date + ' 23:59:59', // 只撈到建單日期可能會有問題.
        endDateTime: dayjs().format('YYYY-MM-DD') + ' 23:59:59', // 改成撈到今天.
      }
      if (inventoryCheckId !== 'new') {
        data.ignoreCheckpoint = inventoryCheckId
      }
      setStockLogs(data)
    } else if (field === 'amount') {
      const data = {
        merchandiseId,
        customerId,
        startDateTime: `${(checkpoint[inventoryCheck.source] || '2019-12-31')} 23:59:59`,
        endDateTime: dayjs(inventoryCheck.baseDate).format('YYYY-MM-DD') + ' 23:59:59',
      }
      if (inventoryCheckId !== 'new') {
        data.ignoreCheckpoint = inventoryCheckId
      }
      setStockLogs(data)
    }
  }

  function onCellValueChanged(id, field, value) {
    for (let m of inventoryCheck.merchandises) {
      if (m.id === id) {
        m[field] = value
        break
      }
    }
    updateInventoryCheckData({ name: 'merchandises' }, inventoryCheck.merchandises);
  }

  function validateField(field, value) {
    // if (field.required && value === '') {
    //   return formatMessage({id: 'form.field.isRequired'})
    // }
    if (field.name === 'merchandises') {
      for (const m of value) {
        if (m.errors && m.errors.amount) {
          return m.errors.amount
        }
      }
    } else if (field.name === 'baseDate') {
      if (value === null) {
        return formatMessage({ id: 'form.field.isRequired' })
      } else if (value.toString() === 'Invalid Date') {
        return formatMessage({ id: 'form.date.formatError' })
      } else {
        if (dayjs(value).format('YYYY-MM-DD') > dayjs().format('YYYY-MM-DD')) {
          return formatMessage({ id: 'form.date.afterToday' })
        }
      }
    }
    return ''
  }

  function updateInventoryCheckData(field, value) {
    let newValue = value
    let err = validateField(field, value)

    if (field.name === 'baseDate') {
      if (err) {
        setDateError({ error: true, helperText: err })
      } else {
        setDateError({})
      }
    }

    let newData = { ...inventoryCheck, [field.name]: newValue, [`${field.name}_err`]: err }
    setOwInventoryCheck(newData)
  }

  async function handleSave() {
    // lockSubmitButton(true)
    setLoading(true);

    const fields = [
      { name: 'source' },
      // {name: 'createdBy'},
      { name: 'date' },
      { name: 'baseDate' },
      { name: 'merchandises' },
      { name: 'note' },
    ]

    let newData = inventoryCheck
    for (let field of fields) {
      if (field.name === 'merchandises') {
        for (const merchandise of newData.merchandises) {
          for (const item of merchandise.items) {
            if (item.errors && Object.keys(item.errors).length) {
              // lockSubmitButton(false)
              setLoading(false);
              return
            }
          }
        }
      } else if (newData[`${field.name}_err`] !== undefined && newData[`${field.name}_err`] !== '') {
        // lockSubmitButton(false)
        setLoading(false);
        return
      }
    }

    let data = {}
    for (let field of fields) {
      if (field.type === '-') continue
      data[field.name] = newData[field.name]
    }

    data.merchandises = data.merchandises.reduce((acc, cur) => {
      const items = cur.items
        .filter(i => !i.ignore)
        .map(i => {
          const item = { ...i, currentAmount: parseInt(i.mainAmount || '0') + i.wareroomAmount }
          delete item.wareroomAmount
          delete item.wareroomData
          delete item.date
          delete item.expectedDate
          delete item.lotNumber
          delete item.errors
          return item
        })
      acc[cur.id] = {
        items,
        note: cur.note
      };
      return acc;
    }, {})
    data.baseDate = dayjs(newData.baseDate).format('YYYY-MM-DD')
    setModification(false)

    try {
      await (firebase.functions().httpsCallable('saveInventoryCheck'))({ id: inventoryCheck.id, ...data })
      if (inventoryCheckId === 'new') {
        handleClose()
      } else {
        setLoading(false);
      }
    } catch (ex) {
      console.log(ex)
    }
    // lockSubmitButton(false)
    // handleClose() // 儲存後不要立刻關閉.
  }

  function handleClose() {
    if (!modification || window.confirm('有更動尚未儲存，確定關閉盤點單嗎？')) {
      if (inventoryCheck.status === 'done') {
        navigate('/stock/inventoryChanges');
      } else {
        navigate('/stock/inventoryCheck/processing');
      }
    }
  }

  function onAdjustAmount(merchandiseId, stockId, value) {
    for (const m of inventoryCheck.merchandises) {
      if (m.id === merchandiseId) {
        for (const item of m.items) {
          if (item.id === stockId) {
            item.mainAmount = value
            if (isNaN(value) || value === '') {
              if (!item.errors) {
                item.errors = {}
              }
              item.errors.mainAmount = '數量錯誤'
            } else {
              if (item.errors && item.errors.mainAmount) {
                delete item.errors.mainAmount
              }
            }
            updateInventoryCheckData({ name: 'merchandises' }, inventoryCheck.merchandises)
            if (!modification) {
              setModification(true)
            }
            break
          }
        }
      }
    }
  }

  function formatData(merchandise) {
    const m = merchandiseMapping[merchandise.id]
    const newData = { ...merchandise }
    const currentAmount = merchandise.items.reduce((acc, cur) => acc + parseInt((isNaN(cur.mainAmount) || cur.mainAmount === '') ? 0 : cur.mainAmount), 0)
    const wareroomAmount = merchandise.items.reduce((acc, cur) => acc + cur.wareroomAmount, 0)
    const amount = merchandise.items.reduce((acc, cur) => acc + cur.expectedAmount, 0)
    newData.amount = `${amount}${m.sku}`
    newData.stock = realtimeStock[merchandise.id] + m.sku
    const balance = isNaN(wareroomAmount) ? 0 : currentAmount + wareroomAmount - amount
    newData.balance = balance > 0 ? `+${balance}` : `${balance}`
    newData.textColor = newData.balance !== '0' ? '#fe2851' : '#000000'
    return newData
  }

  function loadInventory(customer, baseDate, amountMapping) {
    const wareroomMapping = (wareroom[customer] || []).reduce((acc, cur, idx) => {acc[`#${idx + 1}`] = cur; return acc}, { '#default': '主倉庫' })
    firebase.firestore().collection('wh').doc(customer).collection('inventories').where('closeout', '==', false).get().then(snapshot => { // 濾掉在盤點後數量為0的 item
      const baseDateTime = dayjs(baseDate).format('YYYY-MM-DD') + ' 23:59:59'
      const stockMapping = {}
      snapshot.forEach(doc => {
        const m = doc.data()
        let receiptDateTime = `${m.date} ${m.time}`
        // if (receiptDateTime > baseDateTime) return // 直接跳出, 就完全不會出現在盤點的 UI 上.
        // const merchandise = merchandiseMapping[m.id]
        if (!stockMapping[m.id]) stockMapping[m.id] = []
        // const ou = merchandise.orderUnit
        // const sku = merchandise.sku
        // const ou2sku = merchandise.ou2sku
        let amount = receiptDateTime <= baseDateTime ? m.amount : 0
        const mcp = Object.keys(m.checkpoint || {}).map(dateTime => ({ dateTime, amount: m.checkpoint[dateTime] })) // 把 checkpoint 加進去一起排序
        let history = (m.history || []).concat(mcp).sort(sortByField('dateTime')) //history 的內容不會照時間排, 所以要先排序一下.

        // 略過作廢的出貨異動
        const ignoreHistory = []
        for (const i of history) {
          if ((i.deliveryOrderId || i.borrowingDeliveryId || i.returnBorrowingFormId || i.stockRequisitionId || i.scrapFormId) && i.quantity > 0) {
            ignoreHistory.push(i.deliveryOrderId || i.borrowingDeliveryId || i.returnBorrowingFormId || i.stockRequisitionId || i.scrapFormId)
          }
        }
        history = history.filter(h => !ignoreHistory.includes(h.deliveryOrderId || h.borrowingDeliveryId || h.returnBorrowingFormId || h.stockRequisitionId || h.scrapFormId) && !h.target)
        // 計算到基準日之前的數量
        for (const i of history) {
          if (i.dateTime <= baseDateTime) {
            if (i.amount !== undefined) {
              amount = i.amount // 庫存異動的校正
            } else {
              amount += i.quantity
            }
          }
        }

        // 最後算出來的 amount 數量應為 盤點日當天的總量 減去 盤點日後移往分倉的數量
        // 因為要避免盤點後影響到分倉的情況

        // 產生庫存量的 mapping
        // const maxAmount = (ou !== sku ? Math.floor(amount / ou2sku) : amount)
        const wareroomData = receiptDateTime <= baseDateTime ? (Object.keys(m.wareroom || {})
          .filter(k => m.wareroom[k] > 0)
          .map(k => ({ name: wareroomMapping[k], count: `${m.wareroom[k]}${merchandiseMapping[m.id].sku}` }))) : []
        const wareroomAmount = receiptDateTime <= baseDateTime ? Object.keys(m.wareroom || {}).reduce((acc, cur) => acc + m.wareroom[cur], 0) : 0
        const currentAmount = amountMapping ? amountMapping[doc.id] : amount
        const mainAmount = currentAmount - wareroomAmount
        const mData = {
          id: doc.id,
          date: m.date,
          expectedDate: m.expectedDate,
          lotNumber: m.lotNumber,
          // amountInStocks: maxAmount,
          // amountWithUnit: amount + sku,
          expectedAmount: amount,
          mainAmount,
          wareroomAmount,
          wareroomData,
          // maxAmount: amount,
        }
        if (receiptDateTime > baseDateTime) {
          mData.ignore = true
        }
        stockMapping[m.id].push(mData)
      });
      const merchandises = []
      const merchandiseIds = Object.keys(stockMapping)
      for (const id of merchandiseIds) {
        const merchandise = merchandiseMapping[id]
        merchandises.push({
          code: merchandise.code,
          // orderUnit: m.orderUnit,
          name: merchandise.name,
          nickname: merchandise.nickname,
          id: id,
          note: '',
          items: stockMapping[id] = stockMapping[id].sort((a, b) => {
            if (a.expectedDate === '' && b.expectedDate !== '') return 1
            if (a.expectedDate !== '' && b.expectedDate === '') return -1

            if (a.expectedDate === b.expectedDate) {
              if (a.date > b.date) return 1
              if (a.date < b.date) return -1
              if (a.date === b.date) return 0
            }
            if (a.expectedDate > b.expectedDate) return 1
            return -1
          })
        })
      }
      setLoadingInventory(false)
      updateInventoryCheckData({ name: 'merchandises' }, merchandises)
      // setStockMapping(stockMapping)
    })
  }

  function getToolbox() {
    return inventoryCheck.merchandises.length === 0 ? (
      <LoadingButton
        sx={{ m: 1, whiteSpace: 'nowrap' }}
        color="primary"
        onClick={() => {
          setLockSource(true)
          setCustomerId(inventoryCheck.source)
          setLoadingInventory(true)
          loadInventory(inventoryCheck.source, inventoryCheck.baseDate)
        }}
        disabled={inventoryCheck.source === '' || !!inventoryCheck.baseDate_err || loadingInventory}
        loading={loadingInventory}
        loadingPosition="start"
        loadingIndicator={<CircularProgress size={24} />}
        startIcon={<ArchiveIcon />}
        variant="contained"
      >
        <FormattedMessage id="editInventoryCheck.loadInventory" />
      </LoadingButton>
    ) : null
  }

  function getSourceSelector() {
    if (currentUser.inventoryCheckSource.length === 1 || inventoryCheckId !== 'new') {
      const value = inventoryCheckId !== 'new' ?
        (customerMapping[inventoryCheck.source].nickname ? customerMapping[inventoryCheck.source].nickname : '') :
        (customerMapping[currentUser.inventoryCheckSource[0]] ? customerMapping[currentUser.inventoryCheckSource[0]].nickname : '')

      return (<TextField
        disabled
        type="text"
        label={formatMessage({ id: 'inventoryCheck.table.detail.source' })}
        variant="outlined"
        value={value}
        fullWidth
        size="small"
      />)
    } else {
      return (<TextField
        select
        required
        disabled={lockSource}
        type="text"
        label={formatMessage({ id: 'inventoryCheck.table.detail.source' })}
        variant="outlined"
        value={inventoryCheck.source}
        onChange={e => updateInventoryCheckData({ name: 'source' }, e.target.value)}
        fullWidth
        size="small"
        error={inventoryCheck.source_err ? true : false}
        helperText={inventoryCheck.source_err}
      >
        {filteredCustomers.map(c => <MenuItem key={c.id} value={c.id}>
          {c.nickname}
        </MenuItem>)}
      </TextField>)
    }
  }

  return (
    <div style={{ flexGrow: 1 }}>
      {stockLogs &&
        <StockLogDetails info={stockLogs} onClose={() => setStockLogs(null)} />
      }
      {!!wareroomInfo && <WareroomInfoDiablog
        info={wareroomInfo}
        onClose={() => setWareroomInfo(null)}
      />}
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            {getSourceSelector()}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'inventoryCheck.table.detail.createdBy' })}
              variant="outlined"
              value={userMapping[inventoryCheck.createdBy] ? userMapping[inventoryCheck.createdBy].displayName : ''}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'inventoryCheck.table.detail.date' })}
              variant="outlined"
              value={inventoryCheck.date}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePickerField
              required
              fullWidth
              disabled={lockSource}
              label={formatMessage({ id: 'inventoryCheck.table.detail.baseDate' })}
              value={inventoryCheck.baseDate}
              onChange={handleDateChange}
              {...dateError}
              maxDateMessage={formatMessage({ id: 'form.date.afterToday' })}
              invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
              maxDate={new Date()}
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: '8px 0px' }} />
        <EnhancedTableToolbar
          title="editInventoryCheck.table.title"
          selectdMessage="editInventoryCheck.table.selected"
          numSelected={0}
          toolbox={getToolbox()}
          // toolboxForSelection={getToolboxForSelection()}
        />
        {inventoryCheck.merchandises.length > 0 && <TableContainer component={Paper}>
          <Table>
            <EnhancedTableHead
              headerCells={headerCells}
              numSelected={0}
              // onCheckboxClick={handleSelectAllClick}
              rowCount={inventoryCheck.merchandises.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              expandable
            />
            <TableBody>
              {stableSort(inventoryCheck.merchandises.map(m => formatData(m)), getComparator(order, orderBy)).map(merchandise => (
                <EnhancedTableRow
                  key={merchandise.id}
                  rowCells={rowCells}
                  cellData={merchandise}
                  expandable
                  expandContent={<MerchandiseDetail
                    merchandiseId={merchandise.id}
                    items={merchandise.items}
                    onAdjustAmount={onAdjustAmount}
                    showWareroomInfo={showWareroomInfo}
                  />}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>}

        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'inventoryCheck.table.detail.note' })}
              variant="outlined"
              value={inventoryCheck.note}
              onChange={e => updateInventoryCheckData({ name: 'note' }, e.target.value)}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id={modification || inventoryCheckId === 'new' ? 'button.cancel' : 'button.close'} />
          </Button>
          {inventoryCheck.status !== 'done' &&
            <LoadingButton
              color="primary"
              onClick={handleSave}
              disabled={disableSubmitButton || loading || !lockSource || inventoryCheck.merchandises.length === 0}
              loading={loading}
              loadingPosition="start"
              loadingIndicator={<CircularProgress size={24} />}
              startIcon={<div />}
              variant="contained"
            >
              <FormattedMessage id="button.save" />
            </LoadingButton>
          }
          {inventoryCheck.status === 'done' &&
            <LoadingButton
              color="success"
              onClick={handleSave}
              disabled={disableSubmitButton || loading}
              loading={loading}
              loadingPosition="start"
              loadingIndicator={<CircularProgress size={24} />}
              startIcon={<div />}
              variant="contained"
            >
              <FormattedMessage id="button.submit" />
            </LoadingButton>
          }
        </Stack>
      </Box>
    </div>
  );
}


// EditInventoryCheckPage.defaultProps = {

// }

EditInventoryCheckPage.propTypes = {
  checkpoint: PropTypes.object.isRequired,
};

export default EditInventoryCheckPage;
