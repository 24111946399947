const i18n = {
  'editWorkflow': {
    'addStep': '新增簽核步驟',
    'deleteStep': '刪除此步驟',
    'inventoryCheckSteps': {
      'table': {
        'title': '庫存盤點流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '盤點人',
          'approver': '簽核人',
        }
      },
      'firstStep': '建立盤點單',
      'finalStep': '解鎖盤點單',
    },
    'requisitionSteps': {
      'table': {
        'title': '請購流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '請購人',
          'approver': '簽核人',
          'skipCategories': '略過商品分類',
        }
      },
      'firstStep': '送出請購單',
      'finalStep': '編輯已完成的請購單',
    },
    'purchaseOrderSteps': {
      'table': {
        'title': '採購流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '採購人',
          'approver': '簽核人',
          'skipCategories': '略過商品分類',
        }
      },
      'firstStep': '送出採購單',
      'finalStep': '手動結單',
    },
    'receiptSteps': {
      'table': {
        'title': '進貨流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '進貨者',
          'approver': '簽核人',
          'skipCategories': '略過商品分類',
        }
      },
      'firstStep': '送出進貨單',
      'finalStep': '編輯已完成的進貨單',
    },
    'newSupplierSteps': {
      'table': {
        'title': '新供應商申請流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '申請人',
          'approver': '簽核人',
        }
      },
      'firstStep': '送出新供應商申請單',
      'finalStep': '通知建立資料',
    },
    'newMerchandiseSteps': {
      'table': {
        'title': '新商品申請流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '申請人',
          'approver': '簽核人',
        }
      },
      'firstStep': '送出新商品申請單',
      'finalStep': '通知建立資料',
    },
    'newProductSteps': {
      'table': {
        'title': '新療程申請流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '申請人',
          'approver': '簽核人',
        }
      },
      'firstStep': '送出新療程申請單',
      'finalStep': '通知建立資料',
    },
    'stockRequisitionSteps': {
      'table': {
        'title': '沙貨領用流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '申請人',
          'approver': '簽核人',
          'skipCategories': '略過商品分類',
        }
      },
      'firstStep': '送出沙貨領用單',
    },
    'scrapFormSteps': {
      'table': {
        'title': '報廢出庫流程',
        'detail': {
          'name': '步驟名稱',
          'creator': '申請人',
          'approver': '簽核人',
        }
      },
      'firstStep': '送出報廢出庫單',
    },
  },
  'editBorrowingStaff': {
    'table': {
      'title': '借貨流程',
      'borrowingOrder': '建立借貨單',
      'borrowingDelivery': '建立借貨出貨單',
      'borrowingReceipt': '建立借貨入庫單',
      'returnBorrowingForm': '建立借貨歸還單',
      'returnBorrowingReceipt': '驗收借貨歸還入庫單',
      'detail': {
        'name': '步驟名稱',
        'user': '使用者',
      }
    },
  },
  'editWarehouseStaff': {
    'table': {
      'title': '倉管流程',
      'query': '庫存查詢',
      'inventory': '庫存盤點',
      'deliveryOrder': '建立銷貨單',
      'returnForm': '建立退貨單',
      'detail': {
        'name': '步驟名稱',
        'user': '使用者',
      }
    },
  },
}
export default i18n
