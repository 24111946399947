import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { arrayMoveImmutable } from 'array-move';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import firebase from 'firebase/app';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import DehazeIcon from '@mui/icons-material/Dehaze';
import Stack from '@mui/material/Stack';

import ContextStore from '../../modules/context';
import DelectIconButton from '../../components/DelectIconButton';
import SimpleTableToolbar from '../../components/SimpleTableToolbar';
import SelectUserDialog from './SelectUserDialog';
import SelectCategoryDialog from './SelectCategoryDialog';
import WorkflowSteps from '../../components/WorkflowSteps';
import { useFirestoreDataAndMapping } from '../../modules/uitls';

const rowStyle = {
  borderTop: 'solid 1px #efefef',
  width: '100%',
  height: '80px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#fff',
  padding: '0px 16px 0px 8px',
  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)',
}

const cellStyle = {
  marginLeft: '8px',
  marginRight: '8px',
  flexGrow: 2
}

const StepRow = styled('div')(() => ({
  width: '100%',
  height: '80px',
  backgroundColor: '#fff',
  padding: '0px 68px 0px 40px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}));

const DragHandle = sortableHandle(() => <DehazeIcon style={{ zIndex: 2, cursor: 'row-resize' }}/>);

const SortableItem = sortableElement(({ content, onDeleteItem }) => {
  const { formatMessage } = useIntl()
  return <div style={rowStyle}>
    <DragHandle />
    <div style={cellStyle}>{content}</div>
    <DelectIconButton text={formatMessage({ id: 'editWorkflow.deleteStep' })} onClick={onDeleteItem} />
  </div>
});

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

function StepRowItem({ workflowName, categoryFilter, rowData, index, onChange, showSelectUserPopup, showSelectCategoryPopup }) {
  const { formatMessage } = useIntl()
  const categoriesMapping = ['DPO', 'DOI', 'DIV', 'DSC', 'CIN', 'CPR', 'CBD', 'CIP', 'CCO', 'CWI', 'COT']
    .reduce((acc, cur) => {acc[cur] = formatMessage({ id: `merchandise.category.${cur}` }); return acc}, {})

  return (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <TextField
          required
          type="text"
          label={formatMessage({ id: `editWorkflow.${workflowName}.table.detail.name` })}
          variant="outlined"
          value={rowData.name}
          onChange={e => onChange(index, 'name', e.target.value)}
          error={rowData.name_err ? true : false}
          helperText={rowData.name_err}
          fullWidth
        />
      </Grid>
      <Grid item xs={categoryFilter ? 6 : 9}>
        <TextField
          required
          type="text"
          label={formatMessage({ id: `editWorkflow.${workflowName}.table.detail.approver` })}
          variant="outlined"
          value={rowData.users.map(s => s.name).join(', ')}
          onClick={() => showSelectUserPopup(index)}
          error={rowData.users_err ? true : false}
          helperText={rowData.users_err}
          fullWidth
        />
      </Grid>
      {categoryFilter && <Grid item xs={3}>
        <TextField
          type="text"
          label={formatMessage({ id: `editWorkflow.${workflowName}.table.detail.skipCategories` })}
          variant="outlined"
          value={rowData.skipCategories.map(s => categoriesMapping[s]).join(', ')}
          onClick={() => showSelectCategoryPopup(index)}
          fullWidth
        />
      </Grid>}
    </Grid>
  )
}

StepRowItem.propTypes = {
  rowData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    skipCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
    name_err: PropTypes.string,
    users_err: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  showSelectUserPopup: PropTypes.func.isRequired,
  showSelectCategoryPopup: PropTypes.func.isRequired,
};

function EditWorkflow() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const { customerId, workflowName } = useParams()
  const categoryFilter = ['requisitionSteps', 'purchaseOrderSteps', 'stockRequisitionSteps'].includes(workflowName)
  const additionalStep = [
    'requisitionSteps',
    'purchaseOrderSteps',
    'inventoryCheckSteps',
    'newSupplierSteps',
    'newMerchandiseSteps',
    'newProductSteps',
    'scrapFormSteps'
  ].includes(workflowName)
  const noFinalStep = ['stockRequisitionSteps', 'scrapFormSteps'].includes(workflowName)
  const finalStepField = workflowName === 'inventoryCheckSteps' ? 'unlock' : 'overwrite'

  const [userMapping, users] = useFirestoreDataAndMapping('users')
  const [customerMapping, customers] = useFirestoreDataAndMapping('customers')

  const [userDialogData, setUserDialogData] = useState(null);
  const [categoryDialogData, setCategoryDialogData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [approveSteps, setApproveSteps] = useState([]);
  const [firstStep, setFirstStep] = useState({
    name: formatMessage({ id: `editWorkflow.${workflowName}.firstStep` }),
    users: [],
    skipCategories: []
  });
  const [finalStep, setFinalStep] = useState(noFinalStep ? {} : {
    name: formatMessage({ id: `editWorkflow.${workflowName}.finalStep` }),
    users: [],
    skipCategories: []
  });

  const allSteps = workflowName === 'inventoryCheckSteps' ?
    [firstStep, { ...firstStep, name: '送出盤點結果' }, ...approveSteps, finalStep] :
    (noFinalStep ? [firstStep, ...approveSteps] : [firstStep, ...approveSteps, finalStep])

  function onSortEnd({ oldIndex, newIndex }) {
    setApproveSteps(arrayMoveImmutable(approveSteps, oldIndex, newIndex))
  };

  useEffect(() => {
    setBreadcrumbs([{
      link: '/vendor',
      text: formatMessage({ id: 'sideMenu.vendor' })
    },{
      text: formatMessage({ id: `supplier.${workflowName}.edit` })
    }])
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    if (customers.length) {
      const customer = customerMapping[customerId]
      if (!customer) {
        navigate('/vendor');
        return
      }
      const steps = customer[workflowName]
      if (steps.length > 0) {
        setFirstStep({
          name: steps[0].name,
          users: steps[0].users.map(u => ({ id: u, name: userMapping[u] ? userMapping[u].displayName : '' })),
          skipCategories: [],
        })

        setApproveSteps(steps.slice(1, steps.length).map(s => ({
          name: s.name,
          users: s.users.map(u => ({ id: u, name: userMapping[u] ? userMapping[u].displayName : '' })),
          skipCategories: s.skipCategories || []
        })))

        if (!noFinalStep) {
          setFinalStep({
            name: formatMessage({ id: `editWorkflow.${workflowName}.finalStep` }),
            users: steps[0][finalStepField].map(u => ({ id: u, name: userMapping[u] ? userMapping[u].displayName : '' })),
            skipCategories: [],
          })
        }
      }
    }
  }, [customers.length, users.length, workflowName, customerId]);

  function onDeleteItem(index) {
    const newData = [...approveSteps]
    newData.splice(index, 1)
    setApproveSteps(newData)
  }

  function onSkipCategoriesChanged(index, categories) {
    updateApproveStep(index, 'skipCategories', categories)
  }

  function onStepUsersChanged(index, users) {
    if (index === -1) {
      const newData = { ...firstStep, users: users }
      if (!users.length) {
        newData.users_err = formatMessage({ id: 'form.field.isRequired' })
      } else {
        delete newData.users_err
      }
      setFirstStep(newData)
    } else if (index === -2) {
      const newData = { ...finalStep, users: users }
      if (!users.length) {
        newData.users_err = formatMessage({ id: 'form.field.isRequired' })
      } else {
        delete newData.users_err
      }
      setFinalStep(newData)
    } else {
      updateApproveStep(index, 'users', users)
    }
  }

  function updateApproveStep(index, field, value) {
    // validate field
    const step = { name: approveSteps[index].name, users: approveSteps[index].users, skipCategories: approveSteps[index].skipCategories }
    if (field === 'name' && value.trim() === '') {
      step.name_err = formatMessage({ id: 'form.field.isRequired' })
    }
    else if (field === 'users' && !value.length) {
      step.users_err = formatMessage({ id: 'form.field.isRequired' })
    }

    step[field] = value
    const newData = [...approveSteps]
    newData[index] = step

    setApproveSteps(newData)
  }

  function addStep() {
    const newData = [...approveSteps, { name: '', users: [], skipCategories: [] }]
    setApproveSteps(newData)
  }

  function showSelectUserPopup(index) {
    let users = null
    if (index === -1) users = firstStep.users
    else if (index === -2) users = finalStep.users
    else users = approveSteps[index].users
    setUserDialogData({ index, users })
  }

  function showSelectCategoryPopup(index) {
    setCategoryDialogData({ index, categories: approveSteps[index].skipCategories })
  }

  async function handleSave() {
    setLoading(true);

    let err = false
    for (const step of approveSteps) {
      if (step.name.trim() === '') {
        step.name_err = formatMessage({ id: 'form.field.isRequired' })
        err = true
      }
      if (step.users.length === 0) {
        step.users_err = formatMessage({ id: 'form.field.isRequired' })
        err = true
      }
    }
    if (err) {
      setApproveSteps([...approveSteps])
      setLoading(false);
      return
    }

    let newSteps = noFinalStep ?
      [{ ...firstStep }, ...(approveSteps.map(i => ({ ...i })))] :
      [{ ...firstStep }, ...(approveSteps.map(i => ({ ...i }))), { ...finalStep }]
    let fields = ['name', 'users', 'skipCategories']
    for (const step of newSteps) {
      for (const field of fields) {
        if (step[`${field}_err`] !== undefined && step[`${field}_err`] !== '') {
          setLoading(false);
          return
        }
      }
    }

    for (const step of newSteps) {
      step.users = step.users.map(s => s.id)
    }

    for (const step of newSteps) {
      if (categoryFilter) {
        step.skipCategories = step.skipCategories || []
      } else {
        delete step.skipCategories
      }
    }

    if (!noFinalStep) {
      const tmp = newSteps.pop()
      newSteps[0][finalStepField] = tmp.users
    }

    try {
      await (firebase.functions().httpsCallable('updateWorkflow'))({ id: customerId, [workflowName]: newSteps })
    } catch (ex) {
      console.log(ex)
    }
    handleClose()
  }

  function handleClose() {
    navigate('/vendor');
  }

  function getToolbox() {
    return (
      <div>
        <Button
          disabled={currentUser.userRights['vendor-edit'] !== true}
          sx={{ m: 1, whiteSpace: 'nowrap' }}
          variant="contained"
          color="primary"
          onClick={() => addStep()}
        >
          <FormattedMessage id="editWorkflow.addStep" />
        </Button>
      </div>
    )
  }

  return (
    <div style={{ flexGrow: 1 }}>
      {userDialogData && <SelectUserDialog
        handleClose={() => setUserDialogData(null)}
        handleSave={onStepUsersChanged}
        index={userDialogData.index}
        defaultSelectedItems={userDialogData.users}
      />}
      {categoryDialogData && <SelectCategoryDialog
        handleClose={() => setCategoryDialogData(null)}
        handleSave={onSkipCategoriesChanged}
        index={categoryDialogData.index}
        defaultSelectedItems={categoryDialogData.categories}
      />}
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <SimpleTableToolbar
          title={`editWorkflow.${workflowName}.table.title`}
          note={`(${customerMapping[customerId].nickname})`}
          toolbox={additionalStep ? getToolbox() : null}
        />
        <Paper sx={{ overflow: 'hidden' }}>
          <StepRow>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  disabled
                  type="text"
                  label={formatMessage({ id: `editWorkflow.${workflowName}.table.detail.name` })}
                  variant="outlined"
                  value={firstStep.name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  required
                  type="text"
                  label={formatMessage({ id: `editWorkflow.${workflowName}.table.detail.creator` })}
                  variant="outlined"
                  fullWidth
                  // value=""
                  value={firstStep.users.map(s => s.name).join(', ')}
                  onClick={() => showSelectUserPopup(-1)}
                  error={firstStep.users_err ? true : false}
                  helperText={firstStep.users_err}
                />
              </Grid>
            </Grid>
          </StepRow>
          {additionalStep ? <SortableContainer onSortEnd={onSortEnd} useDragHandle>
            {approveSteps.map((step, index) => (
              <SortableItem
                key={`approve-step-item-${index}`}
                index={index}
                content={<StepRowItem
                  workflowName={workflowName}
                  categoryFilter={categoryFilter}
                  index={index}
                  rowData={step}
                  onChange={updateApproveStep}
                  showSelectUserPopup={showSelectUserPopup}
                  showSelectCategoryPopup={showSelectCategoryPopup}
                />}
                onDeleteItem={() => onDeleteItem(index)}
              />
            ))}
          </SortableContainer> :
            <div>
              {approveSteps.map((step, index) => (
                <StepRow key={`approve-step-item-${index}`}>
                  <StepRowItem
                    workflowName={workflowName}
                    categoryFilter={categoryFilter}
                    index={index}
                    rowData={step}
                    onChange={updateApproveStep}
                    showSelectUserPopup={showSelectUserPopup}
                    showSelectCategoryPopup={showSelectCategoryPopup}
                  />
                </StepRow>
              ))}
            </div>
          }
          <Divider />
          {!noFinalStep && <StepRow>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  disabled
                  type="text"
                  label={formatMessage({ id: `editWorkflow.${workflowName}.table.detail.name` })}
                  variant="outlined"
                  value={finalStep.name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  required
                  type="text"
                  label="指定使用者"
                  variant="outlined"
                  fullWidth
                  // value=""
                  value={finalStep.users.map(s => s.name).join(', ')}
                  onClick={() => showSelectUserPopup(-2)}
                  error={finalStep.users_err ? true : false}
                  helperText={finalStep.users_err}
                />
              </Grid>
            </Grid>
          </StepRow>}
        </Paper>
        <Divider style={{ margin: '8px 0px' }} />
        <Paper sx={{ overflow: 'hidden', pt: '16px' }}>
          <WorkflowSteps steps={allSteps} />
        </Paper>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            disabled={currentUser.userRights['vendor-edit'] !== true || loading || approveSteps.length === 0}
            loading={loading}
            loadingPosition="start"
            loadingIndicator={<CircularProgress size={24} />}
            startIcon={<div />}
            variant="contained"
          >
            <FormattedMessage id="button.submit" />
          </LoadingButton>
        </Stack>
      </Box>
    </div>
  );
}

export default EditWorkflow;
