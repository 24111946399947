import { createContext } from 'react';

const ContextStore = createContext({
  breadcrumbs: [],
  setBreadcrumbs: () => {},
  purchaseOrderCache: {},
  setPurchaseOrderCache: () => {},
  currentUser: null,
})

export default ContextStore
