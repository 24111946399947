import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import EnhancedTable from '../../components/EnhancedTable';
import { unwrap } from '../../modules/uitls';

function DoctorBonusView({ tableId, productMapping, userMapping, yearMonth, customerName }) {
  const { formatMessage } = useIntl()
  const [doctorBounsData, setDoctorBounsData] = useState([]);

  useEffect(() => {
    const onSnapshot = snapshot => {
      const list = []
      const data = unwrap(snapshot.data())
      const keys = Object.keys(data.doctorBounsList)
      for (const key of keys) {
        list.push({ ...data.doctorBounsList[key], id: key })
      }
      setDoctorBounsData(list)
    }
    const unsubscribe = firebase.firestore().collection('financeData').doc(tableId).onSnapshot(onSnapshot, err => {})
    return () => unsubscribe()
  }, [tableId]);

  const headerCells = [
    { field: 'kardexDate' },
    { field: 'kardexId' },
    { field: 'doctorName' },
    { field: 'patientName' },
    { field: 'productCode' },
    { field: 'productName' },
    { field: 'quantity' },
    { field: 'price' },
    { field: 'bonus' }
  ].map(c => {c.text = formatMessage({ id: `doctorBonus.detail.${c.field}` });return c})

  const rowCells = [
    { field: 'kardexDate' },
    { field: 'kardexId' },
    { field: 'doctorName' },
    { field: 'patientName' },
    { field: 'productCode' },
    { field: 'productName' },
    { field: 'quantity' },
    { field: 'price' },
    { field: 'bonus' }
  ]

  function formatData(data) {
    return {
      ...data,
      productCode: productMapping[data.productId]?.code ?? '',
      productName: productMapping[data.productId]?.name ?? '',
      doctorName: userMapping[data.doctorId] ?? '',
      bonusType: formatMessage({ id: `product.doctorBonusType.${data.bonusType}` })
      // statusText,
    }
  }

  async function exportData() {
    const fields = ['kardexDate', 'kardexId', 'doctorName', 'patientName', 'productCode', 'productName', 'quantity', 'price', 'bonusType', 'cost', 'bonus']
    const rows = ['#,' + fields.map(i => formatMessage({ id: `doctorBonus.detail.${i}` })).join(',')]
    const list = doctorBounsData.map(i => formatData(i))
    for (const idx in list) {
      const item = list[idx]
      const rowData = [String(parseInt(idx) + 1)]
      for (const field of fields) {
        rowData.push(item[field])
      }
      rows.push(rowData.join(','))
    }
    const content = rows.join('\n')
    const csvData = new Blob(['\uFEFF' + content], { type: 'text/csv' })
    const csvUrl = URL.createObjectURL(csvData)
    let aExport = document.createElement('a')
    aExport.href = csvUrl
    aExport.target = '_blank'
    aExport.download = `${yearMonth}_${customerName}_醫師獎金.csv`
    aExport.click()
    aExport = null
  }

  return (
    <div style={{ flexGrow: 1, height: '100%' }}>
      <Box p={0}>
        <EnhancedTable
          size="small"
          defaultOrder="asc"
          defaultOrderField="code"
          headerCells={headerCells}
          rowCells={rowCells}
          tableData={doctorBounsData.map(i => formatData(i))}
        />

        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item key="buttons" xs={12} sm={12} md={12}>
            <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
              <LoadingButton
                color="primary"
                onClick={exportData}
                // disabled={loadingVoid}
                // loading={loadingVoid}
                loadingPosition="start"
                loadingIndicator={<CircularProgress size={24} />}
                startIcon={<div />}
                variant="contained"
              >
                <FormattedMessage id="button.export" />
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

DoctorBonusView.propTypes = {
  tableId: PropTypes.string.isRequired,
  productMapping: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  yearMonth: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
};

export default DoctorBonusView;
