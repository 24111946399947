import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import ContextStore from '../../modules/context';

function FixedAssets() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const location = useLocation();

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.fixed-assets' })
    }])
    return () => {
    };
  }, [location.pathname]);

  return (
    <div>FixedAssets</div>
  );
}

export default FixedAssets;
