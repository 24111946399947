import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';

import ContextStore from '../../modules/context';
import DateRangePickerField from '../../components/DateRangePickerField';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import SimpleTableToolbar from '../../components/SimpleTableToolbar';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { getComparator, stableSort } from '../../modules/sort';
import ExpandButton from '../../components/ExpandButton';
import SearchBox from '../../components/SearchBox';
import FabAdd from '../../components/FabAdd';
import BorrowingOrderView from './BorrowingOrderView';
import { useFirestoreDataAndMapping, useSupplierDataAndMapping } from '../../modules/uitls';

function BorrowingOrderDetail({ userMapping, borrowingOrder, ...props }) {
  const r = { ...borrowingOrder }
  const keys = borrowingOrder.merchandises ? Object.keys(borrowingOrder.merchandises) : []
  r.merchandises = keys.map(k => ({ ...r.merchandises[k], id: k }))
  r.createdBy = { id: r.createdBy, name: userMapping[r.createdBy]?.displayName }
  return (
    <div style={{ padding: 15 }}>
      <BorrowingOrderView userMapping={userMapping} borrowingOrder={r} {...props} />
    </div>
  )
}

BorrowingOrderDetail.propTypes = {
  borrowingOrder: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
};

function BorrowingOrderList() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const location = useLocation()

  const [userMapping] = useFirestoreDataAndMapping('users')
  const [customerMapping] = useFirestoreDataAndMapping('customers')
  const [supplierMapping] = useSupplierDataAndMapping()
  const [merchandiseMapping] = useFirestoreDataAndMapping('merchandises')

  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('sn')
  const [currentFilter, setCurrentFilter] = useState(null)
  const [expand, setExpand] = useState(true);
  const [borrowingOrders, setBorrowingOrders] = useState([])
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.borrowing.borrowingOrder' })
    }])
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    if (borrowingOrders.length) setBorrowingOrders([])
    const onSnapshot = snapshot => {
      const borrowingOrders = []
      snapshot.forEach(doc => {
        borrowingOrders.push({ ...doc.data(), id: doc.id })
      });
      setBorrowingOrders(borrowingOrders)
    }
    const unsubscribe = firebase.firestore().collection('borrowingOrders').where('date', '>=', startDate).where('date', '<=', endDate).onSnapshot(onSnapshot, err => {})
    return () => unsubscribe()
  }, [startDate, endDate]);

  const borrowingOrdersForTab = borrowingOrders.filter(r =>
    currentUser.userRights.debugging || currentUser.borrowingOrderSource.includes(r.source)
  );

  // const reqWaitingForProcess = waitingForProcess()
  const filteredBorrowingOrders = currentFilter && currentFilter.text ? filterByText() : borrowingOrdersForTab

  const headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'sourceName', sort: 'sourceName' },
    { text: 'supplierName', sort: 'supplierName' },
    { text: 'createdBy', sort: 'createdBy' },
    { text: 'date', sort: 'createdAt' },
    // {text: 'expectedDate', sort: 'expectedDate'},
  ].map(c => {c.text = formatMessage({ id: `borrowingOrder.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'sn' },
    { field: 'sourceName' },
    { field: 'supplierName' },
    { field: 'createdBy' },
    { field: 'date' },
    // {field: 'expectedDate'},
  ]

  function filterByText() {
    if (currentFilter.name === 'sourceName') {
      return borrowingOrdersForTab.filter(s => customerMapping[s.source]?.nickname.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'supplierName') {
      return borrowingOrdersForTab.filter(s => supplierMapping[s.supplier]?.nickname.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else if (currentFilter.name === 'createdBy') {
      return borrowingOrdersForTab.filter(s => userMapping[s.createdBy]?.displayName.toLowerCase().includes(currentFilter.text.toLowerCase()))
    } else {
      return borrowingOrdersForTab.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
  }

  const formatData = (borrowingOrder) => {
    const newData = { ...borrowingOrder }
    newData.sourceName = customerMapping[newData.source]?.nickname
    newData.supplierName = supplierMapping[newData.supplier]?.nickname
    newData.createdBy = userMapping[newData.createdBy]?.displayName
    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filterItems = [
    { name: 'sn' },
    { name: 'sourceName', type: 'customer' },
    { name: 'createdBy' },
    { name: 'date' },
    { name: 'note' },
  ].map(i => {i.text = formatMessage({ id: `borrowingOrder.table.detail.${i.name}` });return i})

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  return (
    <div style={{ padding: '24px 24px 60px 24px' }}>
      {currentUser.userRights['borrowingOrder-create'] === true && <FabAdd to="/borrowing/borrowingOrder/edit/new" />}
      <DateRangePickerField
        startDate={startDate}
        endDate={endDate}
        onStartDateChanged={setStartDate}
        onEndDateChanged={setEndDate}
      />
      <SimpleTableToolbar
        title="borrowingOrder.table.title"
        buttons={<ExpandButton open={expand} onExpandChange={setExpand} />}
        toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
      />
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <TableContainer component={Paper}>
          <Table>
            <EnhancedTableHead
              headerCells={headerCells}
              order={order}
              orderBy={orderBy}
              expandable
              onRequestSort={handleRequestSort}
              rowCount={filteredBorrowingOrders.length}
            />
            <TableBody>
              {stableSort(filteredBorrowingOrders.map(r => formatData(r)), getComparator(order, orderBy)).map(borrowingOrder => (
                <EnhancedTableRow
                  key={borrowingOrder.id}
                  rowCells={rowCells}
                  cellData={borrowingOrder}
                  expandable
                  expandContent={<BorrowingOrderDetail userMapping={userMapping} merchandiseMapping={merchandiseMapping} borrowingOrder={borrowingOrder} />}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </div>
  );
}

export default BorrowingOrderList;
