import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Grid from '@mui/material/Grid';

import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableRow from './EnhancedTableRow';
import SearchBox from './SearchBox';
import { getComparator, stableSort } from '../modules/sort';
import { useFirestoreData } from '../modules/uitls';

function SelectMedicinelMerchandiseDialog({ defaultSelectedItems, handleClose, handleSave, customers, defaultCategoryFilter = {}, hideFields = [], ignoreIds, allowIds, size = 'xl' }) {
  const { formatMessage } = useIntl()

  const merchandises = useFirestoreData('merchandises')
    .reduce((acc, cur) => {
      let err = false
      for (const c of customers) {
        if (!cur.customers[c]) {
          err = true
        }
      }

      if (!err) {
        acc.push(cur)
      }

      return acc
    }, [])
    .filter(m => !allowIds || allowIds.includes(m.id))
    .filter(m => !ignoreIds.includes(m.id)).map(m => {
      return {
        ...m,
      }
    })

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const [selectedItems, setSelectedItems] = useState(defaultSelectedItems.reduce((acc, cur) => { acc[cur.id] = true; return acc; }, {}));

  const [currentFilter, setCurrentFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState(defaultCategoryFilter);

  const filteredMerchandises = filterMerchandises()
  const filteredselection = filteredMerchandises.filter(s => selectedItems[s.id]).reduce((acc, cur) => { acc[cur.id] = true; return acc; }, {})

  const _headerCells = [
    { text: 'code', sort: 'code' },
    // {text: 'name', sort: 'name'},
    { text: 'nickname', sort: 'nickname' },
    { text: 'orderUnit' },
    // {text: 'price'},
    { text: 'stock' },
    { text: 'note' },
  ]

  const _rowCells = [
    { field: 'code' },
    // {field: 'name'},
    { field: 'nickname', tooltip: 'name' },
    { field: 'orderUnit' },
    // {field: 'price'},
    { field: 'stock' },
    { field: 'note' },
  ]

  const headerCells = _headerCells.filter(i => !hideFields.includes(i.text))
    .map(c => { c.text = formatMessage({ id: `selectMerchandiseDialog.table.detail.${c.text}` }); return c })

  const rowCells = _rowCells.filter(i => !hideFields.includes(i.field))

  const filterItems = [
    { name: 'nickname' },
    { name: 'name' },
    { name: 'code' },
    { name: 'note' },
  ].map(i => { i.text = formatMessage({ id: `merchandise.table.detail.${i.name}` }); return i })

  const categoryFilterItems = [
    { name: 'type' },
    { name: 'category' },
  ].map(i => { i.text = formatMessage({ id: `merchandise.table.detail.${i.name}` }); return i })

  function formatData(merchandise) {
    const newData = { ...merchandise }
    if (newData.orderUnit !== newData.sku) {
      newData.orderUnit += `(${newData.ou2sku}${newData.sku})`
    }

    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredMerchandises.reduce((acc, cur) => { acc[cur.id] = true; return acc }, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  };

  const handleCheckboxClick = (id) => {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  function filterMerchandises() {
    if (!currentFilter &&
      !categoryFilter.type &&
      !categoryFilter.category) {
      return merchandises
    }
    let items = [...merchandises]
    if (categoryFilter.type) {
      if (categoryFilter.type === 'g') {
        items = items.filter(i => ['G', 'B'].includes(i.type))
      } else if (categoryFilter.type === 'i') {
        items = items.filter(i => ['I', 'B'].includes(i.type))
      }
    }
    if (categoryFilter.category) {
      items = items.filter(i => i.category === categoryFilter.category)
    }

    if (currentFilter) {
      items = items.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
    return items
  }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  function getMenuItem(name) {
    if (name === 'type') {
      return [{ value: '' }, { value: 'g' }, { value: 'i' }].map(i => {
        i.label = i.value ? formatMessage({ id: `merchandise.type.${i.value}` }) : formatMessage({ id: 'merchandise.category.all' })
        return i
      })
    } else if (name === 'category') {
      return [
        { value: '' },
        { value: 'M', header: true },
        { value: 'DPO' },
        { value: 'DOI' },
        { value: 'DIV' },
        { value: 'DSC' },
        { value: 'I', header: true },
        { value: 'CIN' },
        { value: 'CPR' },
        { value: 'CBD' },
        { value: 'CIP' },
        { value: 'CCO' },
        { value: 'CWI' },
        { value: 'COT' },
      ].map(i => {
        i.label = i.value ? formatMessage({ id: `merchandise.category.${i.value}` }) : formatMessage({ id: 'merchandise.category.all' })
        return i
      })
    }

    return []
  }

  function updateCategoryFilter(name, value) {
    const newFilter = { ...categoryFilter }
    newFilter[name] = value
    setCategoryFilter(newFilter)
  }

  const getFilter = (filter) => {
    return <Grid item key={filter.name} xs={3} sm={3} md={3}>
      <TextField
        select
        disabled={!!defaultCategoryFilter[filter.name]}
        type="text"
        size="small"
        label={filter.text}
        variant="outlined"
        onChange={e => { updateCategoryFilter(filter.name, e.target.value) }}
        value={categoryFilter[filter.name] || ''}
        fullWidth
      >
        {getMenuItem(filter.name).map((option, idx) => {
          return option.header ?
            (<ListSubheader key={`${option.value}-${idx}`}>{option.label}</ListSubheader>) :
            (<MenuItem key={`${option.value}-${idx}`} value={option.value}>{option.label}</MenuItem>)
        })}
      </TextField>
    </Grid>
  }

  const onApply = () => {
    const selectedMerchandises = merchandises.filter(s => selectedItems[s.id]).map(s => ({
      code: s.code,
      name: s.name,
      sku: s.sku,
      orderUnit: s.orderUnit,
      ou2sku: s.ou2sku,
      nickname: s.nickname,
      id: s.id,
    }))
    handleSave(selectedMerchandises)
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={size}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title"><FormattedMessage id="selectMerchandiseDialog.title" /></DialogTitle>
      <DialogContent dividers={true}>
        <div style={{ flexGrow: 1 }}>
          <Toolbar sx={{ pl: 2, pr: 1 }}>
            <Typography sx={{ flex: '1 1 100%', flexShrink: 2 }} variant="h6" id="tableTitle" component="div">
              <FormattedMessage id="merchandise.table.title" />
            </Typography>
            <Grid container spacing={3}>
              {categoryFilterItems.map(f => getFilter(f))}
            </Grid>
            <SearchBox autoFocus filterItems={filterItems} onFilterChanged={onFilterChanged} />
          </Toolbar>
          <TableContainer component={Paper}>
            <Table>
              <EnhancedTableHead
                headerCells={headerCells}
                numSelected={Object.keys(filteredselection).length}
                order={order}
                orderBy={orderBy}
                onCheckboxClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={filteredMerchandises.length}
              />
              <TableBody>
                {stableSort(filteredMerchandises.map(m => formatData(m)), getComparator(order, orderBy)).map(merchandise => (
                  <EnhancedTableRow
                    key={merchandise.id}
                    rowCells={rowCells}
                    cellData={merchandise}
                    onCheckboxClick={(e) => handleCheckboxClick(merchandise.id)}
                    selected={selectedItems[merchandise.id] || false}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button variant="contained" onClick={onApply} color="primary">
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SelectMedicinelMerchandiseDialog.propTypes = {
  customers: PropTypes.array.isRequired,
  ignoreIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  allowIds: PropTypes.arrayOf(PropTypes.string),
  defaultCategoryFilter: PropTypes.object,
  defaultSelectedItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    nickname: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })),
  hideFields: PropTypes.arrayOf(PropTypes.string),
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  size: PropTypes.string,
};

export default SelectMedicinelMerchandiseDialog;
