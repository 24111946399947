import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { getComparator, stableSort } from '../../modules/sort';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import SimpleTableToolbar from '../../components/SimpleTableToolbar';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import ExpandButton from '../../components/ExpandButton';
import SearchBox from '../../components/SearchBox';

import { useFirestoreDataAndMapping } from '../../modules/uitls';
import ContextStore from '../../modules/context';
import SelectYMCustomerDialog from '../../components/SelectYMCustomerDialog';
import FabAdd from '../../components/FabAdd';
import StockReportView from './StockReportView'

function StockReport() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const location = useLocation()
  const [merchandiseMapping] = useFirestoreDataAndMapping('merchandises')
  const [expand, setExpand] = useState(true);
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('date')
  const [currentFilter, setCurrentFilter] = useState(null)
  const [reportTables, setReportTables] = useState([])
  const [stockReportDialog, setStockReportDialog] = useState(null)
  const [, _customers] = useFirestoreDataAndMapping('customers')
  const customers = _customers.filter(c => c.code === 'S000001')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setBreadcrumbs([{
      text: `${formatMessage({ id: 'sideMenu.reports.stock' })}${formatMessage({ id: 'sideMenu.reports.root' })}`
    }])
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    setReportTables([])
    const onSnapshot = snapshot => {
      const tables = []
      snapshot.forEach(doc => {
        tables.push({ ...doc.data(), id: doc.id })
      });
      setReportTables(tables)
    }
    const unsubscribe = firebase.firestore().collection('stockReport').onSnapshot(onSnapshot, err => { })
    return () => unsubscribe()
  }, []);

  function showBonusTableDialog() {
    setStockReportDialog({
      action: 'stock'
    })
  }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  async function handleExecute(data) {
    // 呼叫 cloud function, 開始撈資料作計算
    setLoading(true)
    try {
      await (firebase.functions().httpsCallable('getStockReport'))(data)
      setLoading(false)
    } catch (ex) {
      console.log(ex)
      setLoading(false)
    }
    setStockReportDialog(null)
  }

  function filterTables() {
    let items = [...reportTables]

    if (currentFilter && currentFilter.text) {
      const lowerCaseText = currentFilter.text.toLowerCase()
      return items.filter(s => s[currentFilter.name].toLowerCase().includes(lowerCaseText))
    }
    return items
  }

  const formatData = (reportTable) => {
    const newData = { ...reportTable }
    newData.createdDate = dayjs(newData.createdAt.seconds * 1000).format('YYYY-MM-DD')
    return newData
  }

  const filterItems = [
    { name: 'date' },
    { name: 'createdDate' },
  ].map(i => { i.text = formatMessage({ id: `report.stock.table.${i.name}` }); return i })

  const headerCells = [
    { text: 'date', sort: 'id' },
    { text: 'createdDate', sort: 'createdDate' },
  ].map(c => { c.text = formatMessage({ id: `report.stock.header.${c.text}` }); return c })

  const rowCells = [
    { field: 'id' },
    { field: 'createdDate' },
  ]

  const filteredTables = filterTables()

  return (
    <div style={{ padding: '24px 24px 80px 24px' }}>
      {loading && <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open
      >
        <CircularProgress color="inherit" />
      </Backdrop>}
      {!!currentUser.userRights['finance-create'] && <FabAdd onClick={showBonusTableDialog} />}
      {stockReportDialog && <SelectYMCustomerDialog
        title={formatMessage({ id: 'newStockReportDialog.title' })}
        handleClose={() => setStockReportDialog(null)}
        handleExecute={handleExecute}
        action={stockReportDialog.action}
        customers={customers}
      />}
      <SimpleTableToolbar
        title={'report.stock.title'}
        buttons={<ExpandButton open={expand} onExpandChange={setExpand} />}
        toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
      />
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: '650px' }}>
            <EnhancedTableHead
              headerCells={headerCells}
              order={order}
              orderBy={orderBy}
              expandable
              onRequestSort={handleRequestSort}
              rowCount={filteredTables.length}
            />
            <TableBody>
              {stableSort(filteredTables.map(f => formatData(f)), getComparator(order, orderBy)).map(stockData => (
                <EnhancedTableRow
                  key={stockData.id}
                  rowCells={rowCells}
                  cellData={stockData}
                  expandable
                  expandContent={
                    <StockReportView
                      merchandiseMapping={merchandiseMapping}
                      stockData={stockData}
                    />
                  }
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </div>
  );
}

export default StockReport;
