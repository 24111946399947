import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import ContextStore from '../../modules/context';
import DateRangePickerField from '../../components/DateRangePickerField';
import SalesReturnFormList from './SalesReturnFormList';

function SalesReturnFormTab() {
  const { tabName } = useParams()
  const { currentUser } = useContext(ContextStore)

  const [salesReturnForms, setSalesReturnForms] = useState([])
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  useEffect(() => {
    if (salesReturnForms.length) setSalesReturnForms([])
    const onSnapshot = snapshot => {
      const salesReturnForms = []
      snapshot.forEach(doc => {
        salesReturnForms.push({ ...doc.data(), id: doc.id })
      });
      setSalesReturnForms(salesReturnForms)
    }
    const unsubscribe = tabName === 'pending' ?
      firebase.firestore().collection('salesReturnForms').where('status', '==', tabName).onSnapshot(onSnapshot, err => {}) :
      firebase.firestore().collection('salesReturnForms').where('status', '==', tabName).where('date', '>=', startDate).where('date', '<=', endDate).onSnapshot(onSnapshot, err => {})
    return () => unsubscribe()
  }, [startDate, endDate]);

  const salesReturnFormsForTab = salesReturnForms.filter(r =>
    currentUser.userRights.debugging || currentUser.returnFormSource.includes(r.source)
  );

  return (
    <div style={{ paddingBottom: '60px' }}>
      {tabName !== 'pending' && <DateRangePickerField
        startDate={startDate}
        endDate={endDate}
        onStartDateChanged={setStartDate}
        onEndDateChanged={setEndDate}
      />}
      <SalesReturnFormList salesReturnForms={salesReturnFormsForTab} title="salesReturnForm.table.title" />
    </div>
  );
}

export default SalesReturnFormTab;
