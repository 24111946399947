import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import ContextStore from '../../modules/context';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableToolbar from '../../components/EnhancedTableToolbar';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { useFirestoreDataAndMapping, lockSubmitButton } from '../../modules/uitls';
import { getComparator, stableSort } from '../../modules/sort';
import SelectSupplierDialog from '../../components/SelectSupplierDialog';
import SelectSourceOrderDialog from '../../components/SelectSourceOrderDialog';
import SelectStockDialog from './SelectStockDialog';
import DeliveryAmountDialog from './DeliveryAmountDialog';

function MerchandiseDetail({ merchandise }) {
  const { formatMessage } = useIntl()
  const headerCells = [
    { text: 'expectedDate', sort: 'expectedDate' },
    { text: 'lotNumber' },
    { text: 'amount', align: 'right' },
  ].map(c => {c.text = formatMessage({ id: `selectStockDialog.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'expectedDate' },
    { field: 'lotNumber' },
    { field: 'amount', align: 'right' },
  ]

  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="collapsible table">
          <EnhancedTableHead
            headerCells={headerCells}
            numSelected={0}
            rowCount={merchandise.items.length}
          />
          <TableBody>
            {merchandise.items.map(m => (
              <EnhancedTableRow
                key={m.id}
                rowCells={rowCells}
                cellData={m}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

MerchandiseDetail.propTypes = {
  merchandise: PropTypes.object.isRequired,
};

function EditDeliveryOrderPage({ formName }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const { deliveryOrderId } = useParams()

  let refSn = ''
  let refId = ''
  let deliveryOrderSourceField = ''
  if (formName === 'deliveryOrder') {
    // pageName = 'editDeliveryOrderPage'
    refSn = 'purchaseOrderSn'
    refId = 'purchaseOrderId'
    deliveryOrderSourceField = 'deliveryOrderSource'
  } else if (formName === 'borrowingDelivery') {
    // pageName = 'editBorrowingDelivery'
    refSn = 'borrowingOrderSn'
    refId = 'borrowingOrderId'
    deliveryOrderSourceField = 'borrowingDeliverySource'
  } else if (formName === 'returnBorrowingForm') {
    // pageName = 'editReturnBorrowingForm'
    refSn = 'borrowingOrderSn'
    refId = 'borrowingOrderId'
    deliveryOrderSourceField = 'returnBorrowingFormSource'
  }

  const [customerMapping, customers] = useFirestoreDataAndMapping('customers')
  const [supplierMapping] = useFirestoreDataAndMapping('suppliers')

  const filteredSources = customers.filter(c => currentUser[deliveryOrderSourceField].includes(c.id))
  const [merchandiseMapping, merchandises] = useFirestoreDataAndMapping('merchandises')
  const [userMapping] = useFirestoreDataAndMapping('users')

  const [selectedItems, setSelectedItems] = useState({});
  const [sourceOrderDialog, setSourceOrderDialog] = useState(false);

  const [loading, setLoading] = useState(false);
  const [lockSource, setLockSource] = useState(false);
  const [lockDestination, setLockDestination] = useState(false);
  const [selectStockInfo, setSelectStockInfo] = useState(null);
  const [deliveryAmountInfo, setDeliveryAmountInfo] = useState(null);
  const [openSupplierDialog, setOpenSupplierDialog] = useState(null);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const [logs, setLogs] = useState([]);
  const [dailySnapshot, setDailySnapshot] = useState({});

  const [sourceOrders, setSourceOrders] = useState([]);
  const [deliveryOrderItemMapping, setDeliveryOrderItemMapping] = useState({});

  const [owDeliveryOrder, setOwDeliveryOrder] = useState({
  });

  let deliveryOrder = {}

  // sourceOrder => purchaseOrder or borrowingOrder
  const sourceOrderIds = [...new Set(sourceOrders.map(p => p.id))]
  const sourceOrderSortString = sourceOrderIds.sort().join(',')

  useEffect(() => {
    if (filteredSources.length === 1 && deliveryOrderId === 'new') {
      updateDeliveryOrderData({ name: 'source' }, filteredSources[0].id);
    }
  }, [filteredSources.length]);

  useEffect(() => {
    let link = ''
    let text = ''
    if (formName === 'deliveryOrder') {
      link = '/sales/deliveryOrder'
      text = formatMessage({ id: 'sideMenu.sales.deliveryOrder' })
    } else if (formName === 'borrowingDelivery') {
      link = '/borrowing/borrowingDelivery'
      text = formatMessage({ id: 'sideMenu.borrowing.borrowingDelivery' })
    } else if (formName === 'returnBorrowingForm') {
      link = '/borrowing/returnBorrowingForm'
      text = formatMessage({ id: 'sideMenu.borrowing.returnBorrowingForm' })
    }
    const breadcrumbs = [{ link, text }]
    if (deliveryOrderId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: `${formName}.dialog.title.add` }) })
    } else {
      breadcrumbs.push({ text: formatMessage({ id: `${formName}.dialog.title.edit` }) })
    }
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    // 撈即時庫存
    const today = dayjs().format('YYYY-MM-DD')
    const unsubscribe = owDeliveryOrder.source ? firebase.firestore().collection('wh').doc(owDeliveryOrder.source).collection('logs').where('date', '==', today)
      .onSnapshot( snapshot => {
        const logs = []
        snapshot.forEach(doc => {
          const data = doc.data()
          if (data.valid !== false) {
            logs.push(data)
          }
        });
        setLogs(logs)
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, [owDeliveryOrder.source]);

  useEffect(() => {
    // 撈即時庫存
    const yesterday = dayjs().subtract(1, 'days').format('YYYY-MM-DD')
    const unsubscribe = owDeliveryOrder.source ? firebase.firestore().collection('wh').doc(owDeliveryOrder.source).collection('dailySnapshot').doc(yesterday)
      .onSnapshot( snapshot => {
        setDailySnapshot(snapshot?.data()?.extData || {})
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, [owDeliveryOrder.source]);

  useEffect(() => {
    const months = formName === 'deliveryOrder' ? 2 : 12
    const date = dayjs().subtract(months, 'months').format('YYYY-MM')
    const unsubscribe = sourceOrderIds.length ? firebase.firestore().collection(`${formName}s`).where('date', '>=', date)
      .onSnapshot(snapshot => {
        const itemMapping = {}
        const idField = formName === 'deliveryOrder' ? 'purchaseOrderId' : 'borrowingOrderId'

        snapshot.forEach(doc => {
          const r = doc.data()
          if (r.status !== 'void' && r[idField] && doc.id !== deliveryOrderId && sourceOrderIds.includes(r[idField])) {
            const keys = Object.keys(r.merchandises)
            for (const key of keys) {
              const fullId = `${r[idField]}@${key}`
              if (itemMapping[fullId] === undefined) {
                itemMapping[fullId] = r.merchandises[key].amount
              } else {
                itemMapping[fullId] = itemMapping[fullId] + r.merchandises[key].amount
              }
            }
          }
        });
        setDeliveryOrderItemMapping(itemMapping)
      }, err => {}) : null
    return () => {if (unsubscribe) unsubscribe()}
  }, [sourceOrderSortString]);

  useEffect(() => {
    const months = formName === 'deliveryOrder' ? 2 : 12
    const date = dayjs().subtract(months, 'months').format('YYYY-MM')
    const collectionName = formName === 'deliveryOrder' ? 'purchaseOrders' : 'borrowingOrders'
    const unsubscribe = firebase.firestore().collection(collectionName).where('status', '==', 'done').where('date', '>=', date)
      .onSnapshot( snapshot => {
        const deliveryOrderSupplier = currentUser[deliveryOrderSourceField].filter(c => customerMapping[c])
        const sourceOrderList = []
        snapshot.forEach(doc => {
          const sourceOrder = doc.data()
          if (formName === 'returnBorrowingForm') {
            if (sourceOrder.closeout && !sourceOrder.returnAll && currentUser[deliveryOrderSourceField].includes(sourceOrder.source)) {
              sourceOrderList.push({ id: doc.id, ...sourceOrder })
            }
          } else {
            if (!sourceOrder.closeout && sourceOrder.deliveryOrder && deliveryOrderSupplier.includes(sourceOrder.supplier)) {
              sourceOrderList.push({ id: doc.id, ...sourceOrder })
            }
          }
        });
        setSourceOrders(sourceOrderList)
      }, err => {})
    return () => unsubscribe()
  }, []);

  const merchandiseDailySnapshot = merchandises.reduce((acc, cur) => {
    if (!acc[cur.id]) acc[cur.id] = 0
    return acc
  }, { ...dailySnapshot })

  const realtimeStock = logs.reduce((acc, cur) => {
    if (acc[cur.merchandiseId] === undefined) return acc
    acc[cur.merchandiseId] += cur.quantity
    return acc
  }, { ...merchandiseDailySnapshot })

  deliveryOrder = { ...{
    id: 'new',
    source: '',
    destination: '',
    date: dayjs().format('YYYY-MM-DD'),
    createdBy: currentUser.key,
    createdAt: Math.floor(Date.now() / 1000) * 1000,
    note: '',
    discount: '',
    merchandises: [],
  }, ...owDeliveryOrder }

  deliveryOrder.merchandises.forEach(m => {
    m.maxAmount = m.poAmount - (deliveryOrder[`${m[refId]}@${m.id}`] || 0)
    const ou = merchandiseMapping[m.id].orderUnit
    const sku = merchandiseMapping[m.id].sku
    const ou2sku = merchandiseMapping[m.id].ou2sku
    if (m.warehousing) {
      if (m.orderBySku) {
        m.stock = realtimeStock[m.id] + sku
      } else {
        m.stock = (ou !== sku ? Math.floor(realtimeStock[m.id] / ou2sku) : realtimeStock[m.id]) + ou
      }
    } else {
      m.stock = formatMessage({ id: 'merchandise.warehousing.type2' })
    }
  })

  const totalDelivery = deliveryOrder.merchandises.reduce((acc, cur) => acc + cur.delivery, 0)

  const currentSourceOrder = deliveryOrder.merchandises.length ? sourceOrders.find(p => p.id === deliveryOrder.merchandises[0][refId]) : null
  const filteredSourceOrders = getFilteredSourceOrders()

  const _headerCells = [
    { field: refSn, order: 0 },
    { field: 'code', sort: 'code', order: 1 },
    { field: 'name', order: 2 },
    { field: 'require', align: 'right', order: 4 },
    { field: 'stock', align: 'right', order: 5 },
    { field: 'delivery', align: 'right', order: 6 },
    { field: 'note', align: 'right', order: 7 },
  ]

  const _rowCells = [
    { field: refSn, order: 0 },
    { field: 'code', order: 1 },
    { field: 'nickname', tooltip: 'name', order: 2 },
    { field: 'require', align: 'right', order: 4 },
    { field: 'stock', align: 'right', order: 5 },
    { field: 'delivery', align: 'right', order: 6 },
    { field: 'note', align: 'right', order: 7, type: 'input', required: false, params: [refId], label: '備註', onValueChanged: onCellValueChanged },
  ]

  const headerCells = (formName === 'deliveryOrder' ?
    [..._headerCells,
      { field: 'unitPrice', align: 'right', order: 3 }] :
    _headerCells
  ).map(c => {c.text = formatMessage({ id: `${formName}.merchandise.${c.field}` });return c}).sort((a, b) => a.order - b.order)

  const rowCells = (formName === 'deliveryOrder' ?
    [..._rowCells,
      { field: 'unitPrice', align: 'right', order: 3 }] :
    _rowCells
  ).sort((a, b) => a.order - b.order)

  const handleRequestTable1Sort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function getFilteredSourceOrders() {
    if (formName === 'returnBorrowingForm') {
      return currentSourceOrder ? [currentSourceOrder] : (deliveryOrder.destination !== '' ? sourceOrders.filter(p =>  p.supplier === deliveryOrder.destination) : [])
    } else {
      return currentSourceOrder ? [currentSourceOrder] : (deliveryOrder.destination !== '' ? sourceOrders.filter(p =>  p.source === deliveryOrder.destination) : [])
    }
  }

  function formatData(merchandise) {
    const newData = {
      ...merchandise,
      code: merchandiseMapping[merchandise.id].code,
      name: merchandiseMapping[merchandise.id].name,
      nickname: merchandiseMapping[merchandise.id].nickname,
    }
    newData.require = newData.amount + newData.orderUnit
    newData.sku = merchandiseMapping[merchandise.id].sku
    newData.ou2sku = merchandiseMapping[merchandise.id].ou2sku
    return newData
  }

  function onCellValueChanged(id, field, value, params) {
    if (params) {
      const sourceOrderId = params[0]
      for (let m of deliveryOrder.merchandises) {
        if (m.id === id && m[refId] === sourceOrderId) {
          if (field === 'amount') {
            m.amount = value
            if (isNaN(value) || value === '' || parseInt(value) === 0) {
              if (!m.errors) {
                m.errors = {}
              }
              m.errors.amount = '數量錯誤'
            } else if (value > params[1]) {
              if (!m.errors) {
                m.errors = {}
              }
              m.errors.amount = '超過請購數量'
            } else {
              if (m.errors && m.errors.amount) {
                delete m.errors.amount
              }
            }
          } else if (field === 'unitPrice'){
            m.unitPrice = value
            if (isNaN(value) || value === '' || parseFloat(value) === 0) {
              if (!m.errors) {
                m.errors = {}
              }
              m.errors.unitPrice = '價格錯誤'
            } else {
              if (m.errors && m.errors.unitPrice) {
                delete m.errors.unitPrice
              }
            }
          } else if (field === 'expectedDate'){
            m.expectedDate = value
            if (value === null || value.toString() === 'Invalid Date') {
              if (!m.errors) {
                m.errors = {}
              }
              m.errors.expectedDate = '無效的日期格式'
            } else {
              if (m.errors && m.errors.expectedDate) {
                delete m.errors.expectedDate
              }
            }
          } else {
            m[field] = value
          }
          break
        }
      }
      updateDeliveryOrderData({ name: 'merchandises' }, deliveryOrder.merchandises);
    }
  }

  function onAddMerchandises(merchandises) {
    if (merchandises.length) {
      for (let m of merchandises) {
        const warehousing = merchandiseMapping[m.id].warehousing
        m.expectedDate = null
        m.lotNumber = ''
        m.discount = ''
        m.note = ''
        m.delivery = 0
        m.warehousing = warehousing
        if (warehousing) {
          m.items = []
        }
      }
      updateDeliveryOrderData({ name: 'merchandises' }, deliveryOrder.merchandises.concat(merchandises));
    }
  }

  function onSelectStockItems(m) {
    const amount = m.items.reduce((acc, cur) => acc + parseInt(cur.amount), 0)
    for (let merchandise of deliveryOrder.merchandises) {
      if (merchandise.id === m.merchandiseId) {
        merchandise.delivery = amount
        merchandise.items = m.items
        updateDeliveryOrderData({ name: 'merchandises' }, deliveryOrder.merchandises);
      }
    }
  }

  function onChangeDeliveryAmount(m) {
    for (let merchandise of deliveryOrder.merchandises) {
      if (merchandise.id === m.merchandiseId) {
        merchandise.delivery = m.amount
        updateDeliveryOrderData({ name: 'merchandises' }, deliveryOrder.merchandises);
        break
      }
    }
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = deliveryOrder.merchandises.reduce((acc, cur) => {acc[cur.id] = true;return acc}, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  }

  function handleCheckboxClick(id) {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  function onDeleteItems() {
    const merchandises = deliveryOrder.merchandises.filter(m => !selectedItems[m.id])
    if (merchandises.length !== deliveryOrder.merchandises.length) {
      updateDeliveryOrderData({ name: 'merchandises' }, merchandises);
      setSelectedItems({});
    }
  }

  function validateField(field, value) {
    // if (field.required && value === '') {
    //   return formatMessage({id: 'form.field.isRequired'})
    // }
    if (field.name === 'merchandises') {
      for (const m of value) {
        if (m.errors && m.errors.amount) {
          return m.errors.amount
        }
      }
    } else if (field.name === 'expectedDate' && value === '') {
      return formatMessage({ id: 'form.date.formatError' })
    } else if (field.name === 'invoiceNumber' && value && value !== '  -        ' && value.trim().length !== 11) {
      return formatMessage({ id: 'form.invoiceNumber.formatError' })
    }
    return ''
  }

  function updateDeliveryOrderData(field, value) {
    let newValue = value
    if (field.uppercase) {
      newValue = newValue.toUpperCase()
    }

    // if (field.allowCharacter) {
    //   newValue = newValue.replace(field.allowCharacter, '')
    // }
    // if (field.maxLength) {
    //   newValue = newValue.substring(0, field.maxLength)
    // }

    // if (supplierData[field.name] === newValue) return;

    let err = validateField(field, value)

    let newData = { ...owDeliveryOrder, [field.name]: newValue, [`${field.name}_err`]: err }
    if (field.name === 'source' && newValue === deliveryOrder.destination) {
      newData.destination = ''
    }

    setOwDeliveryOrder(newData)
  }

  async function handleSave() {
    lockSubmitButton(true)
    setLoading(true);

    const fields = [
      { name: 'source' },
      { name: 'destination' },
      { name: 'date' },
      { name: 'merchandises' },
      { name: 'note' },
      // {name: 'invoiceNumber'}
    ]

    // let err = {}
    let newData = deliveryOrder
    // if (Object.keys(err).length > 0) {
    //   newData = {...deliveryOrder, ...err}
    //   setOwDeliveryOrder(newData)
    // }
    for (let field of fields) {
      if (newData[`${field.name}_err`] !== undefined && newData[`${field.name}_err`] !== '') {
        lockSubmitButton(false)
        setLoading(false);
        return
      }
    }

    let data = {}
    for (let field of fields) {
      if (field.type === '-') continue
      data[field.name] = newData[field.name]
    }

    data.merchandises = data.merchandises.reduce((acc, cur) => {
      if (parseInt(cur.delivery) > 0) {
        const warehousing = !!merchandiseMapping[cur.id].warehousing[data.source]
        acc[cur.id] = {
          warehousing,
          [refId]: cur[refId],
          [refSn]: cur[refSn],
          poAmount: parseInt(cur.poAmount),
          amount: parseInt(cur.delivery),
          // unitPrice: parseFloat(cur.unitPrice),
          note: cur.note,
        };
        if (cur.orderBySku) {
          acc[cur.id].orderBySku = true
        }
        if (formName === 'deliveryOrder') {
          acc[cur.id].unitPrice = parseFloat(cur.unitPrice)
        }
        if (warehousing) {
          acc[cur.id].items = cur.items
        }
        // if (cur.discount) {
        //   acc[cur.id].discount = parseInt(cur.discount)
        // }
      }
      return acc;
    }, {})

    data[refId] = currentSourceOrder ? currentSourceOrder.id : ''
    // data.discount = parseInt(data.discount || 0)

    try {
      // console.log(JSON.stringify({id: deliveryOrder.id, formName, ...data}, null, 4))
      await (firebase.functions().httpsCallable('saveDeliveryOrder'))({ id: deliveryOrder.id, formName, ...data })
    } catch (ex) {
      console.log(ex)
    }
    // setLoading(false);
    lockSubmitButton(false)
    handleClose()
  }

  function handleClose() {
    if (formName === 'deliveryOrder') {
      navigate('/sales/deliveryOrder');
    } else if (formName === 'borrowingDelivery') {
      navigate('/borrowing/borrowingDelivery');
    } else if (formName === 'returnBorrowingForm') {
      navigate('/borrowing/returnBorrowingForm');
    }
  }

  function getSourceSelector() {
    if (currentUser[deliveryOrderSourceField].length === 1) {
      return (<TextField
        disabled
        type="text"
        label={formatMessage({ id: `${formName}.table.detail.source` })}
        variant="outlined"
        value={customerMapping[currentUser[deliveryOrderSourceField][0]].nickname}
        fullWidth
        size="small"
      />)
    } else {
      return (<TextField
        select
        required
        disabled={lockSource}
        type="text"
        label={formatMessage({ id: `${formName}.table.detail.source` })}
        variant="outlined"
        value={deliveryOrder.source}
        onChange={e => updateDeliveryOrderData({ name: 'source' }, e.target.value)}
        fullWidth
        size="small"
        error={deliveryOrder.source_err ? true : false}
        helperText={deliveryOrder.source_err}
      >
        {filteredSources.map(c => <MenuItem key={c.id} value={c.id}>
          {c.nickname}
        </MenuItem>)}
      </TextField>)
    }
  }

  function getDestinationSelector() {
    if (formName === 'returnBorrowingForm') {
      return (<TextField
        required
        disabled={lockDestination}
        type="text"
        size="small"
        label={formatMessage({ id: `${formName}.table.detail.destination` })}
        variant="outlined"
        onClick={() => setOpenSupplierDialog(!lockDestination ? { external: false } : null)} //open popup
        value={deliveryOrder.destination ? supplierMapping[deliveryOrder.destination].nickname : ''}
        error={deliveryOrder.destination_err ? true : false}
        helperText={deliveryOrder.destination_err}
        fullWidth
      />)
    } else {
      if (deliveryOrder.destination && !customerMapping[deliveryOrder.destination]) {
        return (<TextField
          required
          disabled={lockDestination}
          type="text"
          size="small"
          label={formatMessage({ id: `${formName}.table.detail.destination` })}
          variant="outlined"
          onClick={() => setOpenSupplierDialog(!lockDestination ? { external: true } : null)} //open popup
          value={deliveryOrder.destination ? supplierMapping[deliveryOrder.destination].nickname : ''}
          error={deliveryOrder.destination_err ? true : false}
          helperText={deliveryOrder.destination_err}
          fullWidth
        />)
      } else {
        return (<TextField
          select
          required
          disabled={lockDestination}
          type="text"
          label={formatMessage({ id: `${formName}.table.detail.destination` })}
          variant="outlined"
          value={deliveryOrder.destination}
          onChange={e => {
            if (e.target.value === 'external') {
              setOpenSupplierDialog(!lockDestination ? { external: true } : null)
            } else {
              updateDeliveryOrderData({ name: 'destination' }, e.target.value)
            }
          }}
          fullWidth
          size="small"
          error={deliveryOrder.destination_err ? true : false}
          helperText={deliveryOrder.destination_err}
        >
          {customers.filter(c => c.id !== deliveryOrder.source).map(c => <MenuItem key={c.id} value={c.id}>
            {c.nickname}
          </MenuItem>)}
          <Divider />
          <MenuItem value={'external'}>外部廠商</MenuItem>
        </TextField>)
      }
    }
  }

  // const invoiceSubtotal = deliveryOrder.merchandises.reduce((acc, cur) => {
  //   acc += cur.unitPrice * cur.amount
  //   return acc;
  // }, 0)
  // const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  // const invoiceTotal = invoiceTaxes + invoiceSubtotal;

  return (
    <div style={{ flexGrow: 1 }}>
      {sourceOrderDialog && <SelectSourceOrderDialog
        sourceOrders={filteredSourceOrders}
        defaultSelectedItems={[]}
        handleClose={() => setSourceOrderDialog(false)}
        handleSave={onAddMerchandises}
        ignoreIds={deliveryOrder.merchandises.map(m => `${m[refId]}@${m.id}`)}
        receiptItemMapping={deliveryOrderItemMapping}
        refId={refId}
        refSn={refSn}
      />}
      {selectStockInfo && <SelectStockDialog
        customer={deliveryOrder.source}
        merchandise={selectStockInfo}
        handleClose={() => setSelectStockInfo(null)}
        handleSave={onSelectStockItems}
      />}
      {deliveryAmountInfo && <DeliveryAmountDialog
        merchandise={deliveryAmountInfo}
        handleClose={() => setDeliveryAmountInfo(null)}
        handleSave={onChangeDeliveryAmount}
      />}
      {openSupplierDialog && <SelectSupplierDialog
        defaultSelectedItem={deliveryOrder.destination}
        handleClose={() => setOpenSupplierDialog(null)}
        handleSave={value => updateDeliveryOrderData({ name: 'destination' }, value)}
        ignoreIds={deliveryOrder.source && customerMapping[deliveryOrder.source].supplierId ? [customerMapping[deliveryOrder.source].supplierId] : []}
        external={openSupplierDialog?.external ? true : false}
      />}
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            {getSourceSelector()}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {getDestinationSelector()}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: `${formName}.table.detail.createdBy` })}
              variant="outlined"
              value={userMapping[deliveryOrder.createdBy] ? userMapping[deliveryOrder.createdBy].displayName : ''}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: `${formName}.table.detail.createdAt` })}
              variant="outlined"
              value={dayjs(deliveryOrder.createdAt).format('YYYY-MM-DD')}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: '8px 0px' }} />
        <EnhancedTableToolbar
          title="editDeliveryOrder.table.title"
          selectdMessage="editDeliveryOrder.table.selected"
          numSelected={Object.keys(selectedItems).length}
          toolbox={
            <Button
              disabled={deliveryOrder.source === '' || deliveryOrder.destination === '' || (currentSourceOrder && Object.keys(currentSourceOrder.merchandises).length === deliveryOrder.merchandises.length)}
              sx={{ m: 1, whiteSpace: 'nowrap' }}
              variant="contained"
              color="primary"
              onClick={() => {
                setLockSource(true)
                setLockDestination(true)
                setSourceOrderDialog(true)
              }}
            >
              <FormattedMessage id={formName === 'deliveryOrder' ? 'editDeliveryOrder.addFromPurchaseOrder' : 'editDeliveryOrder.addFromBorrowingOrder'} />
            </Button>
          }
          toolboxForSelection={
            <Button
              variant="contained"
              color="primary"
              startIcon={<DeleteIcon />}
              style={{ whiteSpace: 'nowrap', marginRight: '8px' }}
              onClick={onDeleteItems}
            >
              <FormattedMessage id="editDeliveryOrder.removeMerchandise" />
            </Button>
          }
        />
        <TableContainer component={Paper}>
          <Table>
            <EnhancedTableHead
              headerCells={headerCells}
              numSelected={Object.keys(selectedItems).length}
              onCheckboxClick={handleSelectAllClick}
              rowCount={deliveryOrder.merchandises.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestTable1Sort}
              actionButton
              expandable
            />
            <TableBody>
              {stableSort(deliveryOrder.merchandises.map(m => formatData(m)), getComparator(order, orderBy)).map(merchandise => (
                <EnhancedTableRow
                  key={`${merchandise[refId]}-${merchandise.id}`}
                  rowCells={rowCells}
                  cellData={merchandise}
                  onCheckboxClick={(e) => handleCheckboxClick(merchandise.id)}
                  selected={selectedItems[merchandise.id] || false}
                  lock={merchandise.lock}
                  expandable
                  expandContent={merchandise.warehousing ? <MerchandiseDetail merchandise={merchandise} /> : (null)}
                  actionIcons={
                    merchandise.warehousing ? <Button
                      variant="contained"
                      color="primary"
                      style={{ whiteSpace: 'nowrap' }}
                      onClick={() => setSelectStockInfo(merchandise)}
                    >
                      <FormattedMessage id="editDeliveryOrder.selectStockItems" />
                    </Button> : <Button
                      variant="contained"
                      color="primary"
                      style={{ whiteSpace: 'nowrap' }}
                      onClick={() => setDeliveryAmountInfo(merchandise)}
                    >
                      <FormattedMessage id="editDeliveryOrder.setDeliveryAmount" />
                    </Button>
                  }
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: `${formName}.table.detail.note` })}
              variant="outlined"
              value={deliveryOrder.note}
              onChange={e => updateDeliveryOrderData({ name: 'note' }, e.target.value)}
              fullWidth
              size="small"
              multiline
            />
          </Grid>
        </Grid>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            disabled={loading || (deliveryOrder.merchandises.length === 0) || totalDelivery === 0}
            loading={loading}
            loadingPosition="start"
            loadingIndicator={<CircularProgress size={24} />}
            startIcon={<div />}
            variant="contained"
          >
            <FormattedMessage id="button.submit" />
          </LoadingButton>
        </Stack>
      </Box>
    </div>
  );
}

EditDeliveryOrderPage.propTypes = {
  formName: PropTypes.string.isRequired,
};

export default EditDeliveryOrderPage;
