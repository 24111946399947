import { useSelector } from 'react-redux';

// const useFirebaseData = (fieldName) => {
//   return (useSelector(state => state.firebase.ordered[fieldName]) || []).map(e => ({...e.value, id: e.key}))
// }

const useFirestoreData = (fieldName) => {
  return (useSelector(state => state.firestore.ordered[fieldName]) || [])
}

const useFirestoreDataAndStatus = (fieldName) => {
  const data = useSelector(state => state.firestore.ordered[fieldName])
  return [(data || []), data !== undefined]
}

// const useFirebaseDataAndMapping = (fieldName) => {
//   const array = getFirebaseData(fieldName)
//   return [array.reduce((acc, cur) => {acc[cur.id] = cur; return acc;}, {}), array]
// }

const useFirestoreDataAndMapping = (fieldName) => {
  const array = useFirestoreData(fieldName)
  return [array.reduce((acc, cur) => {acc[cur.id] = cur; return acc;}, {}), array]
}

const useSupplierDataAndMapping = () => {
  const array = useFirestoreData('suppliers').filter(s => s.supplier)
  return [array.reduce((acc, cur) => {acc[cur.id] = cur; return acc;}, {}), array]
}

const anyIntersection = (array1, array2) => {
  for (const i of array1) {
    if (array2.includes(i)) return true
  }
  return false
}

const getIntersection = (array1, array2) => {
  const intersection = []
  for (const i of array1) {
    if (array2.includes(i)) intersection.push(i)
  }
  return intersection
}

const lockSubmitButton = (lock) => {
  const submitbuttons = document.querySelectorAll('[submitbutton]')
  for (const submitbutton of submitbuttons) {
    if (submitbutton && submitbutton.parentNode) {
      if (lock) {
        submitbutton.parentNode.style.pointerEvents = 'none';
      } else {
        submitbutton.parentNode.style.pointerEvents = 'unset';
      }
    }
  }
}

const sortByField = (field) => {
  return (a, b) => {
    if (a[field] > b[field]) return 1
    else if (a[field] < b[field]) return -1
    return 0
  }
}

function unwrap(data) {
  if (data) {
    const { extData, ...rootData } = data
    return { ...rootData, ...extData }
  } else {
    return data
  }
}

function calculate(data, expression) {
  let calculateExpression = expression
  let postfix = null
  if (expression.includes('->')) {
    const variable = expression.split('->')
    postfix = variable[1]
    calculateExpression = variable[0]
  }
  if (calculateExpression.includes('*')) {
    const variable = calculateExpression.split('*')
    if (isNaN(data[variable[0]]) || isNaN(data[variable[1]])) {
      return 0
    }
    const result = parseFloat(data[variable[0]] || 0) * parseFloat(data[variable[1]] || 0)
    if (postfix === 'Round') {
      return Math.round(result)
    } else {
      return result
    }
  }
}

export {
  // useFirebaseData,
  useFirestoreData,
  useFirestoreDataAndStatus,
  // useFirebaseDataAndMapping,
  useFirestoreDataAndMapping,
  useSupplierDataAndMapping,
  anyIntersection,
  getIntersection,
  lockSubmitButton,
  sortByField,
  unwrap,
  calculate,
};
