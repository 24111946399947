import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import ContextStore from '../../modules/context';

const _fields = [
  // {name: 'code', sm: 6},
  { name: 'finance', sm: 6 },
  { name: 'medicine', sm: 6 },
  { name: 'merchandise', sm: 6 },
  { name: 'product', sm: 6 },
  { name: 'report', sm: 6 },
  { name: 'vendor', sm: 6 },
].map(field => {field.multiline = field.multiline || false; field.md = field.md || 3; return field})

function UserView({ user, handleEditClick }) {
  const { formatMessage } = useIntl()
  const { currentUser } = useContext(ContextStore)

  const fields = []
  for (const field of _fields) {
    fields.push({ ...field, required: field.required || false, md: field.md || 3 })
  }

  function createField(field) {
    const rights = [
      { key: 'view', name: '檢視' },
      { key: 'create', name: '新建' },
      { key:'edit', name: '編輯' }
    ]
    let newValue = []
    for (const right of rights) {
      const value = user.userRights?.[`${field.name}-${right.key}`]
      if (value) {
        newValue.push(right.name)
      }
    }

    return <Grid item key={`${field.name}.${field.index}`} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={formatMessage({ id: `user.rights.${field.name}` })}
        value={newValue.join(', ')}
        fullWidth
        size="small"
        variant="standard"
        readOnly
      />
    </Grid>
  }

  return (
    <div style={{ flexGrow: 1, height: '100%' }}>
      <Grid container spacing={2}>
        {fields.map(field => createField(field))}
        {currentUser.userRights['vendor-edit'] === true && <Grid item key="buttons" xs={12} sm={12} md={12}>
          <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
            <Button variant="contained" onClick={() => handleEditClick()} color="primary">
              <FormattedMessage id="button.edit" />
            </Button>
          </Stack>
        </Grid>}
      </Grid>
    </div>
  )
}

UserView.propTypes = {
  user: PropTypes.object.isRequired,
  handleEditClick: PropTypes.func.isRequired,
};

export default UserView;
