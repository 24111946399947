const i18n = {
  'salesBonus': {
    'name': '銷售/操作獎金',
    'table': {
      'title': '銷售/操作獎金列表',
      'header': {
        'date': '列表日期',
        'createdDate': '建立日期',
        'sourceName': '診所',
      },
      'detail': {
        'date': '列表日期',
        'createdDate': '建立日期',
        'sourceName': '診所',
      },
    },
    'list': {
      'salesBonus': '銷售獎金列表',
      'serviceBonus': '操作獎金列表',
    },
    'detail': {
      'purchaseOrderDate': '營收單日期',
      'purchaseOrderId': '營收單號',
      'patientName': '客戶名',
      'salesRepName': '客代',
      'productCode': '品號',
      'productName': '品名',
      'quantity': '數量',
      'price': '金額',
      'bonusA': '開發獎金(A)',
      'bonusB': '結單獎金(B)',
      'bonusC': '服務獎金(C)',
      'commission': '銷售獎金',
      'note': '備註',
      'kardexDate': '日期',
      'kardexId': 'Kardex ID',
      'assistantName': '跟診人員',
      'operationBonus': '操作獎金',
      'personalBonus': '匯出 {name} 個人獎金',
      'total': {
        'typeA': '開發獎金合計',
        'typeB': '結單獎金合計',
        'typeC': '服務獎金合計',
        'operationBonus': '操作獎金合計',
        'commission': '銷售獎金合計',
        'root': '合計',
        'allTotal': '總獎金合計'
      }
    }
  },
}
export default i18n
