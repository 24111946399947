const i18n = {
  'returnBorrowingForm': {
    'name': '借貨歸還單',
    'table': {
      'title': '借貨歸還單列表',
      'selected': '已選擇 {count} 筆借貨歸還單',
      'header': {
        'sn': '借貨歸還單號',
        'source': '原借貨單位',
        'sourceName': '原借貨單位',
        'destination': '收貨單位',
        'destinationName': '收貨單位',
        'createdBy': '出貨人',
        'createdAt': '出貨日期',
        'date': '出貨日期',
      },
      'detail': {
        'sn': '借貨歸還單號',
        'source': '原借貨單位',
        'sourceName': '原借貨單位',
        'destination': '收貨單位',
        'destinationName': '收貨單位',
        'createdBy': '出貨人',
        'createdAt': '出貨日期',
        'note': '備註',
        'merchandise': '商品',
        'merchandiseName': '商品名稱',
        'merchandiseCode': '商品品號',
      },
    },
    'dialog': {
      'title': {
        'add': '新增借貨歸還單',
        'edit': '編輯借貨歸還單',
      }
    },
    'merchandise': {
      'borrowingOrderSn': '借貨單號',
      'code': '品號',
      'name': '品名',
      'require': '原借貨量',
      'unitPrice': '單價',
      'amount': '數量',
      'orderUnit': '訂貨單位(OU)',
      'sku': '單位',
      'price': '金額',
      'lotNumber': '批號',
      'expectedDate': '效期',
      'statusText': '狀態',
      'discount': '商品折扣',
      'note': '備註',
      'stock': '即時庫存',
      'delivery': ' 本次歸還量',
    }
  }
}
export default i18n
