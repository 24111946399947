import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useFirebase } from 'react-redux-firebase';

import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Notification from './Notification';
import ContextStore from '../../modules/context';

const drawerWidth = 240;

const Breadcrumbs = styled(MuiBreadcrumbs)(({ theme }) => ({
  '& ol > :not(:last-child)': {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
  flexGrow: 1,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function AppNavBar({ isDrawerOpen, handleDrawerOpen }) {
  const { breadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const firebaseRedux = useFirebase()

  const shoppingCart = useSelector(state => state.firestore.data.shoppingCart) || {}
  const merchandises = Object.keys(shoppingCart)

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function openShoppingCart() {
    handleClose()
    navigate('/user/shoppingCart');
  }

  return (
    <AppBar position="fixed" open={isDrawerOpen}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(isDrawerOpen && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
        <Breadcrumbs separator={<NavigateNextIcon style={{ color: '#fff' }} fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs.map((b, index) => b.link ?
            (<Link key={`link-${index}`} style={{ cursor: 'pointer' }} href={b.link} onClick={e => {e.preventDefault(); navigate(b.link);}}>
              <Typography style={{ color: '#FFF' }} variant="h6">{b.text}</Typography>
            </Link>) :
            (<Typography key={`link-${index}`} style={{ color: '#FFF' }} variant="h6">{b.text}</Typography>))
          }
        </Breadcrumbs>
        <div>
          <Notification />
        </div>
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={menuOpen}
            onClose={handleClose}
          >
            <MenuItem disabled>
              {currentUser.displayName}
            </MenuItem>
            <MenuItem onClick={()=>{openShoppingCart()}}>
              <FormattedMessage id="header.menu.shoppingCart" />
              <Chip sx={{ ml: '8px' }} size="small" color="primary" label={merchandises.length} />
            </MenuItem>
            <MenuItem onClick={()=>{firebaseRedux.logout()}}>
              <FormattedMessage id="header.menu.logout" />
            </MenuItem>
          </Menu>
        </div>

      </Toolbar>
    </AppBar>
  );
}

AppNavBar.propTypes = {
  handleDrawerOpen: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
};

export default AppNavBar;
