import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import EnhancedTable from './EnhancedTable';
import SearchBox from './SearchBox';
import { useFirestoreData } from '../modules/uitls';

function SelectSupplierDialog({ defaultSelectedItem, handleClose, handleSave, ignoreIds, external = false }) {
  const { formatMessage } = useIntl()
  const suppliers = useFirestoreData('suppliers').filter(s => (external ? !s.internal : s.supplier) && !ignoreIds.includes(s.id));
  const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

  const [currentFilter, setCurrentFilter] = useState(null);

  const filteredSuppliers = currentFilter ? (suppliers.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))) : suppliers
  // const filteredselection = filteredSuppliers.filter(s => selectedItems[s.id]).reduce((acc, cur) => {acc[cur.id] = true;return acc;}, {})

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'name' },
    { text: 'nickname' },
    { text: 'businessNumber' },
  ].map(c => {c.text = formatMessage({ id: `supplier.table.detail.${c.text}` });return c})

  const rowCells = [
    { field: 'code' },
    { field: 'name' },
    { field: 'nickname' },
    { field: 'businessNumber' },
  ]

  const filterItems = [
    { name: 'name' },
    { name: 'nickname' },
    { name: 'businessNumber' },
    { name: 'note' },
    { name: 'code' },
  ].map(i => {i.text = formatMessage({ id: `supplier.table.detail.${i.name}` });return i})

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  const onApply = () => {
    handleSave(selectedItem)
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title"><FormattedMessage id="selectSupplierDialog.title" /></DialogTitle>
      <DialogContent dividers={true}>
        <div style={{ flexGrow: 1 }}>
          <Toolbar sx={{ pl: 2, pr: 1 }}>
            <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
              <FormattedMessage id="supplier.table.title" />
            </Typography>
            <SearchBox autoFocus filterItems={filterItems} onFilterChanged={onFilterChanged} />
          </Toolbar>
          <EnhancedTable
            defaultOrder="asc"
            defaultOrderField="code"
            headerCells={headerCells}
            rowCells={rowCells}
            tableData={filteredSuppliers}
            onRadioButtonClick={({ id }) => setSelectedItem(id)}
            getRowCheckBoxStatus={supplier => selectedItem === supplier.id}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button variant="contained" disabled={!selectedItem} onClick={onApply} color="primary">
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SelectSupplierDialog.propTypes = {
  ignoreIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultSelectedItem: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  external: PropTypes.bool
};

export default SelectSupplierDialog;
