import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import Divider from '@mui/material/Divider';

import ContextStore from '../../modules/context';
import DateRangePickerField from '../../components/DateRangePickerField';
import InventoryCheckList from './InventoryCheckList';

function InventoryCheckTab() {
  const { tabName } = useParams()
  const { currentUser } = useContext(ContextStore)

  const [inventoryChecks, setInventoryChecks] = useState([])
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  useEffect(() => {
    if (inventoryChecks.length) setInventoryChecks([])
    const onSnapshot = snapshot => {
      const inventoryChecks = []
      snapshot.forEach(doc => {
        inventoryChecks.push({ ...doc.data(), id: doc.id })
      });
      setInventoryChecks(inventoryChecks)
    }
    const unsubscribe = ['processing', 'pending'].includes(tabName) ?
      firebase.firestore().collection('inventoryChecks').where('status', '==', tabName).onSnapshot(onSnapshot, err => {}) :
      firebase.firestore().collection('inventoryChecks').where('status', '==', tabName).where('date', '>=', startDate).where('date', '<=', endDate).onSnapshot(onSnapshot, err => {})
    return () => unsubscribe()
  }, [startDate, endDate]);

  const inventoryChecksForTab = inventoryChecks.filter(r =>
    currentUser.userRights.debugging ||
    currentUser.inventoryCheckSource.includes(r.source) ||
    currentUser.inventoryCheckReview.includes(r.source)
  );

  const reqWaitingForProcess = waitingForProcess()

  function waitingForProcess() {
    if (tabName === 'pending') {
      return inventoryChecksForTab.filter(r => {
        const history = r.history || []
        const currentStep = history.length > 0 ? history[history.length - 1].step : 0
        if (isNaN(currentStep) || currentStep === r.steps.length) return false
        return r.steps[currentStep].users.includes(currentUser.key)
      })
    } else {
      return []
    }
  }

  return (
    <div style={{ paddingBottom: '60px' }}>
      {tabName === 'done' && <DateRangePickerField
        startDate={startDate}
        endDate={endDate}
        onStartDateChanged={setStartDate}
        onEndDateChanged={setEndDate}
      />}
      {reqWaitingForProcess.length > 0 && <>
        <InventoryCheckList inventoryChecks={reqWaitingForProcess} title={`inventoryCheck.table.${tabName}.title`} />
        <Divider sx={{ mx: '0px', my: '10px' }}/>
      </>}
      <InventoryCheckList inventoryChecks={inventoryChecksForTab} title="inventoryCheck.table.title" />
    </div>
  );
}

export default InventoryCheckTab;
