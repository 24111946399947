import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl';
import { useFirebase } from 'react-redux-firebase'

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';

const customTheme = createTheme({
  palette: {
    // primary: {
    //   main: '#3f51b5',
    // },
  },
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        ZOHU YAN CO., LTD.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function LoginForm() {
  const { formatMessage } = useIntl()
  const [page, setPage] = useState('login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [snackPack, setSnackPack] = React.useState([]);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState(undefined);
  const firebase = useFirebase();

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpenMessage(true);
    } else if (snackPack.length && messageInfo && openMessage) {
      // Close an active snack when a new one is added
      setOpenMessage(false);
    }
  }, [snackPack, messageInfo, openMessage]);

  async function onSubmit() {
    if (page === 'login') {
      try {
        await firebase.login({ email, password })
      } catch(ex) {
        console.log(ex)
        if (ex.code === 'auth/user-not-found') {
          setEmailError(formatMessage({ id: 'login.error.email' }))
        } else if (ex.code === 'auth/wrong-password') {
          setPasswordError(formatMessage({ id: 'login.error.password' }))
        }
      }
    } else {
      await firebase.resetPassword(email)
      setSnackPack((prev) => [
        ...prev,
        {
          message: formatMessage({ id: 'login.form.resetPasswordMessage' }),
          key: new Date().getTime()
        }
      ]);
      setPage('login')
    }
  }

  function switchPage(e) {
    e.stopPropagation()
    e.preventDefault()
    setPage(page === 'login' ? 'resetPassword' : 'login')
  }

  const handleMessageClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMessage(false);
  };

  const handleMessageExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Snackbar
          key={messageInfo ? messageInfo.key : undefined}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={openMessage}
          autoHideDuration={4000}
          onClose={handleMessageClose}
          TransitionProps={{ onExited: handleMessageExited }}
          message={messageInfo ? messageInfo.message : undefined}
        />
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              <FormattedMessage id={page === 'login' ? 'login.form.login' : 'login.form.resetPassword'} />
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1, width: '100%' }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label={formatMessage({ id: 'login.form.email' })}
                name="email"
                autoComplete="email"
                onChange={e => {
                  if (emailError !== '') {
                    setEmailError('')
                  }
                  setEmail(e.target.value)
                }}
                autoFocus
                error={emailError !== ''}
                helperText={emailError}
              />
              {page === 'login' && <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={formatMessage({ id: 'login.form.password' })}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={e => {
                  if (passwordError !== '') {
                    setPasswordError('')
                  }
                  setPassword(e.target.value)
                }}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    onSubmit()
                  }
                }}
                error={passwordError !== ''}
                helperText={passwordError}
              />}
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                onClick={onSubmit}
              >
                <FormattedMessage id={page === 'login' ? 'login.form.login' : 'login.form.submit'} />
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/" variant="body2" onClick={switchPage}>
                    <FormattedMessage id={page === 'login' ? 'login.form.forgotPassword' : 'login.form.loginPage'} />
                  </Link>
                </Grid>
                <Grid item>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default LoginForm;
