import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';

import { styled } from '@mui/material/styles';
import MuiFab from '@mui/material/Fab';
import PrintIcon from '@mui/icons-material/Print';

import ReceiptReportView from './ReceiptReportView';

const Fab = styled(MuiFab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}));

function ReceiptReport({ receipts, merchandiseMapping, formName }) {
  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Fab color="primary" aria-label="add" onClick={handlePrint}>
        <PrintIcon />
      </Fab>
      <div style={{ display: 'none' }}>
        <div ref={componentRef}>
          {receipts.map((receipt, idx) =>
            <ReceiptReportView key={`print.box.${idx}`} receipt={receipt} merchandiseMapping={merchandiseMapping} formName={formName} />
          )}
        </div>
      </div>
    </>
  );
}

ReceiptReport.propTypes = {
  receipts: PropTypes.arrayOf(PropTypes.object).isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
};

export default ReceiptReport;
