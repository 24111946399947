import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import WorkflowSteps from '../../components/WorkflowSteps';
import { useFirestoreDataAndMapping } from '../../modules/uitls';

const DividerText = styled('div')(({ readOnly }) => ({
  position: 'absolute',
  top: readOnly ? '14px' : '8px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

function WorkflowView({ vendor, editable }) {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const [userMapping] = useFirestoreDataAndMapping('users')

  const wflist = []
  const stepers = [
    { name: 'requisitionSteps', finalStepText: '編輯已完成的請購單', finalStepField: 'overwrite' },
    { name: 'purchaseOrderSteps', finalStepText: '手動結單', finalStepField: 'overwrite' },
    { name: 'receiptSteps', finalStepText: '編輯已完成的進貨單', finalStepField: 'overwrite' },
    { name: 'inventoryCheckSteps', finalStepText: '解鎖盤點單', finalStepField: 'unlock' },
    { name: 'scrapFormSteps' },
    { name: 'stockRequisitionSteps' },
    { name: 'newSupplierSteps', finalStepText: '通知建立資料', finalStepField: 'overwrite' },
    { name: 'newMerchandiseSteps', finalStepText: '通知建立資料', finalStepField: 'overwrite' },
    { name: 'newProductSteps', finalStepText: '通知建立資料', finalStepField: 'overwrite' },
  ]

  for (const steper of stepers) {
    if (vendor[steper.name] && vendor[steper.name].length > 0) {
      const steps = vendor[steper.name]
      const approveSteps = steps.map(s => ({
        name: s.name,
        users: s.users.map(u => ({ id: u, name: userMapping[u]?.displayName || '' })),
      }))
      if (steper.finalStepField) {
        approveSteps.push({
          name: steper.finalStepText,
          users: steps[0][steper.finalStepField].map(u => ({ id: u, name: userMapping[u]?.displayName || '' })),
        })
      }
      wflist.push({ name: steper.name, steps: approveSteps, type: 'steper' })
    }
  }
  wflist.push({ name: 'editBorrowingStaff', steps: [
    { field: 'borrowingOrder', value: vendor.borrowing.borrowingOrder.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') },
    { field: 'borrowingDelivery', value: vendor.borrowing.borrowingDelivery.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') },
    { field: 'borrowingReceipt', value: vendor.borrowing.borrowingReceipt.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') },
    { field: 'returnBorrowingForm', value: vendor.borrowing.returnBorrowingForm.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') },
    { field: 'returnBorrowingReceipt', value: vendor.borrowing.returnBorrowingReceipt.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') },
  ], type: 'action' })
  wflist.push({ name: 'editWarehouseStaff', steps: [
    { field: 'query', value: vendor.stock.query.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') },
    { field: 'inventory', value: vendor.stock.inventory.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') },
    { field: 'deliveryOrder', value: vendor.sales.deliveryOrder.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') },
    { field: 'returnForm', value: vendor.sales.returnForm.filter(u => userMapping[u]).map(u => userMapping[u].displayName).join(', ') },
  ], type: 'action' })

  function handleEditClick(e, wf) {
    if (e.ctrlKey || e.metaKey) {
      window.open(`/vendor/workflow/${wf}/${vendor.id}`, '_blank')
    } else {
      navigate(`/vendor/workflow/${wf}/${vendor.id}`)
    }
  }

  return wflist.map(wf => (<React.Fragment key={wf.name}>
    <Grid item xs={12} sm={12} md={12} style={{ position: 'relative' }} >
      <Divider sx={{ mt: '8px', mb: '8px' }} />
      <DividerText readOnly={!editable}>
        <Typography color="textSecondary" variant="caption">
          {formatMessage({ id: `supplier.${wf.name}.title` })}
        </Typography>
        {editable && <Tooltip title={formatMessage({ id: `supplier.${wf.name}.edit` })}>
          <IconButton onClick={e => handleEditClick(e, wf.name)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>}
      </DividerText>
    </Grid>
    {wf.type === 'steper' ? <Grid item xs={12} sm={12} md={12}><WorkflowSteps steps={wf.steps} small /></Grid> : (
      wf.steps.map(s => <React.Fragment key={`${wf.name}-${s.field}`}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            type="text"
            size="small"
            label={formatMessage({ id: `${wf.name}.table.${s.field}` })}
            value={s.value}
            fullWidth
            variant="standard"
            readOnly
          />
        </Grid>
      </React.Fragment>)
    )}

  </React.Fragment>));
}

WorkflowView.propTypes = {
  vendor: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
};


export default WorkflowView;
