class BaseModel {
  constructor(record) {
    (this.constructor.EXPOSE_ATTRIBUTES || Object.keys(record)).forEach(attr => { this[attr] = record[attr] });
    this['key'] = record['key'];
  }
}

class UserModel extends BaseModel {
  static TABLE = 'users';
  static EXPOSE_ATTRIBUTES = [
    'email', 'displayName', 'active', 'employeeId', 'userRights',
    'requisitionSource', 'requisitionReview', 'requisitionOverwrite',
    'purchaseOrderSource', 'purchaseOrderReview', 'purchaseOrderOverwrite',
    'receiptSource', 'receiptReview', 'receiptOverwrite',
    'stockRequisitionSource', 'stockRequisitionReview',
    'scrapFormSource', 'scrapFormReview',
    'newSupplierSource', 'newSupplierReview', 'newSupplierOverwrite',
    'newMerchandiseSource', 'newMerchandiseReview', 'newMerchandiseOverwrite',
    'newProductSource', 'newProductReview', 'newProductOverwrite',
    'whQuerySource', 'whInventorySource', 'deliveryOrderSource', 'returnFormSource',
    'borrowingOrderSource', 'borrowingDeliverySource', 'borrowingReceiptSource',
    'returnBorrowingFormSource', 'returnBorrowingReceiptSource',
    'inventoryCheckSource', 'inventoryCheckReview', 'inventoryCheckUnlock',
  ];
}

const getCurrentUser = (auth) => {
  const currentUser = new UserModel(auth);
  currentUser.key = currentUser.key || auth.uid;
  if (currentUser.userRights) {
    const rights = Object.keys(currentUser.userRights);
    for (const r of rights) {
      if (r.endsWith('-*')) {
        let prefix = r.substring(0, r.indexOf('-')) + '-'
        if (currentUser.userRights[prefix + 'create'] !== false) {
          currentUser.userRights[prefix + 'create'] = true
        }
        if (currentUser.userRights[prefix + 'edit'] !== false) {
          currentUser.userRights[prefix + 'edit'] = true
        }
        if (currentUser.userRights[prefix + 'view'] !== false) {
          currentUser.userRights[prefix + 'view'] = true
        }
        delete currentUser.userRights[r]
      }
    }
  }

  return currentUser;
};

const getUserList = node => node ? node.map(i => { i.value.key = i.key; return new UserModel(i.value)}) : [];

export {
  getCurrentUser,
  getUserList,
};
