import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

// import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
// import Switch from '@mui/material/Switch';

import EditNewUserView from '../../components/EditNewUserView';
// import ContextStore from '../../modules/context';

// const SwitchContainer = styled('div')(({ theme }) => ({
//   fontSize: '1rem',
//   fontWeight: 400,
//   lineHeight: 1.75,
//   letterSpacing: '0.00938em',
//   position: 'absolute',
//   right: theme.spacing(2),
//   top: theme.spacing(2),
//   flexDirection: 'row',
//   display: 'flex',
//   alignItems: 'center',
// }));

function EditUserDialog({ user, handleClose }) {
  // const { currentUser } = useContext(ContextStore)
  const childRef = useRef()
  const [loading, setLoading] = useState(false)

  const handleSave = async () => {
    setLoading(true);
    const data = childRef.current.getContent()

    if (data) {
      try {
        if (user.id === 'new') {
          const res = await (firebase.functions().httpsCallable('createAuthentication'))({ id: user.id, ...data })
          if (res.data) {
            await (firebase.functions().httpsCallable('saveUsers'))({ id: res.data.uid, ...data })
          }
        } else {
          await (firebase.functions().httpsCallable('saveUsers'))({ id: user.id, ...data })
        }
      } catch (ex) {
        console.log(ex)
      }
    } else {
      setLoading(false);
      return
    }
    setLoading(false);
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <FormattedMessage id={`supplier.dialog.title.${user.id === 'new' ? 'add' : 'edit'}`} />
      </DialogTitle>
      <DialogContent dividers={true}>
        <EditNewUserView ref={childRef} user={user} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <LoadingButton
          color="primary"
          onClick={handleSave}
          loading={loading}
          loadingPosition="start"
          loadingIndicator={<CircularProgress size={24} />}
          startIcon={<div />}
          variant="contained"
        >
          <FormattedMessage id="button.save" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

EditUserDialog.propTypes = {
  user: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};

export default EditUserDialog;
