import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Toolbar from '@mui/material/Toolbar';

import EnhancedTable from '../../components/EnhancedTable';
import SearchBox from '../../components/SearchBox';
import FabAdd from '../../components/FabAdd';
import ContextStore from '../../modules/context';
import RecordDialog from '../../components/RecordDialog';
import { useFirestoreDataAndMapping, useSupplierDataAndMapping, useFirestoreData, sortByField } from '../../modules/uitls';

const fields = [
  { name: 'type', type: 'select', sm: 6 },
  { name: 'category', type: 'select', sm: 6 },
  { name: 'orderUnit', md: 2, sm: 6 },
  { name: 'sku', md: 2, sm: 6 },
  { name: 'ou2sku', md: 2, sm: 6 },
  { name: 'warehousing', md: 6, sm: 12 },
  { name: 'customers', md: 6, sm: 12 },
  { name: 'orderBySku', md: 6, sm: 12 },
  { name: 'note', type: 'multiline', multiline: true, sm: 12, md: 12 },
].map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

function MerchandiseDetail({ allowEditing, customerMapping, merchandise }) {
  const { formatMessage } = useIntl()
  const [openRecordDialog, setRecordDialog] = useState(false)
  const customers = Object.keys(merchandise.customers || []).filter(c => customerMapping[c]).map(c => customerMapping[c])
  const orderBySku = Object.keys(merchandise.orderBySku || []).filter(c => customerMapping[c]).map(c => customerMapping[c])
  const warehousing = Object.keys(merchandise.warehousing || []).filter(c => customerMapping[c]).map(c => customerMapping[c])

  const selectMapping = {
    type: {
      G: formatMessage({ id: 'merchandise.type.g' }),
      I: formatMessage({ id: 'merchandise.type.i' }),
      B: formatMessage({ id: 'merchandise.type.b' }),
    },
    category: {
      DPO: '藥品-口服藥',
      DOI: '藥品-藥膏',
      DIV: '藥品-針劑',
      DSC: '藥品-保養品/保健品',
      CIN: '耗材-填充注射',
      CPR: '耗材-電音波類(探頭)',
      CBD: '耗材-體雕(點數卡)',
      CIP: '植入物',
      CCO: '耗材-消耗醫材',
      CWI: '耗材-線材',
      COT: '其他',
    }
  }

  const headerCells = [
    { text: 'code' },
    { text: 'name' },
    { text: 'moq', align: 'right' }, //最小訂貨量
    { text: 'price', align: 'right' }
  ].map(c => { c.text = formatMessage({ id: `merchandise.table.detail.suppiler.${c.text}` }); return c })

  const rowCells = [
    { field: 'code' },
    { field: 'nickname', tooltip: 'name' },
    { field: 'moq', align: 'right' },
    { field: 'price', align: 'right' }
  ]

  function createField(field, value) {
    let newValue = value
    if (field.type === 'select') {
      newValue = selectMapping[field.name][value]
    } else if (field.name === 'customers') {
      newValue = customers.map(s => s.nickname).join(', ')
    } else if (field.name === 'orderBySku') {
      newValue = orderBySku.map(s => s.nickname).join(', ')
    } else if (field.name === 'warehousing') {
      newValue = warehousing.map(s => s.nickname).join(', ')
    }

    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={formatMessage({ id: `merchandise.table.detail.${field.name}` })}
        value={newValue}
        fullWidth
        size="small"
        variant="standard"
        readOnly
      />
    </Grid>
  }

  return (
    <div style={{ padding: 15 }}>
      {openRecordDialog && <RecordDialog
        handleClose={() => setRecordDialog(false)}
        currentMapping={{ id: merchandise.id, type: 'merchandise' }}
      />}
      <Grid container spacing={2}>
        {fields.map(field => createField(field, merchandise[field.name]))}
        <div style={{ paddingLeft: '8px', marginTop: '8px' }}>
          <Typography variant="button" component="div">
            <FormattedMessage id="editMerchandise.table.title" />:
          </Typography>
        </div>
        <EnhancedTable
          containerStyle={{ marginBottom: '8px' }}
          size="small"
          headerCells={headerCells}
          rowCells={rowCells}
          tableData={merchandise.suppliers}
        />
        {allowEditing && <Grid item key="buttons" xs={12} sm={12} md={12}>
          <div style={{ height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
            <Button variant="contained" color="primary" sx={{ mr: '20px' }} onClick={() => setRecordDialog(true)}>
              <FormattedMessage id="button.record" />
            </Button>
            <Link to={`/merchandise/${merchandise.id}`} style={{ textDecoration: 'none', color: '#000' }}>
              <Button variant="contained" color="primary">
                <FormattedMessage id="button.edit" />
              </Button>
            </Link>
          </div>
        </Grid>}
      </Grid>
    </div>
  )
}

MerchandiseDetail.propTypes = {
  merchandise: PropTypes.object.isRequired,
  customerMapping: PropTypes.object.isRequired,
  allowEditing: PropTypes.bool.isRequired,
};

function Merchandise() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const tableRef = useRef()
  const navigate = useNavigate()
  const location = useLocation()
  // const merchandises = (useSelector(state => state.firestore.ordered.merchandises) || []).map(e => ({...e, category: e.category.substring(1)}));
  const merchandises = useFirestoreData('merchandises')
  const [currentFilter, setCurrentFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState({});
  const [supplierMapping] = useSupplierDataAndMapping()
  const [customerMapping] = useFirestoreDataAndMapping('customers')
  const filteredMerchandises = filterMerchandises()

  const filterItems = [
    { name: 'name' },
    { name: 'nickname' },
    { name: 'code' },
    { name: 'customers' },
    { name: 'suppliers' },
    { name: 'note' },
  ].map(i => { i.text = formatMessage({ id: `merchandise.table.detail.${i.name}` }); return i })

  const categoryFilterItems = [
    { name: 'type' },
    { name: 'category' },
    { name: 'warehousing' },
  ].map(i => { i.text = formatMessage({ id: `merchandise.table.detail.${i.name}` }); return i })

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.merchandise' })
    }])
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    const sp = new URLSearchParams(location.search)

    let needUpdate = false
    let newFilter = {}
    for (const f of categoryFilterItems) {
      const value = sp.get(f.name)
      if (value && value !== categoryFilter[f.name]) {
        newFilter[f.name] = value
        needUpdate = true
      } else if (!value && categoryFilter[f.name]) {
        needUpdate = true
      }
    }
    if (needUpdate) {
      setCategoryFilter(newFilter)
    }
  }, [location.search]);

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'name' },
    { text: 'nickname' },
    // {text: 'price', sort: 'price'},
    { text: 'sku' },
  ].map(c => { c.text = formatMessage({ id: `merchandise.table.header.${c.text}` }); return c })

  const rowCells = [
    { field: 'code' },
    { field: 'name' },
    { field: 'nickname' },
    // {field: 'price'},
    { field: 'sku' },
  ]

  const formatData = (merchandise) => {
    const newData = { ...merchandise }
    newData.suppliers = Object.keys(merchandise.suppliers || [])
      .filter(id => supplierMapping[id])
      .map(id => ({
        ...merchandise.suppliers[id],
        id,
        code: supplierMapping[id].code,
        name: supplierMapping[id].name,
        nickname: supplierMapping[id].nickname
      })).sort(sortByField('code'))
    return newData
  }

  function filterMerchandises() {
    if (!currentFilter &&
      !categoryFilter.type &&
      !categoryFilter.category &&
      !categoryFilter.warehousing) {
      return merchandises
    }
    let items = [...merchandises]
    if (categoryFilter.type) {
      if (categoryFilter.type === 'g') {
        items = items.filter(i => ['G', 'B'].includes(i.type))
      } else if (categoryFilter.type === 'i') {
        items = items.filter(i => ['I', 'B'].includes(i.type))
      }
    }
    if (categoryFilter.category) {
      items = items.filter(i => i.category === categoryFilter.category)
    }
    if (categoryFilter.warehousing) {
      const type = categoryFilter.warehousing === 'type1'
      items = items.filter(i => i.warehousing === type)
    }
    if (currentFilter) {
      const lowerCaseText = currentFilter.text.toLowerCase()
      if (currentFilter.name === 'customers') {
        items = items.filter(s => Object.keys(s.customers || {})
          .filter(c => customerMapping[c])
          .filter(c => customerMapping[c].name.toLowerCase().includes(lowerCaseText) || customerMapping[c].nickname.toLowerCase().includes(lowerCaseText)).length)
      } else if (currentFilter.name === 'suppliers') {
        items = items.filter(s => Object.keys(s.suppliers || {})
          .filter(c => supplierMapping[c])
          .filter(c => supplierMapping[c].name.toLowerCase().includes(lowerCaseText) || supplierMapping[c].nickname.toLowerCase().includes(lowerCaseText)).length)
      } else {
        items = items.filter(s => s[currentFilter.name].toLowerCase().includes(lowerCaseText))
      }
    }
    return items
  }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  function getMenuItem(name) {
    if (name === 'type') {
      return [{ value: '' }, { value: 'g' }, { value: 'i' }].map(i => {
        i.label = i.value ? formatMessage({ id: `merchandise.type.${i.value}` }) : formatMessage({ id: 'merchandise.category.all' })
        return i
      })
    } else if (name === 'category') {
      return [
        { value: '' },
        { value: 'M', header: true },
        { value: 'DPO' },
        { value: 'DOI' },
        { value: 'DIV' },
        { value: 'DSC' },
        { value: 'I', header: true },
        { value: 'CIN' },
        { value: 'CPR' },
        { value: 'CBD' },
        { value: 'CIP' },
        { value: 'CCO' },
        { value: 'CWI' },
        { value: 'COT' },
      ].map(i => {
        i.label = i.value ? formatMessage({ id: `merchandise.category.${i.value}` }) : formatMessage({ id: 'merchandise.category.all' })
        return i
      })
    } else if (name === 'warehousing') {
      return [{ value: '' }, { value: 'type1' }, { value: 'type2' }].map(i => {
        i.label = i.value ? formatMessage({ id: `merchandise.warehousing.${i.value}` }) : formatMessage({ id: 'merchandise.category.all' })
        return i
      })
    }
    return []
  }

  function updateCategoryFilter(name, value) {
    const newFilter = { ...categoryFilter }
    newFilter[name] = value
    setCategoryFilter(newFilter)
    const sp = new URLSearchParams(location.search)
    const str1 = sp.toString()
    if (value === '') {
      sp.delete(name)
    } else {
      sp.set(name, value)
    }
    const str2 = sp.toString()
    if (str1 !== str2) {
      navigate({ pathname: location.pathname, search: `?${str2}` }, { replace: true });
    }
  }

  const getFilter = (filter) => {
    return <Grid item key={filter.name} xs={3} sm={3} md={3}>
      <TextField
        select
        type="text"
        size="small"
        label={filter.text}
        variant="outlined"
        onChange={e => { updateCategoryFilter(filter.name, e.target.value) }}
        value={categoryFilter[filter.name] || ''}
        fullWidth
      >
        {getMenuItem(filter.name).map((option, idx) => {
          return option.header ?
            (<ListSubheader key={`${option.value}-${idx}`}>{option.label}</ListSubheader>) :
            (<MenuItem key={`${option.value}-${idx}`} value={option.value}>{option.label}</MenuItem>)
        })}
      </TextField>
    </Grid>
  }

  function exportList() {
    const selectMapping = {
      type: {
        G: formatMessage({ id: 'merchandise.type.g' }),
        I: formatMessage({ id: 'merchandise.type.i' }),
        B: formatMessage({ id: 'merchandise.type.b' }),
      },
      category: {
        DPO: '藥品-口服藥',
        DOI: '藥品-藥膏',
        DIV: '藥品-針劑',
        DSC: '藥品-保養品/保健品',
        CIN: '耗材-填充注射',
        CPR: '耗材-電音波類(探頭)',
        CBD: '耗材-體雕(點數卡)',
        CIP: '植入物',
        CCO: '耗材-消耗醫材',
        CWI: '耗材-線材',
        COT: '其他',
      }
    }

    const _formatData = (merchandise) => {
      const newData = { ...merchandise }
      newData.warehousing = Object.keys(merchandise.warehousing || [])
        .filter(id => supplierMapping[id])
        .reduce((acc, cur) => {
          acc.push(supplierMapping[cur].nickname)

          return acc
        }, []).join('/')

      newData.customers = Object.keys(merchandise.customers || [])
        .filter(id => supplierMapping[id])
        .reduce((acc, cur) => {
          acc.push(supplierMapping[cur].nickname)

          return acc
        }, []).join('/')

      newData.orderBySku = Object.keys(merchandise.orderBySku || [])
        .filter(id => supplierMapping[id])
        .reduce((acc, cur) => {
          acc.push(supplierMapping[cur].nickname)

          return acc
        }, []).join('/')

      newData.name = newData.name.replace(/(?:\r\n|\r|\n)/g, ' ').replace(/,/gi, '')
      newData.nickname = newData.nickname.replace(/(?:\r\n|\r|\n)/g, ' ').replace(/,/gi, '')
      newData.note = newData.note.replace(/(?:\r\n|\r|\n)/g, ' ').replace(/,/gi, '')
      newData.type = selectMapping.type[newData.type]
      newData.category = selectMapping.category[newData.category]
      return newData
    }

    const rows = []
    const _rows = []

    const fields = [
      { name: 'code', text: '品號', sort: 'code' },
      { name: 'name', text: '名稱', },
      { name: 'nickname', text: '簡稱', },
      // {text: 'price', sort: 'price'},
      { name: 'type', type: 'select', sm: 6 },
      { name: 'category', type: 'select', sm: 6 },
      { name: 'orderUnit', md: 2, sm: 6 },
      { name: 'sku', md: 2, sm: 6 },
      { name: 'ou2sku', md: 2, sm: 6 },
      { name: 'warehousing', md: 6, sm: 12 },
      { name: 'customers', md: 6, sm: 12 },
      { name: 'orderBySku', md: 6, sm: 12 },
      { name: 'note', type: 'multiline', multiline: true, sm: 12, md: 12 },
    ].map(i => { if (!i.text) i.text = formatMessage({ id: `merchandise.table.detail.${i.name}` }); return i })

    for (const f of fields) {
      _rows.push(f.text)
    }

    const newData = merchandises.map(i => _formatData(i))

    rows.push(_rows.concat(['供應商_1', 'MOQ_1', '進貨單價_1', '供應商_2', 'MOQ_2', '進貨單價_2', '供應商_3', 'MOQ_3', '進貨單價_3', '供應商_4', 'MOQ_4', '進貨單價_4', '供應商_5', 'MOQ_5', '進貨單價_5']).join(','))

    for (const n of newData) {
      let _rows2 = []
      for (const f of fields) {
        _rows2.push(n[f.name])
      }

      for (const s of Object.keys(n.suppliers)) {
        _rows2.push(supplierMapping[s]?.nickname ?? ' ')
        _rows2.push(n.suppliers[s].moq)
        _rows2.push(n.suppliers[s].price)
      }

      rows.push(_rows2.join(','))
    }

    exportFields(rows, `MerchandiseList_${dayjs().format('YYYY-MM-DD')}`)  
  }

  function exportFields(rows, path) {
    try {
      const content = rows.join('\n')
      const csvData = new Blob(['\uFEFF' + content], { type: 'text/csv' })
      const csvUrl = URL.createObjectURL(csvData)
      const aExport = document.createElement('a')
      aExport.href = csvUrl
      aExport.target = '_blank'
      aExport.download = path
      aExport.click()
    } catch (error) {
      console.error(error) // eslint-disable-line
      alert('An error occurred. Please refresh and try again.')
    }
  }

  return (
    <div style={{ paddingBottom: '100px' }}>
      {currentUser.userRights['merchandise-create'] === true && <FabAdd to="/merchandise/new" />}
      <Toolbar sx={{ pl: 2, pr: 1 }}>
        <Typography sx={{ flex: '1 1 100%', flexShrink: 2 }} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id="merchandise.table.title" />
        </Typography>
        <IconButton
          onClick={() => exportList()}
          size="large">
          <GetAppIcon></GetAppIcon>
          <Typography noWrap variant="button">{formatMessage({ id: 'button.export' })}</Typography>
        </IconButton>
        <Grid container spacing={3}>
          {categoryFilterItems.map(f => getFilter(f))}
        </Grid>
        <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} updateUrlParams />
      </Toolbar>
      <EnhancedTable
        defaultOrder="asc"
        defaultOrderField="code"
        headerCells={headerCells}
        rowCells={rowCells}
        getExpandContent={merchandise =>
          <MerchandiseDetail
            allowEditing={!!currentUser.userRights['merchandise-edit']}
            customerMapping={customerMapping}
            merchandise={formatData(merchandise)}
          />
        }
        tableData={filteredMerchandises}
      />
    </div>
  );
}

export default Merchandise;
