const i18n = {
  'header': {
    'menu': {
      'shoppingCart': '採購清單',
      'userRights': '使用者權限',
      'logout': '登出',
    },
    'message': {
      'pendingRequisition': '待處理請購單',
      'pendingPurchaseOrder': '待處理採購單',
      'waitingForPurchase': '待採購的請購單',
      'pendingReceipt': '待處理的進貨單',
      'pendingSalesReturnForm': '待處理的銷貨退回單',
      'pendingStockRequisition': '待處理的沙貨領用單',
      'pendingNewSupplier': '待處理的新供應商申請',
      'pendingNewMerchandise': '待處理的新商品申請',
      'pendingNewProduct': '待處理的新療程申請',
      'pendingNewPackageProduct': '待處理的新套裝療程申請',
      'pendingNewCombinationProduct': '待處理的新組合療程申請',
      'waitingCreateSupplier': '待處理的新供應商建立',
      'waitingCreateMerchandise': '待處理的新商品建立',
      'waitingCreateProduct': '待處理的新療程建立',
      'waitingCreatePackageProduct': '待處理的新套裝療程建立',
      'waitingCreateCombinationProduct': '待處理的新組合療程建立',
      'pendingBorrowingDelivery': '待出貨的借貨單',
      'pendingBorrowingReceipt': '待處理的借貨入庫單',
      'pendingReturnBorrowingForm': '待歸還的借貨單',
      'pendingReturnBorrowingReceipt': '待處理的借貨歸還入庫單',
    }
  }
}
export default i18n
