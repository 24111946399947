import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import ContextStore from '../../modules/context';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import ProgressStep from '../../components/ProgressStep';
import { getComparator, stableSort } from '../../modules/sort';

function MerchandiseDetail({ items }) {
  const { formatMessage } = useIntl()
  const headerCells = [
    { field: 'amount' },
    { field: 'expectedDate' },
    { field: 'lotNumber' },
    { field: 'wareroom' },
    { field: 'arrangementAmount' },
    { field: 'targetWareroom' },
  ].map(c => {c.text = formatMessage({ id: `arrangeStockForm.merchandise.${c.field}` });return c})

  const rowCells = [
    { field: 'amount' },
    { field: 'expectedDate' },
    { field: 'lotNumber' },
    { field: 'wareroom' },
    { field: 'arrangementAmount' },
    { field: 'targetWareroom' },
  ]

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="collapsible table">
        <EnhancedTableHead
          headerCells={headerCells}
          rowCount={items.length}
        />
        <TableBody>
          {items.map(i => (
            <EnhancedTableRow
              key={`${i.id}${i.wareroom || '#'}`}
              rowCells={rowCells}
              cellData={i}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

MerchandiseDetail.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object.isRequired),
};

function ArrangeStockFormView({ arrangeStockForm, userMapping, merchandiseMapping, wareroom }) {
  const { formatMessage } = useIntl()
  const { currentUser } = useContext(ContextStore)
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');

  const arrangeStockFormHistory = (arrangeStockForm.history || []).map(h => ({ ...h }))
  const wareroomMapping = (wareroom[arrangeStockForm.source] || []).reduce((acc, cur, idx) => {acc[`#${idx + 1}`] = cur; return acc}, { '#default': '主倉庫' })

  const headerCells = [
    { field: 'code', sort: 'code' },
    { field: 'name' },
    { field: 'note', align: 'right' }
  ].map(c => {c.text = formatMessage({ id: `arrangeStockForm.merchandise.${c.field}` });return c})

  const rowCells = [
    { field: 'code' },
    { field: 'nickname', tooltip: 'name' },
    { field: 'note', align: 'right', order: 7, maxWidth: '120px' }
  ]

  function formatData(merchandise) {
    return {
      ...merchandise,
      code: merchandiseMapping[merchandise.id].code,
      name: merchandiseMapping[merchandise.id].name,
      nickname: merchandiseMapping[merchandise.id].nickname,
      items: merchandise.items.map(item => ({
        ...item,
        wareroom: wareroomMapping[item.wareroom],
        targetWareroom: wareroomMapping[item.targetWareroom],
      }))
      // statusText,
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function decodeModifyLog(log) {
    if (!arrangeStockForm.steps[0].overwrite.includes(currentUser.key)) {
      log = log.split('\n').filter(line => (!line.includes('f{discount}') && !line.includes('f{merchandiseDiscount}'))).join('\n')
    }
    log = log.replace(/f{modify}/g, formatMessage({ id: 'step.action.modify' }))
    log = log.replace(/f{update}/g, formatMessage({ id: 'step.action.update' }))
    log = log.replace(/f{add}/g, formatMessage({ id: 'step.action.add' }))
    log = log.replace(/f{remove}/g, formatMessage({ id: 'step.action.remove' }))

    let matchs = [...new Set((log.match(/i{.+?}/g) || []).map(i => i.substring(2, i.length - 1)))]
    for (const m of matchs) {
      log = log.replace((new RegExp(`i{${m}}`, 'g')), merchandiseMapping[m].name)
    }
    return log
  }

  const steps = [...arrangeStockFormHistory]
  for (const step of steps) {
    if (step.action === 'modify') {
      step.detail = decodeModifyLog(step.note)
    }
    if (step.dateTime) {
      const s = step.dateTime.split(' ')
      step.text = step.name + `\n${userMapping[step.user]?.displayName}[${formatMessage({ id: 'step.action.' + step.action })}]`
      step.text += `\n日期: ${s[0]}`
      step.text += `\n時間: ${s[1]}`
      if (step.action === 'modify') {
        step.text += `${step.detail ? '\n' : ''}`
      } else {
        step.text += `${step.note ? '\n備註: ' + step.note : ''}`
      }
    } else {
      step.text = step.name
      if (step.users) {
        step.hint = step.users.map(u => userMapping[u]?.displayName || '').join(' / ')
      }
    }
    if (['reject', 'void'].includes(step.action)) {
      // step.stepProps = {completed: false}
      step.labelProps = { error: true }
    }
  }

  return (
    <div style={{ flexGrow: 1, height: '100%' }}>
      <Box p={0}>
        <ProgressStep activeStep={arrangeStockForm.history.length} steps={steps} />
        <TableContainer component={Paper}>
          <Table size="small" aria-label="collapsible table">
            <EnhancedTableHead
              headerCells={headerCells}
              rowCount={arrangeStockForm.merchandises.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              expandable
            />
            <TableBody>
              {stableSort(arrangeStockForm.merchandises.map(m => formatData(m)), getComparator(order, orderBy)).map(merchandise => (
                <EnhancedTableRow
                  key={merchandise.id}
                  rowCells={rowCells}
                  cellData={merchandise}
                  expandable
                  expandContent={<MerchandiseDetail items={merchandise.items} />}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'arrangeStockForm.table.detail.note' })}
              value={arrangeStockForm.note}
              fullWidth
              size="small"
              variant="standard"
              readOnly
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

ArrangeStockFormView.propTypes = {
  arrangeStockForm: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
  wareroom: PropTypes.object.isRequired,
};

export default ArrangeStockFormView;
