import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import DatePickerField from './DatePickerField';
import { calculate } from '../modules/uitls';

export default function EnhancedTableRow(props) {
  const { cellData, selected, rowCells, expandable, expanded = false, expandContent, onCheckboxClick, onRadioButtonClick, actionIcons, lock } = props;
  const [open, setOpen] = useState(false);
  const colSpan = rowCells.length + (onCheckboxClick ? 1 : 0) + (onRadioButtonClick ? 1 : 0) + (expandable ? 1 : 0) + (actionIcons ? 1 : 0)
  const allError = cellData.errors || {}
  const [anchorEl, setAnchorEl] = useState(null);

  const getCellElem = (cellData, cell) => {
    if (cell.type === 'calculate') {
      return calculate(cellData, cell.calculate)
    } else if (cell.type === 'input' || cell.type === 'input-number' || cell.type === 'input-floating-point-number') {
      return (
        <TextField
          required={cell.required}
          disabled={lock || (cell.getEnableStatus && !cell.getEnableStatus(cellData, cell.field))}
          type="text"
          label={cell.label}
          variant="outlined"
          value={cellData[cell.field]}
          onClick={e => {
            e.stopPropagation()
          }}
          onChange={e => {
            const params = cell.params ? cell.params.map(p => cellData[p]) : null
            if (cell.type === 'input-number') {
              const newValue = e.target.value.replace(/[^0-9]/g, '')
              cell.onValueChanged(cellData.id, cell.field, newValue, params)
            } else if (cell.type === 'input-floating-point-number') {
              const newValue = e.target.value.replace(/[^0-9.]/g, '')
              cell.onValueChanged(cellData.id, cell.field, newValue, params)
            } else {
              cell.onValueChanged(cellData.id, cell.field, e.target.value, params)
            }
          }}
          error={allError[cell.field] ? true : false}
          helperText={allError[cell.field]}
          size="small" />
      );
    } else if (cell.type === 'date') {
      return (<DatePickerField
        disabled={lock || (cell.getEnableStatus && !cell.getEnableStatus(cellData, cell.field))}
        required={cell.required}
        label={cell.label}
        // minDateMessage="日期不得早於今日"
        invalidDateMessage="無效的日期格式"
        value={cellData[cell.field]}
        onChange={date => {
          const params = cell.params ? cell.params.map(p => cellData[p]) : null
          cell.onValueChanged(cellData.id, cell.field, date, params)
        }}
      // minDate={new Date()}
      />)
    } else if (cell.type === 'input-select') {
      return (
        <TextField
          required={cell.required}
          disabled={lock || (cell.getEnableStatus && !cell.getEnableStatus(cellData, cell.field))}
          select
          type="text"
          size="small"
          label={cell.label}
          variant="outlined"
          onChange={e => {
            const params = cell.params ? cell.params.map(p => cellData[p]) : null
            cell.onValueChanged(cellData.key || cellData.id, cell.field, e.target.value, params)
          }}
          value={cellData[cell.field] ?? ''}
          error={allError[cell.field] ? true : false}
          helperText={allError[cell.field]}
          fullWidth={cell.fullWidth || false}
        >
          {cell.getMenuItems(cellData).map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )
    } else if (cell.type === 'input-menu') {
      return (<>
        <TextField
          required
          disabled={lock}
          type="text"
          size="small"
          label={cell.label}
          variant="outlined"
          onClick={e => { setAnchorEl(e.currentTarget) }}
          value={cellData[cell.field]}
          error={allError[cell.field] ? true : false}
          helperText={allError[cell.field]}
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {cell.getMenuItems(cellData).map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => {
                const params = cell.params ? cell.params.map(p => cellData[p]) : null
                cell.onValueChanged(cellData.key || cellData.id, cell.field, option.value, params)
                setAnchorEl(null)
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </>)
    } else if (cell.type === 'info' && cellData[cell.field] !== 'N/A') {
      return <div><span style={{ color: '#3f51b5', cursor: 'pointer' }} onClick={e => { e.preventDefault(); e.stopPropagation(); cell.onButtonClick(cellData.key || cellData.id, cell.field, cellData) }}>{cellData[cell.field]}</span></div>
    } else {
      if (cell.tooltip) {
        return <Tooltip title={cellData[cell.tooltip]}><span>{cellData[cell.field]}</span></Tooltip>
      }
      if (cell.textColor) {
        return cellData.textColor ? <span style={{ color: cellData.textColor }}>{cellData[cell.field]}</span> : <span>{cellData[cell.field]}</span>
      }
      return cellData[cell.field]
    }
  }

  return (
    <React.Fragment>
      <TableRow hover selected={open && expandable} sx={{ '& > *': { borderBottom: 'unset' } }}>
        {onRadioButtonClick && <TableCell padding="checkbox">
          <Radio onChange={onRadioButtonClick} checked={selected} disabled={lock} />
        </TableCell>}
        {onCheckboxClick && <TableCell padding="checkbox">
          <Checkbox onClick={onCheckboxClick} checked={selected} disabled={lock} />
        </TableCell>}
        {expandable && expandContent && <TableCell onClick={() => setOpen(!open)}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>}
        {(expandable && !expandContent) && <TableCell></TableCell>}
        {rowCells.map(cell =>
          cell.maxWidth ?
            (<TableCell
              sx={cell.sx || {}}
              style={{ maxWidth: cell.maxWidth, textOverflow: 'ellipsis', overflow: 'hidden' }}
              key={`rowCell-${cell.field}`}
              align={cell.align || 'left'}
              onClick={() => { if (expandable && expandContent) setOpen(!open) }}
            >
              {cell.type && cell.type.startsWith('input') ? getCellElem(cellData, cell) :
                <Tooltip title={getCellElem(cellData, cell)}>
                  <span>{getCellElem(cellData, cell)}</span>
                </Tooltip>
              }
            </TableCell>) :
            (cell.width ? <TableCell
              sx={cell.sx || {}}
              style={{ width: cell.width }}
              key={`rowCell-${cell.field}`}
              align={cell.align || 'left'}
              onClick={() => { if (expandable && expandContent) setOpen(!open) }}
            >
              {getCellElem(cellData, cell)}
            </TableCell> : <TableCell
              sx={cell.sx || {}}
              key={`rowCell-${cell.field}`}
              align={cell.align || 'left'}
              onClick={() => { if (expandable && expandContent) setOpen(!open) }}
            >
              {getCellElem(cellData, cell)}
            </TableCell>)
        )}
        {actionIcons && <TableCell align="right" sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{actionIcons}</TableCell>}
      </TableRow>
      <TableRow>
        <TableCell style={(open || expanded) ? { borderTop: 'solid 1px #e0e0e0', padding: 0 } : { padding: 0 }} colSpan={colSpan}>
          <Collapse in={open || expanded} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {expandContent}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

EnhancedTableRow.propTypes = {
  expandable: PropTypes.bool,
  expanded: PropTypes.bool,
  expandContent: PropTypes.element,
  cellData: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onCheckboxClick: PropTypes.func,
  onRadioButtonClick: PropTypes.func,
  radioButtonChecked: PropTypes.bool,
  actionIcons: PropTypes.element,
  lock: PropTypes.bool,
  rowCells: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    align: PropTypes.string,
    type: PropTypes.string,
    calculate: PropTypes.string,
    label: PropTypes.string,
    onValueChanged: PropTypes.func,
  })).isRequired,
};
