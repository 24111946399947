import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import ContextStore from '../../modules/context';
import { useFirestoreDataAndMapping, useSupplierDataAndMapping, lockSubmitButton, unwrap } from '../../modules/uitls';
import EditNewSupplierView from '../../components/EditNewSupplierView';
import EditNewMerchandiseView from '../../components/EditNewMerchandiseView';
import EditNewProductView from '../../components/EditNewProductView';
import EditNewProductPackageView from '../../components/EditNewProductPackageView';

function EditNewProfilePage({ profile }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  let tableName = ''
  let profileName = ''
  let defaultContent = null
  if (profile === 'supplier') {
    tableName = 'newSupplier'
    defaultContent = {
      id: 'new',
      // code: (String(suppliers.length+1)).padStart(6, 'S000000'),
      name: '',
      nickname: '',
      businessNumber: '', // 04595257
      phone: '',
      fax: '',
      address: '',
      shippingOut: [],
      email: '',
      basicPurchaseAmount: '',
      freeShippingAmount: '',
      ownerName: '',
      ownerPhone: '',
      ownerMobile: '',
      billRule: '',
      note: '',
      supplier: true,
      internal: false,
      active: true,
      contact: [],
    }
  } else if (profile === 'merchandise') {
    tableName = 'newMerchandise'
    defaultContent = {
      id: 'new',
      warehousing: [],
      type: '',
      category: '',
      name: '',
      nickname: '',
      orderUnit: '',
      sku: '',
      ou2sku: '',
      suppliers: {},
      customers: [],
      orderBySku: [],
      note: '',
    }
  } else if (profile === 'product') {
    tableName = 'newProduct'
    defaultContent = {
      id: 'new',
      type: '',
      tags: [],
      name: '',
      cost: '',
      staffprice: '',
      staffRelativesPrice: '',
      vipprice: '',
      customers: [],
      note: '',
      consumable: false,
    }
  } else if (['package', 'combination', 'folder'].includes(profile)) {
    tableName = 'newProduct'
    if (profile === 'package') {
      profileName = 'newPackageProduct'
    } else if (profile === 'combination') {
      profileName = 'newCombinationProduct'
    } else {
      profileName = 'newFolderProduct'
    }

    defaultContent = {
      id: 'new',
      type: '',
      tags: [],
      name: '',
      cost: '',
      staffprice: '',
      staffRelativesPrice: '',
      vipprice: '',
      consumable: false,
      customers: [],
      note: '',
      package: []
    }
  }

  const childRef = useRef()
  const packageChildRef = useRef()
  const { newProfileId } = useParams()

  const [customerMapping, customers] = useFirestoreDataAndMapping('customers')
  const filteredCustomers = customers.filter(c => currentUser[`${tableName}Source`].includes(c.id))
  const [supplierMapping] = useSupplierDataAndMapping()
  const [userMapping] = useFirestoreDataAndMapping('users')
  const [loading, setLoading] = useState(false)
  const [productMapping, setProductMapping] = useState({})
  const [customerError, setCustomerError] = useState(false)

  const [rawProfileData, setRawProfileData] = useState({
    id: newProfileId,
    source: '',
    createdBy: currentUser.key,
    date: dayjs().format('YYYY-MM-DD'),
    note: '',
    content: defaultContent,
  });

  const [owProfileData, setOwProfileData] = useState({
  });
  let profileData = {}

  const disableSubmitButton = newProfileId === 'new' ?
    (currentUser.userRights[`${tableName}-create`] !== true) :
    (currentUser.userRights[`${tableName}-edit`] !== true);

  useEffect(() => {
    if (filteredCustomers.length === 1 && newProfileId === 'new') {
      updateProfileData({ name: 'source' }, filteredCustomers[0].id);
    }
  }, [filteredCustomers.length]);

  useEffect(() => {
    // let title = formatMessage({id: 'sideMenu.purchase.root'}) + ' ＞ ' + formatMessage({id: 'sideMenu.purchase.requisition'})
    const breadcrumbs = [{
      link: `/profile/${tableName}/pending`,
      text: formatMessage({ id: `sideMenu.profile.${tableName}` })
    }]
    if (['package', 'combination', 'folder'].includes(profile)) {
      if (newProfileId === 'new') {
        breadcrumbs.push({ text: formatMessage({ id: `newProfile.dialog.title.${profileName}.add` }) })
      } else {
        breadcrumbs.push({ text: formatMessage({ id: `newProfile.dialog.title.${profileName}.edit` }) })
      }
    } else {
      if (newProfileId === 'new') {
        breadcrumbs.push({ text: formatMessage({ id: `newProfile.dialog.title.${tableName}.add` }) })
      } else {
        breadcrumbs.push({ text: formatMessage({ id: `newProfile.dialog.title.${tableName}.edit` }) })
      }
    }
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    const unsubscribe = (['package', 'combination', 'folder'].includes(profile)) ? firebase.firestore().collection('products').onSnapshot(snapshot => {
      const productMapping = {}
      snapshot.forEach(doc => {
        productMapping[doc.id] = { id: doc.id, ...unwrap(doc.data()) }
      })

      setProductMapping(productMapping)
    }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, []);

  useEffect(() => {
    if (newProfileId !== 'new') { // edit
      const unsubscribe = firebase.firestore().collection('newProfiles').doc(newProfileId)
        .onSnapshot(snapshot => {
          const data = unwrap(snapshot.data())
          if (data.profile !== profile) {
            navigate('/');
          } else {
            setRawProfileData({ id: snapshot.id, ...data })
          }
        }, err => { })
      return () => unsubscribe()
    } else {
      return () => { };
    }
  }, []);

  if (newProfileId !== 'new' && !rawProfileData.source) {
    return ('Loading...')
  }

  profileData = {
    ...{
      id: newProfileId,
      source: rawProfileData.source,
      createdBy: rawProfileData.createdBy,
      date: rawProfileData.date,
      note: rawProfileData.note,
      content: rawProfileData.content,
    }, ...owProfileData
  }

  function validateField(field, value) {
    // if (field.required && value === '') {
    //   return formatMessage({id: 'form.field.isRequired'})
    // }
    if (field.name === 'merchandises') {
      for (const m of value) {
        if (m.errors && m.errors.amount) {
          return m.errors.amount
        }
      }
    }
    return ''
  }

  function updateProfileData(field, value) {
    let newValue = value
    // if (field.allowCharacter) {
    //   newValue = newValue.replace(field.allowCharacter, '')
    // }
    // if (field.maxLength) {
    //   newValue = newValue.substring(0, field.maxLength)
    // }

    // if (supplierData[field.name] === newValue) return;

    let err = validateField(field, value)

    let newData = { ...profileData, [field.name]: newValue, [`${field.name}_err`]: err }
    setOwProfileData(newData)
  }

  async function handleSave() {
    lockSubmitButton(true)
    setLoading(true);
    const content = childRef.current.getContent()
    const fields = [
      { name: 'source', required: true },
    ]

    let err = {}
    for (let field of fields) {
      if (field.required && profileData[field.name] === '') {
        err[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
      }
    }
    let newData = profileData
    if (Object.keys(err).length > 0) {
      newData = { ...profileData, ...err }
      setOwProfileData(newData)
    }


    for (let field of fields) {
      if (newData[`${field.name}_err`] !== undefined && newData[`${field.name}_err`] !== '') {
        lockSubmitButton(false)
        setLoading(false);
        return
      }
    }

    if (!content) {
      lockSubmitButton(false)
      setLoading(false);
      return
    }

    let data = {}
    for (let field of fields) {
      if (field.type === '-') continue
      data[field.name] = newData[field.name]
    }

    data.content = content
    if (profile === 'merchandise' || profile === 'product') {
      data.content.customers = { [profileData.source]: true }
    }

    if (['package', 'combination', 'folder'].includes(profile)) {
      const packageContent = packageChildRef.current.getContent()

      if (!packageContent) {
        lockSubmitButton(false)
        setLoading(false);
        return
      }

      let productMapping = {}
      let costCount = 0
      let packageError = true


      for (const p of packageContent.package) {
        if (profile !== 'folder') {
          productMapping[p.id] = { amount: parseInt(p.amount) }
          if (p.ratio !== undefined) {
            productMapping[p.id].ratio = parseInt(p.ratio)
          }
        } else {
          productMapping[p.id] = { amount: parseInt(p.amount), price: parseInt(p.price), lowestPrice: parseInt(p.lowestPrice)  }
        }

        data.content.customers = { [profileData.source]: true }

        costCount = Number(p.cost) + costCount
        packageError = packageError && Object.keys(p.customers).includes(profileData.source)
      }
      data.content = {
        ...content,
        cost: costCount,
        package: productMapping,
        treatments: profile
      }
      setCustomerError(packageError)
    }

    if (customerError) {
      lockSubmitButton(false)
      setLoading(false);
      return
    }

    try {
      await (firebase.functions().httpsCallable('saveNewProfile'))({ id: profileData.id, profile, ...data })
    } catch (ex) {
      console.log(ex)
    }
    lockSubmitButton(false)
    handleClose()
  }

  function handleClose() {
    if (['package', 'combination', 'folder'].includes(profile)) {
      navigate(`/profile/${profileName}/pending`);
    } else {
      navigate(`/profile/${tableName}/pending`);
    }
  }

  function getDisabledFields() {
    const fields = ['staffprice', 'staffRelativesPrice'];
    if (['package', 'combination', 'folder'].includes(profile)) {
      fields.push('consumable')
    }
    return fields
  }

  function editParentData(field, value) {
    childRef.current.setEmployeePirce(field, value)
  }

  function getSourceSelector() { //-MCoIbvtO2syM5scDVhN
    if (currentUser[`${tableName}Source`].length === 1 || newProfileId !== 'new') {
      const value = newProfileId !== 'new' ?
        (customerMapping[profileData.source] ? customerMapping[profileData.source].nickname : '') :
        (customerMapping[currentUser[`${tableName}Source`][0]] ? customerMapping[currentUser[`${tableName}Source`][0]].nickname : '')

      return (<TextField
        disabled
        type="text"
        label={formatMessage({ id: 'newProfile.table.detail.source' })}
        variant="outlined"
        value={value}
        fullWidth
        size="small"
      />)
    } else {
      return (<TextField
        select
        required
        // disabled={lockSource}
        type="text"
        label={formatMessage({ id: 'newProfile.table.detail.source' })}
        variant="outlined"
        value={profileData.source}
        onChange={e => updateProfileData({ name: 'source' }, e.target.value)}
        fullWidth
        size="small"
        error={profileData.source_err ? true : false}
        helperText={profileData.source_err}
      >
        {filteredCustomers.map(c => <MenuItem key={c.id} value={c.id}>
          {c.nickname}
        </MenuItem>)}
      </TextField>)
    }
  }

  // const invoiceSubtotal = requisition.merchandises.reduce((acc, cur) => {
  //   acc += cur.unitPrice * cur.amount
  //   return acc;
  // }, 0)
  // const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  // const invoiceTotal = invoiceTaxes + invoiceSubtotal;
  // const showVoidButton = (requisition.id !== 'new' && requisition.merchandises.length === 0)

  function getProfileContent() {
    if (profile === 'supplier') {
      const supplier = { ...profileData.content }
      supplier.shippingOut = Object.keys(supplier.shippingOut || {}).map(k => ({
        name: supplierMapping[k].name,
        nickname: supplierMapping[k].nickname,
        id: supplierMapping[k].id,
      }))
      return <EditNewSupplierView ref={childRef} supplier={supplier} ignoreFields={['shipping', 'shippingOut', 'supplier', 'internal', 'active']} />
    } else if (profile === 'merchandise') {
      const merchandise = { ...profileData.content }
      return (
        <EditNewMerchandiseView
          ref={childRef}
          merchandise={merchandise}
          ignoreFields={['customers', 'orderBySku', 'suppliers']}
          requiredFields={['name', 'nickname', 'type', 'category']}
        />)
    } else if (profile === 'product') {
      const product = { ...profileData.content }
      return (
        <EditNewProductView
          ref={childRef}
          product={product}
          ignoreFields={['customers']}
          disabledFields={getDisabledFields()}
          requiredFields={['name', 'type', 'cost']}
        />)
    } else if (['package', 'combination', 'folder'].includes(profile)) {
      const product = { ...profileData.content }
      return (<>
        <EditNewProductView
          ref={childRef}
          product={product}
          disabledFields={getDisabledFields()}
          ignoreFields={['customers', 'cost']}
          requiredFields={['name', 'type']}
        />
        {customerError &&
          <div style={{ color: 'red', fontSize: '14px', marginBottom: '-20px', marginTop: '20px' }}>
            {formatMessage({ id: 'form.customer.error' })}
          </div>
        }
        <EditNewProductPackageView
          ref={packageChildRef}
          product={product}
          pageName={profile}
          editParentData={editParentData}
          productMapping={productMapping}
        />
      </>)
    }
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            {getSourceSelector()}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'newProfile.table.detail.createdBy' })}
              variant="outlined"
              value={userMapping[profileData.createdBy] ? userMapping[profileData.createdBy].displayName : ''}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'newProfile.table.detail.date' })}
              variant="outlined"
              value={profileData.date}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: '24px 0px' }} />
        <Typography sx={{ marginBottom: '8px' }} color="inherit" variant="subtitle1" component="div">
          <FormattedMessage id={`newProfile.table.${tableName}.content`} />
        </Typography>
        {getProfileContent()}
        {/* <Divider style={{margin: '24px 0px'}} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({id: 'requisition.table.detail.note'})}
              variant="outlined"
              value={profileData.note}
              onChange={e => updateProfileData({name: 'note'}, e.target.value)}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid> */}
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            disabled={disableSubmitButton || loading}
            loading={loading}
            loadingPosition="start"
            loadingIndicator={<CircularProgress size={24} />}
            startIcon={<div />}
            variant="contained"
          >
            <FormattedMessage id="button.submit" />
          </LoadingButton>
        </Stack>
      </Box>
    </div>
  );
}


// EditNewProfilePage.defaultProps = {

// }

EditNewProfilePage.propTypes = {
  profile: PropTypes.string.isRequired,
};

export default EditNewProfilePage;
