import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import EnhancedTable from './EnhancedTable';
import SearchBox from './SearchBox';
import { useFirestoreData } from '../modules/uitls';

function SelectCustomerDialog({ defaultSelectedItems, handleClose, handleSave, ignoreIds }) {
  const { formatMessage } = useIntl()
  const customers = useFirestoreData('customers').filter(s => !ignoreIds.includes(s.id));
  const [selectedItems, setSelectedItems] = useState(defaultSelectedItems.reduce((acc, cur) => {acc[cur.id] = true; return acc;}, {}));

  const [currentFilter, setCurrentFilter] = useState(null);

  const filteredCustomers = currentFilter ? (customers.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))) : customers
  const filteredselection = filteredCustomers.filter(s => selectedItems[s.id]).reduce((acc, cur) => {acc[cur.id] = true;return acc;}, {})

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'name' },
    { text: 'nickname' },
    { text: 'businessNumber' },
  ].map(c => {c.text = formatMessage({ id: `supplier.table.detail.${c.text}` });return c})

  const rowCells = [
    { field: 'code' },
    { field: 'name' },
    { field: 'nickname' },
    { field: 'businessNumber' },
  ]

  const filterItems = [
    { name: 'name' },
    { name: 'nickname' },
    { name: 'businessNumber' },
    { name: 'note' },
    { name: 'code' },
  ].map(i => {i.text = formatMessage({ id: `supplier.table.detail.${i.name}` });return i})

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredCustomers.reduce((acc, cur) => {acc[cur.id] = true;return acc}, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  };

  const handleCheckboxClick = ({ id }) => {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  const onApply = () => {
    const selectedCustomers = customers
      .filter(s => selectedItems[s.id])
      .map(s => ({ code: s.code, name: s.name, nickname: s.nickname, id: s.id }))
    handleSave(selectedCustomers)
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title"><FormattedMessage id="selectCustomerDialog.title" /></DialogTitle>
      <DialogContent dividers={true}>
        <div style={{ flexGrow: 1 }}>
          <Toolbar sx={{ pl: 2, pr: 1 }}>
            <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
              <FormattedMessage id="supplier.table.title" />
            </Typography>
            <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />
          </Toolbar>
          <EnhancedTable
            defaultOrder="asc"
            defaultOrderField="code"
            headerCells={headerCells}
            rowCells={rowCells}
            tableData={filteredCustomers}
            onHeaderCheckboxClick={handleSelectAllClick}
            onRowCheckboxClick={handleCheckboxClick}
            getSelectionCount={() => Object.keys(filteredselection).length}
            getRowCheckBoxStatus={customer => selectedItems[customer.id] || false}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button variant="contained" onClick={onApply} color="primary">
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SelectCustomerDialog.propTypes = {
  ignoreIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultSelectedItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    nickname: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })),
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default SelectCustomerDialog;
