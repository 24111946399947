import React, { useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import DelectIconButton from './DelectIconButton';
import EnhancedTable from './EnhancedTable';
import SelectMedicinelMerchandiseDialog from './SelectMedicinelMerchandiseDialog';
import SelectCustomerDialog from './SelectCustomerDialog';
import { useFirestoreDataAndMapping } from '../modules/uitls';

const EditNewMedicineView = forwardRef((props, ref) => {
  const { ignoreFields = [], requiredFields = [], disabledFields = [], medicine } = props
  const { formatMessage } = useIntl()
  const [customerMapping] = useFirestoreDataAndMapping('customers')

  const customers = Object.keys(medicine.customers || []).filter(c => customerMapping[c])
  const defaultState = {
    name: medicine.name,
    category: medicine.category,
    unit: medicine.unit,
    customers: customers.map(s => customerMapping[s]),
    controlledDrug: medicine.controlledDrug,
    cost: medicine.cost,
    effect: medicine.effect,
    appearance: medicine.appearance,
    note: medicine.note,
    merchandises: medicine.merchandises
  }

  const [openMerchandiseDialog, setOpenMerchandiseDialog] = useState(false)
  const [openCustomerDialog, setOpenCustomerDialog] = useState('');

  const [medicineData, setMedicineData] = useState(defaultState);
  // const [loading, setLoading] = useState(false);

  useImperativeHandle(
    ref, () => ({
      getContent: () => {
        return _getContent()
      }
    }),
  )

  // const phoneRule = /[^0-9#]/g
  const numberRule = /[^0-9]/g

  const fields = [
    { name: 'name', type: 'text', sm: 6 },
    { name: 'category', type: 'select', sm: 6 },
    { name: 'unit', type: 'text', sm: 6 },
    { name: 'cost', type: 'text', sm: 6, allowCharacter: numberRule },
    { name: 'effect', type: 'text', sm: 6, md: 6 },
    { name: 'appearance', type: 'text', sm: 6, md: 6 },
    { name: 'controlledDrug', type: 'checkbox', sm: 6, md: 3 },
    { name: 'customers', type: 'popup', sm: 12, md: 12 },
    { name: 'note', type: 'multiline', sm: 12 },
  ].map(field => {
    field.required = requiredFields.includes(field.name)
    field.disabled = disabledFields.includes(field.name)
    field.md = field.md || 3
    return field
  })

  const headerCells = [
    { text: 'code' },
    { text: 'name' },
    { text: 'amount' },
    { text: 'sku' },
  ].map(c => { c.text = formatMessage({ id: `medicine.table.detail.merchandise.${c.text}` }); return c })

  const rowCells = [
    { field: 'code' },
    { field: 'name', tooltip: 'name' },
    {
      field: 'amount',
      type: 'input-number',
      label: formatMessage({ id: 'medicine.table.detail.merchandise.amount' }),
      onValueChanged: onCellValueChanged
    },
    { field: 'sku' },
  ]

  function onCellValueChanged(id, field, value) {
    for (let s of medicineData.merchandises) {
      if (s.id === id) {
        if (field === 'amount') {
          s.amount = value
          if (isNaN(value) || value === '' || value === '0') {
            if (!s.errors) s.errors = {}
            s.errors.amount = '數量錯誤'
          } else {
            if (s.errors && s.errors.amount) delete s.errors.amount
          }
        }
        break
      }
    }
    updateMedicineData({ name: 'merchandises' }, [...medicineData.merchandises]);
  }

  function getMenuItem(field) {
    if (field === 'category') {
      return [
        { value: 'PO' },
        { value: 'OI' },
        { value: 'IV' },
      ].map(i => {
        i.label = i.value ? formatMessage({ id: `medicine.category.${i.value}` }) : formatMessage({ id: 'medicine.category.all' })
        return i
      })
    }
    return []
  }

  const onCustomerChanged = (customers) => {
    updateMedicineData({ name: openCustomerDialog }, customers);
  }

  function onMerchandiseChanged(merchandises) {
    if (merchandises.length) {
      for (let m of merchandises) {
        m.amount = 0
      }
      updateMedicineData({ name: 'merchandises' }, (medicineData.merchandises || []).concat(merchandises));
    }
  }

  const onMerchandiseRemoved = (merchandise) => {
    const merchandises = medicineData.merchandises.filter(s => s.id !== merchandise.id)
    updateMedicineData({ name: 'merchandises' }, [...merchandises]);
  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }
    return ''
  }

  function updateMedicineData(field, value) {
    let newValue = value
    if (field.allowCharacter) {
      newValue = newValue.replace(field.allowCharacter, '')
    }
    if (field.maxLength) {
      newValue = newValue.substring(0, field.maxLength)
    }

    if (newValue === undefined || medicineData[field.name] === newValue) return;

    let err = validateField(field, value)

    let newData = { ...medicineData, [field.name]: newValue, [`${field.name}_err`]: err }

    setMedicineData(newData)
  }

  function createField(field) {
    if (ignoreFields.includes(field.name)) return null
    if (field.type === 'text') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          disabled={field.disabled}
          required={field.required}
          type="text"
          size="small"
          label={formatMessage({ id: `medicine.edit.${field.name}` })}
          variant="outlined"
          onChange={e => { updateMedicineData(field, e.target.value) }}
          value={medicineData[field.name]}
          error={medicineData[`${field.name}_err`] ? true : false}
          helperText={medicineData[`${field.name}_err`]}
          fullWidth
        />
      </Grid>
    } else if (field.type === 'checkbox') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={3}>
        <FormControlLabel
          control={
            <Checkbox
              checked={medicineData[field.name]}
              onChange={e => { updateMedicineData(field, e.target.checked) }}
              color="primary"
            />
          }
          label={formatMessage({ id: `medicine.edit.${field.name}` })}
        />
      </Grid>
    } else if (field.type === 'popup') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          disabled={field.disabled}
          required={field.required}
          type="text"
          size="small"
          label={formatMessage({ id: `medicine.edit.${field.name}` })}
          variant="outlined"
          onClick={() => setOpenCustomerDialog(field.name)} //open popup
          value={medicineData[field.name].map(s => s.nickname).join(', ')}
          error={medicineData[`${field.name}_err`] ? true : false}
          helperText={medicineData[`${field.name}_err`]}
          fullWidth
        />
      </Grid>
    } else if (field.type === 'select') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={3}>
        <TextField
          disabled={field.disabled}
          required={field.required}
          select
          type="text"
          size="small"
          label={formatMessage({ id: `medicine.edit.${field.name}` })}
          variant="outlined"
          onChange={e => { updateMedicineData(field, e.target.value) }}
          value={medicineData[field.name]}
          error={medicineData[`${field.name}_err`] ? true : false}
          helperText={medicineData[`${field.name}_err`]}
          fullWidth
        >
          {getMenuItem(field.name).map((option) => {
            return option.header ?
              (<ListSubheader disabled key={option.value}>{option.label}</ListSubheader>) :
              (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)
          })}
        </TextField>
      </Grid>
    } else if (field.type === 'multiline') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={12}>
        <TextField
          multiline
          required={field.required}
          type="text"
          size="small"
          label={formatMessage({ id: `medicine.edit.${field.name}` })}
          variant="outlined"
          onChange={e => { updateMedicineData(field, e.target.value) }}
          value={medicineData[field.name]}
          fullWidth
        />
      </Grid>
    } else if (field.type === '-') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
    }
  }

  function _getContent() {
    // setLoading(true);

    let err = {}
    for (let field of fields) {
      if (field.required && medicineData[field.name] === '') {
        err[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
      }
    }

    let newData = medicineData

    if (Object.keys(err).length > 0) {
      newData = { ...medicineData, ...err }
      setMedicineData(newData)
    }

    for (let field of fields) {
      if (newData[`${field.name}_err`] !== undefined && newData[`${field.name}_err`] !== '') {
        // setLoading(false);
        return
      }
    }

    let data = {}
    for (let field of fields) {
      if (field.type === '-') continue
      data[field.name] = newData[field.name]
    }

    data.merchandises = newData.merchandises.reduce((acc, cur) => {
      acc[cur.id] = {
        amount: parseInt(cur.amount),
      };
      return acc;
    }, {})

    data.customers = data.customers.reduce((acc, cur) => { acc[cur.id] = true; return acc; }, {})

    return { ...data }
  }

  return (
    <div style={{ flexGrow: 1 }}>
      {openMerchandiseDialog && <SelectMedicinelMerchandiseDialog
        customers={customers}
        defaultSelectedItems={[]}
        handleClose={() => setOpenMerchandiseDialog(false)}
        handleSave={onMerchandiseChanged}
        ignoreIds={medicineData.merchandises.map(m => m.id)}
        hideFields={['stock']}
        size="lg"
      />}
      {openCustomerDialog && <SelectCustomerDialog
        defaultSelectedItems={medicineData[openCustomerDialog]}
        handleClose={() => setOpenCustomerDialog('')}
        handleSave={onCustomerChanged}
        ignoreIds={[]}
      />}
      <Grid container spacing={3}>
        {fields.map(field => createField(field))}
      </Grid>
      <Divider style={{ margin: '8px 0px' }} />
      <Toolbar sx={{ pl: 2, pr: 1 }}>
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id="editMedicine.table.title" />*
          {/* {medicineData.merchandises_err && <span style={{ color: '#f44336', fontSize: '0.75rem' }}>{medicineData.merchandises_err}</span>} */}
        </Typography>
        <Button
          sx={{ m: 1, whiteSpace: 'nowrap' }}
          variant="contained"
          color="primary"
          onClick={() => setOpenMerchandiseDialog(true)}
        >
          <FormattedMessage id="editMedicine.add" />
        </Button>
      </Toolbar>
      <EnhancedTable
        headerCells={headerCells}
        rowCells={rowCells}
        tableData={medicineData.merchandises}
        getActionIcons={merchandise =>
          <DelectIconButton text={formatMessage({ id: 'editMedicine.remove' })} onClick={() => onMerchandiseRemoved(merchandise)} />
        }
      />
    </div>
  );
})

EditNewMedicineView.displayName = 'EditNewMedicienView'

EditNewMedicineView.propTypes = {
  medicine: PropTypes.object,
  ignoreFields: PropTypes.arrayOf(PropTypes.string),
  requiredFields: PropTypes.arrayOf(PropTypes.string),
  disabledFields: PropTypes.arrayOf(PropTypes.string),
};

export default EditNewMedicineView;
