import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import dayjs from 'dayjs';

import { useFirestoreDataAndMapping, unwrap } from '../modules/uitls';

function RecordDialog({ handleClose, currentMapping }) {
  const { formatMessage } = useIntl()
  const [currentData, setCurrentData] = useState([]);
  const [merchandiseMapping] = useFirestoreDataAndMapping('merchandises');
  const [productMapping, setProductMapping] = useState({});

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('products').onSnapshot(snapshot => {
      const productMapping = {}
      snapshot.forEach(doc => {
        const newData = unwrap(doc.data())
        productMapping[doc.id] = newData
      })
      setProductMapping(productMapping)
    }, err => { })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    if (currentMapping.type === 'merchandise') {
      const unsubscribe = firebase.firestore().collection('merchandisesHistories').where('merchandise', '==', currentMapping.id).onSnapshot(snapshot => {
        const data = []
        snapshot.forEach(doc => {
          data.push({ id: doc.id, ...doc.data() })
        })

        setCurrentData(data.sort((a, b) => {
          return dayjs(dayjs(a.createdAt.seconds * 1000) - dayjs(b.createdAt.seconds * 1000))
        }))
      }, err => { })
      return () => unsubscribe()
    } else {
      const unsubscribe = firebase.firestore().collection('productsHistories').where('product', '==', currentMapping.id).onSnapshot(snapshot => {
        const data = []
        snapshot.forEach(doc => {
          data.push({ id: doc.id, ...doc.data() })
        })

        setCurrentData(data.sort((a, b) => {
          return dayjs(dayjs(a.createdAt.seconds * 1000) - dayjs(b.createdAt.seconds * 1000))
        }))
      }, err => { })
      return () => unsubscribe()
    }
  }, []);

  function formatData(data) {
    let newData = ''
    if (data.fieldName === 'code') {
      newData = formatMessage({ id: 'recordDialog.table.create' }, {
        name: data.createdByName,
        time: dayjs(data.createdAt.seconds * 1000).format('YYYY-MM-DD HH:mm'),
        item: currentMapping.type === 'merchandise' ? merchandiseMapping[data.merchandise].name : productMapping[data.product].name
      })
    } else {
      newData = formatMessage({ id: 'recordDialog.table.edit' }, {
        name: data.createdByName,
        time: dayjs(data.createdAt.seconds * 1000).format('YYYY-MM-DD HH:mm'),
        item: currentMapping.type === 'merchandise' ? merchandiseMapping[data.merchandise].name : productMapping[data.product].name,
        type: formatMessage({ id: `recordDialog.table.type.${currentMapping.type}.${data.fieldName}` }),
        old: data.oldValue,
        new: data.newValue
      })
    }

    return newData
  }


  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title"><FormattedMessage id={`recordDialog.title.${currentMapping.type}`} /></DialogTitle>
      <DialogContent dividers={true}>
        {currentData.map(data => {
          return <div key={data.id}>{formatData(data)}</div>
        })}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RecordDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  currentMapping: PropTypes.object.isRequired
};

export default RecordDialog;
