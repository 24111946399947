import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';

import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { getComparator, stableSort } from '../../modules/sort';
import SearchBox from '../../components/SearchBox';
import FabAdd from '../../components/FabAdd';
import ContextStore from '../../modules/context';
import { useFirestoreDataAndMapping, unwrap, sortByField } from '../../modules/uitls';

const fields = [
  { name: 'name', sm: 6 },
  { name: 'category', type: 'select', sm: 6 },
  { name: 'unit', sm: 6 },
  { name: 'controlledDrug', type: 'select', sm: 6 },

  { name: 'appearance', type: 'text', md: 6, sm: 6 },
  { name: 'effect', type: 'text', md: 6, sm: 6 },

  { name: 'customers', md: 6, sm: 12 },
  { name: 'note', type: 'multiline', multiline: true, sm: 12, md: 12 },
].map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

function MedicineDetail({ allowEditing, customerMapping, medicine }) {
  const { formatMessage } = useIntl()
  const customers = Object.keys(medicine.customers || []).filter(c => customerMapping[c]).map(c => customerMapping[c])

  const selectMapping = {
    controlledDrug: {
      'true': formatMessage({ id: 'medicine.controlledDrug.type1' }),
      'false': formatMessage({ id: 'medicine.controlledDrug.type2' })
    },

    category: {
      PO: '口服藥',
      OI: '藥膏',
      IV: '針劑',
    }
  }

  const headerCells = [
    { text: 'code' },
    { text: 'name' },
    { text: 'amount' },
    { text: 'sku' }
  ].map(c => { c.text = formatMessage({ id: `medicine.table.detail.merchandise.${c.text}` }); return c })

  const rowCells = [
    { field: 'code' },
    { field: 'name' },
    { field: 'amount' },
    { field: 'sku' }
  ]

  function createField(field, value) {
    let newValue = value
    if (field.type === 'select') {
      newValue = selectMapping[field.name][value]
    } else if (field.name === 'customers') {
      newValue = customers.map(s => s.nickname).join(', ')
    }

    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={formatMessage({ id: `medicine.table.detail.${field.name}` })}
        value={newValue}
        fullWidth
        size="small"
        variant="standard"
        readOnly
      />
    </Grid>
  }

  return (
    <div style={{ padding: 15 }}>
      <Grid container spacing={2}>
        {fields.map(field => createField(field, medicine[field.name]))}
        <div style={{ paddingLeft: '8px', marginTop: '8px' }}>
          <Typography variant="button" component="div">
            <FormattedMessage id="editMedicine.table.title" />:
          </Typography>
        </div>
        <TableContainer style={{ marginBottom: '8px' }} component={Paper}>
          <Table size="small" aria-label="collapsible table">
            <EnhancedTableHead
              headerCells={headerCells}
              rowCount={medicine.merchandises.length}
            />
            <TableBody>
              {medicine.merchandises.map(merchandise => (
                <EnhancedTableRow
                  key={merchandise.id}
                  rowCells={rowCells}
                  cellData={merchandise}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {allowEditing && <Grid item key="buttons" xs={12} sm={12} md={12}>
          <div style={{ height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
            <Link to={`/medicine/${medicine.id}`} style={{ textDecoration: 'none', color: '#000' }}>
              <Button variant="contained" color="primary">
                <FormattedMessage id="button.edit" />
              </Button>
            </Link>
          </div>
        </Grid>}
      </Grid>
    </div>
  )
}

MedicineDetail.propTypes = {
  medicine: PropTypes.object.isRequired,
  customerMapping: PropTypes.object.isRequired,
  allowEditing: PropTypes.bool.isRequired,
};

function Medicine() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const [medicines, setMedicines] = useState([])
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const [currentFilter, setCurrentFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState({});
  const [customerMapping] = useFirestoreDataAndMapping('customers')
  const [merchandiseMapping] = useFirestoreDataAndMapping('merchandises')
  const filteredMedicines = filterMedicines()

  const filterItems = [
    { name: 'name' },
    { name: 'code' },
  ].map(i => { i.text = formatMessage({ id: `medicine.table.detail.${i.name}` }); return i })

  const categoryFilterItems = [
    { name: 'category' },
  ].map(i => { i.text = formatMessage({ id: `medicine.table.detail.${i.name}` }); return i })

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.medicine' })
    }])
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('medicines').onSnapshot(snapshot => {
      const medicines = []
      snapshot.forEach(doc => {
        const newData = unwrap(doc.data())
        medicines.push({ id: doc.id, ...newData })
      })

      setMedicines(medicines)
    }, err => { })
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const sp = new URLSearchParams(location.search)

    let needUpdate = false
    let newFilter = {}
    for (const f of categoryFilterItems) {
      const value = sp.get(f.name)
      if (value && value !== categoryFilter[f.name]) {
        newFilter[f.name] = value
        needUpdate = true
      } else if (!value && categoryFilter[f.name]) {
        needUpdate = true
      }
    }
    if (needUpdate) {
      setCategoryFilter(newFilter)
    }
  }, [location.search]);

  const headerCells = [
    { text: 'code' },
    { text: 'name', sort: 'name' },
    { text: 'unit' },
    // { text: 'nickname' },
    // // {text: 'price', sort: 'price'},
    // { text: 'sku' },
  ].map(c => { c.text = formatMessage({ id: `medicine.table.header.${c.text}` }); return c })

  const rowCells = [
    { field: 'code' },
    { field: 'name' },
    { field: 'unit' },
    // { field: 'nickname' },
    // // {field: 'price'},
    // { field: 'sku' },
  ]

  const formatData = (medicine) => {
    const newData = { ...medicine }
    newData.merchandises = Object.keys(medicine.merchandises || [])
      .filter(id => merchandiseMapping[id])
      .map(id => ({
        ...medicine.merchandises[id],
        id,
        code: merchandiseMapping[id].code,
        name: merchandiseMapping[id].name,
        sku: merchandiseMapping[id].sku
      })).sort(sortByField('code'))
    return newData
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function filterMedicines() {
    if (!currentFilter &&
      !categoryFilter.category) {
      return medicines
    }
    let items = [...medicines]
    if (categoryFilter.category) {
      items = items.filter(i => i.category === categoryFilter.category)
    }

    if (currentFilter) {
      const lowerCaseText = currentFilter.text.toLowerCase()
      if (currentFilter.name === 'customers') {
        items = items.filter(s => Object.keys(s.customers || {})
          .filter(c => customerMapping[c])
          .filter(c => customerMapping[c].name.toLowerCase().includes(lowerCaseText) || customerMapping[c].nickname.toLowerCase().includes(lowerCaseText)).length)
      } else {
        items = items.filter(s => s[currentFilter.name].toLowerCase().includes(lowerCaseText))
      }
    }
    return items
  }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  function getMenuItem(name) {
    if (name === 'category') {
      return [
        { value: '' },
        { value: 'PO' },
        { value: 'OI' },
        { value: 'IV' },
      ].map(i => {
        i.label = i.value ? formatMessage({ id: `medicine.category.${i.value}` }) : formatMessage({ id: 'medicine.category.all' })
        return i
      })
    }

    return []
  }

  function updateCategoryFilter(name, value) {
    const newFilter = { ...categoryFilter }
    newFilter[name] = value
    setCategoryFilter(newFilter)
    const sp = new URLSearchParams(location.search)
    const str1 = sp.toString()
    if (value === '') {
      sp.delete(name)
    } else {
      sp.set(name, value)
    }
    const str2 = sp.toString()
    if (str1 !== str2) {
      navigate({ pathname: location.pathname, search: `?${str2}` }, { replace: true });
    }
  }

  const getFilter = (filter) => {
    return <Grid item key={filter.name} xs={3} sm={3} md={3}>
      <TextField
        select
        type="text"
        size="small"
        label={filter.text}
        variant="outlined"
        onChange={e => { updateCategoryFilter(filter.name, e.target.value) }}
        value={categoryFilter[filter.name] || ''}
        fullWidth
      >
        {getMenuItem(filter.name).map((option, idx) => {
          return option.header ?
            (<ListSubheader key={`${option.value}-${idx}`}>{option.label}</ListSubheader>) :
            (<MenuItem key={`${option.value}-${idx}`} value={option.value}>{option.label}</MenuItem>)
        })}
      </TextField>
    </Grid>
  }

  function exportList() {
    const selectMapping = {
      controlledDrug: {
        'true': formatMessage({ id: 'medicine.controlledDrug.type1' }),
        'false': formatMessage({ id: 'medicine.controlledDrug.type2' })
      },
  
      category: {
        PO: '口服藥',
        OI: '藥膏',
        IV: '針劑',
      }
    }

    const _formatData = (merchandise) => {
      const newData = { ...merchandise }
      newData.name = newData.name.replace(/(?:\r\n|\r|\n)/g, ' ').replace(/,/gi, '')
      newData.note = newData.note.replace(/(?:\r\n|\r|\n)/g, ' ').replace(/,/gi, '')
      newData.controlledDrug = selectMapping.controlledDrug[newData.controlledDrug]
      newData.category = selectMapping.category[newData.category]

      return newData
    }

    const rows = []
    const _rows = []

    const fields = [
      { name: 'name', sm: 6 },
      { name: 'category', type: 'select', sm: 6 },
      { name: 'unit', sm: 6 },
      { name: 'controlledDrug', type: 'select', sm: 6 },

      { name: 'appearance', type: 'text', md: 6, sm: 6 },
      { name: 'effect', type: 'text', md: 6, sm: 6 },

      { name: 'customers', md: 6, sm: 12 },
      { name: 'note', type: 'multiline', multiline: true, sm: 12, md: 12 },
    ].map(i => { if (!i.text) i.text = formatMessage({ id: `medicine.table.detail.${i.name}` }); return i })

    for (const f of fields) {
      _rows.push(f.text)
    }

    const newData = medicines.map(i => _formatData(i))

    rows.push(_rows.join(','))

    for (const n of newData) {
      let _rows2 = []
      
      for (const f of fields) {
        if(f.name === 'customers') {
          const customers = Object.keys(n.customers || []).filter(c => customerMapping[c]).map(c => customerMapping[c].name).join('/')
          _rows2.push(customers)
        } else {
          _rows2.push(n[f.name])
        }
      }

      rows.push(_rows2.join(','))
    }

    exportFields(rows, `MedicineList_${dayjs().format('YYYY-MM-DD')}`)
  }


  function exportFields(rows, path) {
    try {
      const content = rows.join('\n')
      const csvData = new Blob(['\uFEFF' + content], { type: 'text/csv' })
      const csvUrl = URL.createObjectURL(csvData)
      const aExport = document.createElement('a')
      aExport.href = csvUrl
      aExport.target = '_blank'
      aExport.download = path
      aExport.click()
    } catch (error) {
      console.error(error) // eslint-disable-line
      alert('An error occurred. Please refresh and try again.')
    }
  }

  return (
    <div style={{ paddingBottom: '100px' }}>
      {currentUser.userRights['medicine-create'] === true && <FabAdd to="/medicine/new" />}
      <Toolbar sx={{ pl: 2, pr: 1 }}>
        <Typography sx={{ flex: '1 1 100%', flexShrink: 2 }} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id="medicine.table.title" />
        </Typography>
        <IconButton
          onClick={() => exportList()}
          size="large">
          <GetAppIcon></GetAppIcon>
          <Typography noWrap variant="button">{formatMessage({ id: 'button.export' })}</Typography>
        </IconButton>
        <Grid container spacing={3}>
          {categoryFilterItems.map(f => getFilter(f))}
        </Grid>
        <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} updateUrlParams />
      </Toolbar>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: '650px' }} aria-label="collapsible table">
          <EnhancedTableHead
            headerCells={headerCells}
            order={order}
            orderBy={orderBy}
            expandable
            onRequestSort={handleRequestSort}
            rowCount={filteredMedicines.length}
          />
          <TableBody>
            {stableSort(filteredMedicines, getComparator(order, orderBy)).map(medicine => (
              <EnhancedTableRow
                key={medicine.id}
                rowCells={rowCells}
                cellData={medicine}
                expandable
                expandContent={<MedicineDetail
                  allowEditing={!!currentUser.userRights['medicine-edit']}
                  customerMapping={customerMapping}
                  medicine={formatData(medicine)}
                />}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Medicine;
