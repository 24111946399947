import React from 'react';
import PropTypes from 'prop-types';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { useSelector, Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { isLoaded, isEmpty } from 'react-redux-firebase';

import LoginForm from './containers/LoginForm/LoginForm';
import { store, firebase } from './store/index';
import { getCurrentUser } from './modules/user';
import App from './App';
import i18n from './i18n/i18n'
import { useFirestoreDataAndStatus } from './modules/uitls';

const cache = createIntlCache()
const intl = createIntl({
  locale: 'zh-TW',
  messages: i18n
}, cache)

function loadUserRights({ auth, customer, rootKey, key, userRights, overWrite, unlock, sr }) {
  const steps = customer[`${key}Steps`] || []

  sr[`${key}Source`] = sr[`${key}Source`] || {}
  sr[`${key}Review`] = sr[`${key}Review`] || {}
  if (overWrite) {
    sr[`${key}Overwrite`] = sr[`${key}Overwrite`] || {}
  }
  if (unlock) {
    sr[`${key}Unlock`] = sr[`${key}Unlock`] || {}
  }

  for (const i in steps) {
    const step = steps[i]
    if (Number(i) === 0) {
      if (step.users.includes(auth.uid)) {
        userRights[`${key}-create`] = true
        userRights[`${key}-edit`] = true
        userRights[`${key}-view`] = true
        userRights[`${rootKey}-view`] = true
        sr[`${key}Source`][customer.id] = true
      }
      if (overWrite && step.overwrite.includes(auth.uid)) {
        userRights[`${rootKey}-view`] = true
        userRights[`${key}-view`] = true
        sr[`${key}Overwrite`][customer.id] = true
      }
      if (unlock && step.unlock.includes(auth.uid)) {
        userRights[`${rootKey}-view`] = true
        userRights[`${key}-view`] = true
        sr[`${key}Unlock`][customer.id] = true
      }
    } else {
      if (step.users.includes(auth.uid)) {
        userRights[`${key}-review`] = true
        userRights[`${key}-view`] = true
        userRights[`${rootKey}-view`] = true
        sr[`${key}Review`][customer.id] = true
      }
    }
  }

}

function UserIsLoaded({ auth }) {
  store.firestore.setListeners([
    { collection: 'users', storeAs: 'users' },
    { collection: 'users', doc: auth.uid, storeAs: 'user' },
    { collection: 'shoppingCart', where: ['user', '==', auth.uid], storeAs: 'shoppingCart' },
    { collection: 'merchandises', storeAs: 'merchandises' },
    { collection: 'suppliers', storeAs: 'suppliers' },
    // { collection: 'suppliers', where: ['internal', '==', true], orderBy: ['code', 'desc'], storeAs: 'customers'}, // requires an index(internal + code)
    { collection: 'suppliers', where: ['internal', '==', true], storeAs: 'customers' },
    { collection: 'configuration', storeAs: 'config' },
  ])
  const [customers, customerDataReady] = useFirestoreDataAndStatus('customers')
  const config = useSelector(state => state.firestore.data.config)

  const user = useSelector(state => state.firestore.data.user);
  if (!user || !customerDataReady || config === undefined) {
    return (<div className='loginWithGoogle'>
      loading...
    </div>)
  }

  const userRights = {}
  const sr = {}
  const whQuerySource = {}
  const whInventorySource = {}
  const borrowingOrderSource = {}
  const borrowingDeliverySource = {}
  const borrowingReceiptSource = {}
  const returnBorrowingFormSource = {}
  const returnBorrowingReceiptSource = {}
  const deliveryOrderSource = {}
  const returnFormSource = {}

  for (const customer of customers) {
    loadUserRights({ auth, customer, rootKey: 'purchase', key: 'requisition', userRights, overWrite: true, sr })
    loadUserRights({ auth, customer, rootKey: 'purchase', key: 'purchaseOrder', userRights, overWrite: true, sr })
    loadUserRights({ auth, customer, rootKey: 'purchase', key: 'receipt', userRights, overWrite: true, sr })
    loadUserRights({ auth, customer, rootKey: 'stock', key: 'stockRequisition', userRights, sr })
    loadUserRights({ auth, customer, rootKey: 'stock', key: 'scrapForm', userRights, sr })
    loadUserRights({ auth, customer, rootKey: 'newProfile', key: 'newSupplier', userRights, overWrite: true, sr })
    loadUserRights({ auth, customer, rootKey: 'newProfile', key: 'newMerchandise', userRights, overWrite: true, sr })
    loadUserRights({ auth, customer, rootKey: 'newProfile', key: 'newProduct', userRights, overWrite: true, sr })
    loadUserRights({ auth, customer, rootKey: 'inventoryCheck', key: 'inventoryCheck', userRights, unlock: true, sr })

    const whQuery = customer?.stock?.query || []
    if (whQuery.includes(auth.uid)) {
      userRights['whQuery-view'] = true
      userRights['stock-view'] = true
      whQuerySource[customer.id] = true
    }
    const whInventory = customer?.stock?.inventory || []
    if (whInventory.includes(auth.uid)) {
      userRights['whInventory-view'] = true
      userRights['stock-view'] = true
      whInventorySource[customer.id] = true
    }

    const borrowingOrder = customer?.borrowing?.borrowingOrder || []
    if (borrowingOrder.includes(auth.uid)) {
      userRights['borrowing-view'] = true
      userRights['borrowingOrder-view'] = true
      userRights['borrowingOrder-create'] = true
      borrowingOrderSource[customer.id] = true
    }

    const borrowingDelivery = customer?.borrowing?.borrowingDelivery || []
    if (borrowingDelivery.includes(auth.uid)) {
      userRights['borrowing-view'] = true
      userRights['borrowingDelivery-view'] = true
      userRights['borrowingDelivery-create'] = true
      borrowingDeliverySource[customer.id] = true
    }

    const borrowingReceipt = customer?.borrowing?.borrowingReceipt || []
    if (borrowingReceipt.includes(auth.uid)) {
      userRights['borrowing-view'] = true
      userRights['borrowingReceipt-view'] = true
      userRights['borrowingReceipt-create'] = true
      borrowingReceiptSource[customer.id] = true
    }

    const returnBorrowingForm = customer?.borrowing?.returnBorrowingForm || []
    if (returnBorrowingForm.includes(auth.uid)) {
      userRights['borrowing-view'] = true
      userRights['returnBorrowingForm-view'] = true
      userRights['returnBorrowingForm-create'] = true
      returnBorrowingFormSource[customer.id] = true
    }

    const returnBorrowingReceipt = customer?.borrowing?.returnBorrowingReceipt || []
    if (returnBorrowingReceipt.includes(auth.uid)) {
      userRights['borrowing-view'] = true
      userRights['returnBorrowingReceipt-view'] = true
      userRights['returnBorrowingReceipt-create'] = true
      returnBorrowingReceiptSource[customer.id] = true
    }

    const whDelivery = customer?.sales?.deliveryOrder || []
    if (whDelivery.includes(auth.uid)) {
      userRights['sales-view'] = true
      userRights['deliveryOrder-view'] = true
      userRights['deliveryOrder-create'] = true
      deliveryOrderSource[customer.id] = true
    }
    const whReturn = customer?.sales?.returnForm || []
    if (whReturn.includes(auth.uid)) {
      userRights['purchaseOrder-view'] = true
      userRights['returnForm-view'] = true
      userRights['returnForm-create'] = true
      returnFormSource[customer.id] = true
    }
  }

  for (const k of Object.keys(sr)) {
    sr[k] = Object.keys(sr[k])
  }

  const currentUser = getCurrentUser({
    ...user,
    key: auth.uid,
    whQuerySource: Object.keys(whQuerySource),
    whInventorySource: Object.keys(whInventorySource),
    borrowingOrderSource: Object.keys(borrowingOrderSource),
    borrowingDeliverySource: Object.keys(borrowingDeliverySource),
    borrowingReceiptSource: Object.keys(borrowingReceiptSource),
    returnBorrowingFormSource: Object.keys(returnBorrowingFormSource),
    returnBorrowingReceiptSource: Object.keys(returnBorrowingReceiptSource),
    deliveryOrderSource: Object.keys(deliveryOrderSource),
    returnFormSource: Object.keys(returnFormSource),
    ...sr,
    userRights: { ...user.userRights, ...userRights }
  });

  return (
    <RawIntlProvider value={intl}>
      <BrowserRouter>
        <App user={currentUser} config={config} />
      </BrowserRouter>
    </RawIntlProvider>
  )
}

UserIsLoaded.propTypes = {
  auth: PropTypes.object,
};


function AuthIsLoaded() {

  const auth = useSelector(state => state.firebase.auth)
  if (!isLoaded(auth)) {
    return (
      <div className='loginWithGoogle'>
        loading...
      </div>
    )
  } else if (isEmpty(auth)) {
    return (
      <RawIntlProvider value={intl}>
        <LoginForm />
      </RawIntlProvider>
    )
  };
  return <UserIsLoaded auth={auth} />
}

const rrfProps = {
  firebase,
  config: {
    userProfile: 'users',
    useFirestoreForProfile: true
  },
  dispatch: store.dispatch,
  // createFirestoreInstance
};

if (process.env.NODE_ENV !== 'production') {
  window.backend = 'http://localhost:3000';
} else {
  window.backend = 'http://localhost:3000';
}

render(
  // <React.StrictMode>
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <AuthIsLoaded />
    </ReactReduxFirebaseProvider>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

if (module.hot) {
  module.hot.accept(App);
}
