import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import ContextStore from '../../modules/context';
import DatePickerField from '../../components/DatePickerField';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableToolbar from '../../components/EnhancedTableToolbar';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { useFirestoreDataAndMapping, lockSubmitButton, unwrap } from '../../modules/uitls';
import { getComparator, stableSort } from '../../modules/sort';
import SelectMerchandiseDialog from '../../components/SelectMerchandiseDialog';

function EditRequisitionPage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const { requisitionId } = useParams()

  const [customerMapping, customers] = useFirestoreDataAndMapping('customers')

  const filteredCustomers = customers.filter(c => currentUser.requisitionSource.includes(c.id))
  const [merchandiseMapping] = useFirestoreDataAndMapping('merchandises')
  const [userMapping] = useFirestoreDataAndMapping('users')

  const [selectedItems, setSelectedItems] = useState({})
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [openDialog, setOpenDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [lockSource, setLockSource] = useState(false)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('code')
  const [dateError, setDateError] = useState({})

  const handleDateChange = (date) => {
    updateRequisitionData({ name: 'expectedDate' }, date)
  };

  const [rawRequisition, setRawRequisition] = useState({
    id: requisitionId,
    source: '',
    createdBy: currentUser.key,
    date: dayjs().format('YYYY-MM-DD'),
    expectedDate: new Date(),
    note: '',
    // merchandises: [],
  });

  const [owRequisition, setOwRequisition] = useState({
  });
  let requisition = {}

  const disableSubmitButton = requisitionId === 'new' ?
    (currentUser.userRights['requisition-create'] !== true) :
    (currentUser.userRights['requisition-edit'] !== true);

  useEffect(() => {
    if (filteredCustomers.length === 1 && requisitionId === 'new') {
      updateRequisitionData({ name: 'source' }, filteredCustomers[0].id);
    }
  }, [filteredCustomers.length]);

  useEffect(() => {
    const breadcrumbs = [{
      link: '/purchase/requisition/pending',
      text: formatMessage({ id: 'sideMenu.purchase.requisition' })
    }]
    if (requisitionId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: 'requisition.dialog.title.add' }) })
    } else {
      breadcrumbs.push({ text: formatMessage({ id: 'requisition.dialog.title.edit' }) })
    }
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    if (requisitionId !== 'new') { // edit
      const unsubscribe = firebase.firestore().collection('requisitions').doc(requisitionId)
        .onSnapshot(snapshot => {
          setRawRequisition({ id: snapshot.id, ...unwrap(snapshot.data()) })
        }, err => {})
      return () => unsubscribe()
    } else {
      return () => {};
    }
  }, []);

  if (requisitionId !== 'new' && !rawRequisition.merchandises) {
    return ('Loading...')
  }

  const merchandiseKeys = Object.keys(rawRequisition.merchandises || {}).filter(m => merchandiseMapping[m])
  requisition = { ...{
    id: requisitionId,
    source: rawRequisition.source,
    createdBy: rawRequisition.createdBy,
    date: rawRequisition.date,
    expectedDate: dayjs(rawRequisition.expectedDate).toDate(),
    note: rawRequisition.note,
    merchandises: merchandiseKeys.map(m => merchandiseMapping[m]).map(m => ({
      code: m.code,
      orderUnit: m.orderUnit,
      sku: m.sku,
      ou2sku: m.ou2sku,
      name: m.name,
      nickname: m.nickname,
      id: m.id,
      orderBySku: rawRequisition.merchandises[m.id].orderBySku,
      note: rawRequisition.merchandises[m.id].note,
      amount: rawRequisition.merchandises[m.id].amount,
      lock: ((rawRequisition.merchandises[m.id].purchaseOrders && Object.keys(rawRequisition.merchandises[m.id].purchaseOrders).length) || rawRequisition.merchandises[m.id].shoppingCart ? true : false),
    }))
  }, ...owRequisition }

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'name' },
    // {text: 'unitPrice', align: 'right'},
    { text: 'amount', align: 'right' },
    { text: 'orderUnit', align: 'right' },
    // {text: 'price', align: 'right'},
    { text: 'note', align: 'right' },
  ].map(c => {c.text = formatMessage({ id: `requisition.merchandise.${c.text}` });return c})

  const rowCells = [
    { field: 'code' },
    { field: 'nickname', tooltip: 'name' },
    // {field: 'unitPrice', align: 'right'},
    { field: 'amount', align: 'right', type: 'input-number', required: true, label: '數量', onValueChanged: onCellValueChanged },
    { field: 'orderUnit', align: 'right' },
    // {field: 'price', align: 'right', type: 'calculate', calculate: 'unitPrice*amount'},
    { field: 'note', align: 'right', type: 'input', required: false, label: '備註', onValueChanged: onCellValueChanged },
  ]

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function onCellValueChanged(id, field, value) {
    for (let m of requisition.merchandises) {
      if (m.id === id) {
        if (field === 'amount') {
          m.amount = value
          if (isNaN(value) || value === '' || parseInt(value) === 0) {
            if (!m.errors) {
              m.errors = {}
            }
            m.errors.amount = '數量錯誤'
          } else {
            if (m.errors && m.errors.amount) {
              delete m.errors.amount
            }
          }
        } else {
          m[field] = value
        }
        break
      }
    }
    updateRequisitionData({ name: 'merchandises' }, requisition.merchandises);
  }

  function onMerchandiseChanged(merchandises) {
    if (merchandises.length) {
      for (let m of merchandises) {
        m.amount = '1'
        m.note = ''
      }
      updateRequisitionData({ name: 'merchandises' }, requisition.merchandises.concat(merchandises));
    }
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = requisition.merchandises.reduce((acc, cur) => {if (!cur.lock) {acc[cur.id] = true};return acc}, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  }

  function handleCheckboxClick(id) {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  function onDeleteItems() {
    const merchandises = requisition.merchandises.filter(m => !selectedItems[m.id])
    if (merchandises.length !== requisition.merchandises.length) {
      updateRequisitionData({ name: 'merchandises' }, merchandises);
      setSelectedItems({});
    }
  }

  function validateField(field, value) {
    // if (field.required && value === '') {
    //   return formatMessage({id: 'form.field.isRequired'})
    // }
    if (field.name === 'merchandises') {
      for (const m of value) {
        if (m.errors && m.errors.amount) {
          return m.errors.amount
        }
      }
    } else if (field.name === 'expectedDate') {
      if (value === null) {
        return formatMessage({ id: 'form.field.isRequired' })
      } else if (value.toString() === 'Invalid Date') {
        return formatMessage({ id: 'form.date.formatError' })
      } else {
        if (dayjs(value).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD')) {
          return formatMessage({ id: 'form.date.beforeToday' })
        }
      }
    }
    return ''
  }

  function updateRequisitionData(field, value) {
    let newValue = value
    // if (field.allowCharacter) {
    //   newValue = newValue.replace(field.allowCharacter, '')
    // }
    // if (field.maxLength) {
    //   newValue = newValue.substring(0, field.maxLength)
    // }

    // if (supplierData[field.name] === newValue) return;

    let err = validateField(field, value)

    if (field.name === 'expectedDate') {
      if (err) {
        setDateError({ error: true, helperText: err })
      } else {
        setDateError({})
      }
    }

    let newData = { ...requisition, [field.name]: newValue, [`${field.name}_err`]: err }
    setOwRequisition(newData)
  }

  async function handleSave() {
    lockSubmitButton(true)
    setLoading(true);

    const fields = [
      { name: 'source' },
      // {name: 'createdBy'},
      { name: 'date' },
      { name: 'expectedDate' },
      { name: 'merchandises' },
      { name: 'note' },
    ]

    // let err = {}
    let newData = requisition
    // if (Object.keys(err).length > 0) {
    //   newData = {...requisition, ...err}
    //   setOwRequisition(newData)
    // }
    for (let field of fields) {
      if (newData[`${field.name}_err`] !== undefined && newData[`${field.name}_err`] !== '') {
        lockSubmitButton(false)
        setLoading(false);
        return
      }
    }

    let data = {}
    for (let field of fields) {
      if (field.type === '-') continue
      data[field.name] = newData[field.name]
    }

    data.merchandises = data.merchandises.reduce((acc, cur) => {
      acc[cur.id] = {
        amount: parseInt(cur.amount),
        note: cur.note
      };
      if (cur.orderBySku) {
        acc[cur.id].orderBySku = true
      }
      return acc;
    }, {})
    data.expectedDate = dayjs(newData.expectedDate).format('YYYY-MM-DD')

    try {
      await (firebase.functions().httpsCallable('saveRequisition'))({ id: requisition.id, ...data })
    } catch (ex) {
      console.log(ex)
    }
    lockSubmitButton(false)
    handleClose()
  }

  function handleClose() {
    navigate('/purchase/requisition/pending');
  }

  function formatData(merchandise) {
    const newData = { ...merchandise }
    if (newData.orderBySku) {
      newData.orderUnit = newData.sku
    } else if (newData.orderUnit !== newData.sku) {
      newData.orderUnit += `(${newData.ou2sku}${newData.sku})`
    }
    return newData
  }

  function getToolbox() {
    return <Button
      disabled={requisition.source === ''}
      sx={{ m: 1, whiteSpace: 'nowrap' }}
      variant="contained"
      color="primary"
      startIcon={<AddShoppingCartIcon />}
      onClick={() => {
        setLockSource(true)
        setOpenDialog(true)
      }}
    >
      <FormattedMessage id="editRequisition.addMerchandise" />
    </Button>
  }

  function getToolboxForSelection() {
    return <Button
      variant="contained"
      color="primary"
      startIcon={<DeleteIcon />}
      style={{ whiteSpace: 'nowrap', marginRight: '8px' }}
      onClick={onDeleteItems}
    >
      <FormattedMessage id="editRequisition.removeMerchandise" />
    </Button>
  }

  function getSourceSelector() {
    if (currentUser.requisitionSource.length === 1 || requisitionId !== 'new') {
      const value = requisitionId !== 'new' ?
        (customerMapping[requisition.source] ? customerMapping[requisition.source].nickname : '') :
        (customerMapping[currentUser.requisitionSource[0]] ? customerMapping[currentUser.requisitionSource[0]].nickname : '')

      return (<TextField
        disabled
        type="text"
        label={formatMessage({ id: 'requisition.table.detail.source' })}
        variant="outlined"
        value={value}
        fullWidth
        size="small"
      />)
    } else {
      return (<TextField
        select
        required
        disabled={lockSource}
        type="text"
        label={formatMessage({ id: 'requisition.table.detail.source' })}
        variant="outlined"
        value={requisition.source}
        onChange={e => updateRequisitionData({ name: 'source' }, e.target.value)}
        fullWidth
        size="small"
        error={requisition.source_err ? true : false}
        helperText={requisition.source_err}
      >
        {filteredCustomers.map(c => <MenuItem key={c.id} value={c.id}>
          {c.nickname}
        </MenuItem>)}
      </TextField>)
    }
  }

  // const invoiceSubtotal = requisition.merchandises.reduce((acc, cur) => {
  //   acc += cur.unitPrice * cur.amount
  //   return acc;
  // }, 0)
  // const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  // const invoiceTotal = invoiceTaxes + invoiceSubtotal;
  const showVoidButton = (requisition.id !== 'new' && requisition.merchandises.length === 0)

  return (
    <div style={{ flexGrow: 1 }}>
      {openDialog && <SelectMerchandiseDialog
        customer={requisition.source}
        defaultSelectedItems={[]}
        handleClose={() => setOpenDialog(false)}
        handleSave={onMerchandiseChanged}
        ignoreIds={requisition.merchandises.map(m => m.id)}
        hideFields={[]}
        loadStockMapping={true}
      />}
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            {getSourceSelector()}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'requisition.table.detail.createdBy' })}
              variant="outlined"
              value={userMapping[requisition.createdBy] ? userMapping[requisition.createdBy].displayName : ''}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'requisition.table.detail.date' })}
              variant="outlined"
              value={requisition.date}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePickerField
              required
              fullWidth
              label={formatMessage({ id: 'requisition.table.detail.expectedDate' })}
              value={requisition.expectedDate}
              onChange={handleDateChange}
              {...dateError}
              minDateMessage={formatMessage({ id: 'form.date.beforeToday' })}
              invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
              minDate={new Date()}
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: '8px 0px' }} />
        <EnhancedTableToolbar
          title="editRequisition.table.title"
          selectdMessage="editRequisition.table.selected"
          numSelected={Object.keys(selectedItems).length}
          toolbox={getToolbox()}
          toolboxForSelection={getToolboxForSelection()}
        />
        <TableContainer component={Paper}>
          <Table>
            <EnhancedTableHead
              headerCells={headerCells}
              numSelected={Object.keys(selectedItems).length}
              onCheckboxClick={handleSelectAllClick}
              rowCount={requisition.merchandises.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(requisition.merchandises.map(m => formatData(m)), getComparator(order, orderBy)).map(merchandise => (
                <EnhancedTableRow
                  key={merchandise.id}
                  rowCells={rowCells}
                  cellData={merchandise}
                  onCheckboxClick={(e) => handleCheckboxClick(merchandise.id)}
                  selected={selectedItems[merchandise.id] || false}
                  lock={merchandise.lock}
                />
              ))}
              {/* <TableRow>
                <TableCell colSpan={5}/>
                <TableCell align="right"><Typography variant="subtitle2"><FormattedMessage id="requisition.table.detail.subtotal" /></Typography></TableCell>
                <TableCell align="right">{invoiceSubtotal}</TableCell>
                <TableCell />
              </TableRow> */}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'requisition.table.detail.note' })}
              variant="outlined"
              value={requisition.note}
              onChange={e => updateRequisitionData({ name: 'note' }, e.target.value)}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          {showVoidButton &&
            <LoadingButton
              color="error"
              onClick={handleSave}
              disabled={loading}
              loading={loading}
              loadingPosition="start"
              loadingIndicator={<CircularProgress size={24} />}
              startIcon={<div />}
              variant="contained"
            >
              <FormattedMessage id="button.void" />
            </LoadingButton>
          }
          {!showVoidButton &&
            <LoadingButton
              color="primary"
              onClick={handleSave}
              disabled={disableSubmitButton || loading || requisition.merchandises.length === 0}
              loading={loading}
              loadingPosition="start"
              loadingIndicator={<CircularProgress size={24} />}
              startIcon={<div />}
              variant="contained"
            >
              <FormattedMessage id="button.submit" />
            </LoadingButton>
          }
        </Stack>
      </Box>
    </div>
  );
}

// EditRequisitionPage.defaultProps = {

// }

export default EditRequisitionPage;
