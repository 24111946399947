import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import EnhancedTable from '../../components/EnhancedTable';
import ContextStore from '../../modules/context';
import { TAX_RATE } from '../../constants/index';

const printFormFields = [
  { text: 'sn', size: 3 },
  { text: 'createdBy', size: 3 },
  { text: 'date', size: 3 },
  { text: 'invoiceNumber', size: 3 },
  // {text: 'note', size: 12},
]

function ReceiptReportView({ receipt: _receipt, merchandiseMapping, formName }) {
  const { formatMessage } = useIntl()
  const { currentUser } = useContext(ContextStore)

  function formatReceiptData(receipt) {
    const data = {
      ...receipt,
    }
    data.invoiceSubtotal = receipt.merchandises.map(m => {
      const newM = { ...m }
      if (newM.items) {
        newM.returnAmount = newM.items.reduce((acc, cur) => acc + (cur.returnAmount || 0), 0)
      } else {
        newM.returnAmount = newM.returnAmount || 0
      }
      return newM
    }).reduce((acc, cur) => {
      acc += Math.round(cur.unitPrice * (cur.amount - cur.returnAmount))
      return acc;
    }, 0)
    data.totalDiscount = receipt.merchandises.reduce((acc, cur) => {
      acc += (cur.discount || 0)
      return acc;
    }, receipt.discount || 0)
    data.invoiceSubtotalTax = Math.round(data.invoiceSubtotal * TAX_RATE)
    data.totalDiscountTax = Math.round(data.totalDiscount * TAX_RATE)

    // const invoiceTaxes = (receipt.tax || receipt.tax === 0) ? receipt.tax : (receipt.taxIncluded ? 0 : Math.round(TAX_RATE * (invoiceSubtotal - totalDiscount)))
    data.invoiceTaxes = (receipt.tax || receipt.tax === 0) ? receipt.tax : (data.invoiceSubtotalTax - data.totalDiscountTax)
    data.invoiceTotal = data.invoiceTaxes + data.invoiceSubtotal - data.totalDiscount + (receipt.shippingFee || 0)
    return data
  }

  const receipt = formatReceiptData(_receipt)

  let refSn = ''
  let refId = ''
  if (formName === 'receipt') {
    refSn = 'purchaseOrderSn'
    refId = 'purchaseOrderId'
  } else if (formName === 'borrowingReceipt') {
    refSn = 'borrowingOrderSn'
    refId = 'borrowingOrderId'
  } else if (formName === 'returnBorrowingReceipt') {
    refSn = 'borrowingOrderSn'
    refId = 'borrowingOrderId'
  }

  // function getHeaderCells(receipt) {
  const _headerCells = [
    { field: refSn, order: 0 },
    { field: 'code', order: 1 },
    { field: 'name', order: 2 },
    { field: 'amount', align: 'right', order: 6 },
    { field: 'returnAmount', align: 'right', order: 7 },
    { field: 'orderUnit', align: 'right', order: 8 },
    { field: 'note', align: 'right', order: 11 },
  ]

  const headerCells = (formName === 'receipt' && (currentUser.userRights.debugging || currentUser.receiptOverwrite.includes(receipt.source)) ?
    [..._headerCells,
      { field: 'unitPrice', align: 'right', order: 5 },
      { field: 'price', align: 'right', order: 9 },
      { field: 'discount', align: 'right', order: 10 }] :
    _headerCells
  ).map(c => {c.text = formatMessage({ id: `${formName}.print.merchandise.${c.field}` });return c}).sort((a, b) => a.order - b.order)

  // return headerCells
  // }

  // function getRowCells(receipt) {
  const _rowCells = [
    { field: refSn, order: 0 },
    { field: 'code', order: 1 },
    { field: 'nickname', order: 2, width: '260px' },
    { field: 'amount', align: 'right', order: 6 },
    { field: 'returnAmount', align: 'right', order: 7 },
    { field: 'orderUnit', align: 'right', order: 8 },
    { field: 'note', align: 'right', maxWidth: '120px', order: 11 },
  ]
  const rowCells = (formName === 'receipt' && (currentUser.userRights.debugging || currentUser.receiptOverwrite.includes(receipt.source)) ?
    [..._rowCells,
      { field: 'unitPrice', align: 'right', order: 5 },
      { field: 'price', align: 'right', type: 'calculate',calculate: 'unitPrice*finalAmount', order: 9 },
      { field: 'discount', align: 'right', order: 10 }] :
    _rowCells
  ).sort((a, b) => a.order - b.order)
  // return rowCells
  // }

  function formatMerchandiseData(merchandise) {
    const data = {
      ...merchandise,
      code: merchandiseMapping[merchandise.id].code,
      name: merchandiseMapping[merchandise.id].name,
      nickname: merchandiseMapping[merchandise.id].nickname,
      orderUnit: merchandiseMapping[merchandise.id].orderUnit,
    }
    if (!data.items) {
      data.items = [{
        id: merchandise.id,
        amount: merchandise.amount,
        returnAmount: merchandise.lotNumber || 0,
        expectedDate: merchandise.expectedDate,
        lotNumber: merchandise.lotNumber
      }]
      data.returnAmount = merchandise.returnAmount || 0
    } else {
      data.returnAmount = data.items.reduce((acc, cur) => acc + (cur.returnAmount || 0), 0)
    }
    data.finalAmount = data.amount - data.returnAmount
    data.key = `${merchandise[refId]}@${merchandise.id}`
    return data
  }

  return (
    <Box style={{ breakInside: 'avoid-page' }} p={1}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            <FormattedMessage id="receipt.print.title" />
          </Typography>
        </Grid>
        {printFormFields.map(f => <Grid key={`print.${f.text}`} item xs={f.size} sm={f.size} md={f.size}>
          <FormattedMessage id={`receipt.print.${f.text}`} />: {receipt[f.text]}
        </Grid>)}
        {receipt.note && <Grid item xs={12} sm={12} md={12}>
          <FormattedMessage id={'receipt.print.note'} />: {receipt.note}
        </Grid>}
        <Grid item xs={12} sm={12} md={12}>
          <EnhancedTable
            size="small"
            headerCells={headerCells}
            rowCells={rowCells}
            tableData={receipt.merchandises.map(m => formatMerchandiseData(m))}
            extRows={
              formName === 'receipt' && (currentUser.userRights.debugging || currentUser.receiptOverwrite.includes(receipt.source)) ?
                <>
                  <TableRow>
                    <TableCell rowSpan={5} colSpan={headerCells.length - 4}/>
                    <TableCell align="right"><Typography variant="subtitle2"><FormattedMessage id="invoice.discount" /></Typography></TableCell>
                    <TableCell align="right" colSpan={2}>{receipt.discount}</TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell align="right"><Typography variant="subtitle2"><FormattedMessage id="invoice.subtotal" /></Typography></TableCell>
                    <TableCell align="right">{receipt.invoiceSubtotal}</TableCell>
                    <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>- {receipt.totalDiscount}</TableCell>
                    <TableCell align="right">{receipt.invoiceSubtotal - receipt.totalDiscount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right"><Typography variant="subtitle2"><FormattedMessage id="invoice.tax" /></Typography></TableCell>
                    <TableCell align="right">{receipt.invoiceSubtotalTax}</TableCell>
                    <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>- {receipt.totalDiscountTax}</TableCell>
                    <TableCell align="right">{receipt.invoiceTaxes}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right"><Typography variant="subtitle2"><FormattedMessage id="invoice.shippingFee" /></Typography></TableCell>
                    <TableCell colSpan={2}/>
                    <TableCell align="right">{receipt.shippingFee || 0}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right"><Typography variant="subtitle2"><FormattedMessage id="invoice.total" /></Typography></TableCell>
                    <TableCell colSpan={2}/>
                    <TableCell align="right">{receipt.invoiceTotal}</TableCell>
                  </TableRow>
                </> : null
            }
          />
        </Grid>
      </Grid>
    </Box>
  )
}

ReceiptReportView.propTypes = {
  receipt: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
};

export default ReceiptReportView;
