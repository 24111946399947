import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import Divider from '@mui/material/Divider';

import ContextStore from '../../modules/context';
import DateRangePickerField from '../../components/DateRangePickerField';
import RequisitionList from './RequisitionList';
import { unwrap } from '../../modules/uitls';

function RequisitionTab() {
  const { tabName } = useParams()
  const { currentUser } = useContext(ContextStore)

  const [requisitions, setRequisitions] = useState([])
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  useEffect(() => {
    if (requisitions.length) setRequisitions([])
    const onSnapshot = snapshot => {
      const requisitions = []
      snapshot.forEach(doc => {
        requisitions.push({ ...unwrap(doc.data()), id: doc.id })
      });
      setRequisitions(requisitions)
    }
    const unsubscribe = tabName === 'pending' ?
      firebase.firestore().collection('requisitions').where('status', '==', tabName).onSnapshot(onSnapshot, err => { }) :
      firebase.firestore().collection('requisitions').where('status', '==', tabName).where('date', '>=', startDate).where('date', '<=', endDate).onSnapshot(onSnapshot, err => { })
    return () => unsubscribe()
  }, [startDate, endDate]);

  const requisitionsForTab = requisitions.filter(r =>
    currentUser.userRights.debugging ||
    currentUser.requisitionSource.includes(r.source) ||
    currentUser.requisitionReview.includes(r.source) ||
    currentUser.requisitionOverwrite.includes(r.source)
  );

  const reqWaitingForProcess = waitingForProcess()

  function waitingForProcess() {
    if (tabName === 'pending') {
      return requisitionsForTab.filter(r => {
        const history = r.history || []
        const currentStep = history.length > 0 ? history[history.length - 1].step : 0
        if (isNaN(currentStep) || currentStep === r.steps.length) return false
        return r.steps[currentStep].users.includes(currentUser.key)
      })
    } else if (tabName === 'done') {
      return requisitionsForTab.filter(r => {
        if (!currentUser.purchaseOrderSource.includes(r.source)) return false
        const merchandises = Object.keys(r.merchandises).map(k => ({ id: k, ...(r.merchandises[k]) }))
        return (merchandises.some(m => {
          if (m.shoppingCart || (!m.shoppingCart && !m.purchaseOrders)) return true
          const poIds = Object.keys(m.purchaseOrders || {})
          let unPurchasedAmount = m.amount
          for (const i of poIds) {
            if (m.purchaseOrders[i].status === 'done') unPurchasedAmount -= m.purchaseOrders[i].amount
          }
          return unPurchasedAmount > 0
        }))
      })
    } else {
      return []
    }
  }

  function exportList() {

  }

  return (
    <div style={{ paddingBottom: '60px' }}>
      {tabName !== 'pending' && <DateRangePickerField
        startDate={startDate}
        endDate={endDate}
        onStartDateChanged={setStartDate}
        onEndDateChanged={setEndDate}
      />}
      {reqWaitingForProcess.length > 0 && <>
        <RequisitionList requisitions={reqWaitingForProcess} title={`requisition.table.${tabName}.title`} />
        <Divider sx={{ mx: '0px', my: '10px' }} />
      </>}
      <RequisitionList requisitions={requisitionsForTab} tabName={tabName} title="requisition.table.title" />
    </div>
  );
}

export default RequisitionTab;
