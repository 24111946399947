import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { getComparator, stableSort } from '../../modules/sort';

// 用於出貨單選擇庫存
function SelectStockDialog({ handleClose, handleSave, customer, merchandise, amountHint = '出貨數量' }) {
  const { formatMessage } = useIntl()
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('expectedDate');
  const [merchandises, setMerchandises] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('wh').doc(customer).collection('inventories').where('id', '==', merchandise.id).where('empty', '==', false)
      .onSnapshot( snapshot => {
        const stocks = []
        snapshot.forEach(doc => {
          const m = doc.data()
          const ou = merchandise.orderUnit
          const sku = merchandise.sku
          const ou2sku = merchandise.ou2sku
          const amount = m.wareroom ? Object.keys(m.wareroom).reduce((acc, cur) => acc - (m.wareroom[cur] ?? 0), m.currentAmount) : m.currentAmount
          // const maxAmount = (merchandise.orderBySku ? amount : Math.floor(amount / ou2sku))
          const maxAmount = (ou !== sku ? Math.floor(amount / ou2sku) : amount)
          if (maxAmount > 0) {
            stocks.push({
              id: doc.id,
              date: m.date,
              expectedDate: m.expectedDate,
              lotNumber: m.lotNumber,
              amountInStocks: maxAmount,
              stocksWithUnit: maxAmount + ou, // 這裡的 OU 是從單據帶過來的, 所以如果系統裡的 OU 有改過, 顯示上可能會出問題
              amount: 0,
              maxAmount: maxAmount
            })
          }
        });
        const sortedStocks = stocks.sort((a, b) => {
          // if (a.expectedDate === '' && b.expectedDate === '') {
          //   return 0
          // }
          if (a.expectedDate === '' && b.expectedDate !== '') return 1
          if (a.expectedDate !== '' && b.expectedDate === '') return -1

          if (a.expectedDate === b.expectedDate) {
            if (a.date > b.date) return 1
            if (a.date < b.date) return -1
            if (a.date === b.date) return 0
          }
          if (a.expectedDate > b.expectedDate) return 1
          return -1
        })
        if (merchandise.items.length > 0) {
          const tmp = merchandise.items.reduce((acc, cur) => {acc[cur.id] = cur; return acc}, {})
          for (const i of sortedStocks) {
            if (tmp[i.id]) {
              i.amount = tmp[i.id].amount
            }
          }
        } else {
          let req = merchandise.amount
          for (const i of sortedStocks) {
            const n = Math.min(i.amountInStocks, req)
            i.amount = n
            req -= n
            if (req === 0) break
          }
        }
        setMerchandises(sortedStocks)
      }, err => {})
    return () => unsubscribe()
  }, []);


  const headerCells = [
    { text: 'date', sort: 'date' },
    { text: 'expectedDate', sort: 'expectedDate' },
    { text: 'lotNumber' },
    { text: 'stocksWithUnit' },
    { text: 'amount', align: 'right' },
  ].map(c => {c.text = formatMessage({ id: `selectStockDialog.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'date' },
    { field: 'expectedDate' },
    { field: 'lotNumber' },
    { field: 'stocksWithUnit' },
    { field: 'amount', align: 'right', type: 'input-number', required: true, params: ['maxAmount'], label: amountHint, onValueChanged: onCellValueChanged },
  ]

  function onCellValueChanged(id, field, value, params) {
    // console.log(params)
    if (params) {
      for (let m of merchandises) {
        if (m.id === id) {
          if (field === 'amount') {
            m.amount = value
            if (value === '') {
              if (m.errors && m.errors.amount) {
                delete m.errors.amount
              }
            } else if (isNaN(value)) {
              if (!m.errors) {
                m.errors = {}
              }
              m.errors.amount = '數量錯誤'
            } else if (value > params[0]) {
              if (!m.errors) {
                m.errors = {}
              }
              m.errors.amount = '超過庫存數量'
            } else {
              if (m.errors && m.errors.amount) {
                delete m.errors.amount
              }
            }
          }
          break
        }
      }
      // console.log(merchandises)
      setMerchandises([...merchandises]);
    }
  }

  function _totalAmount() {
    let t = 0
    for (const merchandise of merchandises) {
      if ((merchandise.errors && merchandise.errors.amount !== undefined) || merchandise.amount === '') {
        continue
      }
      t += parseInt(merchandise.amount)
    }
    return t
  }
  const totalAmount = _totalAmount()

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onApply = () => {
    const m = {
      merchandiseId: merchandise.id,
    }
    for (const merchandise of merchandises) {
      if (merchandise.errors && merchandise.errors.amount !== undefined) {
        return
      }
      if (merchandise.amount !== '' && merchandise.amount > 0) {
        if (!m.items) m.items = []
        m.items.push({
          id: merchandise.id,
          expectedDate: merchandise.expectedDate,
          lotNumber: merchandise.lotNumber,
          amount: parseInt(merchandise.amount),
        })
      }
    }
    handleSave(m)
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title"><FormattedMessage id="selectStockDialog.title" /></DialogTitle>
      <DialogContent dividers={true}>
        <div style={{ flexGrow: 1 }}>
          <Toolbar sx={{ pl: 2, pr: 1 }}>
            <Typography sx={{ flex: '1 1 100%', flexShrink: 2 }} variant="h6" id="tableTitle" component="div">
              {merchandise.name} <FormattedMessage id="selectStockDialog.table.title" /> {merchandise.amount > 0 ? `(需求量:${merchandise.amount})` : ''}
            </Typography>
          </Toolbar>
          <TableContainer component={Paper}>
            <Table>
              <EnhancedTableHead
                headerCells={headerCells}
                numSelected={0}
                order={order}
                orderBy={orderBy}
                // onCheckboxClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={merchandises.length}
              />
              <TableBody>
                {stableSort(merchandises, getComparator(order, orderBy)).map(m => (
                  <EnhancedTableRow
                    key={m.id}
                    rowCells={rowCells}
                    cellData={m}
                    // onCheckboxClick={(e) => handleCheckboxClick(merchandise.id)}
                    // selected={selectedItems[merchandise.id] || false}
                  />
                ))}
                <TableRow>
                  <TableCell rowSpan={2} colSpan={3}/>
                  <TableCell align="right"><Typography variant="subtitle2">合計</Typography></TableCell>
                  <TableCell align="right">
                    {totalAmount}
                    {merchandise.amount > 0 && totalAmount > merchandise.amount && <span style={{ color: '#f44336' }}>
                      <FormattedMessage id="form.amount.overRequire" />
                    </span>}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button variant="contained" disabled={merchandise.amount > 0 && totalAmount > merchandise.amount} onClick={onApply} color="primary">
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SelectStockDialog.propTypes = {
  customer: PropTypes.string.isRequired,
  merchandise: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  amountHint: PropTypes.string,
};

export default SelectStockDialog;
