import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';

export default function DelectIconButton({ text, onClick }) {
  return (
    <Tooltip title={text}>
      <IconButton aria-label="delete" onClick={onClick}>
        <DeleteIcon/>
      </IconButton>
    </Tooltip>
  );
};

DelectIconButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
