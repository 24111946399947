const i18n = {
  'user': {
    'rights': {
      'vendor': '權限-供應商',
      'finance': '權限-財務',
      'medicine': '權限-藥品',
      'merchandise': '權限-商品',
      'product': '權限-療程',
      'report': '權限-報表',
      'vendor-view': '查看供應商和客戶列表',
      'vendor-create': '建立供應商和客戶資料',
      'vendor-edit': '編輯供應商和客戶資料',
      'requisition-view': '查看請購單',
      'requisition-create': '建立請購單',
      'requisition-edit': '編輯請購單',
      'requisition-review': '簽核請購單',
      'purchaseOrder-view': '查看採購單',
      'purchaseOrder-create': '建立採購單',
      'purchaseOrder-edit': '編輯採購單',
      'purchaseOrder-review': '簽核採購單',
      'receipt-view': '查看進貨單',
      'receipt-create': '送出進貨單',
      'receipt-edit': '編輯進貨單',
      'receipt-review': '簽核進貨單',
      'stockRequisition-view': '查看沙貨領用單',
      'stockRequisition-create': '送出沙貨領用單',
      'stockRequisition-review': '簽核沙貨領用單',
      'merchandise-view': '查看商品列表',
      'merchandise-create': '建立商品',
      'merchandise-edit': '編輯商品',
      'product-view': '查看療程產品列表',
      'product-create': '建立療程產品',
      'product-edit': '編輯療程產品',
      'whQuery-view': '查詢庫存',
      'whInventory-view': '盤點庫存',
    },
    'table': {
      'title': '使用者列表',
      'header': {
        'name': '姓名',
        'email': '電子信箱',
        'employeeId': '員工編號',
        'status': '帳號狀態',
      },
      'detail': {
        'name': '姓名',
        'displayName': '姓名',
        'email': '電子信箱',
        'activeStatus': '帳號狀態',
        'status': '帳號狀態',
        'employeeId': '員工編號',
        'finance-create': '新增財務表單',
        'finance-edit': '編輯財務表單',
        'finance-view': '查看財務表單',
        'medicine-create': '新增藥品',
        'medicine-edit': '編輯藥品',
        'medicine-view': '查看藥品',
        'merchandise-create': '新增商品',
        'merchandise-edit': '編輯商品',
        'merchandise-view': '查看商品',
        'product-create': '新增療程',
        'product-edit': '編輯療程',
        'product-view': '查看療程',
        'vendor-create': '新增廠商',
        'vendor-edit': '編輯廠商',
        'vendor-view': '查看廠商',
        'report-view': '查看報表',
      }
    },
    'status': {
      'all': '全部',
      'enabled': '啟用',
      'disabled': '停用',
    }
  }
}
export default i18n
