import React, { useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import EnhancedTableToolbar from './EnhancedTableToolbar'
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableRow from './EnhancedTableRow';
import SelectProductDialog from './SelectProductDialog';
import { getComparator, stableSort } from '../modules/sort';
import { useFirestoreDataAndMapping } from '../modules/uitls';

const EditNewProductPackageView = forwardRef((props, ref) => {
  const { product, pageName, productMapping, editParentData } = props
  const { formatMessage } = useIntl()

  const [customerMapping] = useFirestoreDataAndMapping('customers')
  const defaultState = {
    package: formatProductPackage(product.package),
  }

  const [openDialog, setOpenDialog] = useState(false)
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const [selectedItems, setSelectedItems] = useState({});
  const [productData, setProductData] = useState(defaultState);
  const [ratioError, setRatioError] = useState(false)

  useImperativeHandle(
    ref, () => ({
      getContent: () => {
        return _getContent()
      }
    }),
  )

  const _headerCells = [
    { text: 'name', sort: 'name', order: 0 },
    { text: 'amount', sort: 'amount', order: 1 },
    { text: 'customers', sort: 'customers', order: 5 },
    { text: 'cost', sort: 'cost', order: 6 },
  ]

  const _rowCells = [
    { field: 'name', order: 0 },
    { field: 'amount', align: 'left', type: 'input-number', onValueChanged: onCellValueChanged, required: true, order: 1 },
    { field: 'customers', order: 5 },
    { field: 'cost', order: 6 },
  ]

  if (pageName !== 'folder') {
    _headerCells.push({ text: 'ratio', sort: 'ratio', order: 2 })
    _rowCells.push({ field: 'ratio', align: 'left', type: 'input-number', onValueChanged: onCellValueChanged, required: true, order: 2 })
  } else {
    _headerCells.push({ text: 'price', sort: 'price', order: 2 }, { text: 'lowestPrice', sort: 'lowestPrice', order: 3 })
    _rowCells.push(
      { field: 'price', align: 'left', type: 'input-number', onValueChanged: onCellValueChanged, required: true, order: 2 },
      { field: 'lowestPrice', align: 'left', type: 'input-number', onValueChanged: onCellValueChanged, required: true, order: 3 },
    )
  }

  if (pageName === 'package') {
    _headerCells.push({ text: 'independent', order: 4 })
    _rowCells.push({
      field: 'independent',
      align: 'left',
      type: 'input-select',
      required: true,
      fullWidth: true,
      label: formatMessage({ id: 'product.product.independent' }),
      onValueChanged: onCellValueChanged,
      getMenuItems: () => [{ label: '隨套消耗', value: false }, { label: '單獨消耗', value: true }],
      order: 4
    })
  }

  const headerCells = _headerCells.map(c => { c.text = formatMessage({ id: `product.product.${c.text}` }); return c }).sort((a, b) => a.order - b.order)
  const rowCells = _rowCells.sort((a, b) => a.order - b.order)
  const packageFields = pageName === 'folder' ? ['amount', 'price', 'lowestPrice'] : ['amount', 'ratio']


  if (!productMapping) {
    return ('Loading...')
  }
  const filterProduct = filterProducts()

  function filterProducts() {
    const newData = [];

    for (const key of Object.keys(productMapping)) {
      if (productMapping[key]) {
        newData.push({ id: key, ...productMapping[key] })
      }
    }

    const filterData = newData.filter(m => m.cat.substr(0, 1) === '1')

    return filterData
  }

  function formatProductPackage(data) {
    const newData = []
    for (const p of data) {
      newData.push({
        ...p,
        name: productMapping[p.id].name,
        customers: productMapping[p.id].customers,
        cost: productMapping[p.id].cost,
      })
    }

    return newData
  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }

    return ''
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function onDeleteItems() {
    const products = productData.package.filter(m => !selectedItems[m.id])
    if (products.length !== productData.package.length) {
      updateProductData({ name: 'package' }, products);
      setSelectedItems({});
    }
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = productData.package.reduce((acc, cur) => { acc[cur.id] = true; return acc }, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  }

  function onProductChanged(products) {
    if (products.length) {
      for (let p of products) {
        if (pageName !== 'folder') {
          p.ratio = 0
          p.amount = 1
        } else {
          p.price = 0
          p.lowestPrice = 0
          p.amount = 1
        }
      }
      updateProductData({ name: 'package' }, (productData.package || []).concat(products));
    }
  }

  function handleCheckboxClick(id) {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  function onCellValueChanged(id, field, value) {
    for (let p of productData.package) {
      if (p.id === id) {
        p[field] = value
      }
    }

    updateProductData({ name: 'package' }, [...productData.package]);
  }

  const formatData = (product) => {
    const newData = { ...product }
    const customers = Object.keys(product.customers || []).filter(c => customerMapping[c])
    newData.customers = customers.map(s => customerMapping[s]).map(s => s.nickname).join(', ')
    newData.independent = newData.independent || false
    return newData
  }

  function updateProductData(field, value) {
    let newValue = value
    if (field.allowCharacter) {
      newValue = newValue.replace(field.allowCharacter, '')
    }
    if (field.maxLength) {
      newValue = newValue.substring(0, field.maxLength)
    }

    if (newValue === undefined || productData[field.name] === newValue) return;

    let err = validateField(field, value)

    let costCount = 0
    let consumable = false
    let newData = { ...productData, [field.name]: newValue, [`${field.name}_err`]: err }
    for (const s of newData.package) {
      if (productMapping[s.id] && productMapping[s.id].consumable) {
        consumable = true
      }
      costCount += s.cost
      for (const field of packageFields) {
        if (s[field] !== '') {
          if (!s.errors) s.errors = {}
          s.errors[field] = ''
        }
      }
    }

    if (field.name === 'package') {
      for (const f of ['staffprice', 'staffRelativesPrice']) {
        editParentData(f, Math.round(costCount * (f === 'staffprice' ? 1.2 : 1.5)))
      }

      editParentData('consumable', consumable)
    }

    setProductData(newData)
  }

  function _getContent() {
    let err = {}
    let newData = productData

    let packageError = false
    for (const s of productData.package) {
      for (const field of packageFields) {
        if (s[field] === '') {
          if (!s.errors) s.errors = {}
          s.errors[field] = formatMessage({ id: 'form.field.isRequired' })
          packageError = true
        }

        if (s[field] > 100 && field === 'ratio') {
          s.errors[field] = formatMessage({ id: 'form.field.ratioError' })
          packageError = true
        }
      }
    }

    if (packageError) {
      newData = { ...productData, package: [...productData.package] }
    }
    if (Object.keys(err).length > 0) {
      newData = { ...productData, ...err }
    }
    if (Object.keys(err).length > 0 || packageError) {
      setProductData(newData)
    }

    let ratioCount = 0
    for (const s of newData.package) {
      if (pageName === 'package') {
        s.independent = s.independent || false
      }

      if (s.ratio) {
        ratioCount += parseInt(s.ratio)
        s.ratio = parseInt(s.ratio)
      }
      for (const field of packageFields) {
        if (s.errors && s.errors[field] !== undefined && s.errors[field] !== '') {
          // setLoading(false);
          return
        }
      }
    }

    if (ratioCount > 100) {
      setRatioError(true)
      return
    } else {
      setRatioError(false)
    }

    let data = newData
    data.treatments = pageName
    return { ...data }
  }

  return (
    <div style={{ flexGrow: 1 }}>
      {openDialog && <SelectProductDialog
        customer={' '}
        defaultSelectedItems={[]}
        handleClose={() => setOpenDialog(false)}
        handleSave={onProductChanged}
        ignoreIds={productData.package.map(m => m.id)}
        hideFields={[]}
        size="lg"
        productData={filterProduct}
      />}
      <div style={{ margin: '40px 0px 20px' }}>
        <EnhancedTableToolbar
          title="product.products"
          selectdMessage="editReceipt.table.selected"
          numSelected={Object.keys(selectedItems).length}
          toolbox={
            <Button
              disabled={false}
              sx={{ m: 1, whiteSpace: 'nowrap' }}
              variant="contained"
              color="primary"
              onClick={() => setOpenDialog(true)}
            >
              <FormattedMessage id={'product.edit.addProduct'} />
            </Button>
          }
          toolboxForSelection={
            <Button
              variant="contained"
              color="primary"
              startIcon={<DeleteIcon />}
              style={{ whiteSpace: 'nowrap', marginRight: '8px' }}
              onClick={onDeleteItems}
            >
              <FormattedMessage id="product.edit.removeProduct" />
            </Button>
          }
        />
        <TableContainer component={Paper}>
          <Table>
            <EnhancedTableHead
              headerCells={headerCells}
              numSelected={Object.keys(selectedItems).length}
              onCheckboxClick={handleSelectAllClick}
              rowCount={productData.package.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(productData.package.map(m => formatData(m)), getComparator(order, orderBy)).map(product => (
                <EnhancedTableRow
                  key={`${product.id}`}
                  rowCells={rowCells}
                  cellData={product}
                  onCheckboxClick={(e) => handleCheckboxClick(product.id)}
                  selected={selectedItems[product.id] || false}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {ratioError && <div style={{ color: 'red' }}>{formatMessage({ id: 'form.package.ratioError' })}</div>}
      </div>
    </div>
  );
})

EditNewProductPackageView.displayName = 'EditNewProductPackageView'

EditNewProductPackageView.propTypes = {
  product: PropTypes.object,
  pageName: PropTypes.string.isRequired,
  productMapping: PropTypes.object,
  editParentData: PropTypes.func
};

export default EditNewProductPackageView;
