import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import ContextStore from '../../modules/context';
import DatePickerField from '../../components/DatePickerField';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableToolbar from '../../components/EnhancedTableToolbar';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { useFirestoreDataAndMapping, useSupplierDataAndMapping, lockSubmitButton } from '../../modules/uitls';
import { getComparator, stableSort } from '../../modules/sort';
import SelectMerchandiseDialog from '../../components/SelectMerchandiseDialog';
import SelectSupplierDialog from '../../components/SelectSupplierDialog';

function EditBorrowingOrderPage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const { borrowingOrderId } = useParams()

  const [customerMapping, customers] = useFirestoreDataAndMapping('customers')
  const [supplierMapping] = useSupplierDataAndMapping()

  const filteredCustomers = customers.filter(c => currentUser.borrowingOrderSource.includes(c.id))
  const [merchandiseMapping, merchandises] = useFirestoreDataAndMapping('merchandises')
  const [userMapping] = useFirestoreDataAndMapping('users')

  const [selectedItems, setSelectedItems] = useState({})
  const [openDialog, setOpenDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [lockSource, setLockSource] = useState(false)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('code')
  const [openSupplierDialog, setOpenSupplierDialog] = useState(false);
  const [dateError, setDateError] = useState({})

  const [rawBorrowingOrder, setRawBorrowingOrder] = useState({
    id: borrowingOrderId,
    source: '',
    supplier: '',
    createdBy: currentUser.key,
    date: dayjs().format('YYYY-MM-DD'),
    expectedDate: new Date(),
    note: '',
    // merchandises: [],
  });

  const [owBorrowingOrder, setOwBorrowingOrder] = useState({
  });
  let borrowingOrder = {}

  const disableSubmitButton = borrowingOrderId === 'new' ?
    (currentUser.userRights['borrowingOrder-create'] !== true) :
    (currentUser.userRights['borrowingOrder-edit'] !== true);

  const intraSupplierIds = customers.filter(c => c.supplierId).map(c => c.supplierId)
  const intraSupplierMapping = customers.filter(c => c.supplierId).reduce((acc, cur) => {acc[cur.supplierId] = cur;return acc}, {})

  useEffect(() => {
    if (filteredCustomers.length === 1 && borrowingOrderId === 'new') {
      updateBorrowingOrderData({ name: 'source' }, filteredCustomers[0].id);
    }
  }, [filteredCustomers.length]);

  useEffect(() => {
    const breadcrumbs = [{
      link: '/borrowing/borrowingOrder',
      text: formatMessage({ id: 'sideMenu.borrowing.borrowingOrder' })
    }]
    if (borrowingOrderId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: 'borrowingOrder.dialog.title.add' }) })
    } else {
      breadcrumbs.push({ text: formatMessage({ id: 'borrowingOrder.dialog.title.edit' }) })
    }
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    if (borrowingOrderId !== 'new') { // edit
      const unsubscribe = firebase.firestore().collection('borrowingOrders').doc(borrowingOrderId)
        .onSnapshot(snapshot => {
          setRawBorrowingOrder({ id: snapshot.id, ...snapshot.data() })
        }, err => {})
      return () => unsubscribe()
    } else {
      return () => {};
    }
  }, []);

  if (borrowingOrderId !== 'new' && !rawBorrowingOrder.merchandises) {
    return ('Loading...')
  }

  const merchandiseKeys = Object.keys(rawBorrowingOrder.merchandises || {}).filter(m => merchandiseMapping[m])
  borrowingOrder = { ...{
    id: borrowingOrderId,
    source: rawBorrowingOrder.source,
    supplier: rawBorrowingOrder.supplier,
    createdBy: rawBorrowingOrder.createdBy,
    date: rawBorrowingOrder.date,
    expectedDate: dayjs(rawBorrowingOrder.expectedDate).toDate(),
    note: rawBorrowingOrder.note,
    merchandises: merchandiseKeys.map(m => merchandiseMapping[m]).map(m => ({
      code: m.code,
      orderUnit: m.orderUnit,
      name: m.name,
      nickname: m.nickname,
      id: m.id,
      note: rawBorrowingOrder.merchandises[m.id].note,
      unitPrice: rawBorrowingOrder.merchandises[m.id].unitPrice,
      amount: rawBorrowingOrder.merchandises[m.id].amount,
      lock: (rawBorrowingOrder.merchandises[m.id].purchaseOrder ? true : false),
      purchaseOrder: rawBorrowingOrder.merchandises[m.id].purchaseOrder,
    }))
  }, ...owBorrowingOrder }

  const headerCells = [
    { text: 'code', sort: 'code' },
    { text: 'name' },
    { text: 'amount', align: 'right' },
    { text: 'orderUnit', align: 'right' },
    { text: 'note', align: 'right' },
  ].map(c => {c.text = formatMessage({ id: `borrowingOrder.merchandise.${c.text}` });return c})

  const rowCells = [
    { field: 'code' },
    { field: 'nickname', tooltip: 'name' },
    { field: 'amount', align: 'right', type: 'input-number', required: true, label: '數量', onValueChanged: onCellValueChanged },
    { field: 'orderBy',
      align: 'right',
      type: 'input-select',
      required: true,
      label: formatMessage({ id: 'borrowingOrder.merchandise.orderUnit' }),
      onValueChanged: onCellValueChanged,
      getMenuItems: getUnitList,
    },
    { field: 'note', align: 'right', type: 'input', required: false, label: '備註', onValueChanged: onCellValueChanged },
  ]

  function getUnitList(merchandise) {
    if (merchandise.orderUnit === merchandise.sku) {
      return [{ label: merchandise.orderUnit, value: 'ou' }]
    }
    return [{ label: merchandise.orderUnit, value: 'ou' }, { label: merchandise.sku, value: 'sku' }]
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleDateChange = (date) => {
    updateBorrowingOrderData({ name: 'expectedDate' }, date)
  };

  function getAllowMerchandiseIds() {
    // 如果 supplier 可以對應到一個 customer, 就回傳該 customer 可以買的商品,
    // 如果 supplier 是外部的廠商, 就找出供應商中有該廠商的商品
    if (borrowingOrder.supplier) {
      if (intraSupplierIds.includes(borrowingOrder.supplier)) {
        const customerId = intraSupplierMapping[borrowingOrder.supplier].id
        return merchandises.filter(m => m.customers[customerId]).map(m => m.id)
      } else {
        return merchandises.filter(m => Object.keys(m.suppliers).includes(borrowingOrder.supplier)).map(m => m.id)
      }
    }
    return
  }

  function onCellValueChanged(id, field, value) {
    for (let m of borrowingOrder.merchandises) {
      if (m.id === id) {
        if (field === 'amount') {
          m.amount = value
          if (isNaN(value) || value === '' || parseInt(value) === 0) {
            if (!m.errors) {
              m.errors = {}
            }
            m.errors.amount = '數量錯誤'
          } else {
            if (m.errors && m.errors.amount) {
              delete m.errors.amount
            }
          }
        } else {
          m[field] = value
        }
        break
      }
    }
    updateBorrowingOrderData({ name: 'merchandises' }, borrowingOrder.merchandises);
  }

  function onMerchandiseChanged(merchandises) {
    if (merchandises.length) {
      for (let m of merchandises) {
        m.amount = '1'
        m.note = ''
        m.orderBy = 'ou'
      }
      updateBorrowingOrderData({ name: 'merchandises' }, borrowingOrder.merchandises.concat(merchandises));
    }
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = borrowingOrder.merchandises.reduce((acc, cur) => {if (!cur.lock) {acc[cur.id] = true};return acc}, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  }

  function handleCheckboxClick(id) {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  function onDeleteItems() {
    const merchandises = borrowingOrder.merchandises.filter(m => !selectedItems[m.id])
    if (merchandises.length !== borrowingOrder.merchandises.length) {
      updateBorrowingOrderData({ name: 'merchandises' }, merchandises);
      setSelectedItems({});
    }
  }

  function validateField(field, value) {
    // if (field.required && value === '') {
    //   return formatMessage({id: 'form.field.isRequired'})
    // }
    if (field.name === 'merchandises') {
      for (const m of value) {
        if (m.errors && m.errors.amount) {
          return m.errors.amount
        }
      }
    } else if (field.name === 'expectedDate') {
      if (value === null) {
        return formatMessage({ id: 'form.field.isRequired' })
      } else if (value.toString() === 'Invalid Date') {
        return formatMessage({ id: 'form.date.formatError' })
      } else {
        if (dayjs(value).format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD')) {
          return formatMessage({ id: 'form.date.beforeToday' })
        }
      }
    }
    return ''
  }

  function updateBorrowingOrderData(field, value) {
    let newValue = value
    // if (field.allowCharacter) {
    //   newValue = newValue.replace(field.allowCharacter, '')
    // }
    // if (field.maxLength) {
    //   newValue = newValue.substring(0, field.maxLength)
    // }

    // if (supplierData[field.name] === newValue) return;
    let err = validateField(field, value)

    if (field.name === 'expectedDate') {
      if (err) {
        setDateError({ error: true, helperText: err })
      } else {
        setDateError({})
      }
    }

    let newData = { ...borrowingOrder, [field.name]: newValue, [`${field.name}_err`]: err }
    if (field.name === 'source' && customerMapping[value].supplierId && customerMapping[value].supplierId === borrowingOrder.supplier) {
      newData.supplier = ''
    }
    setOwBorrowingOrder(newData)
  }

  async function handleSave() {
    lockSubmitButton(true)
    setLoading(true);

    const fields = [
      { name: 'source' },
      { name: 'supplier' },
      // {name: 'createdBy'},
      { name: 'date' },
      { name: 'expectedDate' },
      { name: 'merchandises' },
      { name: 'note' },
    ]

    // let err = {}
    let newData = borrowingOrder
    // if (Object.keys(err).length > 0) {
    //   newData = {...requisition, ...err}
    //   setOwRequisition(newData)
    // }
    for (let field of fields) {
      if (newData[`${field.name}_err`] !== undefined && newData[`${field.name}_err`] !== '') {
        lockSubmitButton(false)
        setLoading(false);
        return
      }
    }

    let data = {}
    for (let field of fields) {
      if (field.type === '-') continue
      data[field.name] = newData[field.name]
    }

    data.merchandises = data.merchandises.reduce((acc, cur) => {
      acc[cur.id] = {
        amount: parseInt(cur.amount),
        orderUnit: cur.orderBy === 'ou' ? cur.orderUnit : cur.sku,
        note: cur.note
      };
      if (cur.orderBy === 'sku') {
        acc[cur.id].orderBySku = true
      }
      if (cur.purchaseOrder) {
        acc[cur.id].purchaseOrder = cur.purchaseOrder
      }
      return acc;
    }, {})
    data.expectedDate = dayjs(newData.expectedDate).format('YYYY-MM-DD')

    try {
      await (firebase.functions().httpsCallable('saveBorrowingOrder'))({
        id: borrowingOrder.id,
        deliveryOrder: supplierMapping[borrowingOrder.supplier].internal || false,
        ...data
      })
    } catch (ex) {
      console.log(ex)
    }
    lockSubmitButton(false)
    handleClose()
  }

  function handleClose() {
    navigate('/borrowing/borrowingOrder');
  }

  function getToolbox() {
    return <Button
      disabled={borrowingOrder.source === '' || borrowingOrder.supplier === ''}
      sx={{ m: 1, whiteSpace: 'nowrap' }}
      variant="contained"
      color="primary"
      startIcon={<AddShoppingCartIcon />}
      onClick={() => {
        setLockSource(true)
        setOpenDialog(true)
      }}
    >
      <FormattedMessage id="editRequisition.addMerchandise" />
    </Button>
  }

  function getToolboxForSelection() {
    return <Button
      variant="contained"
      color="primary"
      startIcon={<DeleteIcon />}
      style={{ whiteSpace: 'nowrap', marginRight: '8px' }}
      onClick={onDeleteItems}
    >
      <FormattedMessage id="editRequisition.removeMerchandise" />
    </Button>
  }

  function getSourceSelector() {
    if (currentUser.borrowingOrderSource.length === 1 || borrowingOrderId !== 'new') {
      const value = borrowingOrderId !== 'new' ?
        (customerMapping[borrowingOrder.source] ? customerMapping[borrowingOrder.source].nickname : '') :
        (customerMapping[currentUser.borrowingOrderSource[0]] ? customerMapping[currentUser.borrowingOrderSource[0]].nickname : '')

      return (<TextField
        disabled
        type="text"
        label={formatMessage({ id: 'borrowingOrder.table.detail.source' })}
        variant="outlined"
        value={value}
        fullWidth
        size="small"
      />)
    } else {
      return (<TextField
        select
        required
        disabled={lockSource}
        type="text"
        label={formatMessage({ id: 'borrowingOrder.table.detail.source' })}
        variant="outlined"
        value={borrowingOrder.source}
        onChange={e => updateBorrowingOrderData({ name: 'source' }, e.target.value)}
        fullWidth
        size="small"
        error={borrowingOrder.source_err ? true : false}
        helperText={borrowingOrder.source_err}
      >
        {filteredCustomers.map(c => <MenuItem key={c.id} value={c.id}>
          {c.nickname}
        </MenuItem>)}
      </TextField>)
    }
  }

  const showVoidButton = (borrowingOrder.id !== 'new' && borrowingOrder.merchandises.length === 0)

  return (
    <div style={{ flexGrow: 1 }}>
      {openDialog && <SelectMerchandiseDialog
        customer={borrowingOrder.source}
        defaultSelectedItems={[]}
        handleClose={() => setOpenDialog(false)}
        handleSave={onMerchandiseChanged}
        ignoreIds={borrowingOrder.merchandises.map(m => m.id)}
        allowIds={getAllowMerchandiseIds()}
        hideFields={['orderUnit']}
        loadStockMapping={true}
      />}
      {openSupplierDialog && <SelectSupplierDialog
        defaultSelectedItem={borrowingOrder.supplier}
        handleClose={() => setOpenSupplierDialog(false)}
        handleSave={value => updateBorrowingOrderData({ name: 'supplier' }, value)}
        ignoreIds={borrowingOrder.source && customerMapping[borrowingOrder.source].supplierId ? [customerMapping[borrowingOrder.source].supplierId] : []}
      />}
      <Box p={2} sx={{ minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative', pb: '64px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            {getSourceSelector()}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              required
              disabled={lockSource}
              type="text"
              size="small"
              label={formatMessage({ id: 'borrowingOrder.table.detail.supplier' })}
              variant="outlined"
              onClick={() => setOpenSupplierDialog(!lockSource)} //open popup
              value={borrowingOrder.supplier ? supplierMapping[borrowingOrder.supplier].nickname : ''}
              error={borrowingOrder.supplier_err ? true : false}
              helperText={borrowingOrder.supplier_err}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'borrowingOrder.table.detail.createdBy' })}
              variant="outlined"
              value={userMapping[borrowingOrder.createdBy] ? userMapping[borrowingOrder.createdBy].displayName : ''}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              disabled
              type="text"
              label={formatMessage({ id: 'borrowingOrder.table.detail.date' })}
              variant="outlined"
              value={borrowingOrder.date}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePickerField
              required
              fullWidth
              label={formatMessage({ id: 'borrowingOrder.table.detail.expectedDate' })}
              value={borrowingOrder.expectedDate}
              onChange={handleDateChange}
              {...dateError}
              minDateMessage={formatMessage({ id: 'form.date.beforeToday' })}
              invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
              minDate={new Date()}
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: '8px 0px' }} />
        <EnhancedTableToolbar
          title="editRequisition.table.title"
          selectdMessage="editRequisition.table.selected"
          numSelected={Object.keys(selectedItems).length}
          toolbox={getToolbox()}
          toolboxForSelection={getToolboxForSelection()}
        />
        <TableContainer component={Paper}>
          <Table>
            <EnhancedTableHead
              headerCells={headerCells}
              numSelected={Object.keys(selectedItems).length}
              onCheckboxClick={handleSelectAllClick}
              rowCount={borrowingOrder.merchandises.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(borrowingOrder.merchandises, getComparator(order, orderBy)).map(merchandise => (
                <EnhancedTableRow
                  key={merchandise.id}
                  rowCells={rowCells}
                  cellData={merchandise}
                  onCheckboxClick={(e) => handleCheckboxClick(merchandise.id)}
                  selected={selectedItems[merchandise.id] || false}
                  lock={merchandise.lock}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              type="text"
              label={formatMessage({ id: 'borrowingOrder.table.detail.note' })}
              variant="outlined"
              value={borrowingOrder.note}
              onChange={e => updateBorrowingOrderData({ name: 'note' }, e.target.value)}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          {showVoidButton &&
            <LoadingButton
              color="error"
              onClick={handleSave}
              disabled={loading}
              loading={loading}
              loadingPosition="start"
              loadingIndicator={<CircularProgress size={24} />}
              startIcon={<div />}
              variant="contained"
            >
              <FormattedMessage id="button.void" />
            </LoadingButton>
          }
          {!showVoidButton &&
            <LoadingButton
              color="primary"
              onClick={handleSave}
              disabled={disableSubmitButton || loading || borrowingOrder.merchandises.length === 0}
              loading={loading}
              loadingPosition="start"
              loadingIndicator={<CircularProgress size={24} />}
              startIcon={<div />}
              variant="contained"
            >
              <FormattedMessage id="button.submit" />
            </LoadingButton>
          }
        </Stack>
      </Box>
    </div>
  );
}

// EditBorrowingOrderPage.defaultProps = {

// }

export default EditBorrowingOrderPage;
