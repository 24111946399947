const i18n = {
  'product': {
    'merchandises': '商品管理',
    'products': '療程管理',
    'commonPrices': '常用價格管理',
    'convertibleProducts': '轉換療程管理',
    'product': {
      'name': '品名',
      'price': '價格',
      'ratio': '營收占比(%)',
      'lowestPrice': '最低價格',
      'cost': '成本',
      'customers': '客戶',
      'amount': '數量',
      'independent': '消耗方式'
    },
    'convertibleProduct': {
      'title': '轉換療程列表',
      'points': '消耗點數',
      'code': '品號',
      'name': '品名'
    },
    'commonPrice': {
      'title': '常用價格列表',
      'name': '價格名稱',
      'fixedAmount': '固定數量',
      'useAmount': '使用次數',
      'price': '價格',
      'onShelfDate': '上架日期',
      'offShelfDate': '下架日期',
    },
    'merchandise': {
      'name': '品名',
      'detail': {
        'nickname': '簡稱',
        'type': '商品類型',
        'usage': '用量與方法',
        'dose': '藥膏數量（罐/條）',
        'total': '總數量（顆/次）'
      },
      'type': {
        'root': '商品類型',
        'medicines': '藥品',
        'merchandises': '固定商品',
        'adjustables': '變動商品'
      },
      'usage': {
        'root': '用量與方法',
        'QID': '一天四次，三餐飯後/前與睡前(QID)',
        'QD': '一天一次(QD)',
        'BID': '一天兩次(BID)',
        'TID': '一天三次，三餐飯後(TID)',
        'HS': '每晚睡前半小時一次(HS)',
        'QN': '每晚一次(QN)',
        'PRN': '需要時使用(PRN)',
        'Stay': '不用列入處方籤(Stay)'
      },
      'dose': '藥膏數量（罐/條）',
      'total': '總數量（顆/次）'
    },
    'table': {
      'title': '療程列表',
      'selected': '已選擇 {count} 項商品',
      'header': {
        'code': '品號',
        'name': '名稱',
        'cost': '成本',
        'nickname': '簡稱',
        'onShelfDate': '上架日期',
        'offShelfDate': '下架日期',
      },
      'detail': {
        'type': '分類1',
        'tags': '標籤',
        'code': '品號',
        'name': '名稱',
        'cost': '成本',
        'customers': '客戶',
        'note': '備註',
        'sku': '單位(SKU)',
        'staffprice': '員工價',
        'staffRelativesPrice': '員工眷屬價',
        'vipprice': 'VIP價',
        'consumable': '消耗類型',
        'directSales': '直接銷售',
        'treatmentConsume': '療程消耗',
        'price': '售價',
        'lowestPrice': '最低售價',
        'nickname': '簡稱',
        'duration': '療程時間',
        'onShelfDate': '上架日期',
        'offShelfDate': '下架日期',
        'bonusType': '獎金分類',
        'doctorBonusType': '醫師獎金分類',
        'salesBonusType': '客代獎金分類',
        'operationBonus': '操作獎金',
        'description': '描述',
        'commonPrice': '常用價格'
      },
    },
    'edit': {
      'type': '分類1',
      'tags': '標籤',
      'code': '品號',
      'name': '名稱',
      'price': '售價',
      'lowestPrice': '最低售價',
      'cost': '成本',
      'staffprice': '員工價',
      'staffRelativesPrice': '員工眷屬價',
      'vipprice': 'VIP價',
      'customers': '客戶',
      'note': '備註',
      'nickname': '簡稱',
      'duration': '療程時間',
      'onShelfDate': '上架日期',
      'offShelfDate': '下架日期',
      'bonusType': '獎金分類',
      'doctorBonusType': '醫師獎金分類',
      'salesBonusType': '客代獎金分類',
      'operationBonus': '操作獎金',
      'addMerchandise': '新增商品',
      'removeMerchandise': '移除商品',
      'removeProduct': '移除療程',
      'addProduct': '新增療程',
      'description': '描述',
      'addCommonPrice': '新增常用價格',
      'removeCommonPrice': '移除常用價格',
    },
    'bonusType': {
      'none': '無',
      'purchaseOrder': '銷售抽成',
      'treatment': '操作抽成'
    },
    'doctorBonusType': {
      'none': '無',
      'laser': '雷射',
      'microShaping': '微整',
      'lift': '拉提',
      'bodyFiguring': '體雕',
      'instalift': '埋線',
      'noImplantOp': '無植入物手術',
      'other': '其他',
    },
    'salesBonusType': {
      'none': '無',
      'a': 'A類',
      'b': 'B類',
      'c': 'C類',
    },
    'dialog': {
      'title': {
        'add': '新增療程',
        'edit': '編輯療程',
      },
      'packageTitle': {
        'add': '新增套裝療程',
        'edit': '編輯套裝療程',
      },
      'combinationTitle': {
        'add': '新增組合療程',
        'edit': '編輯組合療程',
      },
      'folderTitle': {
        'add': '新增集合療程',
        'edit': '編輯集合療程',
      }
    },
    'category': {
      'all': '全部',
    },
    'type': {
      'beauty': '醫學美容',
      'AM': '醫美',
      'OP': '手術',
      'SK': '保品',
      'DP': '儲值',
      'OT': '其他',
      'preventative': '預防醫學',
      'CN': '諮詢',
      'EX': '檢查',
      'TT': '檢驗',
      'GE': '基因檢測',
      'SU': '營養品',
      'ND': '針劑',
      'CU': '療程',
      'other': '其他',
    }
  }
}
export default i18n
