import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import EnhancedTable from '../../components/EnhancedTable';
import SearchBox from '../../components/SearchBox';
import { useFirestoreData } from '../../modules/uitls';

function SelectUserDialog({ index, defaultSelectedItems, handleClose, handleSave }) {
  const { formatMessage } = useIntl()
  const users = useFirestoreData('users')
  const [selectedItems, setSelectedItems] = useState(defaultSelectedItems.reduce((acc, cur) => {acc[cur.id] = true; return acc;}, {}));

  const [currentFilter, setCurrentFilter] = useState(null);
  const [userFilter, setUserFilter] = useState({});

  const filteredUsers = filterUsers()
  const filteredselection = filteredUsers.filter(s => selectedItems[s.id]).reduce((acc, cur) => {acc[cur.id] = true;return acc;}, {})

  const headerCells = [
    { text: 'displayName', sort: 'displayName' },
    { text: 'email' },
    { text: 'activeStatus' }
  ].map(c => {c.text = formatMessage({ id: `user.table.detail.${c.text}` });return c})

  const rowCells = [
    { field: 'displayName' },
    { field: 'email' },
    { field: 'activeStatus' }
  ]

  const filterItems = [
    { name: 'displayName' },
    { name: 'email' }
  ].map(i => {i.text = formatMessage({ id: `user.table.detail.${i.name}` });return i})

  const userFilterItems = [
    { name: 'activeStatus' },
  ].map(i => {i.text = formatMessage({ id: `user.table.detail.${i.name}` });return i})

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredUsers.reduce((acc, cur) => {acc[cur.id] = true;return acc}, {});
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems({});
  };

  const handleCheckboxClick = ({ id }) => {
    const selected = selectedItems[id] || false
    if (selected) {
      const newSelecteds = { ...selectedItems }
      delete newSelecteds[id]
      setSelectedItems(newSelecteds);
    } else {
      const newSelecteds = { ...selectedItems, [id]: true }
      setSelectedItems(newSelecteds);
    }
  }

  function filterUsers() {
    if (!currentFilter &&
        !userFilter.activeStatus) {
      return users
    }
    let items = [...users]
    if (userFilter.activeStatus) {
      items = items.filter(i => (i.active ? 'enabled' : 'disabled') === userFilter.activeStatus)
    }
    if (currentFilter) {
      items = items.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))
    }
    return items
  }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  function getMenuItem(name) {
    if (name === 'activeStatus') {
      return [{ value: '' }, { value: 'enabled' }, { value: 'disabled' }].map(i => {
        i.label = i.value ? formatMessage({ id: `user.status.${i.value}` }) : formatMessage({ id: 'user.status.all' })
        return i
      })
    }
    return []
  }

  function updateUserFilter(name, value) {
    const newFilter = { ...userFilter }
    newFilter[name] = value
    setUserFilter(newFilter)
  }

  const getFilter = (filter) => {
    return <Grid item key={filter.name} xs={6} sm={6} md={6}>
      <TextField
        select
        type="text"
        size="small"
        label={filter.text}
        variant="outlined"
        onChange={e => {updateUserFilter(filter.name, e.target.value)}}
        value={userFilter[filter.name] || ''}
        fullWidth
      >
        {getMenuItem(filter.name).map((option, idx) =>
          <MenuItem key={`${option.value}-${idx}`} value={option.value}>{option.label}</MenuItem>
        )}
      </TextField>
    </Grid>
  }

  function formatData(user) {
    const newData = { ...user }
    newData.activeStatus = newData.active ? formatMessage({ id: 'user.status.enabled' }) : formatMessage({ id: 'user.status.disabled' })
    return newData
  }

  const onApply = () => {
    const selectedUsers = users.filter(u => selectedItems[u.id]).map(u => ({ name: u.displayName, id: u.id }))
    handleSave(index, selectedUsers)
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={true}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title"><FormattedMessage id="selectUserDialog.title" /></DialogTitle>
      <DialogContent dividers={true}>
        <div style={{ flexGrow: 1 }}>
          <Toolbar style={{ padding: '0px' }}>
            <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
              <FormattedMessage id="user.table.title" />
            </Typography>
            <Grid container spacing={3}>
              {userFilterItems.map(f => getFilter(f))}
            </Grid>
            <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />
          </Toolbar>
          <EnhancedTable
            defaultOrder="asc"
            defaultOrderField="displayName"
            headerCells={headerCells}
            rowCells={rowCells}
            tableData={filteredUsers.map(i => formatData(i))}
            onHeaderCheckboxClick={handleSelectAllClick}
            onRowCheckboxClick={handleCheckboxClick}
            getSelectionCount={() => Object.keys(filteredselection).length}
            getRowCheckBoxStatus={user => selectedItems[user.id] || false}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button variant="contained" onClick={onApply} color="primary">
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SelectUserDialog.propTypes = {
  index: PropTypes.number.isRequired,
  defaultSelectedItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })),
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default SelectUserDialog;
