import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/app';
import dayjs from 'dayjs';

import Divider from '@mui/material/Divider';

import DateRangePickerField from '../../components/DateRangePickerField';
import StockRequisitionList from './StockRequisitionList';
import ContextStore from '../../modules/context';

function StockRequisitionTab() {
  const { tabName } = useParams()
  const { currentUser } = useContext(ContextStore)

  const [stockRequisitions, setStockRequisitions] = useState([])
  const [startDate, setStartDate] = useState(dayjs().subtract(2, 'months').format('YYYY-MM') + '-01')
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  useEffect(() => {
    if (stockRequisitions.length) setStockRequisitions([])
    const onSnapshot = snapshot => {
      const stockRequisitions = []
      snapshot.forEach(doc => {
        stockRequisitions.push({ ...doc.data(), id: doc.id })
      });
      setStockRequisitions(stockRequisitions)
    }
    const unsubscribe = tabName === 'pending' ?
      firebase.firestore().collection('stockRequisitions').where('status', '==', tabName).onSnapshot(onSnapshot, err => {}) :
      firebase.firestore().collection('stockRequisitions').where('status', '==', tabName).where('date', '>=', startDate).where('date', '<=', endDate).onSnapshot(onSnapshot, err => {})
    return () => unsubscribe()
  }, [startDate, endDate]);

  const stockRequisitionsForTab = stockRequisitions.filter(r =>
    currentUser.userRights.debugging ||
    currentUser.stockRequisitionSource.includes(r.source) ||
    currentUser.stockRequisitionReview.includes(r.source) ||
    currentUser.stockRequisitionOverwrite.includes(r.source)
  );

  const reqWaitingForProcess = waitingForProcess()

  function waitingForProcess() {
    if (tabName === 'pending') {
      return stockRequisitionsForTab.filter(r => {
        const history = r.history || []
        const currentStep = history.length > 0 ? history[history.length - 1].step : 0
        if (isNaN(currentStep) || currentStep === r.steps.length) return false
        return r.steps[currentStep].users.includes(currentUser.key)
      })
    } else {
      return []
    }
  }

  return (
    <div style={{ paddingBottom: '60px' }}>
      {tabName !== 'pending' && <DateRangePickerField
        startDate={startDate}
        endDate={endDate}
        onStartDateChanged={setStartDate}
        onEndDateChanged={setEndDate}
      />}
      {reqWaitingForProcess.length > 0 && <>
        <StockRequisitionList stockRequisitions={reqWaitingForProcess} title={`stockRequisition.table.${tabName}.title`} />
        <Divider sx={{ mx: '0px', my: '10px' }}/>
      </>}
      <StockRequisitionList stockRequisitions={stockRequisitionsForTab} title="stockRequisition.table.title" />
    </div>
  );
}

export default StockRequisitionTab;
