const i18n = {
  'deliveryOrder': {
    'name': '銷貨單',
    'table': {
      'title': '銷貨單列表',
      'selected': '已選擇 {count} 筆銷貨單',
      'header': {
        'sn': '銷貨單號',
        'source': '銷貨單位',
        'sourceName': '銷貨單位',
        'destination': '進貨單位',
        'destinationName': '進貨單位',
        'createdBy': '出貨人',
        'createdAt': '出貨日期',
        'date': '出貨日期',
      },
      'detail': {
        'sn': '銷貨單號',
        'source': '銷貨單位',
        'sourceName': '銷貨單位',
        'destination': '進貨單位',
        'destinationName': '進貨單位',
        'createdBy': '出貨人',
        'createdAt': '出貨日期',
        'note': '備註',
        'merchandise': '商品',
        'merchandiseName': '商品名稱',
        'merchandiseCode': '商品品號',
      },
    },
    'dialog': {
      'title': {
        'add': '新增銷貨單',
        'edit': '編輯銷貨單',
      }
    },
    'merchandise': {
      'purchaseOrderSn': '採購單號',
      'code': '品號',
      'name': '品名',
      'require': '需求量',
      'unitPrice': '單價',
      'amount': '數量',
      'orderUnit': '單位',
      'sku': '單位',
      'price': '金額',
      'lotNumber': '批號',
      'expectedDate': '效期',
      'statusText': '狀態',
      'discount': '商品折扣',
      'note': '備註',
      'stock': '即時庫存',
      'delivery': ' 出貨量',
      'wareroom': '倉庫'
    }
  },
  'editDeliveryOrder': {
    'table': {
      'title': '商品清單',
      'selected': '已選擇 {count} 個項目',
    },
    'addFromPurchaseOrder': '選擇採購單',
    'addFromBorrowingOrder': '選擇借貨單',
    'removeMerchandise': '移除商品',
    'selectStockItems': '選擇庫存',
    'setDeliveryAmount': '設定出貨量',
  }
}
export default i18n
