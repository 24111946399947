import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import ActionDialog from '../../components/ActionDialog';
import ProgressStep from '../../components/ProgressStep';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import { useFirestoreDataAndMapping } from '../../modules/uitls'
import { ProductCategories as productCategories } from '../../constants/index';
import EditProfileDialog from './EditProfileDialog'
import ContextStore from '../../modules/context';

const supplierFields = [
  // {name: 'code', sm: 6},
  { name: 'name', sm: 6 },
  { name: 'nickname', sm: 6 },
  { name: 'businessNumber', sm: 6 },
  { name: 'phone', sm: 6 },
  { name: 'fax', sm: 6 },
  { name: 'billRule', type: 'select', sm: 6 },
  { name: 'basicPurchaseAmount', type: 'text', sm: 6, md: 2 },
  { name: 'freeShippingAmount', type: 'text', sm: 6, md: 2 },
  { name: 'email', type: 'text', sm: 12, md: 6 },
  { name: 'address', type: 'text', sm: 12, md: 6 },
  { name: 'contact', type: '-', reference: 'contacts' },
  { name: 'contacts', type: 'array', sm: 6 },
  { name: 'owner', type: '-' },
  { name: 'ownerName', type: 'text', sm: 6 },
  { name: 'ownerPhone', type: 'text', sm: 6 },
  { name: 'ownerMobile', type: 'text', sm: 6 },
  // {name: 'shipping', type: '-'},
  // {name: 'shippingOut', type: 'text', sm: 12, md: 12},
  { name: 'other', type: '-' },
  { name: 'note', type: 'multiline', multiline: true, sm: 6, md: 6 },
].map(field => {field.multiline = field.multiline || false; field.md = field.md || 3; return field})

const merchandiseFields = [
  { name: 'name', type: 'text', md: 6, sm: 6 },
  { name: 'nickname', type: 'text', md: 6, sm: 6 },
  { name: 'type', type: 'select', sm: 6 },
  { name: 'category', type: 'select', sm: 6 },
  { name: 'warehousing', type: 'multiSelect', sm: 6 },
  { name: 'orderUnit', sm: 6 },
  { name: 'sku', sm: 6 },
  { name: 'ou2sku', sm: 6 },
  { name: 'note', type: 'multiline', multiline: true, sm: 12, md: 12 },
].map(field => {field.multiline = field.multiline || false; field.md = field.md || 3; return field})

const productFields = [
  { name: 'name', type: 'text', md: 3, sm: 6 },
  { name: 'type', type: 'select', md: 3, sm: 6 },
  { name: 'tags', type: 'text', md: 3, sm: 6 },
  { name: 'consumable', md: 3, sm: 6 },
  { name: 'cost', sm: 6 },
  { name: 'staffprice', sm: 6 },
  { name: 'staffRelativesPrice', sm: 6 },
  { name: 'vipprice', sm: 6 },
  { name: 'note', type: 'multiline', multiline: true, sm: 12, md: 12 },
].map(field => {field.multiline = field.multiline || false; field.md = field.md || 3; return field})

function NewProfileView({ content, userMapping, productMapping }) {
  const { formatMessage } = useIntl()
  const { currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const [dialogData, setDialogData] = useState(null);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [customerMapping] = useFirestoreDataAndMapping('customers')

  let fields = []
  let tabName = ''
  let fieldName = ''
  if (content.profile === 'supplier') {
    for (const field of supplierFields) {
      if (field.name === 'contacts') {
        for (const i in content.contacts) {
          fields.push({ name: 'contacts', subField: 'contactName', type: 'text', index: parseInt(i), sm: 6, md: 3 })
          fields.push({ name: 'contacts', subField: 'contactPhone', type: 'phone', index: parseInt(i), sm: 6, md: 3, mask: '(999)9999-9999#99999' })
          fields.push({ name: 'contacts', subField: 'contactMobile', type: 'phone', index: parseInt(i), sm: 6, md: 3, mask: '9999-999-999' })
          fields.push({ name: 'contacts', subField: 'contactNote', type: 'text', index: parseInt(i), sm: 6, md: 3 })
        }
      } else {
        fields.push({ ...field, required: field.required || false, md: field.md || 3 })
      }
    }

    tabName = 'newSupplier'
    fieldName = 'supplierId'
  } else if (content.profile === 'merchandise') {
    fields = merchandiseFields
    tabName = 'newMerchandise'
    fieldName = 'merchandiseId'
  } else if (content.profile === 'product') {
    fields = productFields
    tabName = 'newProduct'
    fieldName = 'productId'
  } else if (content.profile === 'folder') {
    fields = productFields
    tabName = 'newFolderProduct'
    fieldName = 'productId'
  } else if (content.profile === 'package') {
    fields = productFields
    tabName = 'newPackageProduct'
    fieldName = 'productId'
  } else if (content.profile === 'combination') {
    fields = productFields
    tabName = 'newCombinationProduct'
    fieldName = 'productId'
  }

  const selectMapping = {
    type: {
      G: formatMessage({ id: 'merchandise.type.g' }),
      I: formatMessage({ id: 'merchandise.type.i' }),
      B: formatMessage({ id: 'merchandise.type.b' }),
    },
    category: {
      DPO: '藥品-口服藥',
      DOI: '藥品-藥膏',
      DIV: '藥品-針劑',
      DSC: '藥品-保養品/保健品',
      CIN: '耗材-填充注射',
      CPR: '耗材-電音波類(探頭)',
      CBD: '耗材-體雕(點數卡)',
      CIP: '植入物',
      CCO: '耗材-消耗醫材',
      CWI: '耗材-線材',
      COT: '其他',
    }
  }

  const selectMapping2 = {
    type: productCategories.reduce((acc, cur) => {acc[cur] = formatMessage({ id: `product.type.${cur}` }); return acc}, {}),
  }
  const contentHistory = (content.history || []).map(h => ({ ...h }))
  const currentStep = contentHistory.length > 0 ? contentHistory[contentHistory.length - 1].step : 0

  function edit() {
    navigate(`/profile/${tabName}/edit/${content.id}`);
  }

  function reject() {
    setDialogData({ action: 'reject', title: '' })
  }

  function approve() {
    setDialogData({ action: 'approve', title: '' })
  }

  function confirm() {
    setDialogData({ action: 'confirm', title: '' })
  }

  function handleClose() {
    setDialogData(null)
  }

  async function handleExecute(data) {
    const { action, text } = data
    setDialogData(null)
    if (['approve', 'reject', 'confirm'].includes(action)) {
      if (['approve', 'confirm'].includes(action)) {
        setLoadingApprove(true)
      } else {
        setLoadingReject(true)
      }
      try {
        await (firebase.functions().httpsCallable('reviewNewProfile'))({
          id: content.id,
          action: action === 'reject' ? 'reject' : 'approve',
          note: text
        })
        if (['approve', 'confirm'].includes(action)) {
          if (currentStep < content.steps.length - 1) {
            setLoadingApprove(false)
          }
        } else {
          setLoadingReject(false)
        }
      } catch (ex) {
        setLoadingApprove(false)
        setLoadingReject(false)
        console.log(ex)
      }
    }
  }

  const ls = !content.void ? content.steps.slice(currentStep, content.steps.length).map(s => ({ ...s })) : (content.status === 'void' ? [] : content.steps.slice(currentStep, 1).map(s => ({ ...s })))
  if (content.status !== 'void' && content.lock && currentStep === 0) {
    ls[0].name = '確認否決內容'
  }
  const steps = [...contentHistory].concat(ls)
  for (const step of steps) {
    if (step.action === 'modify') {
      step.detail = '' //decodeModifyLog(step.note)
    }
    if (step.dateTime) {
      const s = step.dateTime.split(' ')
      step.text = step.name + `\n${userMapping[step.user]?.displayName}[${formatMessage({ id: 'step.action.' + step.action })}]`
      step.text += `\n日期: ${s[0]}`
      step.text += `\n時間: ${s[1]}`
      if (step.action === 'modify') {
        step.text += `${step.detail ? '\n' : ''}`
      } else {
        step.text += `${step.note ? '\n備註: ' + step.note : ''}`
      }
    } else {
      step.text = step.name
      if (step.users) {
        step.hint = step.users.map(u => userMapping[u]?.displayName || '').join(' / ')
      }
    }
    if (['reject', 'void'].includes(step.action)) {
      // step.stepProps = {completed: false}
      step.labelProps = { error: true }
    }
  }
  const lastHistory = content.history[content.history.length - 1]

  const createNewProfile = () => {
    const newData = { ...content }
    newData.id = 'new'
    if (content.profile === 'supplier') {
      newData.shippingOut = []
    }
    setSelectedProfile(newData)
  }

  function allowEditing() {
    if ((currentStep === 1 && lastHistory.action !== 'reject') && !content.lock && content.steps[0].users.includes(currentUser.key)) {
      return true
    }
    return false
  }

  const billRuleMapping = {
    '30': formatMessage({ id: 'supplier.billRule.rule1' }),
    '60': formatMessage({ id: 'supplier.billRule.rule2' }),
    '90': formatMessage({ id: 'supplier.billRule.rule3' }),
    'cashOnDelivery': formatMessage({ id: 'supplier.billRule.rule4' }),
    'ttInAdvance': formatMessage({ id: 'supplier.billRule.rule5' }),
  }

  function createField(field, content) {
    let profileName = content.profile
    if (['package', 'combination', 'folder'].includes( content.profile)) {
      profileName = 'product'
    }
    const name = field.name
    const value = field.subField ? (content[field.name][field.index][field.subField]) : content[field.name]
    let newValue = value
    if (name === 'billRule') {
      newValue = billRuleMapping[value]
    } else if (name === 'category') {
      newValue = selectMapping[name][value]
    } else if (name === 'type') {
      if (content.profile === 'merchandise') {
        newValue = selectMapping[name][value]
      } else {
        newValue = selectMapping2[name][value]
      }
    } else if (field.type === 'select') {
      newValue = selectMapping[name][value]
    } else if (field.type === 'multiSelect') {
      newValue = Object.keys(content[field.name]).map(k => customerMapping[k].nickname).join(', ')
    }

    if (field.type === '-') {
      return field.reference && content[field.reference].length === 0 ? null : <Grid item key={name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
    }

    if (['phone', 'fax', 'contactPhone', 'ownerPhone'].includes(name)) {
      newValue = newValue.replace(/\s/g, '').replace(/#$/,'')
    }

    return <Grid item key={`${name}.${field.index || 0}.${field.subField || 0}`} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={formatMessage({ id: `${profileName}.table.detail.${field.subField || field.name}` })}
        value={field.name === 'consumable' ? value ? '療程消耗' : '直接銷售' : newValue}
        fullWidth
        size="small"
        variant="standard"
        readOnly
      />
    </Grid>
  }

  const formatContent = (product) => {
    const newData = { ...product }
    const newPackage = []

    if (newData.package) {
      for (const p of Object.keys(newData.package)) {
        newPackage.push({
          id: p,
          ...newData.package[p],
          name: productMapping[p].name,
          customers: productMapping[p].customers,
          cost: productMapping[p].cost
        })
      }

      newData.package = newPackage
    }

    return newData
  }

  const formatData = (product) => {
    const newData = { ...product }
    const customers = Object.keys(product.customers || []).filter(c => customerMapping[c])
    newData.customers = customers.map(s => customerMapping[s]).map(s => s.nickname).join(', ')
    newData.tags = (product.tags || []).join(', ')

    return newData
  }

  const _headerCells = [
    { text: 'name', order: 0 },
    { text: 'amount', order: 1 },
    { text: 'customers', order: 4 },
    { text: 'cost', order: 5 },
  ]

  const _rowCells = [
    { field: 'name', order: 0 },
    { field: 'amount', order: 1 },
    { field: 'customers', order: 4 },
    { field: 'cost', order: 5 },
  ]

  if (content.profile !== 'folder') {
    _headerCells.push({ text: 'ratio', order: 2 })
    _rowCells.push({ field: 'ratio', order: 2 })
  } else {
    _headerCells.push({ text: 'price', order: 2 }, { text: 'lowestPrice', order: 3 })
    _rowCells.push({ field: 'price', order: 2 }, { field: 'lowestPrice', order: 3 })
  }

  const headerCells = _headerCells.map(c => { c.text = formatMessage({ id: `product.product.${c.text}` }); return c }).sort((a, b) => a.order - b.order)
  const rowCells = _rowCells.sort((a, b) => a.order - b.order)

  return (
    <div style={{ flexGrow: 1, height: '100%' }}>
      {selectedProfile && <EditProfileDialog newProfileId={content.id} content={selectedProfile} handleClose={() => setSelectedProfile(null)} productMapping={productMapping} />}
      {dialogData && <ActionDialog
        title={formatMessage({ id: `button.${dialogData.action}` }) + (content.void ? formatMessage({ id: 'button.void' }) : '') + formatMessage({ id: `newProfile.review.title.${content.profile}` })}
        handleClose={handleClose}
        handleExecute={handleExecute}
        textFieldLabel={formatMessage({ id: 'newProfile.review.note' })}
        action={dialogData.action}
      />}

      <Box p={0}>
        <ProgressStep activeStep={content.history.length} steps={steps} />
        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={2}>
          {fields.map(field => createField(field, content))}
          {(['package', 'combination', 'folder'].includes( content.profile)) && <>
            <div style={{ paddingLeft: '8px', marginTop: '8px' }}>
              <Typography variant="button" component="div">
                <FormattedMessage id="product.products" />:
              </Typography>
            </div>
            <TableContainer style={{ marginBottom: '8px' }} component={Paper}>
              <Table size="small" aria-label="collapsible table">
                <EnhancedTableHead
                  headerCells={headerCells}
                  rowCount={formatContent(content).package.length}
                />
                <TableBody>
                  {formatContent(content).package.map(m => formatData(m)).map(packageProduct => (
                    <EnhancedTableRow
                      key={packageProduct.id}
                      rowCells={rowCells}
                      cellData={packageProduct}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>}
          <Grid item key="buttons" xs={12} sm={12} md={12}>
            <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
              {content.status === 'done' && content.steps[0].overwrite.includes(currentUser.key) && !content[fieldName] &&
                <Button
                  variant="contained"
                  color="primary"
                  onClick={createNewProfile}
                  // disabled={loadingApprove || loadingReject}
                >
                  <FormattedMessage id={`newProfile.button.${tabName}`} />
                </Button>
              }
              {allowEditing() && !content.void &&
                <Button
                  variant="contained"
                  color="primary"
                  onClick={edit}
                  disabled={loadingApprove || loadingReject}
                >
                  <FormattedMessage id="button.edit" />
                </Button>
              }
              {currentStep > 0 && currentStep < content.steps.length && content.steps[currentStep].users.includes(currentUser.key) && !content.void && !content.lock &&
                <LoadingButton
                  color="error"
                  onClick={reject}
                  disabled={loadingApprove || loadingReject}
                  loading={loadingReject}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.reject" />
                </LoadingButton>
              }
              {currentStep > 0 && currentStep < content.steps.length && content.steps[currentStep].users.includes(currentUser.key) && content.status !== 'void' && !content.lock &&
                <LoadingButton
                  color="success"
                  onClick={approve}
                  disabled={loadingApprove || loadingReject}
                  loading={loadingApprove}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.approve" />
                </LoadingButton>
              }
              {currentStep < content.steps.length && content.steps[currentStep].users.includes(currentUser.key) && content.lock &&
                <LoadingButton
                  color="success"
                  onClick={confirm}
                  disabled={loadingApprove || loadingReject}
                  loading={loadingApprove}
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress size={24} />}
                  startIcon={<div />}
                  variant="contained"
                >
                  <FormattedMessage id="button.confirm" />
                </LoadingButton>
              }
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

// NewProfileView.defaultProps = {

// }

NewProfileView.propTypes = {
  content: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  productMapping: PropTypes.object
};

export default NewProfileView;
