const i18n = {
  'returnBorrowingReceipt': {
    'name': '借貨歸還入庫單',
    'table': {
      'pending': {
        'title': '借貨歸還入庫單列表 - 待確認',
      },
      'title': '借貨歸還入庫單列表',
      'selected': '已選擇 {count} 筆借貨歸還入庫單',
      'header': {
        'sn': '借貨歸還入庫單號',
        'supplier': '原借貨單位',
        'supplierName': '原借貨單位',
        'source': '進貨單位',
        'sourceName': '進貨單位',
        'createdBy': '進貨人',
        'createdAt': '建立日期',
        'date': '進貨日期',
      },
      'detail': {
        'sn': '借貨歸還入庫單號',
        'source': '進貨單位',
        'sourceName': '進貨單位',
        'createdBy': '進貨人',
        'createdAt': '建立日期',
        'date': '進貨日期',
        'note': '備註',
        'merchandise': '商品',
        'invoiceNumber': '發票號碼',
        'discount': '現金折扣',
        'shippingFee': '運費',
        'shippingOut': '出貨廠商',
        'supplier': '供應商',
        'supplierName': '供應商',
        'merchandiseName': '商品名稱',
        'merchandiseCode': '商品品號',
      },
    },
    'dialog': {
      'title': {
        'add': '新增借貨歸還入庫單',
        'edit': '編輯借貨歸還入庫單',
      }
    },
    'merchandise': {
      'extra': '贈品',
      'borrowingOrderSn': '借貨單號',
      'code': '品號',
      'name': '品名',
      'require': '需求量',
      'unitPrice': '單價',
      'amount': '數量',
      'orderUnit': '單位',
      'sku': '單位',
      'price': '金額',
      'lotNumber': '批號',
      'expectedDate': '效期',
      'statusText': '狀態',
      'discount': '商品折扣',
      'note': '備註',
    }
  }
}
export default i18n
