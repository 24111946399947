import React, { useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Switch from '@mui/material/Switch';

import InputMask from 'react-input-mask';
import SelectMultiSupplierDialog from './SelectMultiSupplierDialog';

const BNV = [1,2,1,2,1,2,4,1]

const SwitchContainer = styled('div')(() => ({
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
}));

const DividerText = styled('div')(() => ({
  position: 'absolute',
  top: '6px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

const EditNewSupplierView = forwardRef((props, ref) => {
  const { ignoreFields = [], supplier } = props
  const { formatMessage } = useIntl()

  const defaultState = {
    code: supplier.code,
    name: supplier.name,
    nickname: supplier.nickname,
    businessNumber: supplier.businessNumber, // 04595257, 09867123
    phone: supplier.phone,
    fax: supplier.fax,
    email: supplier.email,
    address: supplier.address,
    shippingOut: supplier.shippingOut,
    basicPurchaseAmount: supplier.basicPurchaseAmount,
    freeShippingAmount: supplier.freeShippingAmount,
    ownerName: supplier.ownerName,
    ownerPhone: supplier.ownerPhone,
    ownerMobile: supplier.ownerMobile,
    billRule: supplier.billRule,
    note: supplier.note,
    contacts: supplier.contacts || [],
    supplier: supplier.supplier ?? true,
    internal: supplier.internal ?? false,
    active: supplier.active ?? true,
  }
  const [openSupplierDialog, setOpenSupplierDialog] = useState(false);
  const [supplierData, setSupplierData] = useState(defaultState);

  useImperativeHandle(
    ref, () => ({
      getContent: () => {
        return _getContent()
      }
    }),
  )

  function addContact() {
    const contacts = []
    for (const contact of supplierData.contacts) {
      contacts.push({ ...contact })
    }
    contacts.push({ contactName: '', contactPhone: '', contactMobile: '', contactNote: '' })
    updateSupplierData({ name: 'contacts' }, contacts)
  }

  // const phoneRule = /[^0-9#]/g
  const numberRule = /[^0-9]/g

  const _fields = [
    // {name: 'code', type: 'text', sm: 6, disabled: true},
    { name: 'supplier', type: 'checkbox', sm: 6 },
    { name: 'internal', type: 'switch', sm: 6, textOff: '外部廠商', textOn: '內部廠商' },
    { name: 'active', type: 'switch', sm: 6, textOff: '停用', textOn: '啟用' },
    { name: 'systemFlags', type: '-', hide: ignoreFields.includes('supplier') && ignoreFields.includes('internal') && ignoreFields.includes('active') },
    { name: 'name', type: 'text', sm: 6, required: true },
    { name: 'nickname', type: 'text', sm: 6, required: true },
    { name: 'businessNumber', type: 'text', sm: 6, allowCharacter: numberRule, maxLength: 8 },
    // {name: 'type', type: 'select', sm: 6, required: true},
    { name: 'phone', type: 'phone', sm: 6, mask: '(999)9999-9999#99999' },
    { name: 'fax', type: 'phone', sm: 6, mask: '(999)9999-9999#99999' },
    { name: 'basicPurchaseAmount', type: 'text', sm: 6, allowCharacter: numberRule },
    { name: 'freeShippingAmount', type: 'text', sm: 6, allowCharacter: numberRule },
    { name: 'billRule', type: 'select', sm: 6 },
    { name: 'email', type: 'text', sm: 12, md: 6 },
    { name: 'address', type: 'text', sm: 12, md: 6 },
    { name: 'contact', type: '-', text: '聯絡人', onClick: addContact },
    { name: 'contacts', type: 'array' },
    { name: 'owner', type: '-' },
    { name: 'ownerName', type: 'text', sm: 6 },
    { name: 'ownerPhone', type: 'phone', sm: 6, mask: '(999)9999-9999#99999' },
    { name: 'ownerMobile', type: 'phone', sm: 6, mask: '9999-999-999' },
    { name: 'shipping', type: '-' },
    { name: 'shippingOut', type: 'popup', sm: 12 },
    { name: 'other', type: '-' },
    { name: 'note', type: 'multiline', sm: 12 },
  ]

  const fields = []
  for (const field of _fields) {
    if (field.name === 'contacts') {
      for (const i in supplierData.contacts) {
        fields.push({ name: 'contacts', subField: 'contactName', type: 'text', index: parseInt(i), sm: 6, md: 3 })
        fields.push({ name: 'contacts', subField: 'contactPhone', type: 'phone', index: parseInt(i), sm: 6, md: 3, mask: '(999)9999-9999#99999' })
        fields.push({ name: 'contacts', subField: 'contactMobile', type: 'phone', index: parseInt(i), sm: 6, md: 3, mask: '9999-999-999' })
        fields.push({ name: 'contacts', subField: 'contactNote', type: 'text', index: parseInt(i), sm: 6, md: 3 })
      }
    } else {
      fields.push({ ...field, required: field.required || false, md: field.md || 3 })
    }
  }

  function getMenuItem(field) {
    if (field === 'billRule') {
      return [
        {
          value: '30',
          label: formatMessage({ id: 'supplier.billRule.rule1' }),
        },
        {
          value: '60',
          label: formatMessage({ id: 'supplier.billRule.rule2' }),
        },
        {
          value: '90',
          label: formatMessage({ id: 'supplier.billRule.rule3' }),
        },
        {
          value: 'cashOnDelivery',
          label: formatMessage({ id: 'supplier.billRule.rule4' }),
        },
        {
          value: 'ttInAdvance',
          label: formatMessage({ id: 'supplier.billRule.rule5' }),
        },
      ]
    }
    return []
  }

  function onSupplierChanged(suppliers) {
    updateSupplierData({ name: 'shippingOut' }, suppliers);
  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }
    if (field.name === 'businessNumber') {
      if (value.length !== 8) return formatMessage({ id: 'form.businessNumber.lengthError' })
      let arr = [...value].map(v => parseInt(v))
      let arr2 = arr.map((v, i) => {
        let t = v * BNV[i]
        if (t > 9) {
          let a2 = [...String(t)].map(v => parseInt(v))
          t = a2[0] + a2[1]
        }
        return t
      })
      let sum = arr2.reduce((acc, cur) => acc + cur)
      if (sum % 10 === 0) return ''
      if (arr[6] === 7 && (sum + 1) % 10 === 0) return ''
      return formatMessage({ id: 'form.businessNumber.validateError' })
    } else if ((['contactMobile', 'ownerMobile'].includes(field.name)) && value !== '    -   -   ' && value !== '' && value.replace(/[-\s]/g, '').trim().length !== 10) {
      return formatMessage({ id: 'form.mobile.lengthError' })
    } else if (['phone', 'fax', 'contactPhone', 'ownerPhone'].includes(field.name) && value !== '(   )    -    #     ' && value !== '') {
      let p = value.split('#')[0].trim()
      if (p.length < 12) return formatMessage({ id: 'form.mobile.formatError' })
    }
    return ''
  }

  function updateSupplierData(field, value) {
    let newValue = value
    if (field.allowCharacter) {
      newValue = newValue.replace(field.allowCharacter, '')
    }
    if (field.maxLength) {
      newValue = newValue.substring(0, field.maxLength)
    }
    if (field.name === 'contacts' && field.index !== undefined) {
      if (supplierData.contacts[field.index][field.subField] === newValue) return;

      let err = validateField({ name: field.subField }, value)
      const v = [...supplierData.contacts]
      v[field.index] = { ...v[field.index] }
      v[field.index][field.subField] = newValue
      v[field.index][`${field.subField}_err`] = err
      let newData = { ...supplierData, 'contacts': v }
      setSupplierData(newData)
    } else {
      if (supplierData[field.name] === newValue) return;

      let err = validateField(field, value)

      let newData = { ...supplierData, [field.name]: newValue, [`${field.name}_err`]: err }
      setSupplierData(newData)
    }
  }

  function createField(field) {
    if (ignoreFields.includes(field.name)) return null
    if (field.type === 'text') {
      return <Grid item key={`${field.name}.${field.index || 0}.${field.subField || 0}`} xs={12} sm={field.sm} md={field.md}>
        <TextField
          disabled={field.disabled}
          required={field.required}
          type="text"
          size="small"
          label={formatMessage({ id: `supplier.table.detail.${field.subField || field.name}` })}
          variant="outlined"
          onChange={e => {updateSupplierData(field, e.target.value)}}
          value={field.subField ? supplierData[field.name][field.index][field.subField] : supplierData[field.name]}
          error={
            field.subField ?
              (supplierData[field.name][field.index][`${field.subField}_err`] ? true : false) :
              (supplierData[`${field.name}_err`] ? true : false)
          }
          helperText={
            field.subField ?
              supplierData[field.name][field.index][`${field.subField}_err`] :
              supplierData[`${field.name}_err`]
          }
          fullWidth
        />
      </Grid>
    } else if (field.type === 'checkbox') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={3}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={supplierData[field.name]}
              onChange={e => {updateSupplierData(field, e.target.checked)}}
              color="primary"
            />
          }
          label={formatMessage({ id: `supplier.table.detail.${field.name}Type` })}
        />
      </Grid>
    } else if (field.type === 'switch') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={3}>
        <Typography variant="subtitle1">
          <SwitchContainer>
            <div>{field.textOff}</div>
            <Switch
              checked={supplierData[field.name]}
              onChange={e => {updateSupplierData(field, e.target.checked)}}
              color="primary"
              name="editmode"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <div>{field.textOn}</div>
          </SwitchContainer>
        </Typography>
      </Grid>
    } else if (field.type === 'phone') {
      return <Grid item key={`${field.name}.${field.index || 0}.${field.subField || 0}`} xs={12} sm={field.sm} md={3}>
        <InputMask
          mask={field.mask}
          maskChar=" "
          onChange={e => {updateSupplierData(field, e.target.value)}}
          value={field.subField ? supplierData[field.name][field.index][field.subField] : supplierData[field.name]}
        >
          {() => <TextField
            required={field.required}
            type="text"
            size="small"
            label={formatMessage({ id: `supplier.table.detail.${field.subField || field.name}` })}
            variant="outlined"
            onCompositionStart={
              e => {
                e.target.addEventListener('input', e2 => {
                  if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e2.data)) {
                    e2.preventDefault()
                    e2.stopPropagation()
                    e2.stopImmediatePropagation()
                  }
                }, { once: true })
              }
            }
            error={
              field.subField ?
                (supplierData[field.name][field.index][`${field.subField}_err`] ? true : false) :
                (supplierData[`${field.name}_err`] ? true : false)
            }
            helperText={
              field.subField ?
                supplierData[field.name][field.index][`${field.subField}_err`] :
                supplierData[`${field.name}_err`]
            }
            fullWidth
          />}
        </InputMask>
      </Grid>
    } else if (field.type === 'popup') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={12}>
        <TextField
          disabled={field.disabled}
          required={field.required}
          type="text"
          size="small"
          label={formatMessage({ id: `supplier.table.detail.${field.name}` })}
          variant="outlined"
          onClick={() => setOpenSupplierDialog(true)} //open popup
          value={supplierData[field.name].map(s => s.name).join(', ')}
          error={supplierData[`${field.name}_err`] ? true : false}
          helperText={supplierData[`${field.name}_err`]}
          fullWidth
        />
      </Grid>
    } else if (field.type === 'select') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={3}>
        <TextField
          required={field.required}
          select
          type="text"
          size="small"
          label={formatMessage({ id: `supplier.table.detail.${field.name}` })}
          variant="outlined"
          onChange={e => {updateSupplierData(field, e.target.value)}}
          value={supplierData[field.name]}
          error={supplierData[`${field.name}_err`] ? true : false}
          helperText={supplierData[`${field.name}_err`]}
          fullWidth
        >
          {getMenuItem(field.name).map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    } else if (field.type === 'multiline') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={12}>
        <TextField
          multiline
          required={field.required}
          type="text"
          size="small"
          label={formatMessage({ id: `supplier.table.detail.${field.name}` })}
          variant="outlined"
          onChange={e => {updateSupplierData(field, e.target.value)}}
          value={supplierData[field.name]}
          fullWidth
        />
      </Grid>
    } else if (field.type === '-') {
      if (field.hide) return null
      if (field.text) {
        return (
          <Grid item key={field.name} xs={12} sm={field.sm} md={12} style={{ position: 'relative' }}>
            <Divider />
            <DividerText>
              <Typography color="textSecondary" variant="caption">{field.text}</Typography>
              <Tooltip title="新增聯絡人">
                <IconButton onClick={field.onClick}>
                  <PersonAddIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </DividerText>
          </Grid>
        );
      } else {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider /></Grid>
      }
    }
  }

  function _getContent() {
    // setLoading(true);
    const contactSubFields = ['contactName', 'contactPhone', 'contactMobile', 'contactNote']
    let err = {}
    for (let field of fields) {
      if (field.required && supplierData[field.name] === '') {
        err[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
      }
    }
    let newData = supplierData
    if (Object.keys(err).length > 0) {
      newData = { ...supplierData, ...err }
      setSupplierData(newData)
    }
    for (let field of fields) {
      if (field.name === 'contacts') {
        // 檢查 contacts 欄位的 err
        for (let contact of newData.contacts) {
          for (const f of contactSubFields) {
            if (contact[`${f}_err`] !== undefined && contact[`${f}_err`] !== '') {
              return
            }
          }
        }
      } else {
        if (newData[`${field.name}_err`] !== undefined && newData[`${field.name}_err`] !== '') {
          return
        }
      }
    }
    let data = {}
    for (let field of _fields) {
      if (field.type === '-') continue
      data[field.name] = newData[field.name]
    }
    data.basicPurchaseAmount = data.basicPurchaseAmount ? parseInt(data.basicPurchaseAmount) : 0
    data.freeShippingAmount = data.freeShippingAmount ? parseInt(data.freeShippingAmount) : 0
    data.shippingOut = data.shippingOut.reduce((acc, cur) => {acc[cur.id] = true; return acc;}, {})
    data.contacts = data.contacts.filter(c => c.contactName !== '' || c.contactPhone !== '' || c.contactMobile !== '' || c.contactNote !== '') // 儲存 contacts 欄位, 去掉4欄都沒有資料的
    return { ...data }
  }

  return (
    <div style={{ flexGrow: 1 }}>
      {openSupplierDialog && <SelectMultiSupplierDialog
        defaultSelectedItems={supplierData.shippingOut}
        handleClose={() => setOpenSupplierDialog(false)}
        handleSave={onSupplierChanged}
        ignoreIds={[]}
      />}
      <Grid container spacing={3}>
        {fields.map(field => createField(field))}
      </Grid>
    </div>
  );
})

EditNewSupplierView.displayName = 'EditNewSupplierView'

EditNewSupplierView.propTypes = {
  supplier: PropTypes.object,
  ignoreFields: PropTypes.arrayOf(PropTypes.string),
};

export default EditNewSupplierView;
