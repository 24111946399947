import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import EnhancedTable from '../../components/EnhancedTable';
import SimpleTableToolbar from '../../components/SimpleTableToolbar';
import { unwrap } from '../../modules/uitls';

function SalesBonusView({ tableId, productMapping, userMapping, yearMonth, customerName }) {
  const { formatMessage } = useIntl()
  const [salesBounsData, setSalesBounsData] = useState([]);
  const [serviceBounsData, setServiceBounsData] = useState([]);
  const [selectedSalesRep, setSelectedSalesRep] = useState('');
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const reduceData = salesBounsData.concat(serviceBounsData).reduce((acc, cur) => {
    const key = cur.assistant || cur.salesRepId
    if (!acc[key]) {
      acc[key] = true
    } else {
      acc = {
        ...acc,
        [key]: true
      }
    }
    return acc
  }, {})
  const salesReps = Object.keys(reduceData).reduce((acc, cur) => {
    acc.push(cur)
    return acc
  }, [])

  useEffect(() => {
    const onSnapshot = snapshot => {
      const salesBounsList = []
      const salesBounsData = unwrap(snapshot.data())
      for (const key of Object.keys(salesBounsData.salesBounsList)) {
        salesBounsList.push({
          ...salesBounsData.salesBounsList[key],
          id: key
        })
      }
      setSalesBounsData(salesBounsList)
      const serviceBounsList = []
      const serviceBounsData = unwrap(snapshot.data())
      for (const key of Object.keys(serviceBounsData.serviceBounsList)) {
        serviceBounsList.push({
          ...serviceBounsData.serviceBounsList[key],
          id: key
        })
      }
      setServiceBounsData(serviceBounsList)
    }
    const unsubscribe = firebase.firestore().collection('financeData').doc(tableId).onSnapshot(onSnapshot, err => { })
    return () => unsubscribe()
  }, [tableId]);

  const headerCells = [
    { field: 'purchaseOrderDate' },
    { field: 'purchaseOrderId' },
    { field: 'patientName' },
    { field: 'salesRepName' },
    { field: 'productCode' },
    { field: 'productName' },
    { field: 'quantity' },
    { field: 'price' },
    { field: 'bonusA' },
    { field: 'bonusB' },
    { field: 'bonusC' },
    { field: 'commission' }
  ].map(c => { c.text = formatMessage({ id: `salesBonus.detail.${c.field}` }); return c })

  const rowCells = [
    { field: 'purchaseOrderDate' },
    { field: 'purchaseOrderId' },
    { field: 'patientName' },
    { field: 'salesRepName' },
    { field: 'productCode' },
    { field: 'productName' },
    { field: 'quantity' },
    { field: 'price' },
    { field: 'bonusA' },
    { field: 'bonusB' },
    { field: 'bonusC' },
    { field: 'commission' }
  ]

  const serviceHeaderCells = [
    { field: 'kardexDate' },
    { field: 'kardexId' },
    { field: 'patientName' },
    { field: 'productCode' },
    { field: 'productName' },
    { field: 'quantity' },
    { field: 'price' },
    { field: 'assistantName' },
    { field: 'operationBonus' }
  ].map(c => { c.text = formatMessage({ id: `salesBonus.detail.${c.field}` }); return c })

  const serviceRowCells = [
    { field: 'kardexDate' },
    { field: 'kardexId' },
    { field: 'patientName' },
    { field: 'productCode' },
    { field: 'productName' },
    { field: 'quantity' },
    { field: 'price' },
    { field: 'assistantName' },
    { field: 'operationBonus' }
  ]

  function formatSalesBounsData(data) {
    return {
      ...data,
      productCode: productMapping[data.productId]?.code ?? '',
      productName: productMapping[data.productId]?.name ?? '',
      salesRepName: userMapping[data.salesRepId] ?? '',
    }
  }

  function formatServiceBounsData(data) {
    return {
      ...data,
      productCode: productMapping[data.productId]?.code ?? '',
      productName: productMapping[data.productId]?.name ?? '',
      assistantName: userMapping[data.assistant] ?? '',
    }
  }

  function createCsv(rows, fieldName) {
    const content = rows.join('\n')
    const csvData = new Blob(['\uFEFF' + content], { type: 'text/csv' })
    const csvUrl = URL.createObjectURL(csvData)
    let aExport = document.createElement('a')
    aExport.href = csvUrl
    aExport.target = '_blank'
    aExport.download = fieldName
    aExport.click()
    aExport = null
  }

  async function exportData(table) {
    const fields = table === 'salesBonus' ? [
      'purchaseOrderDate',
      'purchaseOrderId',
      'patientName',
      'salesRepName',
      'productCode',
      'productName',
      'quantity',
      'price',
      'bonusA',
      'bonusB',
      'bonusC',
      'commission',
      'note'
    ] : [
      'kardexDate',
      'kardexId',
      'patientName',
      'productCode',
      'productName',
      'quantity',
      'price',
      'assistantName',
      'operationBonus'
    ]

    const rows = ['#,' + fields.map(i => formatMessage({ id: `salesBonus.detail.${i}` })).join(',')]
    const list = table === 'salesBonus' ? salesBounsData.map(i => formatSalesBounsData(i)) : serviceBounsData.map(i => formatServiceBounsData(i))
    for (const idx in list) {
      const item = list[idx]
      const rowData = [String(parseInt(idx) + 1)]
      for (const field of fields) {
        rowData.push(item[field])
      }
      rows.push(rowData.join(','))
    }

    const fieldName = `${yearMonth}_${customerName}_${table === 'salesBonus' ? '銷售獎金' : '操作獎金'}.csv`
    createCsv(rows, fieldName)
  }

  function exportPersonalData() {
    const currentSalesData = salesBounsData.filter(s => s.salesRepId === selectedSalesRep)
    const currentServiceData = serviceBounsData.filter(s => s.assistant === selectedSalesRep)
    let totalData = {
      bonusA: 0,
      bonusB: 0,
      bonusC: 0,
      commission: 0,
      operationBonus: 0,
      total: 0
    }
    const rows = []
    const purchaseRows = headerCells.reduce((acc, cur) => {
      if (cur.field !== 'salesRepName') {
        acc.push(cur.text)
      }
      return acc
    }, [])

    const kardexRows = serviceHeaderCells.reduce((acc, cur) => {
      if (cur.field !== 'assistantName') {
        acc.push(cur.text)
      }
      return acc
    }, [])

    rows.push(purchaseRows.join(','))
    for (const s of currentSalesData) {
      totalData.bonusA += s.bonusA
      totalData.bonusB += s.bonusB
      totalData.bonusC += s.bonusC
      totalData.commission += s.commission
      totalData.total += s.bonusA + s.bonusB + s.bonusC + s.commission
      rows.push([
        s.purchaseOrderDate,
        s.purchaseOrderId,
        s.patientName,
        productMapping[s.productId].code,
        productMapping[s.productId].name,
        s.quantity,
        s.price,
        s.bonusA,
        s.bonusB,
        s.bonusC,
        s.commission
      ])
    }

    rows.push([' '])
    rows.push(kardexRows.join(','))

    for (const s of currentServiceData) {
      totalData.operationBonus += s.operationBonus
      totalData.total += s.operationBonus
      rows.push([
        s.kardexDate,
        s.kardexId,
        s.patientName,
        productMapping[s.productId].code,
        productMapping[s.productId].name,
        s.quantity,
        s.price,
        s.operationBonus
      ])
    }

    const totalHeaderRows = ['typeA', 'typeB', 'typeC', 'commission', 'operationBonus', 'allTotal'].reduce((acc, cur) => {
      acc.push(formatMessage({ id: `salesBonus.detail.total.${cur}` }))
      return acc
    }, [])

    rows.push([' '])
    rows.push(['', '', '', '', ''].concat(totalHeaderRows).join(','))

    rows.push([
      '', '', '', '', '',
      totalData.bonusA,
      totalData.bonusB,
      totalData.bonusC,
      totalData.commission,
      totalData.operationBonus,
      totalData.total,
    ])

    const fieldName = `${yearMonth}_${userMapping[selectedSalesRep]}_個人獎金.csv`
    createCsv(rows, fieldName)
  };

  const handleMenuItemClick = (event, key) => {
    setSelectedSalesRep(key);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div style={{ flexGrow: 1, height: '100%' }}>
      <Box p={0}>
        <SimpleTableToolbar
          title="salesBonus.list.salesBonus"
        />
        <EnhancedTable
          size="small"
          defaultOrder="asc"
          defaultOrderField="code"
          headerCells={headerCells}
          rowCells={rowCells}
          tableData={salesBounsData.map(i => formatSalesBounsData(i))}
        />
        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item key="buttons" xs={12} sm={12} md={12}>
            <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
              <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                <Button onClick={() => exportPersonalData()} disabled={selectedSalesRep === ''}>
                  {formatMessage({ id: 'salesBonus.detail.personalBonus' }, { name: userMapping[selectedSalesRep] })}
                </Button>
                <Button
                  size="small"
                  aria-controls={open ? 'split-button-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu">
                          {salesReps.map(salesRep => (
                            <MenuItem
                              key={salesRep}
                              selected={salesRep === selectedSalesRep}
                              onClick={(event) => handleMenuItemClick(event, salesRep)}
                            >
                              {userMapping[salesRep]}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              <LoadingButton
                color="primary"
                onClick={() => exportData('salesBonus')}
                // disabled={loadingVoid}
                // loading={loadingVoid}
                loadingPosition="start"
                loadingIndicator={<CircularProgress size={24} />}
                startIcon={<div />}
                variant="contained"
              >
                <FormattedMessage id="button.export" />
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>

        <SimpleTableToolbar
          title="salesBonus.list.serviceBonus"
        />
        <EnhancedTable
          size="small"
          defaultOrder="asc"
          defaultOrderField="code"
          headerCells={serviceHeaderCells}
          rowCells={serviceRowCells}
          tableData={serviceBounsData.map(i => formatServiceBounsData(i))}
        />
        <Divider style={{ margin: '8px 0px' }} />
        <Grid container spacing={1}>
          <Grid item key="buttons" xs={12} sm={12} md={12}>
            <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end' }}>
              <LoadingButton
                color="primary"
                onClick={() => exportData('serviceBonus')}
                // disabled={loadingVoid}
                // loading={loadingVoid}
                loadingPosition="start"
                loadingIndicator={<CircularProgress size={24} />}
                startIcon={<div />}
                variant="contained"
              >
                <FormattedMessage id="button.export" />
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>

      </Box>
    </div>
  );
}

SalesBonusView.propTypes = {
  tableId: PropTypes.string.isRequired,
  productMapping: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  yearMonth: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
};

export default SalesBonusView;
