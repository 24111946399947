import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Collapse from '@mui/material/Collapse';

import EnhancedTable from '../../components/EnhancedTable';
import SimpleTableToolbar from '../../components/SimpleTableToolbar';
import SearchBox from '../../components/SearchBox';
import ExpandButton from '../../components/ExpandButton';
import SupplierInfoDiablog from '../../components/SupplierInfoDiablog';
import PurchaseOrderView from './PurchaseOrderView';
import { useFirestoreData, useFirestoreDataAndMapping, useSupplierDataAndMapping } from '../../modules/uitls';

function PurchaseOrderDetail({ userMapping, purchaseOrder, ...props }) {
  const r = { ...purchaseOrder }
  const keys = purchaseOrder.merchandises ? Object.keys(purchaseOrder.merchandises) : []
  const extraKeys = purchaseOrder.extraMerchandises ? Object.keys(purchaseOrder.extraMerchandises) : []

  r.merchandises = keys.map(k => ({ ...r.merchandises[k], id: k, eid: k }))
    .concat(extraKeys.map(k => ({ ...r.extraMerchandises[k], unitPrice: 0, id: k, eid: `${k}@extra` })))

  // r.createdBy = {id: r.createdBy, name: userMapping[r.createdBy]?.displayName}
  return (
    <div style={{ padding: 15 }}>
      <PurchaseOrderView userMapping={userMapping} purchaseOrder={r} {...props} />
    </div>
  )
}

PurchaseOrderDetail.propTypes = {
  purchaseOrder: PropTypes.object.isRequired,
  userMapping: PropTypes.object.isRequired,
  merchandiseMapping: PropTypes.object.isRequired,
};

function PurchaseOrderList({ purchaseOrders, title }) {
  const { formatMessage } = useIntl()
  const [userMapping] = useFirestoreDataAndMapping('users')
  const [supplierMapping] = useSupplierDataAndMapping()
  const [customerMapping] = useFirestoreDataAndMapping('customers')
  const [merchandiseMapping] = useFirestoreDataAndMapping('merchandises')
  const sourceMapping = useFirestoreData('suppliers').reduce((acc, cur) => {acc[cur.id] = cur; return acc;}, {})

  const [currentFilter, setCurrentFilter] = useState(null)
  const [supplierInfo, setSupplierInfo] = useState(null);
  const [expand, setExpand] = useState(true);
  const filteredPurchaseOrders = currentFilter && currentFilter.text ? filterByText() : purchaseOrders

  const billRuleMapping = {
    '30': formatMessage({ id: 'supplier.billRule.rule1' }),
    '60': formatMessage({ id: 'supplier.billRule.rule2' }),
    '90': formatMessage({ id: 'supplier.billRule.rule3' }),
    'cashOnDelivery': formatMessage({ id: 'supplier.billRule.rule4' }),
    'ttInAdvance': formatMessage({ id: 'supplier.billRule.rule5' }),
  }

  const headerCells = [
    { text: 'sn', sort: 'sn' },
    { text: 'source', sort: 'source' },
    { text: 'createdBy', sort: 'createdBy' },
    { text: 'date', sort: 'createdAt' },
    { text: 'expectedDate', sort: 'expectedDate' },
    { text: 'supplier', sort: 'supplier' },
  ].map(c => {c.text = formatMessage({ id: `purchaseOrder.table.header.${c.text}` });return c})

  const rowCells = [
    { field: 'sn' },
    { field: 'sourceName' },
    { field: 'createdBy' },
    { field: 'date' },
    { field: 'expectedDate' },
    { field: 'supplier', type: 'info', onButtonClick: showSupplierInfo },
  ]

  function filterByText() {
    const lowerCaseText = currentFilter.text.toLowerCase()
    if (currentFilter.name === 'sourceName') {
      return purchaseOrders.filter(s => customerMapping[s.source]?.nickname.toLowerCase().includes(lowerCaseText))
    } else if (currentFilter.name === 'createdBy') {
      return purchaseOrders.filter(s => userMapping[s.createdBy]?.displayName.toLowerCase().includes(lowerCaseText))
    } else if (currentFilter.name === 'supplier') {
      return purchaseOrders.filter(s => supplierMapping[s.supplier]?.nickname.toLowerCase().includes(lowerCaseText))
    } else if (currentFilter.name === 'merchandiseName') {
      return purchaseOrders.filter(s => Object.keys(s.merchandises || {}).filter(c => merchandiseMapping[c])
        .filter(c => merchandiseMapping[c].name.toLowerCase().includes(lowerCaseText) ||
          merchandiseMapping[c].nickname.toLowerCase().includes(lowerCaseText)).length)
    } else if (currentFilter.name === 'merchandiseCode') {
      return purchaseOrders.filter(s => Object.keys(s.merchandises || {}).filter(c => merchandiseMapping[c])
        .filter(c => merchandiseMapping[c].code.toLowerCase().includes(lowerCaseText)).length)
    } else {
      return purchaseOrders.filter(s => s[currentFilter.name].toLowerCase().includes(lowerCaseText))
    }
  }

  function showSupplierInfo(purchaseOrderId, field, purchaseOrder) {
    setSupplierInfo(purchaseOrder.supplierData)
  }

  const formatData = (purchaseOrder) => {
    const newData = { ...purchaseOrder }
    newData.sourceName = sourceMapping[newData.source]?.nickname
    newData.sourceFullName = sourceMapping[newData.source]?.name
    newData.createdBy = userMapping[newData.createdBy]?.displayName

    const supplier = supplierMapping[newData.supplier]
    newData.billRule = supplier ? billRuleMapping[supplier.billRule] : ''
    newData.supplierFullName = supplier ? supplier.name : ''
    newData.supplierPhone = supplier && supplier.contacts.length ? supplier.contacts[0].contactPhone.replace(/\s/g, '').replace(/#$/,'') : ''
    newData.supplierContactName = supplier && supplier.contacts.length ? supplier.contacts[0].contactName : ''
    newData.supplierData = supplier ? supplier : null
    newData.supplier = supplier ? supplier.nickname : ''

    newData.address = customerMapping[newData.source]?.address
    newData.phone = customerMapping[newData.source]?.phone.replace(/\s/g, '').replace(/#$/,'')
    newData.businessNumber = customerMapping[newData.source]?.businessNumber
    const shipping = customerMapping[newData.shipping || newData.source]
    newData.shippingAddress = shipping?.address ?? ''
    newData.contactPhone = shipping && shipping.contacts.length ? shipping.contacts[0].contactPhone.replace(/\s/g, '').replace(/#$/,'') : ''
    newData.contactName = shipping && shipping.contacts.length ? shipping.contacts[0].contactName : ''
    newData.shipping = shipping?.nickname ?? ''

    return newData
  }

  const filterItems = [
    { name: 'sn' },
    { name: 'sourceName', type: 'customer' },
    { name: 'createdBy' },
    { name: 'supplier' },
    { name: 'date' },
    { name: 'merchandiseName' },
    { name: 'merchandiseCode' },
    { name: 'note' },
  ].map(i => {i.text = formatMessage({ id: `purchaseOrder.table.detail.${i.name}` });return i})

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  return (
    <div>
      {supplierInfo && <SupplierInfoDiablog
        supplier={supplierInfo}
        onClose={() => setSupplierInfo(null)}
      />}
      <SimpleTableToolbar
        title={title}
        buttons={<ExpandButton open={expand} onExpandChange={setExpand} />}
        toolbox={<SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} />}
      />
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <EnhancedTable
          defaultOrder="desc"
          defaultOrderField="sn"
          headerCells={headerCells}
          rowCells={rowCells}
          getExpandContent={purchaseOrder =>
            <PurchaseOrderDetail
              userMapping={userMapping}
              merchandiseMapping={merchandiseMapping}
              purchaseOrder={purchaseOrder}
            />
          }
          tableData={filteredPurchaseOrders.map(p => formatData(p))}
        />
      </Collapse>
    </div>
  );
}

PurchaseOrderList.propTypes = {
  purchaseOrders: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  title: PropTypes.string.isRequired,
};

export default PurchaseOrderList;
