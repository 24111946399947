import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material/styles';

import TabContainer from '../../containers/TabContainer/TabContainer';
import ContextStore from '../../modules/context';
import FabAdd from '../../components/FabAdd';
import StockRequisitionTab from './StockRequisitionTab';

const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
}));

function StockRequisition() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const [activeTabIndex, setActiveTabIndex] = useState(undefined)
  const { tabName } = useParams()

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.stock.stockRequisition' })
    }])
    const pathname = location.pathname
    if (pathname === '/stock/stockRequisition/pending' && activeTabIndex !== 0) {
      setActiveTabIndex(0)
    } else if (pathname === '/stock/stockRequisition/done' && activeTabIndex !== 1) {
      setActiveTabIndex(1)
    } else if (pathname === '/stock/stockRequisition/void' && activeTabIndex !== 2) {
      setActiveTabIndex(2)
    }
    return () => {
    };
  }, [location.pathname]);

  const onTabSelected = (tabIndex) => {
    if (tabIndex === 0) {
      navigate('/stock/stockRequisition/pending');
    } else if (tabIndex === 1) {
      navigate('/stock/stockRequisition/done');
    } else {
      navigate('/stock/stockRequisition/void');
    }
  };

  const tabs = [
    { label: formatMessage({ id: 'stockRequisition.tab.pending' }), component: <StockRequisitionTab /> },
    { label: formatMessage({ id: 'stockRequisition.tab.done' }), component: <StockRequisitionTab /> },
    { label: formatMessage({ id: 'stockRequisition.tab.void' }), component: <StockRequisitionTab /> },
  ]
  const defaultSelect = tabName === 'void' ? 2 : (tabName === 'done' ? 1 : 0)

  return (
    <Root>
      {currentUser.userRights['stockRequisition-create'] === true && <FabAdd to="/stock/stockRequisition/edit/new" />}
      <TabContainer activeTabIndex={activeTabIndex} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} />
    </Root>
  );
}

export default StockRequisition;
