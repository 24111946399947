import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import TabContainer from '../../containers/TabContainer/TabContainer';
import ShoppingCart from './ShoppingCart';
import ContextStore from '../../modules/context';
import { useFirestoreDataAndMapping } from '../../modules/uitls';

const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
}));

function ShoppingCartList() {
  const { setBreadcrumbs } = useContext(ContextStore)
  const location = useLocation()
  const shoppingCart = useSelector(state => state.firestore.data.shoppingCart) || {}
  // const merchandises = Object.keys(shoppingCart).map(i => ({...(shoppingCart[i]), key: i}))
  // issue: when delete doc from shoppingCart, we will get a EMPTY doc like this {key: XXXXXXXXXXX}
  // workaround: filter docs by source field
  const merchandises = Object.keys(shoppingCart).map(i => {
    const maxAmount = (shoppingCart[i]?.purchaseOrders ?
      (Object.keys(shoppingCart[i].purchaseOrders).reduce((acc, cur) => acc - shoppingCart[i].purchaseOrders[cur], shoppingCart[i].amount)) :
      shoppingCart[i]?.amount ?? 0)
    return {
      ...(shoppingCart[i]),
      amount: maxAmount,
      maxAmount,
      key: i
    }
  }).filter(i => i.source)
  const [customerMapping] = useFirestoreDataAndMapping('customers')
  const customers = [...new Set(merchandises.map(i => i.source))]

  useEffect(() => {
    setBreadcrumbs([{
      text: '採購清單'
    }])
    return () => {
    };
  }, [location.pathname]);

  const onTabSelected = (tabIndex) => {
  };

  // NEED FIX: 把購物車清空, 會發生 value > tabs.length -1 的情形, 產生一個 error.
  const tabs = customers.map(c => ({
    label: customerMapping[c].nickname,
    component: <ShoppingCart
      source={c}
      ignoreSuppliers={[c]}
      highPrioritySuppliers={customerMapping[c].highPrioritySuppliers || []}
      merchandises={merchandises.filter(i => i.source === c).reduce((acc, cur) => {acc[cur.key] = cur;return acc;}, {})}
    />,
  }))

  return (
    <Root>
      {customers.length ?
        <TabContainer tabs={tabs} onTabSelected={onTabSelected} /> :
        <Box p={2}>
          <Typography variant="h6" id="tableTitle" component="div">
            <FormattedMessage id="shoppingCartList.empty" />
          </Typography>
        </Box>
      }
    </Root>
  );
}

export default ShoppingCartList;
