import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import MuiFab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

const Fab = styled(MuiFab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}));

export default function FabAdd({ to, onClick }) {
  if (to) {
    return <Link to={to}>
      <Fab color="primary">
        <AddIcon />
      </Fab>
    </Link>
  } else {
    return <Fab color="primary" onClick={onClick}>
      <AddIcon />
    </Fab>
  }
};

FabAdd.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func
};
