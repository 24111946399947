const i18n = {
  'arrangeStockForm': {
    'name': '庫存調撥單',
    'table': {
      'title': '庫存調撥單列表',
      'selected': '已選擇 {count} 筆庫存調撥單',
      'header': {
        'sn': '調撥單號',
        'source': '調撥單位',
        'sourceName': '調撥單位',
        'createdBy': '調撥人',
        'createdAt': '調撥日期',
        'date': '調撥日期',
      },
      'detail': {
        'sn': '調撥單號',
        'source': '調撥單位',
        'sourceName': '調撥單位',
        'createdBy': '調撥人',
        'createdAt': '調撥日期',
        'note': '備註',
        'merchandise': '商品',
        'merchandiseName': '商品名稱',
        'merchandiseCode': '商品品號',
      },
    },
    'dialog': {
      'title': {
        'add': '新增庫存調撥單',
        // 'edit': '編輯庫存調撥單',
      }
    },
    'merchandise': {
      'purchaseOrderSn': '採購單號',
      'code': '品號',
      'name': '品名',
      'unitPrice': '單價',
      'amount': '數量',
      'orderUnit': '單位',
      'sku': '單位',
      'price': '金額',
      'lotNumber': '批號',
      'expectedDate': '效期',
      'statusText': '狀態',
      'note': '備註',
      'wareroom': '倉庫',
      'arrangementAmount': '調撥數量',
      'targetWareroom': '目標倉庫',
      'stock': '即時庫存'
    }
  },
  'editArrangeStockForm': {
    'table': {
      'title': '商品清單',
      'selected': '已選擇 {count} 個項目',
    },
    'addMerchandise': '新增商品',
    'removeMerchandise': '移除商品',
    'selectStockItems': '選擇庫存',
    'addArrangement': '設定調撥內容',
  }
}
export default i18n
