import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import firebase from 'firebase/app';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import ContextStore from '../../modules/context';
import { unwrap, lockSubmitButton, useFirestoreDataAndMapping } from '../../modules/uitls';
import EditNewMedicineView from '../../components/EditNewMedicineView';

function EditMedicinePage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const urMedicineCreate = currentUser.userRights['medicine-create']
  const urMedicineEdit = currentUser.userRights['medicine-edit']

  const childRef = useRef()
  const { medicineId } = useParams()
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(medicineId === 'new' ? true : false);
  const [merchandiseMapping] = useFirestoreDataAndMapping('merchandises')
  const [medicineData, setMedicineData] = useState({
    id: medicineId,
    category: '',
    name: '',
    unit: '', // stock keeping unit
    controlledDrug: false,
    cost: '',
    effect: '',
    appearance: '',
    customers: {},
    note: '',
    merchandises: []
  });

  useEffect(() => {
    const redirect = medicineId === 'new' ? urMedicineCreate !== true : urMedicineEdit !== true

    if (redirect) {
      navigate('/');
    }
  }, [urMedicineCreate, urMedicineEdit]);

  useEffect(() => {
    const breadcrumbs = [{
      link: '/medicine',
      text: formatMessage({ id: 'sideMenu.medicine' })
    }]
    if (medicineId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: 'medicine.dialog.title.add' }) })
    } else {
      breadcrumbs.push({ text: formatMessage({ id: 'medicine.dialog.title.edit' }) })
    }
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname]);

  useEffect(() => {
    if (medicineId !== 'new') { // edit
      const unsubscribe = firebase.firestore().collection('medicines').doc(medicineId)
        .onSnapshot(snapshot => {
          const data = unwrap(snapshot.data())
          if (!data) {
            navigate('/medicine');
            return
          }


          setMedicineData({
            id: medicineId,
            category: data.category,
            name: data.name,
            unit: data.unit,
            controlledDrug: data.controlledDrug,
            cost: data.cost,
            effect: data.effect,
            appearance: data.appearance,
            note: data.note,
            customers: data.customers,
            merchandises: Object.keys(data.merchandises || {}).map(k => ({
              id: k,
              amount: data.merchandises[k].amount,
              name: merchandiseMapping[k].name,
              code: merchandiseMapping[k].code,
              sku: merchandiseMapping[k].sku
            }))
          })

          setReady(true)
        }, err => {})
      return () => unsubscribe()
    } else {
      return () => {};
    }
  }, []);

  const handleSave = async () => {
    lockSubmitButton(true)
    setLoading(true);
    const data = childRef.current.getContent()
    if (data) {
      try {
        await (firebase.functions().httpsCallable('saveMedicine'))({ id: medicineData.id, ...data })
      } catch (ex) {
        console.log(ex)
      }
      lockSubmitButton(false)
      handleClose()
    } else {
      lockSubmitButton(false)
      setLoading(false);
    }
  }

  const handleClose = () => {
    navigate('/medicine');
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <div style={{ padding: '16px 16px 64px 16px ', minHeight: 'calc(100vh - 64px)', overflow: 'scroll', position: 'relative' }}>
        {medicineId !== 'new' && <>
          <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
            <FormattedMessage id="editMedicine.title" />
          </Typography>
          <Divider style={{ margin: '12px 0px' }} />
        </>}
        {ready && <EditNewMedicineView
          ref={childRef}
          medicine={medicineData}
          requiredFields={['name', 'category']}
          disabledFields={medicineId !== 'new' ? ['category'] : []}
        />}
        <Stack spacing={1} direction="row" sx={{ justifyContent: 'flex-end', position: 'absolute', bottom: '16px', right: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            disabled={loading}
            loading={loading}
            loadingPosition="start"
            loadingIndicator={<CircularProgress size={24} />}
            startIcon={<div />}
            variant="contained"
          >
            <FormattedMessage id="button.save" />
          </LoadingButton>
        </Stack>
      </div>
    </div>
  );
}

export default EditMedicinePage;
